import { useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';

export function useIsPc() {
  const [isPc, setIsPc] = useState(false);
  const pc = useMediaQuery({ query: '(min-width:1024px)' });

  useEffect(() => {
    setIsPc(pc);
  }, [pc]);

  return isPc;
}

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: '(max-width:1023px)' });

  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);

  return isMobile;
}

export function useIsMobileSmall() {
  const [isMobile, setIsMobile] = useState(false);
  const mobile = useMediaQuery({ query: '(max-width:425px)' });

  useEffect(() => {
    setIsMobile(mobile);
  }, [mobile]);

  return isMobile;
}
