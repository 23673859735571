/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from 'static/cs/back.svg';

interface NewsDetailData {
  title: string;
  content: any;
  date: string;
}

interface NewsDetailProps {
  data: NewsDetailData;
}

const NewsDetail = ({ data }: NewsDetailProps) => {
  const { mq } = useTheme();
  const navigator = useNavigate();

  const NewsDetailStyle = css`
    display: flex;
    flex-direction: column;
    width: 1000px;
    .back {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    .title {
      margin-top: 24px;
      position: relative;
      box-sizing: border-box;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      display: flex;
      align-items: center;
      gap: 12px;
      .badge {
        border-radius: 3px;
        padding: 5px 10px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        background: #6079ff;
        color: #fff;
      }
    }
    .date {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      color: #98a2b3;
    }
    .content {
      margin-top: 24px;
      border-top: 1px solid #f2f4f7;
      padding-top: 24px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      width: 100%;
      table,
      p,
      img {
        max-width: 100%;
      }
    }

    .btnWrap {
      margin-top: 24px;
      padding: 24px 0;
      border-top: 1px solid #f2f4f7;
      button {
        svg {
          width: 20px;
        }
        background: #f2f4f7;
        display: flex;
        align-items: center;
        gap: 6px;
        border: 0;
        border-radius: 4px;
        color: #667085;
        padding: 7px 12px;
        cursor: pointer;
        font-weight: bold;
      }
    }

    ${mq.mobile} {
      width: 100%;
      .title {
        padding-left: 0;
        margin-top: 0;
        font-size: 24px;
        line-height: 36px;
        flex-direction: column;
        align-items: start;
        &::after {
          width: 30px;
          height: 30px;
          font-size: 18px;
          font-weight: 700;
          line-height: 30px;
          left: 0;
          top: 0;
          border-radius: 3px;
        }
      }
      .content {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
    }
  `;
  return (
    <div css={NewsDetailStyle}>
      <h3 className="title">
        <span className="badge">중요</span>
        {data.title}
      </h3>
      <p className="date">{data.date}</p>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
      <div className="btnWrap">
        <button onClick={() => navigator(-1)}>
          <Back />
          목록으로 돌아가기
        </button>
      </div>
    </div>
  );
};

export default NewsDetail;
