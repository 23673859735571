import axios from 'core/config/axios';
import useAxiosAuth from 'hooks/useAxiosAuth';
import { useMutation } from 'react-query';
import {
  LoginBody,
  CheckEmailBody,
  SendChangePwBody,
  CheckChangePwBody,
  ChangePwBody,
} from './type';

export const useLogin = () =>
  useMutation(async (form: LoginBody) => {
    const { data } = await axios.post(`/login`, JSON.stringify(form), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return data;
  });

export const useLogout = () => {
  const axiosAuth = useAxiosAuth();
  return useMutation(async () => {
    const { data } = await axiosAuth.post('/user/logout');
    return data;
  });
};

export const useFetchAccessCheck = () =>
  useMutation(async (refreshToken: string) => {
    const { data } = await axios.post('/common/token/refresh', {
      refreshToken,
    });
    return data;
  });

export const useCheckExist = () =>
  useMutation(async (form: CheckEmailBody) => {
    const { data } = await axios.post(`/user/users/exist`, form);
    return data;
  });

export const useSendChangeMail = () =>
  useMutation(async (form: SendChangePwBody) => {
    const { data } = await axios.post(`/my-mallpie/email/change-pw/send`, form);
    return data;
  });

export const useCheckChangePw = () =>
  useMutation(async (form: CheckChangePwBody) => {
    const { data } = await axios.post(
      `/my-mallpie/email/change-pw/check`,
      form,
    );
    return data;
  });

export const useChangePw = () =>
  useMutation(async (form: ChangePwBody) => {
    const { data } = await axios.put(`/user/email/change-pw`, form);
    return data;
  });
