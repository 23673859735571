/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import bg from 'static/revenue/bg.png';

import { ReactComponent as Step1 } from 'static/revenue/step1-1.svg';
import { ReactComponent as Step2 } from 'static/revenue/step1-2.svg';
import { ReactComponent as Step3 } from 'static/revenue/step2.svg';
import { ReactComponent as Step4 } from 'static/revenue/step3.svg';
import { ReactComponent as Step5 } from 'static/revenue/step4.svg';
import { ReactComponent as ArrowDown } from 'static/revenue/arrow_down.svg';

const Step = () => {
  const { mq } = useTheme();

  const stepStyle = css`
    background-color: #282828;
    padding: 140px 0px;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    > .title {
      font-size: 56px;
      font-style: normal;
      font-weight: 800;
      line-height: 76px;
      color: #fff;
      text-align: center;
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 48px;
      gap: 48px;
      li {
        width: 603px;
        padding: 24px 32px;
        background: #2f2f2f;
        border-radius: 8px;
        display: flex;
        position: relative;
        border: 1px solid #555;
        .img {
          width: 32px;
          svg {
            width: 100%;
          }
        }
        .text {
          margin-left: 32px;
          .sub {
            font-size: 16px;
            color: #6079ff;
            font-weight: 600;
            line-height: 24px;
          }
          .title {
            margin-top: 4px;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            color: #fff;
          }
        }
        .arrow {
          position: absolute;
          height: 32px;
          bottom: -40px;
          left: 50%;
          transform: translateX(-50%);
        }
        &.on {
          background: #6079ff;
          .text {
            .sub {
              color: #fff;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      padding: 96px 20px;
      background-size: calc(100% + 800px);
      background-position: right bottom;
      > .title {
        font-size: 28px;
        font-style: normal;
        line-height: 40px;
      }
      ul {
        margin-top: 28px;
        gap: 36px;
        li {
          width: 100%;
          padding: 24px 20px;
          .img {
            width: 16px;
          }
          .text {
            margin-left: 10px;
            .sub {
              font-size: 14px;
              line-height: 21px;
            }
            .title {
              margin-top: 4px;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color: #fff;
            }
          }
          .arrow {
            position: absolute;
            width: 20px;
            height: 20px;
            overflow: hidden;
            bottom: -28px;
            left: 50%;
            transform: translateX(-50%);
            svg {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={stepStyle}>
      <h2 className="title">
        한 눈에 확인하는
        <br />
        수익 실현 단계
      </h2>
      <ul>
        <li>
          <div className="img">
            <Step1 />
          </div>
          <div className="text">
            <p className="sub">STEP. 1</p>
            <h3 className="title">쇼핑몰 디자인 설정 & 상품 진열</h3>
          </div>
          <div className="arrow">
            <ArrowDown />
          </div>
        </li>
        <li>
          <div className="img">
            <Step2 />
          </div>
          <div className="text">
            <p className="sub">STEP. 2</p>
            <h3 className="title">개별 또는, SNS를 통한 쇼핑몰 링크 공유</h3>
          </div>
          <div className="arrow">
            <ArrowDown />
          </div>
        </li>
        <li>
          <div className="img">
            <Step3 />
          </div>
          <div className="text">
            <p className="sub">STEP. 3</p>
            <h3 className="title">쇼핑몰에서 상품 판매</h3>
          </div>
          <div className="arrow">
            <ArrowDown />
          </div>
        </li>
        <li>
          <div className="img">
            <Step4 />
          </div>
          <div className="text">
            <p className="sub">STEP. 4</p>
            <h3 className="title">판매된 상품별 수익금, 예치금으로 적립</h3>
          </div>
          <div className="arrow">
            <ArrowDown />
          </div>
        </li>
        <li className="on">
          <div className="img">
            <Step5 />
          </div>
          <div className="text">
            <p className="sub">STEP. 5</p>
            <h3 className="title">정산 기준 금액 도달 시, 환급 신청 가능</h3>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Step;
