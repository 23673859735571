/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeft } from 'static/cs/arrow_left.svg';

interface ListData {
  title: string;
  date?: string;
  link: string;
}

interface ListProps {
  title: string;
  more: string;
  list: ListData[];
}

const List = ({ title, more, list = [] }: ListProps) => {
  const { mq } = useTheme();
  const navigator = useNavigate();

  const ListStyle = css`
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 24px 0;
      border-bottom: 1px solid #eaeaea;
      .text {
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
      }
      .more {
        display: flex;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        align-items: center;
        gap: 10px;
        color: #667085;
        cursor: pointer;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      margin: 24px 0;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text {
          color: #545a67;
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
        }
        .date {
          color: #98a2b3;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    ${mq.mobile} {
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 20px 0;
        border-bottom: 1px solid #eaeaea;
        .text {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        margin: 20px 0;
        li {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 4px;
          .text {
            font-size: 14px;
            line-height: 20px;
          }
          .date {
            font-size: 12px;
          }
        }
      }
    }
  `;
  return (
    <div css={ListStyle}>
      <div className="title">
        <h3 className="text">{title}</h3>
        <Link to={`${more}`} className="more">
          전체보기 <ArrowLeft />
        </Link>
      </div>
      <ul className="list">
        {list?.map((v, k) => (
          <li key={k}>
            <Link to={v.link} className="text">
              {v.title}
            </Link>
            {v.date && <div className="date">{v.date}</div>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
