import { create } from 'zustand';

interface AlertStore {
  children: string | React.ReactNode;
  visible: boolean;
  btnNm: string;
  title?: string;
  icon?: React.ReactNode;
  openAlert: (data: Alert, callbackAction?: () => void) => void;
  callbackAction?: () => void;
  closeAlert: (key?: string) => void;
}

interface Alert {
  title?: string;
  children: string | React.ReactNode;
  icon?: React.ReactNode;
  btnNm: string;
}

const initialState = {
  visible: false,
  callbackAction: undefined,
};

const useAlertStore = create<AlertStore>(set => ({
  visible: false,
  btnNm: '확인',
  title: '',
  children: '',
  openAlert: (data, callbackAction) =>
    set({
      ...data,
      visible: true,
      callbackAction,
    }),
  closeAlert: () => set({ ...initialState }),
}));

export default useAlertStore;
