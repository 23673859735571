/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import list1Img from 'static/revenue/section2_list1.png';
import list2Img from 'static/revenue/section2_list2.png';
import { useIsMobile } from 'core/hooks/useResponsive';

const Section2 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const Section2Style = css`
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #fff 0%, #f9fafb 14.38%);
    .container {
      width: 1068px;
      padding-bottom: 200px;
      margin: 0 auto;
      > .title {
        color: #000;
        font-weight: 700;
        font-size: 56px;
        line-height: 76px;
        text-align: center;
        span {
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 120px;
        margin-top: 120px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          &:nth-of-type(2) {
            flex-direction: row;
          }
          .text {
            .title {
              margin-top: 10px;
              font-size: 40px;
              font-weight: 800;
              line-height: 60px; /* 150% */
              span {
                color: #6079ff;
              }
            }
            .desc {
              margin-top: 10px;
              color: #444;

              font-size: 20px;
              font-weight: 400;
              line-height: 30px; /* 150% */
              span {
                margin-top: 10px;
                font-size: 16px;
                display: block;
                color: #a3a3a3;
              }
            }
          }
          .img {
            width: 600px;
            flex-shrink: 0;
            border-radius: 32px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 40px 0px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 20px 20px 96px;
        width: 100%;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 45px; /* 140.625% */
        }
        .list {
          gap: 64px;
          margin-top: 64px;
          li {
            align-items: flex-start;
            flex-direction: column-reverse;
            &:nth-of-type(2) {
              flex-direction: column-reverse;
            }
            .text {
              padding: 0px;
              text-align: left;
              margin-bottom: 24px;
              .icon {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
              .title {
                font-size: 28px;
                line-height: 40px; /* 142.857% */
                margin-top: 0;
              }
              .desc {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const scaleVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section2Style}>
      <div className="container">
        <h2 className="title">
          또 하나의 월급 통장,
          <br />
          몰파이만의 <span>리워드 프로그램</span>
        </h2>
        <ul className="list">
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list1Img}
                alt="수익이 더 커지는 상품별 수익금 정책 | 이미지"
                title="수익이 더 커지는 상품별 수익금 정책"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                수익이 더 커지는
                <br />
                상품별 <span>수익금 정책</span>
              </h3>
              <p className="desc">
                판매된 금액의 고정된 비율이 아닌,
                <br />
                상품별 책정된 수익금으로 더 큰 수익을 <br />
                기대할 수 있어요.
              </p>
            </motion.div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list2Img}
                alt="까다로운 조건없이 1만원 달성 시, 출금가능 | 이미지"
                title="까다로운 조건없이 1만원 달성 시, 출금가능"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                까다로운 <span>조건 없이</span>
                <br />
                1만원 달성 시, 출금가능
              </h3>
              <p className="desc">
                쌓인 수익금이 10,000원 이상만 된다면 <br />
                언제든 환급 신청이 가능합니다.
                <span>*해당 출금 조건은 추후 변경될 수 있습니다. </span>
              </p>
            </motion.div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section2;
