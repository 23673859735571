/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import icon1 from 'static/myshop/style/section3_1.png';
import icon2 from 'static/myshop/style/section3_2.png';
import icon3 from 'static/myshop/style/section3_3.png';
import icon4 from 'static/myshop/style/section3_4.png';

const Section3 = () => {
  const { mq } = useTheme();

  const section3Style = css`
    text-align: center;
    padding: 110px 0 100px 0;
    .container {
      max-width: 1535px;
      padding: 0 40px;
      margin: 0 auto;

      .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .text {
        font-size: 18px;
        margin-top: 12px;
        font-weight: 200;
        line-height: 28px;
      }
      .list {
        margin-top: 69px;
        display: flex;
        justify-content: space-between;
        li {
          width: calc(25% - 15px);
          padding: 48px 0px;
          border-radius: 12px;
          img {
            width: 96px;
          }
          h3 {
            margin-top: 32px;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            white-space: pre-wrap;
          }
          &:nth-of-type(1) {
            background: rgba(86, 91, 228, 0.1);
          }
          &:nth-of-type(2) {
            background: rgba(86, 194, 228, 0.1);
          }
          &:nth-of-type(3) {
            background: rgba(228, 154, 86, 0.1);
          }
          &:nth-of-type(4) {
            background: rgba(86, 160, 228, 0.1);
          }
        }
      }
    }

    ${mq.mobile} {
      padding-top: 0;
      .container {
        padding: 0 32px;
        .title {
          font-size: 20px;
          line-height: 30px;
        }
        .text {
          font-size: 14px;
          line-height: 21px;
        }
        .list {
          margin-top: 44px;
          display: flex;
          flex-wrap: wrap;
          li {
            width: calc(50% - 8px);
            padding: 20px 0px;
            img {
              width: 50px;
            }
            h3 {
              margin-top: 6px;
              font-size: 14px;
              font-weight: 600;
              line-height: 22px;
            }
            p {
              font-size: 10px;
              font-weight: 400;
              line-height: 14px;
              padding: 0 10px;
            }
            &:nth-of-type(1) {
              background: rgba(86, 91, 228, 0.1);
            }
            &:nth-of-type(2) {
              background: rgba(86, 194, 228, 0.1);
            }
            &:nth-of-type(3) {
              background: rgba(228, 154, 86, 0.1);
              margin-top: 15px;
            }
            &:nth-of-type(4) {
              background: rgba(86, 160, 228, 0.1);
              margin-top: 15px;
            }
          }
        }
      }
    }
  `;

  const contents = [
    {
      title: '나만의 로고 설정',
      text: '쇼핑몰에 사용할 로고를\n설정할 수 있습니다.',
      mText: '쇼핑몰에 사용할 로고를\n설정할 수 있습니다.',
      img: icon1,
    },
    {
      title: '웹브라우저 파비콘',
      text: '브라우저 주소창의 파비콘을\n설정할 수 있습니다.',
      mText: '브라우저 주소창의 파비콘을\n설정할 수 있습니다.',
      img: icon2,
    },
    {
      title: '어울리는 메인 색상 지정',
      text: '쇼핑몰의 버튼, 메뉴를 내가 정한 컬러로\n설정할 수 있습니다.',
      mText: '쇼핑몰의 버튼, 메뉴를 내가 정한\n컬러로 설정할 수 있습니다.',
      img: icon3,
    },
    {
      title: 'SNS 링크 연결',
      text: '인스타그램, 페이스북, 유투브, 블로그\n내가 운영하는 소셜 미디어 링크를\n지원합니다.',
      mText:
        '인스타그램, 페이스북, 유투브,\n블로그 내가 운영하는 소셜 미디\n어링크를 지원합니다.',
      img: icon4,
    },
  ];

  return (
    <div css={section3Style}>
      <div className="container">
        <div className="title">간단한 설정 몇 개면 충분합니다</div>
        <div className="text">
          몰파이는 포토샵이나 별도의 복잡한 디자인이 필요 없어요.
          <br />
          개성있는 나의 몰을 보여주기 위한 심플하면서도 꼭 필요한 설정으로
          멋지게 꾸밀 수 있습니다.
        </div>
        <ul className="list">
          {contents.map((v, k) => (
            <li key={k}>
              <img src={v.img} alt={`list-${k}`} />
              <h3>{v.title}</h3>
              <p>
                <PC>{v.text}</PC>
                <Mobile>{v.mText}</Mobile>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Section3;
