/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { motion } from 'framer-motion';
import content1 from 'static/welfare/section2/content1.png';
import content2 from 'static/welfare/section2/content2.png';
import content3 from 'static/welfare/section2/content3.png';
import content4 from 'static/welfare/section2/content4.png';
import content5 from 'static/welfare/section2/content5.png';

const Section2 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const section2Style = css`
    text-align: center;
    padding: 110px 0 100px 0;
    .container {
      max-width: 1535px;
      padding: 0 160px;
      margin: 0 auto;
      .title {
        text-align: center;
        .main {
          text-align: center;
          font-size: 56px;
          font-weight: 800;
          line-height: 76px;
          &.purple {
            color: #9f83d8;
          }
        }
        .text-color {
          color: #72aed3;
          font-size: 56px;
          font-weight: 800;
          line-height: 76px;
        }
        .text {
          color: #000000;
          font-size: 68px;
          font-weight: 800;
          line-height: 86px;
          position: relative;
          display: inline-block;
          .bg {
            width: calc(100% + 8px);
            height: 32px;
            background-color: #cfeafa;
            position: absolute;
            bottom: 0px;
            z-index: -1;
            left: -4px;
          }
        }
      }
      .list-item {
        margin-top: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        .chip {
          display: flex;
          padding: 8px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 36px;
          background: #ebf7fe;
          color: #507d98;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          letter-spacing: -0.2px;
          &.purple {
            background: #f9f6ff;
            color: #8871b6;
          }
        }
        > .title {
          margin-top: 20px;
          color: #000000;
          font-size: 56px;
          font-weight: 800;
          line-height: 76px;
          .blue {
            color: #72aed3;
          }
        }
        .desc {
          margin-top: 20px;
          color: #6c737f;
          font-size: 24px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.24px;
        }
        .content {
          border-radius: 36px;
          background: #ebf7fe;
          margin-top: 56px;
          padding: 0 22px 0 64px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 1084px;
          &.purple {
            background: #f9f6ff;
          }
          &.pr0 {
            padding-right: 0;
          }
          .pl30 {
            padding-left: 25px;
          }
          .text-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            text-align: left;
            .title {
              color: #5697bf;
              font-size: 32px;
              font-weight: 800;
              line-height: 48px;
              text-align: left;
              &.purple {
                color: #9f83d8;
              }
            }
            .desc {
              color: #5697bf;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: -0.18px;
              &.purple {
                color: #9f83d8;
              }
            }
          }
          .img {
            display: flex;
            img {
              max-width: 564px;
              width: 100%;
            }
          }
        }
      }
    }

    ${mq.mobile} {
      padding: 120px 0 60px 0;
      .container {
        max-width: 100%;
        padding: 0 20px;
        .title {
          .main {
            font-size: 28px;
            font-weight: 800;
            line-height: 40px;
          }
          .text-color {
            font-size: 24px;
            font-weight: 800;
            line-height: 36px;
          }
          .text {
            font-size: 36px;
            font-weight: 800;
            line-height: 48px;
            .bg {
              width: calc(100% + 8px);
              height: 16px;
              background-color: #cfeafa;
              position: absolute;
              bottom: 0px;
              z-index: -1;
              left: -4px;
            }
          }
        }
        .list-item {
          margin-top: 96px;
          .chip {
            padding: 6px 16px;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.14px;
          }
          > .title {
            margin-top: 10px;
            font-size: 28px;
            font-weight: 800;
            line-height: 40px;
          }
          .desc {
            font-size: 16px;
            margin-top: 10px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.16px;
            opacity: 0.8;
          }
          .content {
            flex-direction: column;
            margin-top: 30px;
            padding: 0;
            width: 100%;
            .text-box {
              padding: 36px 30px 0px 30px;
              .title {
                text-align: left;
                font-size: 20px;
                font-weight: 800;
                line-height: 30px;
              }
              .desc {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.16px;
              }
            }
          }
        }
      }
    }
  `;

  const ytVariants = {
    offscreen: {
      y: -100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.7,
      },
    },
  };
  const ybVariants = {
    offscreen: {
      y: 200,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={section2Style}>
      <div className="container">
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={ytVariants}
          className="title"
        >
          <p className="text-color">임직원,담당자 모두가 만족하는</p>
          <p className="text">
            <div className="bg" />
            새로운 복지몰 서비스
          </p>
        </motion.div>
        <div className="list-item">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            whileInView={{
              opacity: 1,
              scale: [1, 1.3, 1],
              transition: {
                ease: 'easeOut',
                duration: 1,
              },
            }}
            className="chip"
          >
            초간편 도입
          </motion.div>
          <div className="title">
            <span className="blue">신청 후 도입까지,</span>
            <span> 단 하루!</span>
          </div>
          <div className="desc">
            복지몰 도입 및 변경 하루만 투자하세요!
            <br />
            복잡한 계약과 절차 없이 하루면 맞춤 복지몰을 {isMobile && <br />}
            구축할 수 있습니다.
          </div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={ybVariants}
            className="content"
          >
            <div className="text-box">
              <div className="title">
                까다로운 조건, 복잡한 절차
                <br />
                무한정 대기 없는 빠른 도입
              </div>
              <div className="desc">
                기업 규모, 예산 등 조건 상관없이
                <br />
                평균 1개월 이상 걸리는 도입을 단 하루만에!
                <br />
                지금 바로, 개설 시작을 누르고
                <br />
                맞춤 복지몰을 만나보세요!
              </div>
            </div>
            <div className="img">
              <img alt="content1" src={content1} />
            </div>
          </motion.div>
        </div>
        <div className="list-item">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            whileInView={{
              opacity: 1,
              scale: [1, 1.3, 1],
              transition: {
                ease: 'easeOut',
                duration: 1,
              },
            }}
            className="chip"
          >
            비용 0원
          </motion.div>
          <div className="title">
            <span className="blue">가입, 구축, 운영 비용</span>
            <br />
            모두 무료!
          </div>
          <div className="desc">
            임직원을 위한 복지몰,
            <br />
            이제는 비용 고민없이 바로 시작하세요!
          </div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={ybVariants}
            className="content"
          >
            <div className="text-box">
              <div className="title">
                도입 및 변경부터
                <br />
                상품 운영까지 모두 0원
              </div>
              <div className="desc">
                복지몰 도입을 망설이게 만드는 구축비,
                <br />
                매월 상품 업데이트 운영 수수료, 모두 0원!
              </div>
            </div>
            <div className="img">
              <img alt="content1" src={content2} />
            </div>
          </motion.div>
        </div>
        <div className="list-item">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            whileInView={{
              opacity: 1,
              scale: [1, 1.3, 1],
              transition: {
                ease: 'easeOut',
                duration: 1,
              },
            }}
            className="chip purple"
          >
            만족도 높은 혜택
          </motion.div>
          <div className="title">
            <span className="main purple">결국 중요한 건,</span>
            <br />
            임직원이 만족하는 상품과 가격
          </div>
          <div className="desc">
            온라인 최저가, 무료 배송, 5만여 개의 다양한{isMobile && <br />} 상품
            구성 등{isPc && <br />}
            몰파이 커머스의 혜택을 임직원도 {isMobile && <br />}동일하게 누릴 수
            있습니다.
          </div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={ybVariants}
            className="content purple"
          >
            <div className="text-box">
              <div className="title purple">
                결국, 임직원 만족이
                <br />
                복지몰 도입의 이유
              </div>
              <div className="desc purple">
                국내 유수 기업이 선택한, 몰파이 커머스의
                <br />
                다양한 상품 구성과 온라인 최저가를
                <br />
                우리 임직원들에게도 동일하게 {isMobile && <br />}제공할 수
                있습니다.
              </div>
            </div>
            <div className="img">
              <img alt="content1" src={content3} />
            </div>
          </motion.div>
        </div>
        <div className="list-item">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            whileInView={{
              opacity: 1,
              scale: [1, 1.3, 1],
              transition: {
                ease: 'easeOut',
                duration: 1,
              },
            }}
            className="chip purple"
          >
            편리한 이용
          </motion.div>
          <div className="title">
            <span className="main purple">비교 불가한 이용 편리성,</span>
            <br />
            세련된 커머스 디자인
          </div>
          <div className="desc">
            커머스 전문 기업이 만든 {isMobile && <br />} 디테일이 남다른 기능과
            사용성,
            <br />
            세련된 디자인으로 더욱 편리하게 이용하는 복지몰
          </div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={ybVariants}
            className="content purple pr0"
          >
            <div className="text-box">
              <div className="title purple">
                이제 복지몰의 디자인도
                <br />
                이전과는 달라야 합니다
              </div>
              <div className="desc purple">
                PC, 모바일 어떤 디바이스에서도
                <br />
                쉽고 편리하게 이용할 수 있는 편의성,
                <br />
                남다른 인터페이스를 제공합니다.
              </div>
            </div>
            <div className="img pl30">
              <img alt="content1" src={content4} />
            </div>
          </motion.div>
        </div>
        <div className="list-item">
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            whileInView={{
              opacity: 1,
              scale: [1, 1.3, 1],
              transition: {
                ease: 'easeOut',
                duration: 1,
              },
            }}
            className="chip purple"
          >
            효율적인 운영 관리
          </motion.div>
          <div className="title">
            <p className="main purple">담당자의 시간을 아껴 줄</p>
            <p className="main">직관적인 관리자 페이지</p>
          </div>
          <div className="desc">
            <p>임직원별 개인화된 포인트 관리, 한눈에 보는 대시보드</p>
            <p>담당자도 만족하는 관리자 페이지를 만나보세요</p>
          </div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={ybVariants}
            className="content purple pr0"
          >
            <div className="text-box">
              <div className="title purple">
                교육이 필요없는
                <br />
                직관적인 관리자 페이지
              </div>
              <div className="desc purple">
                복지몰 도입, 변경 시에도 걱정 없이
                <br />
                빠르게 적응 및 사용 가능한 관리자 페이지로
                <br />
                불필요한 시간 낭비를 줄이세요.
              </div>
            </div>
            <div className="img pl30">
              <img alt="content1" src={content5} />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
