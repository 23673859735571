const useNews = () => {
  const newsList = [
    {
      title: '[4월 프로모션 상품] 한정수량 특가 프로모션',
      content: `<p><span class="sc-jzgbtB theQj">안녕하세요!<br></span>몰파이를 이용해주시는 파트너 분들께 진심으로 감사드립니다.</p>
<p><span class="sc-jzgbtB theQj">&nbsp;3월 몰파이 프로모션 이벤트에 이어, 4월 프로모션 이벤트를 진행하고자 합니다.<br></span>몰파이 오픈 프로모션으로 하루에 2개의 상품을
    파격 할인가에 판매할 수 있는 기회를 드립니다!<br><span class="sc-jzgbtB theQj">프로모션 일정 및 자세한 내용은 하기 내용 참고 부탁드리며, 파트너 분들의 많은 관심 부탁드립니다
        😃</span></p>
<p><strong><span class="sc-jzgbtB theQj">&nbsp;[프로모션 안내]<br></span></strong><strong><span class="sc-jzgbtB theQj">1.
            프로모션 기간 : 4/1~4/30</span></strong></p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/luc5npyl/800xauto_1711686149727.jpg"></p>
<p><strong>2. 프로모션 상품 판매 방법<br></strong>1) 몰파이 파트너 로그인 -&gt; 마이몰 관리 -&gt; 상품관리 로 이동<br>2) 운영 상품 등록 버튼 클릭</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltnpmseu/800xauto_1710208969524.jpg"></p>
<p>&nbsp;</p>
<p>3)프로모션 상품 찾아서 추가하기<br>"MD추천상품" 탭 클릭후 상단 2개 상품 선택 후 하단에 "선택한 상품적용" 버튼 클릭</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltnpmseu/800xauto_1710209224122.jpg" width="847"
        height="471"></p>
<p>&nbsp;</p>
<p>감사합니다.</p>`,
      date: '2024.03.29',
    },
    {
      title: '[몰파이 정산 방법 공지] 매월 동일함',
      content: `<p>안녕하세요. 몰파이 정산 방법에 대해서 공지 드립니다.&nbsp;</p>
<ul>
    <li>정산월(공급시기가 속하는 달)의<strong> 다음달 10</strong>일까지 세금계산서 발급해야 합니다.</li>
    <li style="color: rgb(224, 62, 45);"><span style="color: rgb(224, 62, 45);">예시 : 24.03월 정산&nbsp; 24년 04월
            10일<strong>까지</strong> 세금계산서 발행</span></li>
    <li>작성일자는 반드시 정산월 말일로 발행하셔여합니다.</li>
    <li style="color: rgb(224, 62, 45);"><span style="color: rgb(224, 62, 45);">예시 : 24.03월 정산 24년 03월
            31일<strong>으로</strong> 세금계산서 발행</span></li>
    <li>기안을 지키지 않을 경우 가산세(공급가/공급받는자 둘 다 대상)를 물게 됩니다.</li>
    <li>가산세 발생할 경우 협력사에서 공급받는자 가산세까지 부담해셔야 합니다.(정산금액에서 차감 후 입금)</li>
</ul>
<p><strong>- 입점사 메뉴 -&gt; 정산관리 -&gt;월별정산내역</strong><br>정산월 선택하여 검색<br>미확정 월별 상세내역 클릭 후 월별 정산 내역 확인</p>
<p>&nbsp;</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltgioo7d/800xauto_1709773100799.jpg"></p>
<p><strong>- 월별 상세내역 화면에서 오른쪽 상단 상세내역 다운로드</strong></p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltgioo7d/800xauto_1709773140974.jpg"></p>
<p><strong>- 엑셀파일</strong></p>
<p>L열 과세여부 / W열 판매자 정산금</p>
<p><img src="blob:https://gadmin.mallpie.kr/3454e524-e1b8-4965-8b5d-9af92e6ceeb1"></p>
<p>위 내용은 예시로 작성한거니 <strong><span style="color: rgb(224, 62, 45);">정산월에 맞게&nbsp; 날짜 확인 후 발행</span></strong>해주시면 됩니다.</p>
<p>단, 면세 상품의 배송비/지역별 배송비는 과세되는 항목으로 과세로 포함하고 면세금액에서는 제외해야됨</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltgioo7d/800xauto_1709773284321.jpg" width="823"
        height="141"></p>
<p><strong>정산관련해서 문의사항이 있으시면 문의하기를 통해서 접수해 주세요.<br></strong>몰파이 우측 상단 -&gt; 문의하기 클릭<br>문의 유형을 정산관련으로 선택 후 문의 글 남겨주시면 정산
    담당자가 확인 후 답변 드리겠습니다. <br><img
        src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltgioo7d/800xauto_1709774211627.jpg"></p>
<p>&nbsp;</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltgioo7d/800xauto_1709792055754.jpg"></p>
<p>&nbsp;</p>`,
      date: '2024.03.29',
    },
    {
      title:
        '[6월 프로모션] 몰파이 오픈이벤트 한정수량 특가 프로모션 (최대 88% 할인)',
      content: `<p><span class="sc-jzgbtB theQj">안녕하세요!<br></span>몰파이를 이용해 주시는 파트너 분들께 진심으로 감사드립니다.</p>
<p><span class="sc-jzgbtB theQj">뜨거운 성원에 힘입어 4월 오픈 프로모션에 이어, 5월 프로모션 이벤트를 진행하고자 합니다.</span></p>
<p>몰파이 오픈 프로모션으로 하루에 2개의 상품을 <strong>최대 88%까지 할인된 파격 특가에 판매할 수 있는 기회</strong>를 드립니다!<br><span
        class="sc-jzgbtB theQj">프로모션 일정 및 자세한 내용은 하기 내용 참고 부탁드리며, 파트너 분들의 많은 관심 부탁드립니다 😃</span></p>
<p>&nbsp;</p>
<p><span class="sc-jzgbtB theQj"><strong>&nbsp;[프로모션 안내]<br></strong><strong>1. 프로모션 기간 : 5/1~5/31</strong></span></p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/lvlwwlex/800xauto_1714452873212.jpg"></p>
<p><strong>2. 프로모션 상품 판매 방법<br></strong>1) 몰파이 파트너 로그인 -&gt; 마이몰 관리 -&gt; 상품관리 로 이동<br>2) 운영 상품 등록 버튼 클릭</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltnpmseu/800xauto_1710208969524.jpg"></p>
<p>&nbsp;</p>
<p>3)프로모션 상품 찾아서 추가하기<br>"MD추천상품" 탭 클릭후 상단 2개 상품 선택 후 하단에 "선택한 상품적용" 버튼 클릭</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltnpmseu/800xauto_1710209224122.jpg" width="847"
        height="471"></p>
<p>&nbsp;</p>
<p>감사합니다.</p>
<p>&nbsp;</p>
`,
      date: '2024.05.28',
    },
    {
      title: '[일반 공지] 업체 정보관리 수정 안내',
      content: `<p>업체 정보관리 수정 관련 안내 드립니다.</p>
<p>정보 수정은 몰파이 우측 상단 "&or;" 표시를 누른 후 "업체정보관리"탭에서 수정 가능합니다.</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltgioo7d/a96346b6-aa6c-4221-b7a7-b38e2a719a3a.png" width="1233" height="529"></p>
<p>"사업자 등록번호"와 "사업자 구분"을 제외한 "업체명", "대표자명", "담당자 정보" 등은 자유롭게 수정이 가능하시지만,<br>"정산정보"의 결제계좌는 계좌인증을 해주셔야 수정이 완료 됩니다.</p>
<p>"사업자 등록번호" 변경시 아래 구비서류를 담당 MD분께 사전 제출해주시기 바랍니다.</p>
<p><img src="https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/ltgioo7d/e9e827c7-2062-45cb-a7f7-ce8c43ae5aea.png"></p>
<p>영업양수양도신청서는 담당 MD분께 요청 부탁드립니다.</p>
<p>감사합니다.</p>`,
      date: '2024.05.30',
    },
    {
      title: '[일반 공지] 협력사 고객 대응 관련 프로세스 공유의 건',
      content: `<p>안녕하세요. 지니웍스 상품운영팀입니다.</p>
<p>고객 대응 관련 안내드리오니 업무에 참고 부탁드립니다.</p>
<p>&nbsp;</p>
<p><strong>※ 고객 CS 대응 안내</strong></p>
<p>고객 CS 발생 시 1차적으로 직접 응대 부탁드립니다.</p>
<p>강성클레임 및 대량 미출고 등 이슈 발생 시 지니웍스에 사전 공유해주시고, 가이드 받으셔서 소통 진행 부탁드립니다.</p>
<p>특히, 문자 발송 상품의 배송 이슈 발생 시 즉시 담당 MD와 CS팀에 내용 공유해주시고, 가이드 받아서 고객과 소통 부탁드립니다.</p>
<p><br>감사합니다.<br>- 지니웍스 상품운영팀 -</p>
<p><strong>........................................................</strong></p>
<p><strong>* 고객센터 :&nbsp;1800-1011 (평일 9:30~18:30)</strong></p>
<p><strong>........................................................</strong></p>
<p><strong>* 정산문의 :</strong><strong>&nbsp;070-4283-4141</strong></p>
`,
      date: '2024.05.30',
    },
    {
      title: '[공지] 배송/반품 및 패널티 정책 안내',
      content: `<table class="table tb_listtype01">
    <tbody>
        <tr>
            <td colspan="5" height="400px">
                <p><br>안녕하세요<br>지니웍스 커머스 플랫폼 운영팀입니다.<br>많은 도움 주시는 협력사 분들께 항상 감사드립니다.<br><br>배송 및 반품 패널티 정책 관련 안내드립니다.
                </p>
                <p>아래&nbsp;<strong>배송가이드&nbsp;</strong>와&nbsp;<strong>반품 및 패널티 정책</strong> 숙지 부탁드립니다.<br><img
                        class="fr-fic fr-dib fr-fil"
                        src="https://d-img.picnique.co.kr/product%2F20240613%2F1718258735205-2024-06-13+14_55_13.369.png">
                </p>
            </td>
        </tr>
    </tbody>
</table>`,
      date: '2024.06.13',
    },
  ];

  return { newsList };
};

export default useNews;
