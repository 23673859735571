/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { motion, useMotionValueEvent, useScroll } from 'framer-motion';
import logo from 'static/home/logo.png';
import icon1 from 'static/home/icon1.png';
import icon2 from 'static/home/icon2.png';
import icon3 from 'static/home/icon3.png';
import icon4 from 'static/home/icon4.png';
import icon6 from 'static/home/icon6.png';
import icon7 from 'static/home/icon7.png';
import icon8 from 'static/home/icon8.png';
import bg1 from 'static/home/bg1.png';
import bg2 from 'static/home/bg2.png';
import useWindowSize from 'hooks/useWindowSize';
import { useIsMobile } from 'core/hooks/useResponsive';

const Main = () => {
  const { mq } = useTheme();
  const [fixed, setFixed] = useState(false);
  const [scollValue, setScrollValue] = useState(0);
  const isMobile = useIsMobile();
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const MainStyle = css`
    padding: 160px 70px 76px 70px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .subTitle {
      font-size: 32px;
      color: #6079ff;
    }
    .title {
      font-size: 80px;
      font-weight: 700;
      line-height: 130%;
      color: #4b5563;
      margin-bottom: 40px;
      span {
        color: #6079ff;
        font-weight: inherit;
      }
    }
    .box_wrap {
      height: ${(windowHeight - 120) * 3 + 'px'};
      .box {
        width: 600px;
        min-width: 600px;
        max-width: ${windowWidth - 140 + 'px'};
        max-height: ${windowHeight - 120 + 'px'};
        position: relative;
        overflow: hidden;
        border-radius: 120px;
        margin: 0 auto;
        .bg {
          width: 100%;
          background: linear-gradient(
            180deg,
            #6079ff 0%,
            #752ed5 32.5%,
            #8338e1 66.5%,
            #f9a850 100%
          );
          .pattern1 {
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 1;
            img {
              width: 100%;
            }
          }
          .pattern2 {
            width: 100%;
            right: 0;
            top: ${windowHeight + 300 + 'px'};
            position: absolute;
            z-index: 1;
            img {
              width: 100%;
            }
          }
          .item {
            scroll-snap-align: center;
            width: ${windowWidth - 140 + 'px'};
            height: ${windowHeight - 120 + 'px'};
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 96px;
            font-style: normal;
            font-weight: 800;
            line-height: 114px;
            color: #fff;
            position: relative;
            z-index: 3;
            .text {
              font-weight: bold;
              span {
                font-weight: bold;
                display: inline-flex;
                align-items: center;
              }
              img {
                margin: 0 10px;
              }
            }
            &.logo {
              max-width: ${windowWidth - 140 + 'px'};
              img {
                width: 442px;
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      padding: 50px 20px;
      .box_wrap {
        .box {
          border-radius: 20px;
          min-width: auto;
          max-width: ${windowWidth - 40 + 'px'};
          max-height: ${windowHeight - 120 + 'px'};
          .bg {
            .item {
              scroll-snap-align: center;
              width: ${windowWidth - 40 + 'px'};
              height: ${windowHeight - 120 + 'px'};
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              font-style: normal;
              font-weight: 800;
              line-height: 114px;
              color: #fff;
              position: relative;
              z-index: 3;
              line-height: 30px;
              .text {
                img {
                  width: 36px;
                  margin: 0 6px;
                }
              }
              &.logo {
                max-width: ${windowWidth - 40 + 'px'};
                img {
                  width: 200px;
                }
              }
            }
          }
        }
      }
      .subTitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      }
      .title {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 16px;
      }
    }
  `;

  const boxRef = useRef(null);
  const [active, setActive] = useState(0);
  const [start, setStart] = useState(true);
  const { scrollYProgress } = useScroll({
    target: boxRef,
    offset: ['start center', 'end end'],
  });

  useMotionValueEvent(scrollYProgress, 'change', latest => {
    setScrollValue(latest);

    if (isMobile ? latest > 0.26 : latest > 0.298) {
      setStart(false);
    } else {
      setStart(true);
    }
    if ((isMobile ? latest > 0.26 : latest > 0.298) && latest < 1) {
      setFixed(true);
    } else {
      setFixed(false);
    }
    setActive(latest < 0.305 ? 0 : latest < 0.6 ? 1 : latest < 0.9 ? 2 : 3);
  });

  return (
    <div css={MainStyle} ref={boxRef}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.5 }}
      >
        <motion.div
          className="subTitle"
          variants={{
            offscreen: {
              y: -100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            },
          }}
        >
          몰파이와 함께
        </motion.div>
        <motion.div
          className="title"
          variants={{
            offscreen: {
              y: -100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
              },
            },
          }}
        >
          <span>EASY</span> AND <span>FAST</span>
        </motion.div>
      </motion.div>
      <div className="box_wrap">
        <motion.div
          className="box"
          style={{
            width: isMobile ? '100%' : `${600 + scollValue * 6000}px`,
            position: fixed ? 'fixed' : 'relative',
            y: start ? 0 : fixed ? '-50%' : '200%',
            x: start ? 0 : fixed ? '-50%' : '0%',
            top: fixed ? '50%' : 'auto',
            left: fixed ? '50%' : 'auto',
            transform: fixed
              ? isMobile
                ? 'translate(-50%,-50%)'
                : 'translate(-50%,-50%)'
              : 'translate(0%,0%)',
          }}
        >
          <motion.div
            className="bg"
            animate={{
              y: -(windowHeight - 120) * active,
            }}
            transition={{ duration: 0.5 }}
          >
            <div
              className="item logo"
              style={{ width: `${600 + scollValue * 6000}px` }}
            >
              <img src={logo} title="logo" alt="logo" />
            </div>
            <div className="item">
              <div className="text">
                <span>
                  <img src={icon1} title="icon1" alt="icon1" />
                  개설부터 <img src={icon2} title="icon2" alt="icon2" />
                  운영 까지
                </span>
                <br />
                <span>
                  누구나 손쉽게
                  <img src={icon3} title="icon3" alt="icon3" />
                </span>
              </div>
            </div>
            <div className="item">
              <div className="text">
                <span>
                  5만개 <img src={icon4} title="icon4" alt="icon4" />의 상품
                  연동으로
                </span>
                <br />
                <span>
                  <img src={icon6} title="icon6" alt="icon6" />
                  수익을 만드는
                </span>
              </div>
            </div>
            <div className="item">
              <div className="text">
                <span>
                  쇼핑몰의 쉬운 <img src={icon7} title="icon7" alt="icon7" />
                  시작
                </span>
                <br />
                <span>
                  <img src={icon8} title="icon8" alt="icon8" /> 몰파이
                </span>
              </div>
            </div>
            <div className="pattern1">
              <img src={bg1} title="bg1" alt="bg1" />
            </div>
            <div className="pattern2">
              <img src={bg2} title="bg2" alt="bg2" />
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Main;
