/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile } from 'components/common/Responsive';
import section1 from 'static/myshop/make/section1.png';

const Section1 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    .container {
      max-width: 1535px;
      padding: 0px 40px 140px 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .text {
        line-height: 28px;
        font-size: 18px;
        margin-top: 14px;
        font-weight: 200;
      }
      img {
        width: 800px;
      }
    }
    ${mq.mobile} {
      .container {
        flex-direction: column;
        padding: 0;

        .right {
          padding: 35px 32px 35px 32px;
          width: 100%;
          h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
          }
          .text {
            font-size: 14px;
            line-height: 22px;
            margin-top: 12px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  `;
  return (
    <div css={section1Style}>
      <div className="container">
        <div className="left">
          <img src={section1} alt="section3" />
        </div>
        <div className="right">
          <h2>
            파트너 회원가입하고
            <br /> 몰 생성까지
            <br /> 5분이면 충분해요
          </h2>
          <div className="text">
            여타 다른 쇼핑몰 빌더처럼 복잡한 설정은 하지
            <Mobile>
              <br />
            </Mobile>{' '}
            않아도 됩니다.
            <br /> 파트너로 회원가입을 하시면 누구나 무료로
            <br /> 나만의 쇼핑몰을 만들 수 있습니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
