import React from 'react';
import Layout from 'components/common/NewLayout';
import Title from 'components/class/Title';
import Section1 from 'components/class/Section1';
import Section2 from 'components/class/Section2';
import Faq from 'components/class/Faq';
import Bottom from 'components/class/Bottom';
import Meta from 'components/common/Meta';

const Class = () => {
  return (
    <Layout theme="black">
      <Meta
        title="서비스 소개 - 모임/단체 | 몰파이"
        ogTitle="서비스 소개 - 모임/단체 | 몰파이"
      />
      <Title />
      <Section1 />
      <Section2 />
      <Faq />
      <Bottom />
    </Layout>
  );
};

export default Class;
