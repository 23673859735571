/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';

const Title = () => {
  const { mq } = useTheme();

  const TitleStyle = css`
    .container {
      max-width: 1200px;
      padding-top: 200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .title {
        color: #000;
        font-size: 64px;
        font-weight: 800;
        line-height: 86px;
        br {
          display: none;
        }
      }
      .sub {
        font-size: 30px;
        color: #6079ff;
        font-weight: 700;
        line-height: 42px;
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 0px 0px;
        .title {
          font-size: 36px;
          font-weight: 800;
          line-height: 48px;
          margin-top: 6px;
          br {
            display: block;
          }
        }
        .sub {
          font-size: 16px;
          color: #6079ff;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
  `;
  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <div css={TitleStyle}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        variants={yVariants}
        className="container"
      >
        <div className="sub">몰파이 BIZ</div>
        <h2 className="title">
          기업 서비스 소개서를
          <br /> 받아보세요!
        </h2>
      </motion.div>
    </div>
  );
};

export default Title;
