import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

interface DateProps {
  selectDate: Date;
  format?: string;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  onChange?: (date: Date) => void;
}

const DateSelect = ({
  selectDate,
  format,
  minDate,
  maxDate,
  onChange,
}: DateProps) => {
  return (
    <>
      <DatePicker
        selected={selectDate}
        onChange={date => {
          if (onChange !== undefined) onChange(date);
        }}
        dateFormat={format ? format : 'yyyy.MM.dd'}
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : moment().toDate()}
      />
    </>
  );
};

export default DateSelect;
