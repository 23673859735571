/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import Header from '../NewHeader';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Footer from '../Footer';
import Banner from '../Banner';
import { useCookies } from 'react-cookie';

interface LayoutProps {
  theme?: 'black' | 'white';
  headerTheme?: 'black' | 'white';
  pd?: boolean;
  children: React.ReactNode;
}

const Layout = ({
  theme,
  headerTheme = 'white',
  pd = false,
  children,
}: LayoutProps) => {
  const { mq } = useTheme();
  const { pathname } = useLocation();

  const [banner, setBanner] = useState<boolean>();

  const [startDate] = useState(new Date(2024, 2, 12, 9, 0, 0));
  const [endDate] = useState(new Date(2024, 4, 1, 0, 0, 0));
  const [nowDate] = useState(Date.now());

  const [cookies] = useCookies();

  const compareDates = () => {
    if (pathname.includes('event/')) {
      setBanner(false);
      return;
    }
    const currentTime = new Date(nowDate).getTime();

    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (currentTime >= startTime && currentTime < endTime) {
      setBanner(true);
      checkDisplayBanner();
    } else {
      setBanner(false);
    }
  };

  const checkDisplayBanner = () => {
    if (cookies.notShowToday) {
      setBanner(false);
    } else {
      setBanner(true);
    }
  };
  const todayNotShowHandler = () => {
    setBanner(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    compareDates();
  }, []);

  const layoutStyle = css`
    ${mq.mobile} {
      padding-top: ${banner ? (pd ? '90px' : '138px') : pd ? 0 : '0'};
    }
  `;

  return (
    <>
      <div css={layoutStyle}>
        <Header theme={headerTheme} isBanner={banner} />
        {children}
        <Footer theme={theme} />
      </div>
    </>
  );
};
export default Layout;
