/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import iconImg from 'static/promotion/section2/icon.png';
import titleImg from 'static/promotion/section2/title.png';
import mTitleImg from 'static/promotion/section2/m_text.png';
import list1 from 'static/promotion/section2/list1.png';
import list2 from 'static/promotion/section2/list2.png';
import list3 from 'static/promotion/section2/list3.png';
import list4 from 'static/promotion/section2/list4.png';
import list5 from 'static/promotion/section2/list5.png';
import list6 from 'static/promotion/section2/list6.png';
import Slider from 'react-slick';
import React from 'react';
import { Mobile, PC } from 'components/common/Responsive';

const Section2 = () => {
  const section2Style = css`
    background-color: #000;
    .container {
      padding: 214px 20px 145px 20px;
      max-width: 1560px;
      margin: 0 auto;
      .title {
        img {
          display: block;
        }
      }
      .cont {
        margin-top: 80px;

        .slide {
          div {
            text-align: center;
          }
          button {
            cursor: pointer;
            &::before {
              content: '';
              padding: 0;
              width: 10px;
              height: 10px;
              background: #ab72ff;
              transition: 0.3s;
              opacity: 0.3;
              border-radius: 50%;
              left: 5px;
              top: 5px;
            }
          }
          .slick-slide img {
            margin: 0 auto;
          }
          .slick-active button {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  `;

  const section2MoStyle = css`
    .container {
      padding: 50px 0 80px 0px;
      position: relative;
      background-color: #000;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 170px;
        }
      }
      .cont {
        display: block;
        margin-top: 40px;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 240px;
        }
        button {
          cursor: pointer;
          &::before {
            content: '';
            padding: 0;
            width: 10px;
            height: 10px;
            background: #ab72ff;
            transition: 0.3s;
            opacity: 0.3;
            border-radius: 50%;
            left: 5px;
            top: 5px;
          }
        }
        .slick-slide img {
          margin: 0 auto;
        }
        .slick-active button {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  `;

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    arrows: false,
  };

  const moSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    arrows: false,
  };

  return (
    <>
      <PC>
        <div css={section2Style}>
          <div className="container">
            <div className="title">
              <img src={iconImg} alt="icon" />
              <img src={titleImg} alt="title" />
            </div>
            <div className="cont">
              <Slider {...settings} className="slide">
                <div>
                  <img src={list1} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list2} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list3} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list4} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list5} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list6} alt="슬라이드 이미지" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </PC>
      <Mobile>
        <div css={section2MoStyle}>
          <div className="container">
            <div className="title">
              <img src={mTitleImg} alt="title" />
            </div>
            <div className="cont">
              <Slider {...moSettings} className="slide">
                <div>
                  <img src={list1} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list2} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list3} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list4} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list5} alt="슬라이드 이미지" />
                </div>
                <div>
                  <img src={list6} alt="슬라이드 이미지" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section2;
