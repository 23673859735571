/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/store/store1_list1.png';
import list1MImg from 'static/store/store1_m_list1.png';
import list2Img from 'static/store/store1_list2.png';
import list3Img from 'static/store/store1_list3.png';
import list4Img from 'static/store/store1_list4.png';
import { motion } from 'framer-motion';
import { useIsPc } from 'core/hooks/useResponsive';

const Store1 = () => {
  const { mq } = useTheme();
  const isPc = useIsPc();

  const Store1Style = css`
    display: flex;
    justify-content: center;
    background: #2b2b2b;
    .wrap {
      width: 1084px;
      padding-bottom: 200px;
      .list {
        display: flex;
        flex-direction: column;
        gap: 48px;
        li {
          display: flex;
          position: relative;
          align-items: center;
          height: 564px;
          border-radius: 36px;
          overflow: hidden;
          &:nth-of-type(1) {
            background: #f9fafb;
            .text {
              padding-left: 64px;
              span {
                color: #6079ff;
              }
            }
          }
          &:nth-of-type(2) {
            background: #dae0ff;
            .img {
              padding: 96px 20px 96px 59px;
            }
            .text {
              padding-left: 119px;
              padding-right: 82px;
              color: #22307e;
              .desc {
                color: #22307e;
              }
            }
          }
          &:nth-of-type(3) {
            background: #6079ff;
            .text {
              padding-left: 64px;
              color: #fff;
              .desc {
                color: #fff;
              }
            }
          }
          &:nth-of-type(4) {
            background: #d3e4f4;
            .img {
              padding: 96px 23px 96px 56px;
            }
            .text {
              padding-left: 119px;
              padding-right: 102px;
            }
          }
          .text {
            width: 564px;
            .title {
              font-size: 32px;
              font-weight: 700;
              line-height: 48px;
              span {
                color: #6079ff;
              }
            }
            .desc {
              margin-top: 20px;
              font-size: 18px;
              font-weight: 500;
              line-height: 30px;
              color: #000;
              opacity: 0.6;
            }
          }
          .img {
            width: 564px;
            flex-shrink: 0;
            overflow: hidden;
            border-radius: 36px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .wrap {
        width: 100%;
        padding: 0 20px 96px;
        .list {
          gap: 20px;
          li {
            flex-direction: column;
            height: auto;
            &:nth-of-type(2) {
              flex-direction: column-reverse;
            }
            .text {
              width: 100%;
              padding: 36px 30px;
              .title {
                font-size: 20px;
                line-height: 30px;
              }
              .desc {
                margin-top: 10px;
                font-size: 16px;
                line-height: 24px;
              }
            }
            &:nth-of-type(1) {
              .text {
                padding: 36px 30px;
                span {
                  color: #6079ff;
                }
              }
            }
            &:nth-of-type(2) {
              .img {
                padding: 60px 30px;
              }
              .text {
                padding: 36px 30px;
                .desc {
                }
              }
            }
            &:nth-of-type(3) {
              .text {
                padding: 36px 30px;
                .desc {
                  color: #fff;
                }
              }
            }
            &:nth-of-type(4) {
              flex-direction: column-reverse;
              .img {
                padding: 60px 30px;
              }
              .text {
                padding: 36px 30px;
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Store1Style}>
      <div className="wrap">
        <ul className="list">
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                상품을 등록하면
                <br />
                평균 <span>100여 개 채널</span>에서
                <br />
                판매돼요
              </h3>
              <p className="desc">
                개인, 크리에이터, 모임, 기업이 운영중인 <br />
                마이몰에서 판매 될 수 있어요.
                <br />
                추가 입점 없이 단 한번의 입점으로
                <br />
                다양한 고객을 만나보세요!
              </p>
            </div>
            <div className="img">
              <img
                src={isPc ? list1Img : list1MImg}
                alt="상품을 등록하면 평균 100여 개 채녈에서 판매돼요 | 이미지"
                title="상품을 등록하면 평균 100여 개 채녈에서 판매돼요"
              />
            </div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="img">
              <img
                src={list2Img}
                alt="MD와 협업하여 프로모션 진행이 가능해요 | 이미지"
                title="MD와 협업하여 프로모션 진행이 가능해요"
              />
            </div>
            <div className="text">
              <h3 className="title">
                MD와 협업하여
                <br />
                프로모션 진행이 가능해요
              </h3>
              <p className="desc">
                입점이 완료되면 <br />
                카테고리별 전문 MD가 1:1 매칭돼요.
                <br />
                함께 논의하여 상품 노출 기회를 확대하고,
                <br />
                매출 증대를 이뤄보세요!
              </p>
            </div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                고객 데이터를 분석하여
                <br />
                상품을 추천 하고 있어요
              </h3>
              <p className="desc">
                고객 구매 패턴 및 상품 선호도를 분석하여
                <br />
                맞춤 상품이 자동으로 추천돼요.
                <br />
                몰파이만의 정교한 추천 알고리즘으로
                <br />
                최적의 채널에서 판매될 수 있도록 도와드려요!
              </p>
            </div>
            <div className="img">
              <img
                src={list3Img}
                alt="고객 데이터를 분석하여 상품을 추천 하고 있어요 | 이미지"
                title="고객 데이터를 분석하여 상품을 추천 하고 있어요"
              />
            </div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="img">
              <img
                src={list4Img}
                alt="외부에서 검색되지 않아 페쇄적 운영이 가능해요 | 이미지"
                title="외부에서 검색되지 않아 페쇄적 운영이 가능해요"
              />
            </div>
            <div className="text">
              <h3 className="title">
                외부에서 검색되지 않아
                <br />
                <span>폐쇄적 운영</span>이 가능해요
              </h3>
              <p className="desc">
                네이버 등 검색 사이트에서 노출 및<br />
                검색되지 않는 폐쇄몰 채널이에요.
                <br />
                회원 한정으로 판매 가능한 가격으로
                <br />
                경쟁력을 확보하고 수익을 창출해 보세요!
              </p>
            </div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Store1;
