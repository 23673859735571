/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

import shop1Img from 'static/home/shop1.png';
import shop2Img from 'static/home/shop2.png';
import shop3Img from 'static/home/shop3.png';
import shop4Img from 'static/home/shop4.png';
import shop5Img from 'static/home/shop5.png';
import shop6Img from 'static/home/shop6.png';
import shop7Img from 'static/home/shop7.png';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import { useIsMobile } from 'core/hooks/useResponsive';
import { Link, useNavigate } from 'react-router-dom';

const Shop = () => {
  const { mq } = useTheme();
  const navigator = useNavigate();
  const isMobile = useIsMobile();

  const shopImgArr = [
    {
      img: shop1Img,
      size: '280px',
      mSize: '140px',
    },
    {
      img: shop3Img,
      size: '280px',
      mSize: '140px',
    },
    {
      img: shop4Img,
      size: '280px',
      mSize: '140px',
    },
    {
      img: shop5Img,
      size: '280px',
      mSize: '140px',
    },
    {
      img: shop6Img,
      size: '280px',
      mSize: '140px',
    },
    {
      img: shop7Img,
      size: '280px',
      mSize: '140px',
    },
  ];

  const ShopStyle = css`
    max-width: 100%;
    width: 100%;
    padding: 160px 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    background: #f2f4f7;
    .title {
      font-size: 56px;
      text-align: center;
      font-weight: 700;
      line-height: 130%;
      span {
        font-size: inherit;
        font-weight: inherit;
        color: #6079ff;
      }
    }
    @keyframes dvufTn {
      0% {
        transform: translate(0px);
      }

      100% {
        transform: translate(-50%);
      }
    }
    .imgs {
      width: max-content;
      gap: 24px;
      align-items: flex-end;
      animation: 30s linear 0s infinite normal none running dvufTn;
      display: flex;
      margin-top: 96px;

      li {
        img {
          width: 100%;
        }
      }
    }
    .more {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      padding: 12px 20px;
      color: #6079ff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      gap: 8px;
      cursor: pointer;
    }
    ${mq.mobile} {
      padding: 90px 0px;
      .title {
        font-size: 30px;
        line-height: 42px;
      }
      .imgs {
        gap: 12px;
        margin-top: 48px;
      }
      .more {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }
  `;

  return (
    <div css={ShopStyle}>
      <h2 className="title">
        몰파이와 함께하는 <span>쇼핑몰</span>
      </h2>
      <ul className="imgs">
        {shopImgArr.map((v, k) => (
          <li style={{ width: isMobile ? v.mSize : v.size }} key={`${k}-1`}>
            <img src={v.img} alt="top list item" title="top list item" />
          </li>
        ))}
        {shopImgArr.map((v, k) => (
          <li style={{ width: isMobile ? v.mSize : v.size }} key={`${k}-2`}>
            <img src={v.img} alt="top list item" title="top list item" />
          </li>
        ))}
      </ul>
      <Link to="/now" className="more">
        더 많은 쇼핑몰 보기 <MainArrow />
      </Link>
    </div>
  );
};

export default Shop;
