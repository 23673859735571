import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Bottom from 'components/revenue/Bottom';
import Main from 'components/revenue/Main';
import Section1 from 'components/revenue/Section1';
import Section2 from 'components/revenue/Section2';
import Step from 'components/revenue/Step';
import Title from 'components/revenue/Title';

const Revenue = () => (
  <Layout theme="black">
    <Meta title="단계별 수익실현 | 몰파이" ogTitle="단계별 수익실현 | 몰파이" />
    <Title />
    <Main />
    <Section1 />
    <Section2 />
    <Step />
    <Bottom />
  </Layout>
);

export default Revenue;
