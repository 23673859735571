/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import icon1 from 'static/business/shop/section3_1.png';
import icon2 from 'static/business/shop/section3_2.png';
import icon3 from 'static/business/shop/section3_3.png';
import icon4 from 'static/business/shop/section3_4.png';
import icon5 from 'static/business/shop/section3_5.png';
import icon6 from 'static/business/shop/section3_6.png';
import icon7 from 'static/business/shop/section3_7.png';
import icon8 from 'static/business/shop/section3_8.png';

const Section3 = () => {
  const { mq } = useTheme();

  const section3Style = css`
    text-align: center;
    padding: 130px 0 120px 0;
    .container {
      max-width: 1300px;
      padding: 0 40px;
      margin: 0 auto;

      .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .list {
        display: flex;
        justify-content: center;
        margin-top: 48px;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          padding: 36px 0px;
          width: calc(25% - 9px);
          border-radius: 12px;
          img {
            width: 48px;
          }
          p {
            font-size: 16x;
            font-weight: 600;
            line-height: 24px;
            margin-top: 12px;
            white-space: pre-wrap;
          }
          &:nth-of-type(1) {
            background: #eef7fc;
            color: #397393;
          }
          &:nth-of-type(2) {
            background: #fcf5ee;
            color: #805327;
          }
          &:nth-of-type(3) {
            background: #eefcf7;
            color: #20a073;
          }
          &:nth-of-type(4) {
            background: #fceeef;
            color: #c26167;
          }
          &:nth-of-type(5) {
            background: #fcfaee;
            color: #a89526;
          }
          &:nth-of-type(6) {
            background: #fceeee;
            color: #f14949;
          }
          &:nth-of-type(7) {
            background: #eeeffc;
            color: #4d54a6;
          }
          &:nth-of-type(8) {
            background: #f4fcee;
            color: #4fa00f;
          }
          &:nth-of-type(n + 5) {
            margin-top: 12px;
          }
        }
      }
    }

    ${mq.mobile} {
      padding: 35px 0 60px 0;
      .container {
        padding: 0 32px;
        .title {
          font-size: 20px;
          line-height: 30px;
        }
        .list {
          display: flex;
          li {
            width: calc(50% - 5px);
            padding: 24px;
            margin-left: 0;
            img {
              width: 36px;
            }
            p {
              font-size: 10px;
              font-weight: 600;
              line-height: 20px;
              margin-top: 6px;
            }
            &:nth-of-type(n + 3) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  `;

  const contents = [
    {
      title: '기업 브랜드/멤버십 몰',
      img: icon1,
    },
    {
      title: '기업/단체 복지몰',
      img: icon2,
    },
    {
      title: '제조업 전문몰',
      img: icon3,
    },
    {
      title: '공공/단체 사업화',
      img: icon4,
    },
    {
      title: '모바일앱 사업자',
      img: icon5,
    },
    {
      title: '소호/소상공인',
      img: icon6,
    },
    {
      title: '지역/커뮤니티',
      img: icon7,
    },
    {
      title: '대행사/인플루언서/MCN',
      img: icon8,
    },
  ];

  return (
    <div css={section3Style}>
      <div className="container">
        <div className="title">
          각 비즈니스 분야에
          <br />
          적합한 서비스를 제공해드립니다.
        </div>
        <ul className="list">
          {contents.map((v, k) => (
            <li key={k}>
              <img src={v.img} alt={`list-${k}`} />
              <p>{v.title}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Section3;
