/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import list1Img from 'static/revenue/section1_list1.png';
import list2Img from 'static/revenue/section1_list2.png';
import { useIsMobile } from 'core/hooks/useResponsive';

const Section1 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const Section1Style = css`
    display: flex;
    justify-content: center;
    .container {
      width: 1068px;
      padding-bottom: 200px;
      margin: 0 auto;
      > .title {
        color: #000;
        font-weight: 700;
        font-size: 56px;
        line-height: 76px;
        text-align: center;
        span {
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 120px;
        margin-top: 120px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          &:nth-of-type(2) {
            flex-direction: row;
          }
          .text {
            .title {
              margin-top: 10px;
              font-size: 40px;
              font-weight: 800;
              line-height: 60px; /* 150% */
              span {
                color: #6079ff;
              }
            }
            .desc {
              margin-top: 10px;
              color: #444;
              /* color: #000; */
              font-size: 20px;
              font-weight: 400;
              line-height: 30px; /* 150% */
            }
          }
          .img {
            width: 600px;
            flex-shrink: 0;
            border-radius: 32px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 40px 0px;
            background: #fff;
            img {
              width: 100%;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 20px 20px 96px;
        width: 100%;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 45px; /* 140.625% */
        }
        .list {
          gap: 64px;
          margin-top: 64px;
          li {
            align-items: flex-start;
            flex-direction: column-reverse;
            &:nth-of-type(2) {
              flex-direction: column-reverse;
            }
            .text {
              padding: 0px;
              text-align: left;
              margin-bottom: 24px;
              .icon {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
              .title {
                font-size: 28px;
                line-height: 40px; /* 142.857% */
                margin-top: 0;
              }
              .desc {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const scaleVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section1Style}>
      <div className="container">
        <h2 className="title">
          수익 실현의 시작,
          <br />
          <span>5분만에</span> 원하는 상품을 갖춘 {isMobile && <br />}쇼핑몰
          완성!
        </h2>
        <ul className="list">
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list1Img}
                alt="상품 걱정 없이, 몰파이 상품 연동 | 이미지"
                title="상품 걱정 없이, 몰파이 상품 연동"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                상품 걱정 없이,
                <br />
                몰파이 <span>상품 연동</span>
              </h3>
              <p className="desc">
                몰파이에 입점된 50,000여개 상품을 <br />내 쇼핑몰에 연동하여
                판매할 수 있어요.
              </p>
            </motion.div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list2Img}
                alt="전환율을 높이는 쇼핑몰 디자인 설정 | 이미지"
                title="전환율을 높이는 쇼핑몰 디자인 설정"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                전환율을 높이는
                <br />
                쇼핑몰 <span>디자인 설정</span>
              </h3>
              <p className="desc">
                복잡도는 줄이고, 상품은 더 매력적으로
                <br />
                보여 줄 쇼핑몰 디자인을 시작해 보세요.
              </p>
            </motion.div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section1;
