/** @jsxImportSource @emotion/react */
import React, { ChangeEventHandler, forwardRef } from 'react';
import { css } from '@emotion/react';

interface CheckboxProps {
  children?: React.ReactNode;
  defaultChecked?: boolean;
  checked?: boolean;
  bold?: boolean;
  prefix?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  id?: string;
  className?: string;
}

const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>(
  (
    {
      children,
      onChange,
      checked,
      defaultChecked,
      prefix,
      bold,
      id,
      className,
    },
    ref,
  ) => {
    const CheckboxStyle = css`
      display: flex;
      label {
        display: inline-flex;
        position: relative;
        align-items: center;
        cursor: pointer;
        .checkbox {
          padding: 0;
          position: relative;
          cursor: pointer;
          align-items: center;
          vertical-align: middle;

          input {
            position: absolute;
            opacity: 0;
          }
          span {
            display: block;
            width: 24px;
            height: 24px;
            border: 1px solid #e1e1e1;
            border-radius: 4px;
          }
          input:checked + span {
            border: 1px solid #6079ff;
            background: #6079ff;
            ::after {
              content: '';
              display: block;
              width: 4px;
              height: 10px;
              border-bottom: 2px solid #fff;
              border-right: 2px solid #fff;
              transform: rotate(45deg);
              position: absolute;
              left: 9px;
              top: 4px;
            }
          }
        }
        .inner {
          padding: 0 0 0 12px;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
        }
      }
      .prefix {
        display: block;
        margin-top: 5px;
        padding-left: 34px;
        font-size: 12px;
        color: #ff1515;
      }
    `;

    return (
      <div css={[CheckboxStyle]}>
        <label ref={ref}>
          <span className="checkbox">
            <input
              type="checkbox"
              onChange={onChange}
              checked={checked}
              defaultChecked={defaultChecked}
              id={id}
              className={className}
            />
            <span />
          </span>
          <span className="inner">{children}</span>
        </label>
        {prefix && <span className="prefix">{prefix}</span>}
      </div>
    );
  },
);

export default Checkbox;
