import { create } from 'zustand';

interface ConfirmStore {
  visible: boolean;
  title: string;
  children: string;
  confirmBtnMn: string;
  cancelBtnNm: string;
  openConfirm: (
    data: Confirm,
    confirmAction?: () => void,
    cancelAction?: () => void,
  ) => void;
  cancelAction?: () => void;
  confirmAction?: () => void;
  closeConfrim: (key?: string) => void;
}

interface Confirm {
  title: string;
  children: string;
  confirmBtnMn: string;
  cancelBtnNm: string;
}

const initialState = {
  visible: false,
  confirmAction: undefined,
  cancelAction: undefined,
};

const useConfirmStore = create<ConfirmStore>()(set => ({
  visible: false,
  confirmBtnMn: '확인',
  cancelBtnNm: '취소',
  title: '',
  children: '',
  openConfirm: (data, confirmAction, cancelAction) =>
    set({
      ...data,
      visible: true,
      confirmAction,
      cancelAction,
    }),
  closeConfrim: () => set({ ...initialState }),
}));

export default useConfirmStore;
