/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useMypage } from 'core/queries/mypage';

const UserInfo = () => {
  const { mq } = useTheme();
  const { data: mypageData } = useMypage();

  if (mypageData === undefined) return null;

  const { userEml, userNm, nickname, mallPiePoint, cntCoupon } = mypageData;

  const wrapperStyle = css`
    padding-top: 42px;
    margin: 0 auto;
    max-width: 840px;
    .user {
      text-align: center;
      .name {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
      }
      .nickname {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #6079ff;
      }
      .email {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .info {
      margin: 32px auto;
      padding: 20px;
      max-width: 420px;
      display: flex;
      justify-content: center;
      background: #f6f6f6;
      border-radius: 4px;
    }
    .item {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
      .content {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    ${mq.mobile} {
      padding: 0px 20px;
      .user {
        .name {
          font-size: 28px;
          line-height: 42px;
        }
        .nickname {
          font-size: 14px;
          line-height: 22px;
        }
        .email {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .info {
        font-size: 14px;
        line-height: 22px;
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      <div className="user">
        <div className="name">{userNm}</div>
        <div className="nickname">{nickname}</div>
        <div className="email">{userEml}</div>
      </div>
      <div className="info">
        <div className="item">
          <div className="title">몰파이 적립금</div>
          <div className="content">{mallPiePoint.toLocaleString()}P</div>
        </div>
        <div className="item">
          <div className="title">쿠폰</div>
          <div className="content">{cntCoupon}장</div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
