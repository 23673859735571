/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const BannerStyle = css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  img {
    width: 1024px;
    margin: 0 auto;
    cursor: pointer;
  }

  .show-not-today {
    display: flex;
    position: absolute;
    align-items: center;
    top: 10px;
    right: 10px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    img {
      margin-left: 5px;
      color: #fff;
      width: 18px;
    }
  }
`;

export const BannerStyleM = css`
  display: table;
  width: 100%;
  height: 90px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  overflow: hidden;

  img {
    display: flex;
    height: 90px;
    margin: 0 auto;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .show-not-today {
    display: flex;
    position: absolute;
    align-items: center;
    bottom: 8px;
    right: 10px;
    font-size: 10px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    img {
      color: #fff;
      width: 12px;
    }
  }
`;
