/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';

interface TitleProps {
  img: string;
  titleAccent: string;
  title: string;
  text: string;
  mText: string;
}

const Title = ({ img, titleAccent, title, text, mText }: TitleProps) => {
  const { mq } = useTheme();

  const titleStyle = css`
    text-align: center;
    padding: 120px 0 200px 0px;
    img {
      width: 134px;
    }
    h2 {
      font-size: 76px;
      font-weight: 600;
      line-height: 96px;
      span {
        font-size: inherit;
        font-weight: inherit;
        color: #6079ff;
      }
    }
    .text {
      margin-top: 14px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      white-space: pre-wrap;
    }

    ${mq.mobile} {
      padding: 78px 0 115px 0px;
      img {
        width: 100px;
      }
      h2 {
        font-size: 30px;
        line-height: 40px;
      }
      .text {
        font-size: 14px;
        line-height: 22px;
      }
    }
  `;
  return (
    <div css={titleStyle}>
      <img src={img} alt="icon" />
      <h2>
        <span>{titleAccent}</span> {title}
      </h2>
      <div className="text">
        <PC>{text}</PC>
        <Mobile>{mText}</Mobile>
      </div>
    </div>
  );
};

export default Title;
