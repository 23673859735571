import { axiosAuth } from 'core/config/axios';
import { useInfiniteQuery } from 'react-query';

import { purchaseKeys } from 'constants/queryKeys';

export const usePurchaseList = () => {
  return useInfiniteQuery(
    [...purchaseKeys.all],
    async ({ pageParam = 1 }) => {
      const { data } = await axiosAuth.get(
        `/my-mallpie/buys?page=${pageParam}&size=10`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage) return undefined;

        const { hasNextPage, nextPage } = lastPage.data;
        return hasNextPage ? nextPage : undefined;
      },
    },
  );
};
