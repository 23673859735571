/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'static/intro/arrow_down_gray.svg';
import { motion } from 'framer-motion';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

const Faq = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const faqStyle = css`
    display: flex;
    justify-content: center;
    background: #f9f9f9;
    .container {
      max-width: 1200px;
      padding: 160px 0px;
      > .title {
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        text-align: left;
      }
      .list {
        margin-top: 28px;
        li {
          padding: 30px 0px;
          border-bottom: 1px solid #f2f2f2;
          width: 52.083vw;
          cursor: pointer;
          .title {
            color: #000;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
            }
          }
          .cont {
            margin-top: 16px;
            color: #444;
            font-size: 20px;
            font-weight: 300;
            line-height: 32px;
            white-space: pre-wrap;
          }
          &.active {
            .title {
              color: #6079ff;
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 0px;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 48px;
        }
        .list {
          margin-top: 20px;
          li {
            padding: 20px 0px;
            width: 89.333vw;
            .title {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
            .cont {
              font-size: 16px;
              font-weight: 300;
              line-height: 26px;
            }
          }
        }
      }
    }
  `;
  const [active, setActive] = useState(1);

  const faqList = [
    {
      title: '가입 시, 사업자 유형을 선택하는 기준이 무엇인가요?',
      content:
        '세금계산서 발행 유무를 기준으로 두가지 유형으로 진행됩니다.\n\n1) 개인/간이 과세자 (세금계산서 미발급): 사업소득으로서 수익에서 소득세 3.3%(원천징수)를 제하고 별도의 세금계산서 발행 과정 없이 등록된 계좌로 입금해드립니다.\n\n2) 사업자 (세금계산서 발급): 환급 신청한 금액(부가세 포함)에 대한 세금계산서를 몰파이 운영사인 (주)지니웍스로 발행해주셔야 합니다.\n\n세금계산서 발행에 필요한 이메일 및 (주)지니웍스의 사업자 등록증은 파트너 어드민 공지사항에 안내되어 있습니다.',
    },
    {
      title:
        '운영자 외에도 여러 사람을 관리자로 설정하여 모임 쇼핑몰을 관리할 수 있나요?',
      content:
        '네!  [부운영자 기능]을 활용하여 여러 아이디로 우리 쇼핑몰을 관리할 수 있습니다. \n[파트너 어드민의 판매설정 > 부운영자 관리 메뉴]에서 초대하고 싶은 사람의 이메일을 입력하고 원하는 권한을 부여할 수 있습니다.',
    },
    {
      title: '수익금 정산은 어떤 기준으로 어떻게 진행되나요?',
      content:
        '수익금 정산은 구매 확정된 날짜를 기준으로 익월 예치금으로 적립해드립니다.\n예치금으로 적립된 수익금은 10,000원 이상부터 계좌로 환급 신청이 가능합니다.',
    },
  ];

  return (
    <div css={faqStyle}>
      <div className="container">
        <h2 className="title">자주 묻는 질문</h2>
        <ul className="list">
          {faqList.map((v, k) => {
            return (
              <li
                key={k}
                className={active === k + 1 ? 'active' : ''}
                onClick={() => setActive(active === k + 1 ? 0 : k + 1)}
              >
                <h3 className="title">
                  {v.title}
                  <ArrowDownIcon />
                </h3>
                <motion.div
                  className="cont"
                  style={{
                    y: active === k + 1 ? '' : '',
                    display: active === k + 1 ? 'block' : 'none',
                  }}
                >
                  {v.content}
                </motion.div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Faq;
