/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const Section1Style = css`
  background-color: #5b61f0;
  display: grid;
  place-items: center;
  margin: 0 auto;
  img {
    width: 640px;
  }

  .button-img {
    cursor: pointer;
  }
`;

export const Section1StyleM = css`
  background-color: #5b61f0;
  display: grid;
  place-items: center;
  margin: 0 auto;
  img {
    width: 100%;
  }

  .button-img {
    cursor: pointer;
  }
`;
