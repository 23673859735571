/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'static/intro/arrow_down_gray.svg';
import { motion } from 'framer-motion';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

const Faq = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const faqStyle = css`
    display: flex;
    justify-content: center;
    background: #f9f9f9;
    .container {
      max-width: 1200px;
      padding: 160px 0px;
      /* margin: 0 auto; */
      > .title {
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        text-align: left;
      }
      .list {
        margin-top: 28px;
        li {
          padding: 30px 0px;
          border-bottom: 1px solid #f2f2f2;
          width: 52.083vw;
          cursor: pointer;
          .title {
            color: #000;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px; /* 150% */
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
            }
          }
          .cont {
            margin-top: 16px;
            color: #444;
            font-size: 20px;
            font-weight: 300;
            line-height: 32px; /* 160% */
            white-space: pre-wrap;
          }
          /* &:last-of-type {
            border-bottom: 1px solid #bfbfbf;
          } */
          &.active {
            .title {
              color: #6079ff;
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 0px;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 48px; /* 150% */
        }
        .list {
          margin-top: 20px;
          li {
            padding: 20px 0px;
            width: 89.333vw;
            .title {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            .cont {
              font-size: 16px;
              font-weight: 300;
              line-height: 26px;
            }
          }
        }
      }
    }
  `;
  const [active, setActive] = useState(1);

  const faqList = [
    {
      title: '일반 개인도 정말로 아무 조건/비용 없이 쇼핑몰을 만들 수 있나요?',
      content:
        '별도 비용이나 조건없이 누구나 몰파이 서비스를 이용할 수 있습니다.\n지금 나의 몰을 개설하고, 수익 만들기를 시작해 보세요!',
    },
    {
      title: '상품 판매하고 얼마의 수익을 받을 수 있나요?',
      content:
        '상품마다 고정된 비율이 아닌, 상품별로 최대 40%까지 수익금이 책정되어 있습니다.\n상품 추가 화면에서 상품별 예상 수익금을 확인할 수 있습니다. ',
    },
    {
      title: '제가 만든 쇼핑몰에서 제가 구매해도 수익을 받을 수 있나요?',
      content:
        '네, 몰파이에서는 가능합니다.\n동일하게 예치금이 쌓이고, 정산 기준 금액(10,000원 이상)이 되면 환급 신청이 가능합니다. ',
    },
  ];

  return (
    <div css={faqStyle}>
      <div className="container">
        <h2 className="title">자주 묻는 질문</h2>
        <ul className="list">
          {faqList.map((v, k) => {
            return (
              <li
                key={k}
                className={active === k + 1 ? 'active' : ''}
                onClick={() => setActive(active === k + 1 ? 0 : k + 1)}
              >
                <h3 className="title">
                  {v.title}
                  <ArrowDownIcon />
                </h3>
                <motion.div
                  className="cont"
                  style={{
                    y: active === k + 1 ? '' : '',
                    display: active === k + 1 ? 'block' : 'none',
                  }}
                >
                  {v.content}
                </motion.div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Faq;
