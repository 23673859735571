/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import Button from 'components/common/Button';
import { ReactComponent as ArrowDownIcon } from 'static/user/mypage/arrowDown.svg';
import NoData from '../../NoData';
import CouponPopup from '../CouponPopup';

interface CouponDataProps {
  cpnSn: number;
  cpnNm: string;
  disctValue: number;
  cpnCn: string;
  shopAllYn: string;
  bnefDivCd: string;
  cpnTypeCd: 'PROD' | 'ORDR';
  useTermCd: string;
  minUseAmt: number;
  issueAfterDays: number;
  cpnExpireYmd: string;
  cpnExpireTime: string;
}

export interface InfiniteData<TData> {
  pages: TData[];
  pageParams: unknown[];
}

export interface CouponData {
  list: CouponDataProps[];
  pageNum: number;
  pageSize: number;
  pages: number;
  total: number;
  isLastPage: boolean;
}

interface CouponListPageProps {
  data: InfiniteData<CouponData>;
  loadMore: () => void;
  hasMore: boolean;
}

interface SearchProps {
  cpnSn: number;
  cpnNm: string;
  shopAllYn: string;
}

const CouponList = ({ data, loadMore, hasMore }: CouponListPageProps) => {
  const { mq } = useTheme();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [buttonMore, setButtonMore] = useState<boolean>(false);
  const [couponSearch, setCouponSearch] = useState<SearchProps>();

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getDday = day => {
    if (moment(day).isSame(moment(), 'day')) {
      return -1;
    }
    return moment(day).diff(moment(), 'days');
  };

  const couponListStyle = css`
    .list {
      padding-bottom: 42px;
      margin: 0 auto;
      max-width: 840px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
    .item {
      padding: 20px;
      border: 1px solid #ebebeb;
      border-radius: 4px;
      background: #ffffff;
      .type {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #6079ff;
        padding-bottom: 12px;
        border-bottom: 1px solid #ebebeb;
      }
      .type.prod {
        color: #1cc100;
      }
      .sub {
        padding-top: 12px;
        .title {
          margin-bottom: 4px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .h2 {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 12px;
        .expire {
          display: inline-flex;
          border-radius: 4px;
          padding: 4px 6px;
          font-size: 12px;
          line-height: 18px;
          font-weight: bold;
          background: rgba(251, 59, 59, 0.1);
          color: rgb(251, 59, 59);
          margin-left: 8px;
        }
      }
      .desc {
        margin-bottom: 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #909090;
        span.active {
          color: #fb3b3b;
          font-weight: 700;
        }
      }
      .mall {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        div {
          padding-left: 0.4em;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-decoration-line: underline;
          color: #1e90ff;
          cursor: pointer;
        }
        .shop-all {
          color: #000000;
          cursor: default;
          text-decoration-line: none;
        }
      }
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 42px;
      button {
        display: flex;
        gap: 8px;
      }
    }

    ${mq.mobile} {
      .list {
        padding: 0px 20px 32px;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  `;

  return (
    <div css={couponListStyle}>
      {data?.pages[0].total !== 0 ? (
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={buttonMore ? hasMore : false}
          className="list"
        >
          {data?.pages.map(pageData =>
            pageData.list.map(item => (
              <div className="item" key={item.cpnSn}>
                <div
                  className={`type ${item.cpnTypeCd === 'PROD' ? 'prod' : ''}`}
                >
                  {item.cpnTypeCd === 'PROD' ? '상품 쿠폰' : '주문 쿠폰'}
                </div>
                <div className="sub">
                  <div className="title">{item.cpnNm}</div>
                  <div className="h2">
                    {item.disctValue.toLocaleString()}
                    {item.bnefDivCd === 'AMT' ? '원' : '%'}
                    {item.cpnExpireYmd && getDday(item.cpnExpireYmd) <= 7 && (
                      <div className="expire">만료전에 사용하세요</div>
                    )}
                  </div>
                  <div className="desc">
                    <div>
                      {item.minUseAmt.toLocaleString()}원 이상 구매시 사용 가능
                    </div>
                    <div>
                      {item.useTermCd === '01' && (
                        <p>
                          {moment(item.cpnExpireYmd).format('YYYY.MM.DD')}{' '}
                          {item.cpnExpireTime || '00'}
                          시까지 (
                          <span
                            className={
                              getDday(item.cpnExpireYmd) <= 7 ? 'active' : ''
                            }
                          >
                            D-
                            {getDday(item.cpnExpireYmd) < 0
                              ? 'day'
                              : getDday(item.cpnExpireYmd) + 1}
                          </span>
                          )
                        </p>
                      )}
                    </div>
                    {item.useTermCd === '02' && (
                      <p>발급 후 {item.issueAfterDays}일까지</p>
                    )}
                  </div>
                  <div className="mall">
                    적용 :
                    {item.shopAllYn === 'Y' ? (
                      <div className="shop-all">
                        모든 몰파이 몰에서 사용 가능
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setCouponSearch({
                            cpnSn: item.cpnSn,
                            cpnNm: item.cpnNm,
                            shopAllYn: item.shopAllYn,
                          });
                          handleModalOpen();
                        }}
                      >
                        적용몰 모두보기
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )),
          ) || <></>}
        </InfiniteScroll>
      ) : (
        <NoData text="쿠폰이 없습니다." />
      )}
      {hasMore && (
        <div className="button">
          <Button
            color="gray"
            variant="outlined"
            onClick={() => setButtonMore(true)}
          >
            더보기
            <ArrowDownIcon className="arrow" stroke="#667085" />
          </Button>
        </div>
      )}
      <CouponPopup
        open={modalOpen}
        onClose={handleModalClose}
        couponSn={couponSearch}
      />
    </div>
  );
};

export default CouponList;
