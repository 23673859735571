/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useRef, useState } from 'react';

import { css, keyframes, useTheme } from '@emotion/react';
import { ReactComponent as ArrowDown } from 'static/common/arrow_down.svg';

interface DropdownProps {
  title: string;
  index: number;
  children: React.ReactNode;
  color: 'white' | 'black';
  isActive?: number;
}

const Dropdown = ({
  title,
  children,
  color,
  index,
  isActive,
}: DropdownProps) => {
  const dropMenuRef = useRef<HTMLDivElement>(null);
  const { mainColor } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const onClickMenu = useCallback(() => {
    if (!isOpen) {
      setIsOpen(true);
      setIsVisible(true);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setIsOpen(false);
      }, 400);
    }
  }, [isOpen]);

  const onEnterMouse = useCallback(() => {
    setIsOpen(true);
    setIsVisible(true);
  }, [isOpen]);

  const onLeaveMouse = useCallback(() => {
    setIsOpen(false);
    setIsVisible(false);
  }, [isOpen]);

  useEffect(() => {
    if (isActive !== undefined && isActive !== index) {
      setIsVisible(false);
      setTimeout(() => {
        setIsOpen(false);
      }, 400);
    }
  }, [isActive, index]);

  const fadeIn = keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
`;

  const fadeOut = keyframes`
     0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
`;

  const dropdownStyle = css`
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    font-size: inherit;
    cursor: pointer;
    &:hover {
      color: #6079ff;
      > svg {
        stroke: #6079ff;
      }
    }

    .link {
      font-weight: 700;
    }
    > svg {
      margin-left: 4px;
      width: 16px;
      transform: ${isVisible ? 'rotate(180deg)' : 'rotate(0)'};
    }
    .fade-in {
      overflow: hidden;
      cursor: default;
    }

    .fade-in > ul {
      animation: ${fadeIn} 0.4s ease;
    }

    .fade-out {
      overflow: hidden;
    }

    .fade-out > ul {
      animation: ${fadeOut} 0.4s ease;
    }
  `;

  return (
    <div
      css={dropdownStyle}
      onMouseEnter={onEnterMouse}
      onMouseLeave={onLeaveMouse}
      ref={dropMenuRef}
    >
      <div className="link">{title}</div>
      <ArrowDown stroke={color} />
      {isOpen && (
        <div className={isVisible ? 'fade-in' : 'fade-out'}>{children}</div>
      )}
    </div>
  );
};

export default Dropdown;
