/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Search from 'components/cs/Search';
import { Link, useNavigate } from 'react-router-dom';

interface Menu {
  key: string;
  value: string;
  path: string;
}

interface MenuPorps {
  menus?: Menu[];
  type?: string;
  category?: string;
}

const Menu = ({ menus = [], type, category }: MenuPorps) => {
  const navigator = useNavigate();

  const MenuStyle = css`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    a.link {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      display: block;
      font-weight: 700;
    }
    li {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      color: #98a2b3;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #6079ff;
        color: #6079ff;
      }
    }
  `;
  return (
    <ul css={MenuStyle}>
      {menus.map(v => (
        <li key={v.key} className={v.key === category ? 'active' : ''}>
          <Link to={`${v.path}`} className="link">
            {v.value}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Menu;
