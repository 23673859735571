/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';
import { ReactComponent as DownloadSimple } from 'static/welfare/DownloadSimple.svg';
import { motion, useInView, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

const Bottom = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const { scrollYProgress } = useScroll();

  const scale = useTransform(
    scrollYProgress,
    [isMobile ? 0.2 : 0.5, isMobile ? 0.86 : 0.9],
    [2, 1],
  );

  const BottomStyle = css`
    padding: 0px 60px 60px;
    .box {
      border-radius: 48px;
      background: #282828;
      padding: 160px 0px;
      .container {
        width: 1352px;
        margin: 0 auto;
        > .title {
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 64px;
          text-align: center;
          color: #fff;
          span {
            color: #507d98;
          }
        }
        > .subTitle {
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin-top: 24px;
          text-align: center;
        }
        > .buttonContainer {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          .downloadButton {
            width: 288px;
            padding: 8px 8px 8px 45px;
            display: flex;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #4c6c82;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
            }
          }
          .welfareButton {
            margin-left: 24px;
            width: 288px;
            padding: 8px 8px 8px 45px;
            display: flex;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #72aed3;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      margin-top: 0;
      padding: 0px 18px 60px 18px;
      .box {
        border-radius: 24px;
        padding-top: 64px;
        padding-bottom: 64px;
        .container {
          width: 100%;
          padding: 0px 20px 0px 20px;
          > .title {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
          }
          > .subTitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
          > .buttonContainer {
            flex-direction: column-reverse;
            align-items: center;
            .downloadButton,
            .welfareButton {
              width: 175px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              padding: 8px 8px 8px 20px;
              margin-left: 0;
              margin-top: 0;
              .arrow {
                width: 28px;
                height: 28px;
                padding: 5px;
              }
            }
            .downloadButton {
              margin-top: 12px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={BottomStyle}>
      <motion.div ref={ref} className="box">
        <div className="container">
          <h2 className="title">
            커머스로 만드는
            <br />
            <span>새로운 기회의 시작</span>
          </h2>
          <div className="subTitle">
            몰파이와 함께, 새로운 성장 동력을 {isMobile && <br />} 만들어
            보세요!
          </div>
          <div className="buttonContainer">
            <a
              href="https://mallpie.biz/download"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="downloadButton">
                소개서 다운로드
                <div className="arrow">
                  <DownloadSimple className="down-img" />
                </div>
              </button>
            </a>
            <a href="/inquiry" target="_blank" rel="noopener noreferrer">
              <button className="welfareButton">
                쇼핑몰 도입 문의
                <div className="arrow">
                  <ArrowRight className="arrow-img" />
                </div>
              </button>
            </a>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Bottom;
