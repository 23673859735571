/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { SwiperSlide, Swiper } from 'swiper/react';
import Icon1 from 'static/download/icon1.png';
import Icon2 from 'static/download/icon2.png';
import Icon3 from 'static/download/icon3.png';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const Section2 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section2Style = css`
    max-width: 998px;
    margin: 120px auto 0 auto;
    .box {
      .container {
        margin: 0 auto;
        > .title {
          text-align: center;
          font-size: 48px;
          font-weight: 700;
          line-height: 64px;
          span {
            display: block;
            color: #6079ff;
          }
        }
        > .subTitle {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-align: center;
          color: #6c737f;
          margin-top: 20px;
        }
        ul {
          margin-top: 26px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 24px;
          padding-right: 60px;
          padding: 30px 0px;
          li {
            width: 300px;
            padding: 30px;
            border-radius: 12px;
            background-color: #fff;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
            .icon {
              color: #000;
              font-size: 34px;
              font-style: normal;
              font-weight: 700;
              line-height: 34px;
            }
            .text {
              color: #000;
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px;
              span {
                font-weight: 700;
              }
            }
            .img {
              display: flex;
              margin-top: 40px;
              justify-content: flex-end;
              img {
                width: 120px;
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      margin: 96px auto 0 auto;
      width: 100%;
      .box {
        .container {
          > .title {
            font-size: 24px;
            line-height: 36px;
          }
          > .subTitle {
            font-size: 16px;
            line-height: 24px;
          }
          ul {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            margin-top: 0;
            li {
              width: 260px;
              padding: 24px;
              border-radius: 20px;
              background-color: #fff;
              .icon {
                font-size: 28px;
                font-style: normal;
                line-height: 30px;
              }
              .text {
                font-size: 18px;
                font-style: normal;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  `;

  const xVariants = {
    offscreen: {
      x: 200,
      y: 0,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      x: 0,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section2Style}>
      <div className="box">
        <div className="container">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
            className="title"
          >
            국내 유수의 기업이 선택한
            <br />
            <span>커머스 플랫폼, 몰파이</span>
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
            className="subTitle"
          >
            더 높은 도약을 위해 커머스 도입 및 변경을{isMobile && <br />} 고민
            중이라면
            {isPc && <br />} 1천만+ 고객을 보유한 기업들이 {isMobile && <br />}
            선택한 커머스 플랫폼, 몰파이와 함께 하세요!
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={isMobile ? xVariants : yVariants}
          >
            <Swiper
              className="list"
              tag="ul"
              slidesPerView="auto"
              slidesOffsetBefore={isPc ? 30 : 20}
              slidesOffsetAfter={isPc ? 30 : 20}
              spaceBetween={isPc ? 24 : 12}
            >
              <SwiperSlide tag="li">
                <div className="icon">“</div>
                <div className="text">
                  몰파이와 함께한 서비스는
                  <br />
                  <span>
                    고객 체류 시간 및 구매 전환율 <br />
                    상승
                  </span>
                  을 경험했습니다
                </div>
                <div className="img">
                  <img src={Icon1} alt="icon1" />
                </div>
              </SwiperSlide>
              <SwiperSlide tag="li">
                <div className="icon">“</div>
                <div className="text">
                  몰파이 커머스 도입 후, <br />
                  판매 수수료 기반의 <br />
                  <span>새 수익 모델</span>을 창출했습니다
                </div>
                <div className="img">
                  <img src={Icon2} alt="icon2" />
                </div>
              </SwiperSlide>
              <SwiperSlide tag="li">
                <div className="icon">“</div>
                <div className="text">
                  5만여 개의 상품군과 <br />
                  무료배송 등의 혜택으로 <br />
                  <span>브랜드 신뢰도</span>를 확보했습니다
                </div>
                <div className="img">
                  <img src={Icon3} alt="icon3" />
                </div>
              </SwiperSlide>
            </Swiper>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
