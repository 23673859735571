/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile } from 'components/common/Responsive';
import list1 from 'static/myshop/list/section2_1.png';
import list2 from 'static/myshop/list/section2_2.png';

const Section2 = () => {
  const { mq } = useTheme();

  const section2Style = css`
    text-align: center;
    padding: 110px 0 100px 0;
    .container {
      max-width: 1535px;
      padding: 0 160px;
      margin: 0 auto;

      .title {
        font-size: 48px;
        font-weight: 600;
        line-height: 68px;
      }
      .text {
        font-size: 18px;
        margin-top: 12px;
        font-weight: 200;
        line-height: 28px;
      }
      .list {
        margin-top: 66px;
        display: flex;
        justify-content: space-between;
        li {
          width: calc(50% - 32px);
          text-align: left;
          img {
            width: 100%;
          }
          h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            margin-top: 20px;
          }
          p {
            font-size: 18px;
            font-weight: 200;
            line-height: 28px;
          }
        }
      }
    }

    ${mq.mobile} {
      padding: 0 0 70px 0;
      .container {
        padding: 0 20px;
        .title {
          font-size: 24px;
          line-height: 36px;
        }
        .text {
          font-size: 14px;
          line-height: 21px;
        }
        .list {
          margin-top: 14px;
          display: flex;
          flex-direction: column;
          li {
            width: 100%;
            padding: 30px 0px;
            h3 {
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              margin-top: 21px;
            }
            p {
              font-size: 14px;
              font-weight: 200;
              line-height: 21px;
            }
          }
        }
      }
    }
  `;

  const contents = [
    {
      title: '데이터 추천',
      text: '몰파이 인공지능(AI)이 관심사, 현재 인기 있는\n상품 등을 분석하여 추천 상품을 제시해 줍니다.',
      img: list1,
    },
    {
      title: '몰파이에서 인기 있는 쇼핑몰 확인하기',
      text: '상품 등록이 고민된다면 인기 있는 쇼핑몰에서\n무엇을 판매하는지 확인하고 나의 쇼핑몰에도 등록해보세요.',
      img: list2,
    },
  ];

  return (
    <div css={section2Style}>
      <div className="container">
        <div className="title">무엇을 판매해야할지 고민이세요?</div>
        <div className="text">
          쇼핑몰 운영에 필수적인 기획전, 배너 등의
          <Mobile>
            <br />
          </Mobile>
          다양한 기능 등을 제공하며
          <br />
          나에게 필요하고 기능만 선택하여 사용할 수 있습니다.
        </div>
        <ul className="list">
          {contents.map((v, k) => (
            <li key={k}>
              <img src={v.img} alt={`list-${k}`} />
              <h3>{v.title}</h3>
              <p>{v.text} </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Section2;
