import { useState } from 'react';
/** @jsxImportSource @emotion/react */
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import InfiniteScroll from 'react-infinite-scroller';
import { useExpireReserves } from 'core/queries/mypage/point';
import Button from 'components/common/Button';
import { ReactComponent as ArrowDownIcon } from 'static/user/mypage/arrowDown.svg';

const ExpireContent = () => {
  const navigate = useNavigate();

  const { mq } = useTheme();

  const [buttonMore, setButtonMore] = useState<boolean>(false);

  const {
    data: expireReservesData,
    fetchNextPage,
    isFetchingNextPage: contentsLoading,
    hasNextPage,
  } = useExpireReserves({
    page: 1,
    size: 5,
  });

  const wrapperStyle = css`
    padding-bottom: 32px;
    margin: 0 auto;
    max-width: 840px;
    * {
      word-break: keep-all;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px;
      background: #f2f4f7;
      border-radius: 5px;
      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #98a2b3;
      }
      .number {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
        .icon {
          margin-right: 8px;
        }
      }
      .expire {
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #98a2b3;
        span {
          margin-left: 4px;
          color: #000000;
        }
      }
      .desc {
        button {
          padding: 2px 8px;
          font-size: 12px;
          font-weight: 700;
          border: 1px solid #e1e1e1;
        }
      }
    }
    .tabs {
      ul {
        padding: 32px 0px;
        display: flex;
        li {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: #98a2b3;
          cursor: pointer;
        }
        li.active {
          color: #6079ff;
          border-bottom: 2px solid #6079ff;
        }
        li + li {
          margin-left: 20px;
        }
      }
    }
    .content {
      .sub-info {
        padding: 20px 0px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #909090;
        border-bottom: 1px solid #e1e1e1;
      }
      .item {
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        * {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        .num {
          color: #fb3b3b;
        }
      }
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 42px;
        margin-top: 32px;
        button {
          display: flex;
          gap: 8px;
        }
      }
    }

    ${mq.mobile} {
      padding: 0px 20px 50px;
      .search {
        flex-direction: column;
        .button {
          margin-bottom: 12px;
        }
        button {
          padding: 10px 0px;
        }
      }
      .date {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      .react-datepicker-wrapper,
      .react-datepicker-wrapper input {
        width: 100%;
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      <div className="tabs">
        <div className="content">
          <div className="sub-info">
            30일 내 소멸 예정 포인트만 조회되며, 매일 자정경에 업데이트 됩니다.
          </div>
          <div className="list">
            <InfiniteScroll
              loadMore={fetchNextPage}
              hasMore={buttonMore ? hasNextPage : false}
              className="list"
            >
              {expireReservesData?.pages.map(pageData =>
                pageData.list.map((item, index) => (
                  <div className="item" key={index}>
                    <div>{item.expireYmd} 소멸예정</div>
                    <div className="num">{item.rsve.toLocaleString()}</div>
                  </div>
                )),
              ) || <></>}
            </InfiniteScroll>
            {hasNextPage && (
              <div className="button">
                <Button
                  color="gray"
                  variant="outlined"
                  onClick={() => setButtonMore(true)}
                >
                  더보기
                  <ArrowDownIcon className="arrow" stroke="#667085" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpireContent;
