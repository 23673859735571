/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import {
  useIsMobile,
  useIsMobileSmall,
  useIsPc,
} from 'core/hooks/useResponsive';

import list1Img from 'static/class/section2_list1.png';
import list2Img from 'static/class/section2_list2.png';
import list3Img from 'static/class/section2_list3.png';
import list4Img from 'static/class/section2_list4.png';
import { motion } from 'framer-motion';

const Section2 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isMobileSmall = useIsMobileSmall();
  const isPc = useIsPc();
  const sectionStyle = css`
    display: flex;
    justify-content: center;
    background: #353535;
    .container {
      max-width: 1200px;
      padding: 180px 0px;

      > .title {
        color: #fff;
        text-align: center;
        font-size: 56px;
        font-weight: 800;
        line-height: 76px;
        margin-bottom: 56px;
        span {
          color: #6079ff;
          font-size: 56px;
          font-weight: 800;
          line-height: 76px;
        }
      }
      .list {
        li {
          margin-top: 20px;
          background: #fff;
          border-radius: 36px;
          display: flex;
          width: 1200px;
          height: 564px;
          padding-left: 64px;
          justify-content: space-between;
          align-items: center;
          .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            .title {
              font-size: 32px;
              font-weight: 700;
              line-height: 48px;
              margin-bottom: 20px;
              > span {
                color: #6079ff;
              }
            }
            .desc {
              color: #444;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
            }
          }
          .img {
            overflow: hidden;
            width: 564px;
            img {
              width: 100%;
            }
          }
          &:nth-of-type(1) {
            margin-top: 0;

            background: #fff;
            padding-right: 70px;
          }
          &:nth-of-type(2) {
            padding-right: 92px;
            background: #dae0ff;
            flex-direction: row-reverse;
            .text {
              .title {
                color: #22307e;
              }
              .desc {
                color: #22307e;
                opacity: 0.7;
              }
            }
          }
          &:nth-of-type(3) {
            background: #6079ff;
            .text {
              .title {
                color: #fff;
              }
              .desc {
                color: #fff;
                opacity: 0.6;
              }
            }
          }
          &:nth-of-type(4) {
            padding-left: 0;
            background: #d3e4f4;
            flex-direction: row-reverse;
            padding-right: 65px;
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px;
        width: 100%;
        > .title {
          font-size: 28px;
          font-weight: 800;
          line-height: 40px;
          margin-bottom: 32px;
          span {
            font-size: 28px;
            line-height: 40px;
          }
        }
        .list {
          li {
            margin-top: 20px;
            border-radius: 20px;
            width: 100%;
            height: auto;
            padding: 0 !important;
            gap: 0;
            flex-direction: column !important;
            align-items: flex-start;
            .text {
              padding: 36px 30px 0px 30px !important;
              .title {
                color: #000;
                font-size: 24px;
                font-weight: 800;
                line-height: 32px;
                letter-spacing: -0.24px;
              }
              .desc {
                color: #444;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={sectionStyle}>
      <div className="container">
        <h2 className="title">
          몰파이로 {isPc && '물품'} 구매는 더 쉽게,
          <br />
          <span>
            추가 수익으로 모임비까지 {isMobileSmall && <br />} 챙기세요{' '}
          </span>
        </h2>
        <ul className="list">
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                반복적으로 구매하는
                <br />
                물건을 최저가로 더 싸게
              </h3>
              <p className="desc">
                우리 모임에서 주기적으로 구매하는 {isMobile && <br />}물건이
                있나요? {isPc && <br />}
                몰파이에 담아 최저가로 {isMobile && <br />}구매하고, 쌓인
                수익금은 <br />더 즐거운 모임 활동에 쓰세요!
              </p>
            </div>
            <div className="img">
              <img
                src={list1Img}
                alt="반복적으로 구매하는 물건을 최저가로 더 싸게 | 이미지"
                title="반복적으로 구매하는 물건을 최저가로 더 싸게"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                함께 구매, 투명한 관리
                <br />
                몰파이 모임 쇼핑몰
              </h3>
              <p className="desc">
                총무 부담없이 누구든 모임에 필요한 {isMobile && <br />} 물건을
                {isPc && <br />}
                담을 수 있고, 누구든 구매 내역을{isMobile && <br />} 확인할 수
                있는 {isPc && <br />}
                행복한 몰파이 모임 쇼핑몰!
              </p>
            </div>
            <div className="img">
              <img
                src={list2Img}
                alt="함께 구매 투명한 관리 몰파이 모임 쇼핑몰 | 이미지"
                title="함께 구매 투명한 관리 몰파이 모임 쇼핑몰"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                구매만으로도 리워드가
                <br />
                쌓이는 돈 버는 모임구매
              </h3>
              <p className="desc">
                모임에 필요한 물품들을 대량 구매하면, <br />
                더 많이 쌓이는 리워드! <br />이 리워드로 모임에 필요한 물건을{' '}
                {isMobile && <br />} 추가 구매하거나, {isPc && <br />}
                쏠쏠한 회식비로 활용해요!
              </p>
            </div>
            <div className="img">
              <img
                src={list3Img}
                alt="구매만으로도 리워드가 쌓이는 돈 버는 모임구매 | 이미지"
                title="구매만으로도 리워드가 쌓이는 돈 버는 모임구매"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                모임 이름으로 된 하나의
                <br />
                쇼핑몰에서 편리하게
              </h3>
              <p className="desc">
                모임 물품 구매할 때, 매번 여기저기 쇼핑몰 {isMobile && <br />}
                링크 {isPc && <br />}
                공유하는 것도 일! {isMobile && <br />}이제 우리 모임 이름으로
                만들어진 {isPc && <br />}
                쇼핑몰에서 필요한 상품을 담아 쉽게 구매해요!
              </p>
            </div>
            <div className="img">
              <img
                src={list4Img}
                alt="모임 이름으로 된 하나의 쇼핑몰에서 편리하게 | 이미지"
                title="모임 이름으로 된 하나의 쇼핑몰에서 편리하게"
              />
            </div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section2;
