/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'static/intro/arrow_down_gray.svg';
import { motion } from 'framer-motion';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

const Faq = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const faqStyle = css`
    display: flex;
    justify-content: center;
    background: #f9f9f9;
    .container {
      max-width: 1200px;
      padding: 160px 0px;
      /* margin: 0 auto; */
      > .title {
        font-size: 50px;
        font-weight: 700;
        line-height: 50px;
        text-align: left;
      }
      .list {
        margin-top: 28px;
        li {
          padding: 30px 0px;
          border-bottom: 1px solid #f2f2f2;
          width: 52.083vw;
          cursor: pointer;
          .title {
            color: #000;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px; /* 150% */
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
            }
          }
          .cont {
            margin-top: 16px;
            color: #444;
            font-size: 20px;
            font-weight: 300;
            line-height: 32px; /* 160% */
            white-space: pre-wrap;
          }
          /* &:last-of-type {
            border-bottom: 1px solid #bfbfbf;
          } */
          &.active {
            .title {
              color: #6079ff;
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 0px;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 48px; /* 150% */
        }
        .list {
          margin-top: 20px;
          li {
            padding: 20px 0px;
            width: 89.333vw;
            .title {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            .cont {
              font-size: 16px;
              font-weight: 300;
              line-height: 26px;
            }
          }
        }
      }
    }
  `;
  const [active, setActive] = useState(1);

  const faqList = [
    {
      title: '팔로워를 활용해서 수익을 내고 싶어요.',
      content:
        '지금까지 내가 쌓은 콘텐츠에 맞는 상품을 담아서, 팔로워들에게 소개하세요! \n모든 운영(배송, 반품, CS 등)은 몰파이가 담당합니다. ',
    },
    {
      title: '쇼핑몰을 만들고 싶지만, 상품이 걱정이에요.',
      content:
        '몰파이에서는 내 상품이 없어도, 상품 소싱을 하지 않아도 쇼핑몰을 만들 수 있어요.\n몰파이에 등록되어있는 50,000여 개의 상품에서 자유롭게 선택하여 판매할 수 있어요.',
    },
    {
      title: '판매를 더 활성화 시키고 싶어요.',
      content:
        '판매 활성화를 위해, [쿠폰 기능]을 활용해 보세요! \n원하는 쿠폰 금액과 발급 수량을 설정하고 선결제로 쿠폰을 발급할 수 있습니다.\n그리고 [블로그] 메뉴에서 판매와 관련된 다양한 팁을 확인하고, 나의 몰에서 하나씩 활용해 보세요.',
    },
  ];

  return (
    <div css={faqStyle}>
      <div className="container">
        <h2 className="title">자주 묻는 질문</h2>
        <ul className="list">
          {faqList.map((v, k) => {
            return (
              <li
                key={k}
                className={active === k + 1 ? 'active' : ''}
                onClick={() => setActive(active === k + 1 ? 0 : k + 1)}
              >
                <h3 className="title">
                  {v.title}
                  <ArrowDownIcon />
                </h3>
                <motion.div
                  className="cont"
                  style={{
                    y: active === k + 1 ? '' : '',
                    display: active === k + 1 ? 'block' : 'none',
                  }}
                >
                  {v.content}
                </motion.div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Faq;
