/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { ReactComponent as ArrowRight } from 'static/home/arrow_right.svg';
import { ReactComponent as DownloadSimple } from 'static/welfare/DownloadSimple.svg';
import { motion, useInView, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';

const Bottom = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const { scrollYProgress } = useScroll();

  const BottomStyle = css`
    padding: 0px 60px;
    .box {
      border-radius: 48px;
      background: linear-gradient(0deg, #1f1f1f 0%, #6a91a9 100%);
      padding: 160px 0px;
      .container {
        width: 1352px;
        margin: 0 auto;
        > .title {
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 64px;
          text-align: center;
          color: #fff;
          span {
            color: #89d2fe;
          }
        }
        > .subTitle {
          color: #fff;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          margin-top: 24px;
          text-align: center;
          br {
            display: none;
          }
        }
        > .buttonContainer {
          display: flex;
          justify-content: center;
          margin-top: 40px;
          .downloadButton {
            width: 288px;
            padding: 8px 8px 8px 45px;
            display: flex;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #4c6c82;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
            }
          }
          .welfareButton {
            margin-left: 24px;
            width: 310px;
            padding: 8px 8px 8px 45px;
            display: flex;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            border-radius: 999px;
            border: 0;
            background: #72aed3;
            color: #fff;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .arrow {
              width: 57px;
              height: 57px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #111927;
              border-radius: 50%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      margin-top: 0;
      padding: 0px 18px 0px 18px;
      .box {
        border-radius: 24px;
        padding-top: 64px;
        padding-bottom: 64px;
        .container {
          width: 100%;
          padding: 0px 20px 0px 20px;
          > .title {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px;
          }
          > .subTitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            br {
              display: block;
            }
          }
          > .buttonContainer {
            flex-direction: column-reverse;
            align-items: center;
            .downloadButton,
            .welfareButton {
              width: 185px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 150%;
              padding: 8px 8px 8px 20px;
              margin-left: 0;
              margin-top: 0;

              .arrow {
                width: 30px;
                height: 30px;
                svg {
                  width: 18px;
                }
              }
            }
            .downloadButton {
              margin-top: 12px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={BottomStyle}>
      <motion.div ref={ref} className="box">
        <div className="container">
          <h2 className="title">
            구축비 0원, 조건 없이
            <br />
            <span>
              지금 복지몰을 {isMobile && <br />}
              시작할 수 있습니다
            </span>
          </h2>
          <div className="subTitle">
            간단한 가입으로 <br />
            가장 빠르게 복지몰을 만나 보세요!
          </div>
          <div className="buttonContainer">
            <a href="/inquiry" target="_blank" rel="noopener noreferrer">
              <button className="downloadButton">
                복지몰 도입 문의
                <div className="arrow">
                  <DownloadSimple className="down-img" />
                </div>
              </button>
            </a>
            <a
              href="https://partner.mallpie.biz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="welfareButton">
                지금 바로 시작하기
                <div className="arrow">
                  <ArrowRight className="arrow-img" />
                </div>
              </button>
            </a>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Bottom;
