/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { PC } from 'components/common/Responsive';
import list1 from 'static/business/guide/section1_1.png';
import list2 from 'static/business/guide/section1_2.png';
import list3 from 'static/business/guide/section1_3.png';

const Section1 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    padding: 200px 0 150px 0;
    .container {
      max-width: 1535px;
      padding: 0 40px;
      margin: 0 auto;
      .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
        span {
          font-weight: inherit;
          font-size: inherit;
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        li {
          flex: 1;
          .text {
            h3 {
              font-size: 48px;
              font-style: normal;
              font-weight: 700;
              line-height: 68px;
            }
            p {
              font-size: 16px;
              font-weight: 200;
              line-height: 24px;
              margin-top: 12px;
            }
          }
          img {
            margin-top: 37px;
            width: 600px;
          }

          &:nth-of-type(1) {
            margin-right: auto;
            margin-top: 0;
          }
          &:nth-of-type(2) {
            margin-top: -350px;
            margin-left: auto;
          }
          &:nth-of-type(3) {
            margin-top: -120px;
          }
          &:nth-of-type(4) {
            margin-top: -350px;
            margin-left: auto;
          }
        }
      }
    }

    ${mq.mobile} {
      padding-top: 0;
      .container {
        padding: 0;
        .title {
          font-size: 20px;
          line-height: 30px;
        }
        .list {
          li {
            margin-top: 0 !important;
            .text {
              padding: 128px 32px 35px 32px;
              h3 {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 36px;
              }
              p {
                font-size: 16px;
                font-weight: 200;
                line-height: 24px;
                margin-top: 12px;
              }
            }
            img {
              width: 100%;
              margin-top: 0;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={section1Style}>
      <div className="container">
        <ul className="list">
          <li>
            <div className="text">
              <h3>
                한 번의 상품 등록으로
                <br />
                다채널 동시 판매
              </h3>
              <p>
                몰파이 플랫폼에 입점된 판매처의 상품이 판매될 때 마다 <br />
                판매 수익의 일정금액을 몰을 운영하는 파트너 회원에게 지급합니다.
                <br />
                몰 운영자에게는 내 상품이 없이도 수익 창출이 가능하고, <br />
                동시에 입점사에게는 다양한 채널을 통해 상품이 판매되는 효과적인
                마케팅 경험을 제공합니다.
              </p>
            </div>
            <img src={list1} alt="phone1" />
          </li>
          <li>
            <div className="text">
              <h3>
                데이터 분석을 통한
                <br />
                타게팅 된 상품 광고 효과
              </h3>
              <p>
                몰파이 플랫폼에서 생성된 여러 몰의 특성에 따라, 고객의 구매 패턴
                및 상품 선호도를 분석하여
                <PC>
                  <br />
                </PC>
                입점사가 공급하는 각 상품에 대한 최적의 맞춤 상품을 고객에게
                추천합니다.
                <br />
                불필요한 상품 노출을 최소화하여, 상품의 구매전환을 높여 입점사의
                판매 수익을 높일 수 있습니다.
              </p>
            </div>
            <img src={list2} alt="phone1" />
          </li>
          <li>
            <div className="text">
              <h3>
                전문MD와의 지속적인 협업으로
                <br />
                상품 경쟁력 확보
              </h3>
              <p>
                몰파이는 운영사인 (주)지니웍스의 다년간의 커머스 비즈니스
                경험과,
                <PC>
                  <br />
                </PC>
                분야별 전문MD의 날카로운 시장 분석으로 이미 수천개의 입점사에게
                <PC>
                  <br />
                </PC>
                높은 수준의 서비스를 제공하고 있습니다.
                <br />
                기획 상품 및 프로모션, 이벤트 기획을 비롯한 포괄적인 마케팅을
                상시 지원해드립니다.
              </p>
            </div>
            <img src={list3} alt="phone1" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section1;
