export const mainKeys = {
  all: ['main'] as const,
  cover: () => [...mainKeys.all, 'cover'] as const,
  home: () => [...mainKeys.all, 'home'] as const,
  homeProduct: () => [...mainKeys.home(), 'product'] as const,
  curation: () => [...mainKeys.all, 'curation'] as const,
  curationProduct: () => [...mainKeys.curationProduct(), 'product'] as const,
  icon: () => [...mainKeys.all, 'icon'] as const,
  menu: () => [...mainKeys.all, 'menu'] as const,
  category: () => [...mainKeys.all, 'category'] as const,
};

export const mypageKeys = {
  all: ['mypage'] as const,
  lists: () => [...mypageKeys.all, 'list'] as const,
  detail: () => [...mypageKeys.all, 'detail'] as const,
};

export const couponKeys = {
  all: ['coupon'] as const,
  lists: () => [...couponKeys.all, 'list'] as const,
  detail: () => [...couponKeys.all, 'detail'] as const,
};

export const reservesKeys = {
  all: ['reserves'] as const,
  lists: () => [...reservesKeys.all, 'list'] as const,
  point: () => [...reservesKeys.all, 'point'] as const,
  expire: () => [...reservesKeys.all, 'expire'] as const,
};

export const userKeys = {
  all: ['user'] as const,
  lists: () => [...userKeys.all, 'list'] as const,
};

export const codeKeys = {
  all: ['code'] as const,
};

// 메인 배너
export const bannerKeys = {
  all: ['banner'] as const,
};

// 제일 잘나가는 상품
export const popularProductKeys = {
  all: ['popular'] as const,
};

export const purchaseKeys = {
  all: ['purchase'] as const,
  parcel: () => [...purchaseKeys.all, 'parcel'] as const,
};

export const dealKeys = {
  all: ['deal'] as const,
};

export const mymallKeys = {
  all: ['mymall'] as const,
};

// 몰파이 허브 메인
export const homeKeys = {
  all: ['home'] as const,
  banner: () => [...homeKeys.all, 'banner'] as const,
  popularProduct: () => [...homeKeys.all, 'popular-product'] as const,
  popularShop: () => [...homeKeys.all, 'popular-shop'] as const,
  curation: () => [...homeKeys.all, 'curation'] as const,
  newProduct: () => [...homeKeys.all, 'new-product'] as const,
  newShop: () => [...homeKeys.all, 'new-shop'] as const,
};

export const nowProductKeys = {
  all: ['product'] as const,
  recmd: () => [...nowProductKeys.all, 'recmd'] as const,
  new: () => [...nowProductKeys.all, 'new'] as const,
};

export const barBannerKeys = {
  all: ['bar-banner'] as const,
};

export const nowChannelKeys = {
  all: ['channel'] as const,
  pop: () => [...nowChannelKeys.all, 'pop'] as const,
  new: () => [...nowChannelKeys.all, 'new'] as const,
};
