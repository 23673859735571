import axios from 'axios';

export const encodeValue = btoa(
  `${process.env.REACT_APP_PUBLIC_CLIENT_ID}:${process.env.REACT_APP_PUBLIC_CLIENT_SECRET}`,
);

// 비로그인
export default axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${encodeValue}`,
    ShopId:
      typeof window !== 'undefined' && window.location.hostname.split('.')[0],
  },
});

// 로그인
export const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${encodeValue}`,
    ShopId:
      typeof window !== 'undefined' && window.location.hostname.split('.')[0],
  },
});

// 메인
export const axiosMain = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${encodeValue}`,
    ShopId:
      typeof window !== 'undefined' && window.location.hostname.split('.')[0],
  },
});
