/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Search from 'components/cs/Search';

interface MainProps {
  title: string;
  subTitle?: string;
  isSearch?: boolean;
  children: React.ReactNode;
}

const Main = ({ title, subTitle, isSearch = false, children }: MainProps) => {
  const { mq } = useTheme();
  const MainStyle = css`
    padding: 210px 0 248px 0;
    .wrap {
      width: 1238px;
      margin: 0 auto;
      > .title {
        font-size: 48px;
        font-style: normal;
        font-weight: 800;
        line-height: 56px;
        .sub {
          font-size: 28px;
          line-height: 40px;
          font-weight: 500;
          margin-top: 10px;
        }
      }
      .search {
        margin-top: 48px;
      }
      > .content {
        display: flex;
        margin-top: 80px;
        width: 100%;
        justify-content: space-between;
      }
    }
    ${mq.mobile} {
      padding: 80px 20px 48px 20px;
      .wrap {
        width: 100%;
        > .title {
          font-size: 32px;
          line-height: 48px;
          .sub {
            font-size: 18px;
            line-height: 27px;
          }
        }
        .search {
          margin-top: 40px;
        }
        > .content {
          margin-top: 40px;
          gap: 42px;
          flex-direction: column;
        }
      }
    }
  `;
  return (
    <div css={MainStyle}>
      <div className="wrap">
        <h2 className="title">
          {title}
          {subTitle && <div className="sub">{subTitle}</div>}
        </h2>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Main;
