/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import { Mobile, PC } from 'components/common/Responsive';
import { useNavigate } from 'react-router-dom';

const Section2 = () => {
  const { mq } = useTheme();
  const navigate = useNavigate();

  const section5Style = css`
    margin-top: 60px;
    padding: 96px 0px;
    background: #6079ff;
    text-align: center;
    color: #fff;
    .title {
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
    }
    .text {
      font-size: 18px;
      font-weight: 200;
      line-height: 28px;
      margin-top: 12px;
    }
    button {
      margin-top: 24px;
    }
    ${mq.mobile} {
      .title {
        font-size: 24px;
        line-height: 36px;
      }
      .text {
        font-size: 14px;
        line-height: 21px;
      }
    }
  `;
  return (
    <div css={section5Style}>
      <div className="title">기업 커머스를 위한 몰파이 솔루션</div>
      <div className="text">
        몰파이 플랫폼을 운영하는 (주)지니웍스는 기업 커머스
        <Mobile>
          <br />
        </Mobile>
        서비스 제공 및 운영에
        <PC>
          <br />
        </PC>
        다년간의 경험과 기술이 축적되어 있습니다.
      </div>
      <Button
        color="white"
        onClick={() => navigate('https://mallpie.biz/inquiry')}
      >
        비즈니스 및 도입 문의
      </Button>
    </div>
  );
};

export default Section2;
