import Section1 from 'components/promotion/Section1';
import Section2 from 'components/promotion/Section2';
import Section3 from 'components/promotion/Section3';
import Section4 from 'components/promotion/Section4';
import Title from 'components/promotion/Title';

const Promotion = () => (
  <>
    <Title />
    <Section1 />
    <Section2 />
    <Section4 />
    <Section3 />
  </>
);

export default Promotion;
