/** @jsxImportSource @emotion/react */
import useConfirmStore from 'core/store/common/useConfirmStore';
import { css, useTheme, keyframes } from '@emotion/react';
import ReactModal from 'react-modal';
import Button from '../Button';

const Confirm = () => {
  const {
    visible,
    children,
    confirmBtnMn,
    cancelBtnNm,
    title,
    closeConfrim,
    confirmAction,
    cancelAction,
  } = useConfirmStore();

  const { mq } = useTheme();

  const show = keyframes`
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  `;

  const hide = keyframes`
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  `;

  const scale = keyframes`
    0%{
      transform: scale(.9,.9) translate(-50%, -50%);
    }
    100%{
      transform: scale(1,1) translate(-50%, -50%);
    }
  `;

  const lowScale = keyframes`
    0%{
      transform: scale(1,1);
    }
    100%{
      transform: scale(.9,.9);
    }
  `;

  const overlayStyle = css`
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    animation: ${open ? show : hide} 0.3s ease forwards;
  `;

  const modalChidrenStyle = css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${mq.mobile ? '80%' : '100%'};
    max-width: 480px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
    box-sizing: border-box;
    background: #fff;
    z-index: 10000;
    border: 0;
    animation: ${open ? scale : lowScale} 0.3s ease forwards;
    .title {
      padding: 8px 0px 12px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    .content {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #909090;
    }
    button {
      padding: 12px 20px;
      margin-top: 16px;
    }
    .confirm {
      display: flex;
      button {
        width: 100%;
      }
      button + button {
        margin-left: 8px;
        color: #fff;
        background: #90a4b4;
        border: 1px solid #90a4b4;
      }
    }
  `;

  return (
    <ReactModal
      isOpen={visible}
      onRequestClose={() => {
        if (cancelAction !== undefined) cancelAction();
        closeConfrim();
      }}
      overlayElement={(props, contentElement) => (
        <div css={overlayStyle}>{contentElement}</div>
      )}
      contentElement={() => (
        <div css={modalChidrenStyle}>
          <div className="title">{title}</div>
          <div className="content">{children}</div>
          <div className="confirm">
            <Button
              color="gray"
              fullWidth
              onClick={() => {
                if (cancelAction !== undefined) cancelAction();
                closeConfrim();
              }}
            >
              {cancelBtnNm}
            </Button>
            <Button
              color="gray"
              fullWidth
              onClick={() => {
                if (confirmAction !== undefined) confirmAction();
                closeConfrim();
              }}
            >
              {confirmBtnMn}
            </Button>
          </div>
        </div>
      )}
    />
  );
};

export default Confirm;
