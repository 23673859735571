/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile } from 'core/hooks/useResponsive';
import mBannerImg from 'static/now/m_banner.png';
import bannerImg from 'static/now/banner.png';
import { useNavigate } from 'react-router-dom';
import { useBarBanner } from 'core/queries/now';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

const Banner = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const { data: bannerList } = useBarBanner();

  const BannerStyle = css`
    .container {
      width: 1300px;
      margin: 0 auto;
      .img {
        width: 100%;
        cursor: pointer;
        display: flex;
        img {
          width: 100%;
        }
      }
    }
    .swiper-pagination-fraction {
      display: inline-flex;
      width: auto;
      padding: 5px 10px;
      border-radius: 15px;
      color: #fff;
      font-size: 12px;
      gap: 3px;
      background: rgba(0, 0, 0, 0.4);
      right: 12px;
      bottom: 12px;
      left: auto;
    }

    ${mq.mobile} {
      .container {
        width: 100%;
        padding: 0px 20px;
      }
      .swiper-slide {
        padding-bottom: 30px;
      }
      .swiper-pagination-bullets {
        bottom: 0px;
        .swiper-pagination-bullet {
          width: 10px;
          height: 10px;
          opacity: 1;
          background: #eeeeee;
        }
        .swiper-pagination-bullet-active {
          background: #000;
        }
      }
    }
  `;

  return (
    <div css={BannerStyle}>
      {bannerList?.length > 0 && (
        <div className="container">
          <Swiper
            pagination={{
              type: isMobile ? 'bullets' : 'fraction',
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {bannerList?.map((v, k) => (
              <SwiperSlide key={k}>
                <div>
                  <a
                    href={isMobile ? v.moLink : v.pcLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="wrap">
                      <div className="img">
                        <img
                          src={isMobile ? v.moImgLink : v.pcImgLink}
                          alt={v.bnrNm}
                          title={v.bnrNm}
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="newPrev"> </div>
          <div className="newNext"> </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
