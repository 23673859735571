/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react';

import { ReactComponent as ArrowLeft } from 'static/user/mypage/arrowLeft.svg';
import Button from 'components/common/Button';
import { useState } from 'react';
import ParcelPopup from '../ParcelPopup';

const PurchaseDataList = (list: any) => {
  const { mq } = useTheme();
  const [open, setOpen] = useState(false);
  const [selectItem, setSelectItem] = useState(null);

  const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    gap: 20px;
    .content {
      .list {
        padding: 20px;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        .title {
          display: flex;
          justify-content: space-between;
          .status {
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            button {
              padding: 0px 8px;
              margin-left: 8px;
              font-size: 12px;
              font-weight: 700;
              border: 1px solid #e1e1e1;
            }
          }
          .date {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #909090;
          }
        }
        .mall {
          align-items: start;
          cursor: pointer;
          .mall-wrapper {
            display: flex;
            align-items: center;
            gap: 5px;
            .shop-img {
              width: 20px;
              height: 20px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 100px;
                object-fit: cover;
              }
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #667085;
            }
          }
        }
        .inner {
          margin-top: 12px;
          display: flex;
          align-items: center;
          .img {
            width: 68px;
            height: 68px;
            margin-right: 12px;
            flex-shrink: 0;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text {
            display: flex;
            flex-direction: column;
            gap: 3px;
            .name {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
            }
            .option {
              display: flex;

              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              color: #909090;
            }
            .price {
              font-size: 14px;
              font-weight: 700;
              line-height: 22px;
            }
          }
        }
      }
      .list + .list {
        margin-top: 20px;
      }
      .button {
        display: flex;

        justify-content: center;
        padding-top: 42px;
        button {
          padding: 12px 36px;
          display: flex;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          .arrow {
            margin-left: 8px;
            width: 24px;
          }
        }
      }
    }

    ${mq.mobile} {
      .content {
        padding-bottom: 32px;
        width: 100%;
        .list {
          .mall {
            .mall-wrapper {
              gap: 3px;
              align-items: center;
              .shop-img {
                width: 15px;
                height: 15px;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 100px;
                  object-fit: cover;
                }
              }
              p {
                font-size: 12px;
                font-weight: 400;
                color: #667085;
                margin: 0;
              }
            }
          }
          .inner {
            display: flex;
            overflow: hidden;
            .img {
              flex-shrink: 0;
              img {
              }
            }
            .text {
              flex: 1;
              p {
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              /* .name,
              .option {
                width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              } */
            }
          }
        }
        .button {
          padding-top: 32px;
          button {
            padding: 8px 24px;
            font-size: 14px;
            line-height: 22px;
            .arrow {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      {list?.list.map(it => {
        return (
          <div
            className="content"
            key={it.orderDtlSn}
            style={{
              color: it.orderStatus === '취소완료' ? '#dbdbdb' : '',
            }}
          >
            <div className="list">
              <div className="title">
                <div>
                  <div className="status">
                    {it.orderStatus}
                    {it.orderStatus === '배송중' ||
                    it.orderStatus === '배송완료' ? (
                      <Button
                        color="blue"
                        variant="outlined"
                        onClick={() => {
                          setSelectItem(it);
                          setOpen(true);
                        }}
                      >
                        배송조회
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className="date"
                    style={{
                      color: it.orderStatus === '취소완료' ? '#dbdbdb' : '',
                    }}
                  >
                    {it.regDt} 주문
                  </div>
                </div>
                <div className="mall">
                  <div
                    className="mall-wrapper"
                    onClick={() => {
                      window.open(
                        `//${it.shopId}.${process.env.REACT_APP_PUBLIC_URL}/user/order/list`,
                      );
                    }}
                  >
                    <div className="shop-img">
                      <img src={it.shopImgLink} alt="shopImg"></img>
                    </div>

                    <p>{it.shopNm}</p>
                    <ArrowLeft width={16} height={16} stroke="#667085" />
                  </div>
                </div>
              </div>
              <div className="inner">
                <div className="img">
                  <img src={it.prdImgLink} alt="product" />
                </div>
                <div className="text">
                  <p className="name">{it.prdNm}</p>
                  <div
                    className="option"
                    style={{
                      color: it.orderStatus === '취소완료' ? '#dbdbdb' : '',
                    }}
                  >
                    {it.optType1 && (
                      <p>{`${it.optType1} : ${it.option1} `}&nbsp;</p>
                    )}
                    {it.optType2 && (
                      <p>{`/ ${it.optType2} : ${it.option2} `}&nbsp;</p>
                    )}
                    {it.optType3 && (
                      <p>
                        / {it.optType3} : {it.option3}
                      </p>
                    )}
                  </div>
                  <div className="price">
                    {it.sellPrice
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    원 / {it.orderQty}개
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <ParcelPopup
        coCd={selectItem?.coCd}
        invoice={selectItem?.invoice}
        onOpen={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};

export default PurchaseDataList;
