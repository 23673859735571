import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Main from 'components/cs/Main';

const Cs = () => {
  return (
    <Layout theme="white">
      <Meta title="고객지원 | 몰파이" ogTitle="고객지원 | 몰파이" />
      <Main />
    </Layout>
  );
};

export default Cs;
