/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { useCoupon, useAddCoupon } from 'core/queries/mypage/coupon';
import { CouponPageFormValues } from 'components/user/mypage/coupon/CreateCoupon';
import useAlertStore from 'core/store/common/useAlertStore';
import useConfirmStore from 'core/store/common/useConfirmStore';
import Layout from 'components/common/Layout';
import UserInfo from 'components/user/mypage/UserInfo';
import MypageSelect from 'components/user/mypage/MypageSelect';
import CouponList from 'components/user/mypage/coupon/CouponList';
import CreateCoupon from 'components/user/mypage/coupon/CreateCoupon';

const Coupon = () => {
  const {
    data: couponData,
    fetchNextPage,
    isFetchingNextPage: contentsLoading,
    hasNextPage,
  } = useCoupon({
    page: 1,
    size: 6,
  });

  const { mutate: addCoupon } = useAddCoupon();

  const { openAlert } = useAlertStore();
  const { openConfirm } = useConfirmStore();

  const [tab, setTab] = useState<'list' | 'create'>('list');

  const { mq } = useTheme();

  const onCouponSubmit = async ({ cpnIssueCno }: CouponPageFormValues) => {
    await addCoupon(
      { cpnIssueCno: cpnIssueCno },
      {
        onSuccess: response => {
          if (response.code === 'SUCCESS') {
            openConfirm(
              {
                title: '알림',
                children: '쿠폰이 등록되었습니다. 다른 쿠폰도 계속 등록할까요?',
                confirmBtnMn: '계속 등록할게요',
                cancelBtnNm: '아니요',
              },
              undefined,
              () => {
                setTab('list');
              },
            );
          }
        },
        onError: (error: any) => {
          openAlert({
            title: '알림',
            children: error.response.data.message,
            btnNm: '확인',
          });
        },
      },
    );
  };

  const wrapperStyle = css`
    margin: 0 auto;
    max-width: 840px;
    * {
      word-break: keep-all;
    }
    ul {
      padding: 32px 0px;
      display: flex;
      li {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        color: #98a2b3;
        cursor: pointer;
      }
      li.active {
        color: #6079ff;
        border-bottom: 2px solid #6079ff;
      }
      li + li {
        margin-left: 20px;
      }
    }

    ${mq.mobile} {
      padding: 0px 20px;
    }
  `;

  return (
    <Layout theme="white">
      <UserInfo />
      <MypageSelect active="coupon" />
      <div css={wrapperStyle}>
        <ul>
          <li
            className={tab === 'list' ? 'active' : ''}
            onClick={() => setTab('list')}
          >
            사용 가능({couponData?.pages[0].total.toLocaleString()})
          </li>
          <li
            className={tab === 'create' ? 'active' : ''}
            onClick={() => setTab('create')}
          >
            쿠폰 등록
          </li>
        </ul>
      </div>
      {tab === 'list' && (
        <CouponList
          data={couponData}
          loadMore={() => fetchNextPage({ cancelRefetch: true })}
          hasMore={!contentsLoading && hasNextPage}
        />
      )}
      {tab === 'create' && <CreateCoupon onSubmit={onCouponSubmit} />}
    </Layout>
  );
};

export default Coupon;
