/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import React from 'react';
import { useIsMobile } from 'core/hooks/useResponsive';

const Bottom = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const BottomStyle = css`
    padding: 180px 0;
    background: #282828;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      text-align: center;
      font-size: 64px;
      line-height: 130%;
      font-weight: bold;
      color: #fff;
      span {
        color: #6079ff;
      }
    }
    .text {
      margin-top: 48px;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      text-align: center;
      line-height: 32px;
      color: #fff;
      opacity: 0.7;
    }
    button {
      border-radius: 27px;
      border: 0;
      margin-top: 48px;
      background: linear-gradient(90deg, #6079ff 0%, #af60ff 100%);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      color: #fff;
      padding: 12px 36px;
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 8px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    ${mq.mobile} {
      padding: 96px 20px;
      .title {
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: 46px; /* 135.294% */
      }
      .text {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      button {
        font-size: 18px;
      }
    }
  `;

  return (
    <div css={BottomStyle}>
      <h2 className="title">
        <span>몰파이의 기능이</span>
        <br />더 궁금하신가요?
      </h2>
      <p className="text">
        쇼핑몰 개설부터 수익 실현까지
        <br />더 궁금한 점이 있다면 차근차근 안내해드려요!
      </p>
      <a
        href="https://help-partner.mallpie.kr"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button>
          가이드 보러가기 <MainArrow />
        </button>
      </a>
    </div>
  );
};

export default Bottom;
