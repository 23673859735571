/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player } from '@lottiefiles/react-lottie-player';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/common/Button';
import Input from 'components/common/Input';

export interface ResetPageFormValues {
  email?: string;
}

interface ResetPageProps {
  checkEmail: (value: string) => Promise<boolean | string>;
  onSubmit: SubmitHandler<ResetPageFormValues>;
  onLogin: () => void;
  isSend: boolean;
}

const EmailPasswordContent = ({
  onSubmit,
  checkEmail,
  onLogin,
  isSend,
}: ResetPageProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPageFormValues>({ reValidateMode: 'onBlur' });

  const ResetPageStyle = css`
    margin: 0 auto;
    padding: 105px 0px;
    max-width: 624px;
    .title {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      padding-bottom: 48px;
    }
    form {
      .input + .input {
        margin-top: 6px;
      }
    }
    button {
      margin-top: 48px;
    }
    .sent {
      text-align: center;
      img {
        width: 120px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
      button {
        margin-top: 36px;
      }
    }
  `;

  return (
    <div css={ResetPageStyle}>
      {!isSend ? (
        <>
          <div className="title">
            가입 시 사용한 이메일 주소를 알려주세요.
            <br />
            비밀번호 재설정 링크를 보내드릴게요.
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="text"
              placeholder="가입시 사용한 이메일"
              {...register('email', {
                required: {
                  value: true,
                  message: '이메일을 입력해주세요',
                },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: '이메일 형식으로 올바르게 입력해 주세요.',
                },
                validate: checkEmail,
              })}
              prefix={errors.email?.message}
            />
            <Button color="gray" type="submit" fullWidth>
              비밀번호 재설정 링크 보내기
            </Button>
          </form>
        </>
      ) : (
        <div className="sent">
          <Player
            src="https://event.admerce.co.kr/mall/lottie/mail-sent.json"
            loop
            autoplay
            style={{ height: '120px', width: '120px' }}
          />
          <div className="text">
            비밀번호 재설정 링크를 메일로 보내드렸어요
            <br />
            이메일을 확인해주세요
          </div>
          <Button color="gray" type="submit" fullWidth onClick={onLogin}>
            로그인
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmailPasswordContent;
