import { useEffect, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useReserves } from 'core/queries/mypage/point';
import { useCode } from 'core/queries/code';
import useAlertStore from 'core/store/common/useAlertStore';
import moment from 'moment';
import Button from 'components/common/Button';
import Table from 'components/common/Table';
import DateSelect from 'components/common/DateSelect';

interface ReservesListData {
  rsveHistCd: string;
  rsveHistCN: string;
  osn: string;
  shopNm: string;
  rsveType: string;
  rsve: number;
  rsveExpireYmd: string;
  date: string;
}

const HistoryContent = () => {
  const { mq } = useTheme();

  const { openAlert } = useAlertStore();

  const [buttonDate, setButtonDate] = useState<0 | 30 | 90 | 180 | 365>(30);

  const { data: saveType } = useCode({
    cdGrpId: 'RSVE_SAVE_CD',
  });

  const [search, setSearch] = useState({
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate(),
    page: 1,
    size: 10,
  });

  const { data: reservesData, refetch } = useReserves({
    ...search,
    startDate: moment(search.startDate).format('YYYY.MM.DD'),
    endDate: moment(search.endDate).format('YYYY.MM.DD'),
  });

  useEffect(() => {
    refetch();
  }, [search.page]);

  const checkDate = () => {
    const startDate = moment(search.startDate);
    const endDate = moment(search.endDate);

    if (endDate.diff(startDate, 'days') > 365) {
      openAlert({
        title: '알림',
        children: '날짜는 최대 1년으로만 설정이 가능합니다.',
        btnNm: '확인',
      });
      return;
    }
    refetch();
  };

  const columns: ColumnDef<ReservesListData>[] = [
    {
      header: '날짜',
      accessorFn: row => row.date,
    },
    {
      header: '구분',
      accessorFn: row => row.rsveType,
    },
    {
      header: '내용',
      size: 300,
      accessorFn: row => `${row.osn} ${row.shopNm} ${row.rsveHistCN}`,
      cell: cell => (
        <div>
          {cell.row.original.osn ? (
            <>
              <p>주문번호: {cell.row.original.osn}</p>
              <p className="shop">{cell.row.original.shopNm}</p>
            </>
          ) : (
            cell.row.original.rsveHistCN
          )}
        </div>
      ),
    },
    {
      header: '유효기간',
      accessorFn: row => row.rsveExpireYmd,
    },
    {
      header: '금액',
      size: 80,
      accessorFn: row => `${row.rsve} ${row.rsveHistCd}`,
      cell: cell => (
        <p
          className={
            saveType?.list.filter(
              item => item.cd === cell.row.original.rsveHistCd,
            ).length > 0
              ? 'save'
              : 'sub'
          }
        >
          {saveType?.list.filter(
            item => item.cd === cell.row.original.rsveHistCd,
          ).length > 0
            ? '+' + cell.row.original.rsve.toLocaleString()
            : '-' + cell.row.original.rsve.toLocaleString()}
        </p>
      ),
    },
  ];

  const wrapperStyle = css`
    padding-bottom: 32px;
    margin: 0 auto;
    max-width: 840px;
    * {
      word-break: keep-all;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px;
      background: #f2f4f7;
      border-radius: 5px;
      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #98a2b3;
      }
      .number {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
        .icon {
          margin-right: 8px;
        }
      }
      .expire {
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #98a2b3;
        span {
          margin-left: 4px;
          color: #000000;
        }
      }
      .desc {
        button {
          padding: 2px 8px;
          font-size: 12px;
          font-weight: 700;
          border: 1px solid #e1e1e1;
        }
      }
    }
    .tabs {
      ul {
        padding: 32px 0px;
        display: flex;
        li {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: #98a2b3;
          cursor: pointer;
        }
        li.active {
          color: #6079ff;
          border-bottom: 2px solid #6079ff;
        }
        li + li {
          margin-left: 20px;
        }
      }
    }
    .search {
      display: flex;
      justify-content: space-between;
      .button {
        display: flex;
        button {
          width: 25%;
        }
        button + button {
          margin-left: 8px;
        }
      }
      button {
        padding: 10px 20px;
      }
    }
    .date {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0px 8px;
      }
    }
    .table {
      margin-top: 32px;
      max-width: 840px;
      overflow: hidden;
    }
    .react-datepicker-wrapper input {
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
    }

    ${mq.mobile} {
      padding-bottom: 50px;
      .search {
        padding: 0px 20px;
        flex-direction: column;
        .button {
          margin-bottom: 12px;
        }
        button {
          padding: 10px 0px;
        }
      }
      .table {
        max-width: 820px;
      }
      .date {
        margin-bottom: 12px;
      }
      .react-datepicker-wrapper,
      .react-datepicker-wrapper input {
        width: 100%;
      }
      .pagination {
        padding: 20px !important;
        justify-content: flex-start !important;
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      <div className="search">
        <div className="button">
          <Button
            color={buttonDate === 30 ? 'blue' : 'gray'}
            variant="outlined"
            onClick={() => {
              setButtonDate(30);
              setSearch({
                ...search,
                startDate: moment().subtract(30, 'days').toDate(),
                endDate: moment().toDate(),
              });
            }}
          >
            1개월
          </Button>
          <Button
            color={buttonDate === 90 ? 'blue' : 'gray'}
            variant="outlined"
            onClick={() => {
              setButtonDate(90);
              setSearch({
                ...search,
                startDate: moment().subtract(90, 'days').toDate(),
                endDate: moment().toDate(),
              });
            }}
          >
            3개월
          </Button>
          <Button
            color={buttonDate === 180 ? 'blue' : 'gray'}
            variant="outlined"
            onClick={() => {
              setButtonDate(180);
              setSearch({
                ...search,
                startDate: moment().subtract(180, 'days').toDate(),
                endDate: moment().toDate(),
              });
            }}
          >
            6개월
          </Button>
          <Button
            color={buttonDate === 365 ? 'blue' : 'gray'}
            variant="outlined"
            onClick={() => {
              setButtonDate(365);
              setSearch({
                ...search,
                startDate: moment().subtract(365, 'days').toDate(),
                endDate: moment().toDate(),
              });
            }}
          >
            1년
          </Button>
        </div>
        <div className="date">
          <DateSelect
            selectDate={search.startDate}
            onChange={(date: Date) => {
              setSearch({ ...search, startDate: date });
              setButtonDate(0);
            }}
          />
          <span>~</span>
          <DateSelect
            selectDate={search.endDate}
            onChange={(date: Date) => {
              setSearch({ ...search, endDate: date });
              setButtonDate(0);
            }}
          />
        </div>
        <Button color="blue" variant="contained" onClick={() => checkDate()}>
          조회
        </Button>
      </div>
      <div className="table">
        <Table
          name=""
          columns={columns}
          data={reservesData?.list || []}
          noDataMessage="적립금 내역이 없습니다."
          pageData={reservesData}
          onChange={page => setSearch({ ...search, page: page })}
        />
      </div>
    </div>
  );
};

export default HistoryContent;
