import { codeKeys } from 'constants/queryKeys';

import useAxiosAuth from 'hooks/useAxiosAuth';
import { useQuery } from 'react-query';

import { Codeparms, CodeResponse } from './type';

export const useCode = (params: Codeparms) => {
  const axiosAuth = useAxiosAuth();
  return useQuery([...codeKeys.all, params.cdGrpId], async () => {
    const { data } = await axiosAuth.get<CodeResponse>(`/code/codes`, {
      params,
    });
    return data.data;
  });
};
