import { css, useTheme } from '@emotion/react';
import Layout from 'components/common/NewLayout';
import Bottom from 'components/method/Bottom';
import Method1 from 'components/method/Method1';
import Method2 from 'components/method/Method2';
import Method3 from 'components/method/Method3';
import Method4 from 'components/method/Method4';
import Title from 'components/method/Title';
import Navigation from 'components/method/Navigation';
import React, { useState, useEffect } from 'react';
import Meta from 'components/common/Meta';

const Method = () => {
  const [showUI, setShowUI] = useState(false);

  const handleScroll = () => {
    const section = document.getElementById('methodTitle');
    if (window.scrollY > section.offsetHeight) {
      setShowUI(true);
    } else {
      setShowUI(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout theme="black">
      <Meta title="기능 | 몰파이" ogTitle="기능 | 몰파이" />
      <Title />
      <Method1 />
      <Method2 />
      <Method3 />
      <Method4 />
      <Bottom />
      <Navigation show={showUI} />
    </Layout>
  );
};

export default Method;
