import axios from 'core/config/axios';
import useAxiosAuth from 'hooks/useAxiosAuth';
import { mypageKeys } from 'constants/queryKeys';
import { useQuery, useMutation } from 'react-query';
import { MypageResponse, UserDetailResponse } from './type';

export const useMypage = () => {
  const axiosAuth = useAxiosAuth();

  return useQuery([...mypageKeys.all], async () => {
    const { data } = await axiosAuth.get<MypageResponse>(
      '/my-mallpie/userinfo',
    );
    return data.data;
  });
};

export const useUserDetail = () => {
  const axiosAuth = useAxiosAuth();

  return useQuery([...mypageKeys.detail()], async () => {
    const { data } = await axiosAuth.get<UserDetailResponse>(
      '/my-mallpie/user-detail',
    );
    return data.data;
  });
};
