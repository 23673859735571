/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Element } from 'react-scroll';
import list1Img from 'static/method/method1_list1.png';
import list2Img from 'static/method/method1_list2.png';
import list3Img from 'static/method/method1_list3.png';
import { ReactComponent as MagicWand } from 'static/method/magic_wand.svg';
import { motion } from 'framer-motion';
import { useIsMobile } from 'core/hooks/useResponsive';

const Method1 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const Method1Style = css`
    display: flex;
    justify-content: center;
    .wrap {
      width: 1232px;
      padding: 126px 0px;
      .title {
        svg {
          width: 30px;
        }
        .sub {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 24px;
          font-weight: 700;
          color: #a7acc6;
        }
        .text {
          color: #000;
          font-weight: bold;
          font-size: 56px;
          margin-top: 16px;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-top: 80px;
        li {
          display: flex;
          position: relative;
          align-items: center;
          &:nth-of-type(2) {
            flex-direction: row-reverse;
          }
          .text {
            padding: 0px 112px;
            .title {
              font-size: 40px;
              font-weight: bold;
            }
            .desc {
              margin-top: 20px;
              font-size: 20px;
              color: #667085;
              line-height: 30px;
              span {
                margin-top: 8px;
                display: block;
              }
            }
          }
          .img {
            width: 600px;
            flex-shrink: 0;
            background: #f5f5f5;
            border-radius: 32px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .wrap {
        width: 100%;
        padding: 96px 20px;
        .title {
          .sub {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
          }
          .text {
            font-size: 28px;
            margin-top: 8px;
            line-height: 40px;
          }
        }
        .list {
          gap: 40px;
          margin-top: 40px;
          li {
            flex-direction: column;
            &:nth-of-type(2) {
              flex-direction: column;
            }
            .text {
              padding: 16px 0px 0px;
              width: 100%;
              .title {
                font-size: 24px;
              }
              .desc {
                margin-top: 6px;
                font-size: 16px;
                color: #667085;
                line-height: 24px;
                span {
                  margin-top: 4px;
                }
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const scaleVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Element name="section1" className="element">
      <div css={Method1Style}>
        <div className="wrap">
          <div className="title">
            <div className="sub">
              <MagicWand fill="#9EA2B8" />
              손쉬운 제작
            </div>
            <h2 className="text">
              기본 제공되는{isMobile && <br />} <span>템플릿</span>을 활용하면
              <br />
              코딩없이 쉽게 꾸밀 수 있어요
            </h2>
          </div>
          <ul className="list">
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list1Img}
                  alt="Unsplash 이미지 연동 | 이미지"
                  title="Unsplash 이미지 연동"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">Unsplash 이미지 연동</h3>
                <p className="desc">
                  무료 상업용 이미지 사이트에 업데이트 되어있는 <br />
                  이미지를 사용할 수 있어요.
                  <span>다양한 이미지로 커버 이미지를 꾸며보세요 !</span>
                </p>
              </motion.div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list2Img}
                  alt="SNS 링크 추가 | 이미지"
                  title="SNS 링크 추가"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">SNS 링크 추가</h3>
                <p className="desc">
                  현재 운영중인 인스타그램, 유튜브, 블로그, 홈페이지 <br />
                  링크를 설정하면 아이콘이 생성돼요.
                  <span>
                    회원들이 마이몰을 통해 SNS도 방문할 수 있도록
                    <br /> 유도해보세요 !
                  </span>
                </p>
              </motion.div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list3Img}
                  alt="쇼핑몰 맞춤 기능 지원 | 이미지"
                  title="쇼핑몰 맞춤 기능 지원"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">쇼핑몰 맞춤 기능 지원</h3>
                <p className="desc">
                  메뉴 생성, 기획전, 배너 등 <br />
                  쇼핑몰 운영에 필요한 기능이 기본 제공돼요.
                  <span>상품을 매력적으로 큐레이션하고 홍보해 보세요 !</span>
                </p>
              </motion.div>
            </motion.li>
          </ul>
        </div>
      </div>
    </Element>
  );
};

export default Method1;
