import Layout from 'components/common/Layout';
import Title from 'components/business/Title';
import Section1 from 'components/business/shop/Section1';
import Section2 from 'components/business/shop/Section2';
import Section3 from 'components/business/shop/Section3';
import titleImg from 'static/business/shop/title.png';
import titleImgM from 'static/business/shop/title_m.png';
import Section4 from 'components/business/shop/Section4';
import Section5 from 'components/business/shop/Section5';

const Guide = () => (
  <Layout theme="white">
    <Title
      title="비즈니스용 쇼핑몰 구축"
      img={titleImg}
      mImg={titleImgM}
      text={
        '매체 특성에 맞는 맞춤형 커머스 서비스를 제공하며,\n이를 통해 비즈니스 영역을 확장시켜 고객 리텐션을 높이는 기회를 제공합니다.'
      }
      mark
    />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
  </Layout>
);

export default Guide;
