/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Layout from 'components/common/Layout';
import UserInfo from 'components/user/mypage/UserInfo';
import MypageSelect from 'components/user/mypage/MypageSelect';
import PurchaseContent from 'components/user/mypage/purchase/PurchaseContent';
import MymallData from 'components/user/mypage/mymall/MymallData';

const Purchase = () => {
  return (
    <Layout theme="white">
      <UserInfo />
      <MypageSelect active="history" />
      <PurchaseContent />
    </Layout>
  );
};

export default Purchase;
