/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import TitleImg from 'static/now/title.png';

const Title = () => {
  const { mq } = useTheme();

  const TitleStyle = css`
    background: linear-gradient(180deg, #c2ccff 0%, #fff 100%);
    padding-top: 200px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 60px;
      font-weight: 800;
      line-height: 130%; /* 78px */
      span {
        color: #6079ff;
      }
    }
    .sub {
      margin-top: 24px;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 150% */
    }
    .img {
      width: 654px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    ${mq.mobile} {
      padding: 96px 0px 18px 0px;
      flex-direction: column;
      text-align: center;
      .wrap {
        padding: 0 12px;
        .title {
          font-size: 30px;
        }
        .sub {
          font-size: 20px;
          line-height: 30px;
          letter-spacing: -0.2px;
        }
      }
      .img {
        width: 100%;
        max-width: 475px;
        overflow: hidden;
      }
    }
  `;

  return (
    <div css={TitleStyle}>
      <div className="wrap">
        <h2 className="title">
          몰파이 인기 상품과
          <br />
          <span>다양한 마이몰을 확인하세요</span>
        </h2>
        <p className="sub">
          내 쇼핑몰을 만들고 활용할
          <br />
          다양한 힌트를 얻어보세요!
        </p>
      </div>
      <div className="img">
        <img
          src={TitleImg}
          alt="몰파이 인기 상품과 다양한 마이몰을 확인하세요 | 이미지"
          title="몰파이 인기 상품과 다양한 마이몰을 확인하세요"
        />
      </div>
    </div>
  );
};

export default Title;
