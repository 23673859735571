import Layout from 'components/common/NewLayout';
import Title from 'components/now/Title';
import NewProduct from 'components/now/NewProduct';
import NewShop from 'components/now/NewShop';
import PopularProduct from 'components/now/PopularProduct';
import PopularShop from 'components/now/PopularShop';
import Banner from 'components/now/Banner';
import Meta from 'components/common/Meta';

const Now = () => (
  <Layout theme="black">
    <Meta title="몰파이 NOW | 몰파이" ogTitle="몰파이 NOW | 몰파이" />
    <Title />
    <PopularProduct />
    <Banner />
    <NewProduct />
    <PopularShop />
    <NewShop />
  </Layout>
);

export default Now;
