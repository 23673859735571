/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigation = useNavigate();

  const NotFoundStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 250px;
    gap: 25px;

    h1 {
      font-size: 26px;
      font-weight: 700;
    }

    p {
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(126, 126, 126, 1);
    }

    .button-container {
      display: flex;
      gap: 10px;
      .prev {
        background-color: rgba(242, 244, 247, 1);
        color: rgba(102, 112, 133, 1);
        border: none;
        font-size: 16px;
        border-radius: 4px;
        padding: 12px 20px;

        cursor: pointer;

        :hover {
          background-color: #f2f4f774;
        }
      }
      .next {
        background-color: rgba(96, 121, 255, 1);
        color: rgba(255, 255, 255, 1);
        border: none;
        font-size: 16px;
        border-radius: 4px;
        padding: 12px 20px;
        cursor: pointer;

        :hover {
          background-color: #6078ff7c;
        }
      }
    }
  `;

  return (
    <div css={NotFoundStyle}>
      <img src="/404.png" alt="404" />
      <div>페이지를 찾을 수 없습니다</div>
      <p>
        입력한 주소가 잘못되거나 <br />
        페이지가 삭제 또는, 변경되었을 수 있어요.
        <br />
        다시 확인해 주세요.
      </p>
      <div className="button-container">
        <button
          className="prev"
          onClick={() => {
            navigation(-1);
          }}
        >
          이전 페이지
        </button>
        <button
          className="next"
          onClick={() => {
            navigation('/', { replace: true });
          }}
        >
          몰파이 메인
        </button>
      </div>
    </div>
  );
};

export default NotFound;
