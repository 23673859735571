/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';

const Section3 = () => {
  const { mq } = useTheme();

  const section3Style = css`
    text-align: center;
    padding: 110px 0 100px 0;
    .container {
      max-width: 980px;
      padding: 0 40px;
      margin: 0 auto;
      .title {
        font-size: 48px;
        font-weight: 600;
        line-height: 68px;
      }
      .text {
        font-size: 18px;
        margin-top: 12px;
        font-weight: 200;
        line-height: 28px;
      }
      .contList {
        display: flex;
        justify-content: center;
        margin-top: 64px;
        li {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background-color: rgba(86, 91, 228, 0.1);
          border-radius: 50%;
          width: 195px;
          height: 195px;
          margin-left: -18px;
          .step {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
          }
          .title {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
          }
          &:nth-of-type(1) {
            margin-left: 0;
          }
        }
      }
      .list {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 64px;
        border-bottom: 1px solid #e1e1e1;
        li {
          text-align: left;
          .step {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            color: #6079ff;
          }
          h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            margin-top: 4px;
          }
          p {
            font-size: 18px;
            font-weight: 200;
            line-height: 28px;
          }
          ul {
            margin-top: 12px;
            li {
              font-size: 16px;
              line-height: 24px;
              list-style-type: disc;
              margin-left: 20px;
              a {
                text-decoration: underline;
                color: #1e90ff;
              }
            }
          }
        }
        > li + li {
          margin-top: 46px;
        }
      }
      .btn {
        padding: 64px;
        .number {
          margin-top: 12px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          span {
            font-weight: bold;
            color: #6079ff;
          }
        }
        button {
          width: 360px;
        }
      }
    }

    ${mq.mobile} {
      padding: 0 0 70px 0;
      .container {
        padding: 0 32px;
        .title {
          font-size: 24px;
          line-height: 36px;
        }
        .text {
          font-size: 14px;
          line-height: 21px;
        }
        .contList {
          margin-top: 48px;
          li {
            border-radius: 50%;
            width: 100%;
            height: auto;
            margin-left: -7px;
            .cont {
              position: absolute;
              .step {
                font-size: 10px;
                font-weight: 600;
                line-height: 14px;
                color: #6079ff;
              }
              .title {
                font-size: 10px;
                font-weight: 600;
                line-height: 14px;
              }
            }
            &:nth-of-type(1) {
              margin-left: 0;
            }
            &::after {
              content: '';
              padding-top: 100%;
            }
          }
        }
        .list {
          margin-top: 14px;
          display: flex;
          flex-direction: column;
          padding: 48px 0px;
          li {
            h3 {
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              margin-top: 4px;
            }
            p {
              font-size: 14px;
              font-weight: 200;
              line-height: 21px;
            }
          }
        }
        .btn {
          padding: 48px 0px;
          button {
            width: 100%;
          }
        }
      }
    }
  `;

  const contents = [
    {
      title: '파트너 회원가입',
      list: [
        '판매자 신청에 필요한 사업자등록증, 통신판매업신고증, 통장 사본을 미리 준비해주세요.',
        '아래 <a href="https://my.mallpie.kr" target="_blank">파트너 가입하기</a> 버튼을 통해 먼저 몰파이의 파트너 회원으로 가입해주세요.',
        '파트너 회원 가입시 사업자를 선택해주세요. (사업자가 아닌 경우, 입점이 불가능합니다.)',
      ],
    },
    {
      title: '판매자 신청',
      list: [
        '파트너 회원가입 완료 후 나오는 안내 페이지에서 ‘판매자(셀러)’를 선택하여 판매자 신청을 완료해주세요.',
        '회원가입시 미입력한 정보나, 증빙서류가 있다면 빠짐없이 기재해서 신청해주세요. ',
      ],
    },
    {
      title: 'MD 상담',
      list: [
        '담당MD가 판매자 신청 내역을 확인하여 연락을 드립니다.',
        '필요한 경우, 오프라인 미팅을 따로 진행할 수 있습니다.',
      ],
    },
    {
      title: '입점 계약',
      list: ['MD상담 후 몰파이 입점이 결정되면 계약이 진행됩니다.'],
    },
    {
      title: '입점 승인',
      list: [
        '계약이 완료되면, 몰파이 판매자로 승인해드리며, 승인이 완료되면 가입하신 ID로 몰파이의 입점사 메뉴를 이용할 수 있습니다.',
        '상품등록 및 주문처리에 대해 문의가 있다면, 담당MD 또는 <a href="https://guide.mallpie.kr/seller-common/overview" target="_blank">이용가이드</a>를 참고해주세요.',
      ],
    },
  ];

  return (
    <div css={section3Style}>
      <div className="container">
        <div className="title">입점 절차 안내</div>
        <ul className="contList">
          {contents.map((v, k) => (
            <li key={k}>
              <div className="cont">
                <div className="step">STEP. {k + 1}</div>
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: v.title.replace(' ', ' <br />'),
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
        <ul className="list">
          {contents.map((v, k) => (
            <li key={k}>
              <div className="step">STEP. {k + 1}</div>
              <h3>{v.title}</h3>
              <ul>
                {v.list.map((item, key) => (
                  <li key={key} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <div className="btn">
          <Button
            color="blue"
            onClick={() => window.open('https://my.mallpie.kr', '_blank')}
          >
            파트너 가입하기
          </Button>
          <div className="number">
            입점문의 및 상담 <span>1522-2856</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
