/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface ButtonProps {
  type?: 'button' | 'reset' | 'submit';
  color: 'white' | 'blue' | 'green' | 'gray' | 'black';
  variant?: 'text' | 'outlined' | 'contained';
  onClick?: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  whiteSpace?: 'normal' | 'nowrap';
}

const Button = ({
  color,
  onClick,
  children,
  type = 'button',
  variant = 'contained',
  fullWidth = false,
  disabled = false,
  whiteSpace,
}: ButtonProps) => {
  const buttonStyle = css`
    padding: 12px 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    width: ${fullWidth ? '100%' : 'auto'};
    background: ${color === 'white' || variant === 'outlined'
      ? '#fff'
      : color === 'blue'
      ? '#6079FF'
      : color === 'gray'
      ? '#F2F4F7'
      : color === 'green'
      ? '#277C05'
      : '#000'};
    white-space: ${whiteSpace ? whiteSpace : 'normal'};
    border: 1px solid
      ${color === 'white'
        ? variant === 'outlined'
          ? '#6079FF'
          : '#fff'
        : color === 'blue'
        ? '#6079FF'
        : color === 'gray'
        ? variant === 'outlined'
          ? '#E1E1E1'
          : '#F2F4F7'
        : '#000'};
    color: ${color === 'white'
      ? '#6079FF'
      : color === 'gray'
      ? '#667085'
      : variant === 'outlined'
      ? '#6079FF'
      : '#fff'};
    &:disabled {
      color: #ffffff;
      border: 1px solid #e1e1e1;
      background-color: #e1e1e1;
    }
  `;

  return (
    <button css={buttonStyle} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
