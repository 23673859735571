export const parcelCompany = shippCoCd => {
  switch (shippCoCd) {
    case '01':
      return '우체국택배';
    case '04':
      return 'CJ대한통운';
    case '05':
      return '한진택배';
    case '06':
      return '로젠택배';
    case '08':
      return '롯데택배';
    case '09':
      return 'KG로지스택배((KG옐로우캡)';
    case '10':
      return 'KGB택배';
    case '11':
      return '일양로지스';
    case '12':
      return 'EMS';
    case '13':
      return 'DHL';
    case '14':
      return 'UPS';
    case '15':
      return 'GTX로지스';
    case '16':
      return '한의사랑택배';
    case '17':
      return '천일택배';
    case '18':
      return '건영택배';
    case '20':
      return '한덱스';
    case '21':
      return 'Fedex';
    case '22':
      return '대신택배';
    case '23':
      return '경동택배';
    case '24':
      return 'CVSnet 편의점택배';
    case '25':
      return 'TNT Express';
    case '26':
      return 'USPS';
    case '28':
      return 'GSMNton';
    case '29':
      return '에어보이익스프레스';
    case '30':
      return 'KGL네트웍스';
    case '32':
      return '합동택배';
    case '33':
      return 'DHL Global Mail';
    case '34':
      return 'i_Parcel';
    case '37':
      return '범한판토스';
    case '39':
      return 'KG로지스택배';
    case '40':
      return '굿투럭';
    case '41':
      return '드림택배';
    case '45':
      return '호남택배';
    case '99':
      return '직접배송';
    default:
      return null;
  }
};
