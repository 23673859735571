/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Input from 'components/common/Input';
import Button from 'components/common/Button';

export interface CouponPageFormValues {
  cpnIssueCno?: string;
}

interface CouponListPageProps {
  onSubmit: SubmitHandler<CouponPageFormValues>;
}

const CreateCoupon = ({ onSubmit }: CouponListPageProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CouponPageFormValues>();

  const { mq } = useTheme();

  const couponListStyle = css`
    padding-bottom: 42px;
    margin: 0 auto;
    max-width: 840px;
    * {
      white-space: nowrap;
    }
    .box {
      max-width: 480px;
      .title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .field {
        display: flex;
        align-items: ${errors.cpnIssueCno?.message ? 'center' : 'flex-end'};
        gap: 8px;
        button {
          height: fit-content;
          padding: 10px 20px;
        }
      }
    }

    ${mq.mobile} {
      padding: 0px 20px 32px;
      .field {
        flex-direction: column;
        button {
          width: 100%;
        }
      }
    }
  `;

  return (
    <div css={couponListStyle}>
      <div className="box">
        <form className="field" onSubmit={handleSubmit(onSubmit)}>
          <Input
            type="text"
            label="쿠폰 번호를 입력해 주세요"
            placeholder="공백없이 숫자, 영문으로 입력해 주세요"
            {...register('cpnIssueCno', {
              required: {
                value: true,
                message: '쿠폰 번호를 입력해 주세요.',
              },
              pattern: {
                value: /^[a-z|A-Z|0-9|-]+$/,
                message: '영문,숫자만 입력 가능합니다.',
              },
            })}
            prefix={errors.cpnIssueCno?.message}
          />
          <Button color="blue" type="submit" whiteSpace="nowrap">
            등록
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateCoupon;
