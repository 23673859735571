import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Main from 'components/faq/Main';
import Menu from 'components/faq/Menu';
import NewsList from 'components/faq/NewsList';
import useNews from 'core/hooks/useNews';
import { useParams } from 'react-router-dom';

const Faq = () => {
  const { id, category } = useParams();

  const { newsList } = useNews();

  return (
    <Layout theme="white">
      <Meta title="몰파이 소식 | 몰파이" ogTitle="몰파이 소식 | 몰파이" />
      <Main title="몰파이 소식">
        <NewsList list={newsList} />
      </Main>
    </Layout>
  );
};

export default Faq;
