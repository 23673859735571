/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

const Section2 = () => {
  const { mq } = useTheme();

  const section2Style = css`
    text-align: center;
    padding: 115px 0 130px 0;
    .container {
      max-width: 1535px;
      padding: 0 40px;
      margin: 0 auto;
      .list {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          flex: 1;
          text-align: center;
          h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
          }
          p {
            font-size: 96px;
            font-style: normal;
            font-weight: 600;
            line-height: 96px;
            margin-top: 20px;
            &.active {
              color: #6079ff;
            }
          }
        }
      }
    }

    ${mq.mobile} {
      padding: 35px 0;
      .container {
        padding: 0 32px;
        .list {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          li {
            text-align: center;
            h3 {
              font-size: 14px;
              font-weight: 600;
              line-height: 22px;
            }
            p {
              font-size: 48px;
              font-weight: 600;
              line-height: 96px;
              margin-top: 0;
            }
          }
          li + li {
            margin-top: 40px;
          }
        }
      }
    }
  `;

  return (
    <div css={section2Style}>
      <div className="container">
        <ul className="list">
          <li>
            <h3>입점 브랜드 및 공급처</h3>
            <p className="active">2,800+</p>
          </li>
          <li>
            <h3>취급 상품</h3>
            <p>17,000+</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section2;
