import { useNavigate } from 'react-router-dom';
import axios from 'core/config/axios';
import { useUser } from 'core/store/context/UserContext';
import { useCookies } from 'react-cookie';
import { enCryaes } from 'utils/crypto';
import mem from 'mem';

const useRefreshToken = () => {
  const navigate = useNavigate();
  const [, setCookie, removeCookie] = useCookies(['mallpie-user']);
  const { user, setUserData } = useUser();

  const refreshToken = mem(
    async () => {
      try {
        const res = await axios.post(
          '/login',
          JSON.stringify({ refreshToken: user.refreshToken, mallType: 'IM' }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        );

        setCookie(
          'mallpie-user',
          enCryaes({
            ...res.data.data,
          }),
          { path: '/', domain: process.env.REACT_APP_PUBLIC_URL },
        );
      } catch (error) {
        removeCookie('mallpie-user', {
          path: '/',
          domain: process.env.REACT_APP_PUBLIC_URL,
        });
        setUserData(undefined);
        navigate('/');
      }
    },
    { maxAge: 1000 },
  );

  return refreshToken;
};

export default useRefreshToken;
