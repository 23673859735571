/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { usePopularShop } from 'core/queries/popular';
import Mall from 'components/common/Mall';
import Title from 'components/common/Title';
import { useIsPc } from 'core/hooks/useResponsive';
import { usePopChannel } from 'core/queries/now';

const PopularShop = () => {
  const { mq } = useTheme();

  const { data: shopList } = usePopChannel();

  const isPc = useIsPc();

  const PopularShopStyle = css`
    padding: 90px 0;
    background: #edf0ff;
    overflow: hidden;
    .container {
      width: 1300px;
      margin: 60px auto 0;
      position: relative;
      .swiper {
        position: relative;
        .swiper-wrapper {
          padding-bottom: 50px;
        }
        .swiper-slide {
          width: 420px;
        }
        .swiper-pagination-bullets {
          bottom: 0;
          .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            font-size: 15px;
            opacity: 1;
            background: #ebebeb;
          }
          .swiper-pagination-bullet-active {
            background: #6079ff;
          }
        }
      }
      .shopPrev {
        position: absolute;
        top: 63px;
        left: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/prev_arrow_grey.png');
        background-size: 100% 100%;
      }
      .shopNext {
        position: absolute;
        top: 63px;
        right: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/next_arrow_grey.png');
        background-size: 100% 100%;
      }
    }
    ${mq.mobile} {
      padding: 50px 0 30px 0;
      .wrap {
        padding-left: 20px;
      }
      .container {
        margin-top: 20px;
        width: 100%;
        .swiper {
          .swiper-wrapper {
          }
          .swiper-slide {
            width: 320px;
          }
          .swiper-pagination-bullets {
            display: none;
            .swiper-pagination-bullet {
              width: 8px;
              height: 8px;
              font-size: 8px;
            }
          }
        }
        .shopPrev,
        .shopNext {
          display: none;
        }
      }
    }
  `;

  return (
    <div css={PopularShopStyle}>
      <div className="wrap">
        <Title
          title="몰파이 추천, 인기 마이몰🎖️"
          subTitle="인기 급상승 중인 마이몰 구경 하세요!"
        />
      </div>
      {shopList && shopList.length > 0 && (
        <div className="container">
          <Swiper
            slidesPerView="auto"
            spaceBetween={isPc ? 20 : 15}
            slidesPerGroup={isPc ? 3 : 1}
            slidesOffsetBefore={isPc ? 0 : 20}
            loop
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: '.shopNext',
              prevEl: '.shopPrev',
            }}
            className="mySwiper"
          >
            {shopList?.map((v, k) => (
              <SwiperSlide key={k}>
                <Mall
                  direction="horizontal"
                  imgUrl={
                    v.images.filter(it => it.dsgnImgTypeCd === 'LOGO')[0]
                      .imgLink
                  }
                  shopId={v.shopId}
                  shopName={v.shopNm}
                  shopDesc={v.shopDesc}
                  categories={v.hashtag}
                  onClick={() =>
                    window.open(
                      `https://${v.shopId}.${
                        process.env.REACT_APP_PUBLIC_ENV !== 'production'
                          ? 'sellerd.kr'
                          : 'mallpie.kr'
                      }`,
                    )
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="shopPrev"> </div>
          <div className="shopNext"> </div>
        </div>
      )}
    </div>
  );
};

export default PopularShop;
