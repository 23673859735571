/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Link, useNavigate } from 'react-router-dom';

interface NewsData {
  title: string;
  content: any;
  date: string;
}

interface NewsListProps {
  list: NewsData[];
}

const NewsList = ({ list }: NewsListProps) => {
  const { mq } = useTheme();
  const navigator = useNavigate();

  const NewsListStyle = css`
    display: flex;
    flex-direction: column;
    li {
      width: 1000px;
      padding: 24px 0px;
      border-bottom: 1px solid #f2f4f7;
      cursor: pointer;
      .title {
        display: flex;
        gap: 12px;
        align-items: center;
        color: #667085;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        .badge {
          border-radius: 3px;
          padding: 5px 10px;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          background: #6079ff;
          color: #fff;
        }
      }
      .date {
        margin-top: 12px;
        color: #98a2b3;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
      &:nth-of-type(1) {
        padding-top: 0;
      }
    }
    ${mq.mobile} {
      gap: 0px;
      li {
        width: 100%;
        .title {
          flex-direction: column;
          align-items: flex-start;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
        }
      }
    }
  `;
  return (
    <ul css={NewsListStyle}>
      {list.reverse().map((v, k) => (
        <li key={k}>
          <Link to={`/news/${list.length - k}`} className="link">
            <h3 className="title">
              <span className="badge">중요</span>
              {v.title}
            </h3>
            <div className="date">{v.date}</div>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NewsList;
