import { useLocation, useSearchParams } from 'react-router-dom';
import SnsLogin from 'components/user/login/SnsLogin';

const NaverLogin = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);
  const code = searchParams.get('code');
  const error = searchParams.get('error');

  return (
    <div>
      <SnsLogin type="naver" code={code} error={error} />
    </div>
  );
};

export default NaverLogin;
