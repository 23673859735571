/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { useReservesAll } from 'core/queries/mypage/point';
import Button from 'components/common/Button';
import HistoryContent from '../HistoryContent';
import ExpireContent from '../ExpireContent';
import Modal from 'components/common/Modal';
import { ReactComponent as CoinIcon } from 'static/user/mypage/coin.svg';

const PointInfo = () => {
  const { data: reservesAllData } = useReservesAll();

  const { mq } = useTheme();

  const [tab, setTab] = useState<'history' | 'expire'>('history');

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const wrapperStyle = css`
    padding: 32px 0px;
    margin: 0 auto;
    max-width: 840px;
    * {
      word-break: keep-all;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px;
      background: #f2f4f7;
      border-radius: 5px;
      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #98a2b3;
      }
      .number {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-weight: 700;
        line-height: 42px;
        .icon {
          margin-right: 8px;
        }
      }
      .expire {
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #98a2b3;
        span {
          margin-left: 4px;
          color: #000000;
        }
      }
      .desc {
        button {
          padding: 2px 8px;
          font-size: 12px;
          font-weight: 700;
          border: 1px solid #e1e1e1;
        }
      }
    }
    .tabs {
      .content-tab {
        padding: 32px 0px;
        display: flex;
        li {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          color: #98a2b3;
          cursor: pointer;
        }
        li.active {
          color: #6079ff;
          border-bottom: 2px solid #6079ff;
        }
        li + li {
          margin-left: 20px;
        }
      }
      .pagination {
        padding: 32px 0px;
      }
    }
    .search {
      display: flex;
      justify-content: space-between;
      .button {
        display: flex;
        button {
          width: 25%;
        }
        button + button {
          margin-left: 8px;
        }
      }
      button {
        padding: 10px 20px;
      }
    }
    .date {
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0px 8px;
      }
    }
    .table {
      margin-top: 32px;
    }
    .react-datepicker-wrapper input {
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
    }
    .content {
      .sub-info {
        padding: 20px 0px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #909090;
        border-bottom: 1px solid #e1e1e1;
      }
      .item {
        display: flex;
        justify-content: space-between;
        padding: 12px 0px;
        * {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
        .num {
          color: #fb3b3b;
        }
      }
    }

    ${mq.mobile} {
      padding: 32px 0px 50px;
      .info {
        margin: 0px 20px;
      }
      .tabs {
        .content-tab {
          padding: 32px 20px;
        }
      }
      .search {
        flex-direction: column;
        .button {
          margin-bottom: 12px;
        }
        button {
          padding: 10px 0px;
        }
      }
      .date {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      .react-datepicker-wrapper,
      .react-datepicker-wrapper input {
        width: 100%;
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      <div className="info">
        <div className="title">사용가능</div>
        <div className="number">
          <CoinIcon className="icon" />
          {reservesAllData?.mallpiePoint.toLocaleString()}원
        </div>
        <div className="expire">
          30일 내 소멸 예정
          <span>
            {reservesAllData?.disAllRsve
              ? reservesAllData?.disAllRsve.toLocaleString()
              : 0}
            원
          </span>
        </div>
        <div className="desc">
          <Button color="blue" variant="outlined" onClick={handleModalOpen}>
            적립금 이용안내
          </Button>
        </div>
      </div>
      <div className="tabs">
        <ul className="content-tab">
          <li
            className={tab === 'history' ? 'active' : ''}
            onClick={() => setTab('history')}
          >
            적립/차감
          </li>
          <li
            className={tab === 'expire' ? 'active' : ''}
            onClick={() => setTab('expire')}
          >
            소멸 예정
          </li>
        </ul>
        {tab === 'history' && <HistoryContent />}
        {tab === 'expire' && <ExpireContent />}
      </div>
      <Modal open={modalOpen} enableButton={true} onClose={handleModalClose}>
        <>
          <div className="title">적립금 이용안내</div>
          <div className="content">
            <p>
              몰파이를 통해 운영되는 모든 몰에서 상품 구매시 사용할 수 있습니다.
            </p>
            <br />
            <p>
              몰파이의 여러몰에서 상품 구매 또는 리뷰 작성시 적립되며, 적립 받은
              몰이 달라도 다른 몰에서 사용이 가능합니다.
            </p>
            <br />
            <p>
              적립 기준은 각 몰마다 다르니, 해당 몰의 상품 페이지나 리뷰 작성 시
              안내를 참고해주세요.
            </p>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default PointInfo;
