import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const [cookies] = useCookies(['mallpie-user']);

  const loginData = cookies['mallpie-user'];

  if (!loginData) {
    return <Navigate to="/" />; // from에 현재 URL을 저장하여 리다렉트에 사용
  }

  return <Outlet />;
};

export default PrivateRoute;
