import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaProps {
  title: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
}
const Meta = ({
  title,
  description = '상품과 운영이 필요없는, 누구나 가능한 쇼핑몰의 시작',
  keywords,
  ogTitle,
  ogDescription = '상품과 운영이 필요없는, 누구나 가능한 쇼핑몰의 시작',
}: MetaProps) => {
  return (
    <Helmet>
      <title>{title}</title>

      <meta name="description" content={description}></meta>
      <meta name="keywords" content={keywords}></meta>

      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle}></meta>
      <meta property="og:description" content={ogDescription}></meta>
    </Helmet>
  );
};

export default Meta;
