/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import PurchaseDataList from '../PurchaseDataList';
import { usePurchaseList } from 'core/queries/mypage/purchase';

import { ReactComponent as NoDataIcon } from 'static/user/mypage/noData.svg';
import { ReactComponent as ArrowDownIcon } from 'static/user/mypage/arrowDown.svg';

const PurchaseContent = () => {
  const { mq } = useTheme();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    usePurchaseList();

  const wrapperStyle = css`
    margin: 0 auto;
    max-width: 840px;
    * {
      word-break: keep-all;
    }
    margin-bottom: 42px;
    .info {
      padding: 32px 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      flex-direction: row;
      span {
        font-weight: 700;
      }
    }
    .button {
      display: flex;
      justify-content: center;
      padding-top: 42px;
      button {
        padding: 12px 36px;
        display: flex;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        .arrow {
          margin-left: 8px;
          width: 24px;
        }
      }
    }
    .error {
      margin: 160px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .no-text {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #bdbdbd;
      }
    }
    ${mq.mobile} {
      padding: 0px 20px 50px;
      .info {
        padding: 20px 0px;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      {data?.pages ? (
        <>
          <div className="info">
            <div>몰파이의 모든 몰에서 주문한 내역이며,</div>
            <div>
              <span>상세 내용은 해당 몰에서 확인</span>이 가능합니다.
            </div>
          </div>
          <PurchaseDataList
            list={data?.pages.flatMap(page => page.data.list)}
          />
          {hasNextPage && (
            <div className="button">
              <Button color="gray" variant="outlined" onClick={fetchNextPage}>
                더보기
                <ArrowDownIcon className="arrow" stroke="#667085" />
              </Button>
            </div>
          )}
          {isFetchingNextPage && <div>Loading...</div>}
        </>
      ) : (
        <div className="error">
          <NoDataIcon />
          <p className="no-text">몰파이에서 구매하신 상품이 없습니다.</p>
        </div>
      )}
    </div>
  );
};

export default PurchaseContent;
