/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-scroll';
import { useIsMobile } from 'core/hooks/useResponsive';

const MethodNavigation = ({ show }) => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const NavigationStyle = css`
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, 0);
    padding: 0px 20px;
    .anchor {
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 0px 8px;
      background: #fff;
      border: 1px solid #6079ff;
      margin-bottom: 32px;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      .text {
        display: block;
        text-align: center;
        color: #4b5563;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.16px;
        cursor: pointer;
        padding: 18px 16px 16px;
        word-break: keep-all;
        &.active {
          color: #6079ff;
          background: rgba(96, 121, 255, 0.1);
          border-radius: 30px;
          padding: 8px 16px;
        }
      }
    }
    ${mq.mobile} {
      .anchor {
        border-radius: 20px;
        gap: 10px;
        .text {
          font-size: 13px;
          line-height: 18px;
          padding: 16px 8px;
          &.active {
            border-radius: 12px;
            padding: 8px;
          }
        }
      }
    }
  `;

  return (
    <>
      {show && (
        <div css={NavigationStyle}>
          <ul className="anchor">
            <li>
              <Link
                className="text"
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                duration={500}
              >
                손쉬운 제작
              </Link>
            </li>
            <li>
              <Link
                className="text"
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                duration={500}
              >
                효율적인 운영
              </Link>
            </li>
            <li>
              <Link
                className="text"
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                duration={500}
              >
                다양한 커머스 기능
              </Link>
            </li>
            <li>
              <Link
                className="text"
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                duration={500}
              >
                신규 런칭
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default MethodNavigation;
