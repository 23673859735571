/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import bg from 'static/myshop/calc/section4.png';
import bgM from 'static/myshop/calc/section4_m.png';

const Section4 = () => {
  const { mq } = useTheme();

  const section4Style = css`
    background-image: url(${bg});
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    .container {
      max-width: 1535px;
      padding: 315px 40px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .text {
        text-align: center;

        .sub {
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          margin-top: 7px;
          color: #6079ff;
        }
        h2 {
          font-size: 56px;
          font-weight: 600;
          line-height: 76px;
          color: #000;
          span {
            color: #6079ff;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
      img {
        width: 800px;
      }
      button {
        margin-top: 36px;
      }
    }
    ${mq.mobile} {
      background-image: url(${bgM});
      background-position: top center;
      .container {
        padding: 235px 40px 380px 40px;
        overflow: hidden;
        .text {
          .sub {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
          h2 {
            font-size: 26px;
            font-weight: 600;
            line-height: 36px;
          }
        }
      }
    }
  `;
  return (
    <div css={section4Style}>
      <div className="container">
        <div className="text">
          <h2>
            <span>몰파이로 오늘 바로</span>
            <br />
            수익을 만들어보세요
          </h2>
          <div className="sub">지금 바로 무료로 시작할 수 있습니다.</div>
          <Button
            color="blue"
            onClick={() => window.open('https://my.mallpie.kr', '_blank')}
          >
            무료로 몰파이 시작하기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section4;
