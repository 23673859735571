/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import section2 from 'static/myshop/make/section2.png';
import section2M from 'static/myshop/make/section2_m.png';

const Section2 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    padding: 70px 0;
    .title {
      text-align: center;
      h2 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px;
      }
      .text {
        font-size: 18px;
        font-style: normal;
        font-weight: 200;
        line-height: 28px;
        margin-top: 12px;
      }
    }
    img {
      margin-top: 100px;
      width: 100%;
    }
    ${mq.mobile} {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 90px;
      img {
        margin-top: 0;
      }
      .title {
        padding: 35px 32px 35px 32px;
        width: 100%;
        h2 {
          font-size: 28px;
          font-weight: 700;
          line-height: 36px;
        }
        .text {
          font-size: 14px;
          line-height: 22px;
          margin-top: 12px;
        }
      }
    }
  `;
  return (
    <div css={section1Style}>
      <div className="title">
        <h2>
          내 상품이 없어도{' '}
          <Mobile>
            <br />
          </Mobile>
          판매 수익을 얻을 수 있어요
        </h2>
        <div className="text">
          몰파이에 입점한 수천개의 셀러가 공급하는 상품을 내 몰에서 판매할 수
          있습니다.
          <br />
          개인, 인플루언서, 인기 유투버라면 지인이나 팔로워들이 관심있어 하는
          상품을 내 몰에서 판매하고
          <br /> 판매된 상품에 따라 높은 수익을 얻을 수 있어요.
        </div>
      </div>
      <PC>
        <img src={section2} alt="section2" />
      </PC>
      <Mobile>
        <img src={section2M} alt="section2" />
      </Mobile>
    </div>
  );
};

export default Section2;
