import useAxiosAuth from 'hooks/useAxiosAuth';
import { useQuery, useMutation } from 'react-query';
import {
  NicknameRequest,
  MobileAuthRequest,
  MobileValidRequest,
  ChangeUserRequest,
  ChangePasswordRequest,
} from './type';
import { mypageKeys } from 'constants/queryKeys';

export const useNickname = () => {
  const axiosAuth = useAxiosAuth();
  return useMutation(async (form: NicknameRequest) => {
    const { data } = await axiosAuth.get(`/my-mallpie/users/exist-nickname`, {
      params: {
        nickname: form.nickname,
      },
    });
    return data;
  });
};

export const useMobileAuth = () => {
  const axiosAuth = useAxiosAuth();

  return useMutation(async (form: MobileAuthRequest) => {
    const { data } = await axiosAuth.post(`/my-mallpie/mobile/auth`, form);
    return data;
  });
};

export const useMobileValid = () => {
  const axiosAuth = useAxiosAuth();

  return useMutation(async (form: MobileValidRequest) => {
    const { data } = await axiosAuth.post(`/my-mallpie/mobile/valid`, form);
    return data;
  });
};

export const useChangeUser = () => {
  const axiosAuth = useAxiosAuth();

  return useMutation(async (form: ChangeUserRequest) => {
    const { data } = await axiosAuth.put(`/my-mallpie/user/basic`, form);
    return data;
  });
};

export const useChangePassword = () => {
  const axiosAuth = useAxiosAuth();

  return useMutation(async (form: ChangePasswordRequest) => {
    const { data } = await axiosAuth.put(
      `/my-mallpie/user/change-password`,
      form,
    );
    return data;
  });
};
