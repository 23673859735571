/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { useUser } from 'core/store/context/UserContext';
import { enCryaes, deCryaes } from 'utils/crypto';
import moment from 'moment';
import { useMobileAuth, useMobileValid } from 'core/queries/mypage/info';
import { useUserDetail } from 'core/queries/mypage';
import useAlertStore from 'core/store/common/useAlertStore';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import Radio from 'components/common/Radio';

interface InfoFormValues {
  nickname?: string;
  userNm?: string;
  userMobile?: string;
  phoneNumber?: string;
  mktAgrYn?: string;
  authCode?: string;
}

interface ProfileChangeProps {
  nickname: string;
  userNm: string;
  userMobile: string;
  mktAgrYn: string;
  onSubmit?: SubmitHandler<InfoFormValues>;
}

const ChangeInfo = ({
  nickname,
  userNm,
  userMobile,
  mktAgrYn,
  onSubmit,
}: ProfileChangeProps) => {
  const navigate = useNavigate();

  const { mq } = useTheme();
  const { openAlert } = useAlertStore();

  const { user, setUserData } = useUser();

  const [cookies, setCookie] = useCookies();

  const { mutate: mobileAuth } = useMobileAuth();
  const { mutate: mobileValid } = useMobileValid();

  const [checkNumber, setCheckNumber] = useState<string>('');
  const [changeMobile, setChangeMobile] = useState<boolean>(false);
  const [sentMobile, setSentMobile] = useState<boolean>(false);
  const [checkedMobile, setCheckedMobile] = useState<boolean>(false);
  const [timer, setTimer] = useState<undefined | Date>(undefined);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<InfoFormValues>({
    defaultValues: {
      nickname: nickname,
      userNm: userNm,
      phoneNumber: userMobile,
      mktAgrYn: mktAgrYn,
    },
  });

  useEffect(() => {
    if (checkedMobile) {
      setValue('phoneNumber', checkNumber, { shouldValidate: true });
    }
  }, [checkedMobile]);

  /** 휴대폰 인증번호 발송 */
  const requestAuth = async (number: string) => {
    await mobileAuth(
      { mobile: number },
      {
        onSuccess: () => {
          setSentMobile(true);
          setTimer(moment().add(5, 'minutes').toDate());
        },
        onError: (error: any) => {
          openAlert({
            title: '알림',
            children: error.response.data.message,
            btnNm: '확인',
          });
        },
      },
    );
  };

  /** 인증번호 확인 및 휴대폰번호 변경 */
  const checkAuth = async (number: string, code: string) => {
    await mobileValid(
      { mobile: number, authCode: code },
      {
        onSuccess: async response => {
          setCheckedMobile(true);
          setTimer(undefined);

          setUserData({
            ...user,
            userMobile: number,
          });

          await setCookie(
            'mallpie-user',
            enCryaes({
              ...JSON.parse(deCryaes(cookies['mallpie-user'])),
              userMobile: number,
            }),
            { domain: process.env.REACT_APP_PUBLIC_URL, path: '/' },
          );

          openAlert({
            title: '알림',
            children: response.message,
            btnNm: '확인',
          });
        },
        onError: () => {
          openAlert({
            title: '알림',
            children: '올바른 인증번호를 입력해주세요',
            btnNm: '확인',
          });
        },
      },
    );
  };

  const infoStyle = css`
    padding-bottom: 42px;
    margin: 0 auto;
    max-width: 500px;
    * {
      word-break: keep-all;
    }
    button {
      padding: 10px 20px;
      height: fit-content;
    }
    label {
      width: 100%;
      display: block;
      position: relative;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    .input-box {
      margin-bottom: 32px;
      .phone {
        display: flex;
        align-items: end;
        gap: 8px;
        margin-bottom: 8px;
      }
      .radio-wrap {
        display: flex;
        gap: 30px;
      }
    }
    .withdrawal {
      margin-top: 32px;
      text-align: center;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    ${mq.mobile} {
      padding: 0px 20px 32px;
    }
  `;

  return (
    <div css={infoStyle}>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-box">
            <Input
              label="닉네임"
              {...register('nickname', {
                required: {
                  value: true,
                  message: '닉네임을 입력해주세요.',
                },
                minLength: {
                  value: 2,
                  message: '2 글자 이상으로 입력해주세요.',
                },
                maxLength: {
                  value: 10,
                  message: '10 글자 이하로 입력해주세요.',
                },
                pattern: {
                  value: /^[ㄱ-ㅎ|가-힣|0-9|]+$/,
                  message: '닉네임은 한글,숫자만 가능합니다.',
                },
              })}
              prefix={errors.nickname?.message}
            />
          </div>
          <div className="input-box">
            <Input
              label="이름"
              {...register('userNm', {
                required: '이름은 한글,영어만 가능합니다.',
                minLength: {
                  value: 2,
                  message: '2 글자 이상으로 입력해주세요.',
                },
                maxLength: {
                  value: 20,
                  message: '20 글자 이하로 입력해주세요.',
                },
                pattern: {
                  value: /^[ㄱ-ㅎ|가-힣|a-z|A-Z| ]*$/,
                  message: '이름 형식에 맞지 않습니다.',
                },
              })}
              prefix={errors.userNm?.message}
            />
          </div>
          <div className="input-box">
            <div className="phone">
              <Input
                type="number"
                label="휴대폰 번호"
                disabled={!changeMobile || checkedMobile}
                extra={{
                  show: changeMobile,
                  text: sentMobile ? '재발송' : '인증번호 받기',
                  disabled:
                    watch('phoneNumber') === '' ||
                    watch('phoneNumber') === undefined ||
                    checkedMobile,
                  action: () => {
                    if (watch('phoneNumber') === userMobile) {
                      setError('phoneNumber', {
                        message: '현재 휴대폰 번호와 동일합니다.',
                      });
                      return;
                    }
                    if (watch('phoneNumber')?.length < 11) {
                      setError('phoneNumber', {
                        message: '올바른 휴대폰 번호를 입력해 주세요',
                      });
                      return;
                    }

                    if (
                      !/^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/.test(
                        watch('phoneNumber'),
                      )
                    ) {
                      setError('phoneNumber', {
                        message: '올바른 휴대폰 번호를 입력해 주세요',
                      });
                      return;
                    }
                    clearErrors('phoneNumber');
                    setCheckNumber(watch('phoneNumber'));
                    requestAuth(watch('phoneNumber'));
                  },
                }}
                {...register('phoneNumber', {
                  required: {
                    value: true,
                    message: '휴대폰 번호를 입력해 주세요',
                  },
                  pattern: {
                    value: /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/,
                    message: '휴대폰 번호를 올바르게 입력해 주세요.',
                  },
                })}
                prefix={errors.phoneNumber?.message}
              />
              {!changeMobile && (
                <Button color="blue" onClick={() => setChangeMobile(true)}>
                  변경
                </Button>
              )}
            </div>
            <Input
              type="number"
              placeholder="인증번호 입력"
              disabled={checkedMobile}
              timer={timer}
              className="code"
              extra={{
                show: true,
                text: '확인',
                disabled:
                  watch('authCode') === '' || !sentMobile || checkedMobile,
                action: () => checkAuth(checkNumber, watch('authCode')),
              }}
              {...register('authCode')}
            />
          </div>
          <div className="input-box">
            <label>
              마케팅 정보 수신 동의 여부
              <div className="radio-wrap">
                <Radio
                  value="Y"
                  checked={watch('mktAgrYn') === 'Y'}
                  onChange={() => setValue('mktAgrYn', 'Y')}
                >
                  동의
                </Radio>
                <Radio
                  value="N"
                  checked={watch('mktAgrYn') === 'N'}
                  onChange={() => setValue('mktAgrYn', 'N')}
                >
                  미동의
                </Radio>
              </div>
            </label>
          </div>
          <Button color="blue" fullWidth type="submit">
            정보 변경
          </Button>
        </form>
        <div className="withdrawal">
          <span onClick={() => navigate('/user/mypage/withdrawal')}>
            회원탈퇴
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChangeInfo;
