import { useNavigate, Navigate } from 'react-router-dom';
import { useLogin } from 'core/queries/user';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { enCryaes } from 'utils/crypto';
import { useUser } from 'core/store/context/UserContext';
import useAlertStore from 'core/store/common/useAlertStore';

type LoginProps = {
  type: 'kakao' | 'naver';
  code: string;
  error: string;
};

const SnsLogin = ({ type, code, error }: LoginProps) => {
  const navigate = useNavigate();
  const { setUserData } = useUser();
  const { mutate: login } = useLogin();
  const { openAlert } = useAlertStore();
  const [, setCookie] = useCookies(['mallpie-user']);

  if (error !== '' && error !== null) {
    return <Navigate to="/user/login" />;
  }

  useEffect(() => {
    if (code !== '' && code !== null) {
      onSubmit();
    }
  }, []);

  const onSubmit = async () => {
    const loginForm = {
      loginDivCd: type === 'kakao' ? 'KK' : 'NV',
      authCode: code,
      mallType: 'IM',
    };

    await login(loginForm, {
      onSuccess: response => {
        if (response.code === 'SUCCESS') {
          setCookie(
            'mallpie-user',
            enCryaes({
              ...response.data,
            }),
            { path: '/', domain: process.env.REACT_APP_PUBLIC_URL },
          );
          setUserData({
            accessToken: response.data.accessToken,
            expires: response.data.expires,
            needToChangePwYn: response.data.needToChangePwYn,
            refreshToken: response.data.refreshToken,
            userEml: response.data.userEml,
            userMobile: response.data.userMobile,
            userNm: response.data.userNm,
          });
          navigate('/');
        }
      },
      onError: (error: any) => {
        if (error.response.status === 401 || error.response.status === 500) {
          openAlert(
            {
              title: '알림',
              children:
                error.response.data.data.resultCode === '1000' ? (
                  <div>
                    <p>해당 이메일 또는 SNS 계정으로 가입된 계정이 없습니다.</p>
                    <p>회원가입은 몰파이에서 운영되는</p>
                    <p>여러 쇼핑몰에서 가입할 수 있습니다.</p>
                    <p>아래 몰파이 쇼핑몰 구경하기를 참고해주세요.</p>
                  </div>
                ) : (
                  error.response.data.message
                ),
              btnNm: '확인',
            },
            () => navigate('/user/login'),
          );
        }
      },
    });
  };

  return <></>;
};

export default SnsLogin;
