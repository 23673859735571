/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { useCouponMall } from 'core/queries/mypage/coupon';
import Modal from 'components/common/Modal';
import { ReactComponent as CloseIcon } from 'static/user/mypage/close.svg';
import { ReactComponent as ArrowLeft } from 'static/user/mypage/arrowLeft.svg';

interface CouponPopupProps {
  open: boolean;
  couponSn: SearchProps;
  onClose: () => void;
}

interface MallData {
  list: MallListData[];
}

interface MallListData {
  shopSn: number;
  shopId: string;
  shopNm: string;
  imgLink: string;
  imgPath: string;
  imgNm: string;
}

interface SearchProps {
  cpnSn: number;
  cpnNm: string;
  shopAllYn: string;
}

const CouponPopup = ({ open, couponSn, onClose }: CouponPopupProps) => {
  const { mq } = useTheme();

  if (couponSn === undefined) return null;

  const { data: mallData } = useCouponMall({
    shopAllYn: couponSn.shopAllYn,
    couponSn: couponSn.cpnSn,
  });

  const modalStyle = css`
    .title {
      padding-bottom: 32px;
      border-bottom: 1px solid #e1e1e1;
      text-align: left;
      .sub {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .modal-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-text {
        margin-bottom: 4px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
      .close-button {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .modal-content {
      max-height: 500px;
      overflow-y: scroll;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 24px;
      gap: 24px 20px;
      .item {
        display: flex;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
          border-radius: 50px;
          object-fit: cover;
        }
        > .inner-box {
          margin-left: 12px;
          .name {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
          }
          .link {
            margin-top: 2px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #6079ff;
            cursor: pointer;
          }
        }
      }
    }

    ${mq.mobile} {
      .modal-content {
        max-height: 300px;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  `;

  return (
    <Modal open={open} enableButton={false} onClose={onClose}>
      <div css={modalStyle}>
        <div className="title">
          <div className="modal-inner">
            <div className="title-text">{couponSn.cpnNm}</div>
            <div className="close-button" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className="sub">
            총 {mallData?.mallCount.toLocaleString()}개 몰에서 사용가능
          </div>
        </div>
        <div className="modal-content">
          {mallData?.couponPossShopVos.map((item, index) => (
            <div className="item" key={item.shopSn}>
              <div>
                <img alt="shop-img" src={item.imgLink} />
              </div>
              <div className="inner-box">
                <div className="name">{item.shopNm}</div>
                <div
                  className="link"
                  onClick={() =>
                    window.open(
                      `${window.location.protocol}//${item.shopId}.${process.env.REACT_APP_PUBLIC_URL}`,
                    )
                  }
                >
                  바로가기
                  <ArrowLeft width={16} height={16} stroke="#6079FF" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CouponPopup;
