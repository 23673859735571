/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { ReactComponent as ArrowDownIcon } from 'static/intro/arrow_down_gray.svg';
import { motion } from 'framer-motion';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

const Store3 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const faqStyle = css`
    display: flex;
    justify-content: center;
    .container {
      width: 1000px;
      padding: 200px 0px;
      > .title {
        font-size: 64px;
        font-weight: 800;
        line-height: 86px;
        text-align: left;
      }
      .list {
        margin-top: 64px;
        li {
          padding: 30px 0px;
          border-bottom: 1px solid #f2f2f2;
          width: 100%;
          cursor: pointer;
          .title {
            color: #000;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .text {
              display: flex;
              span {
                font-weight: 700;
                color: #6079ff;
                margin-right: 20px;
              }
            }
            svg {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
            }
          }
          .cont {
            margin-top: 16px;
            color: #444;
            font-size: 20px;
            font-weight: 300;
            line-height: 32px; /* 160% */
            white-space: pre-wrap;
          }
          &.active {
            .title {
              color: #6079ff;
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 48px; /* 150% */
        }
        .list {
          margin-top: 24px;
          li {
            padding: 20px 0px;
            width: 100%;
            .title {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            .cont {
              font-size: 16px;
              font-weight: 300;
              line-height: 26px;
            }
          }
        }
      }
    }
  `;
  const [active, setActive] = useState(1);

  const faqList = [
    {
      sort: '입점',
      title: '입점 시 필요한 서류는 무엇인가요?',
      content:
        '1) 어드민 회원가입 시 : 사업자등록증 사본, 통신판매업 신고증 사본, 법인 명의 통장 사본\n2) 전자 계약 시 (signOK 플랫폼 이용): 범용 공인인증서 또는 간편인증서\n\n가 필요 합니다.',
    },
    {
      sort: '입점',
      title: '지류 계약으로 진행 가능한가요?',
      content:
        '불가 합니다. signOK 플랫폼 이용한 전자 계약으로만 진행되고 있습니다.',
    },
    {
      sort: '입점',
      title: '통신판매업 신고증 필수 인가요?',
      content:
        '네, 전자상거래 소비자보호 관련 법률에 따라 통신판매업 신고 및 증빙은 필수입니다.',
    },
    {
      sort: '입점',
      title: '개인사업자도 입점 가능한가요?',
      content: '네,  가능합니다. 단, 간이과세자는 입점이 불가합니다.',
    },
    {
      sort: '입점',
      title: '입점 결과는 언제 알 수 있나요?',
      content:
        '입점 신청부터 승인까지는 최대 2주 소요될 수 있습니다. 입점 신청 확인 후 개별 연락드리고 있으니 참고 부탁드립니다.',
    },
    {
      sort: '상품',
      title: '수수료가 어떻게 되나요?',
      content:
        '기본 수수료는 15%이며, 건강기능식품은 20% 입니다. 카테고리에 따라 MD 협의를 통해 조정될 수 있습니다.',
    },
    {
      sort: '상품',
      title: '무조건 무료 배송으로 등록해야 하나요?',
      content: '네, 몰파이는 전 상품 무료배송이에요.',
    },
    {
      sort: '상품',
      title: 'N포털사이트에 상품 노출이 되나요?',
      content:
        '몰파이는 폐쇄몰로, N포털사이트에 가격 및 상품이 노출되지 않습니다.',
    },
    {
      sort: '정산',
      title: '정산은 어떤 기준으로 어떻게 진행되나요?',
      content:
        '-정산 기준 : 매월 1일부터 말 일까지의 구매 확정 건\n-정산 프로세스 : 월별 정산금액 확인\n  → 익월 10일까지 세금계산서 발급 (입점사)\n  → 익월 25일경에 정산 계좌로 입금',
    },
    {
      sort: '정산',
      title: '정산 내역은 어디서 확인할 수 있나요?',
      content:
        '어드민에서 월 단위로 확인하실 수 있습니다.\n- 위치 : 입점사 메뉴 → 정산관리 →월별 정산내역',
    },
  ];

  return (
    <div css={faqStyle}>
      <div className="container" id="storeQna">
        <h2 className="title">자주 묻는 질문</h2>
        <ul className="list">
          {faqList.map((v, k) => {
            return (
              <li
                key={k}
                className={active === k + 1 ? 'active' : ''}
                onClick={() => setActive(active === k + 1 ? 0 : k + 1)}
              >
                <h3 className="title">
                  <div className="text">
                    <span>{v.sort}</span>
                    {v.title}
                  </div>
                  <ArrowDownIcon />
                </h3>
                <motion.div
                  className="cont"
                  style={{
                    y: active === k + 1 ? '' : '',
                    display: active === k + 1 ? 'block' : 'none',
                  }}
                >
                  {v.content}
                </motion.div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Store3;
