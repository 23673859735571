/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

import title from 'static/revenue/title.png';
import mTitle from 'static/revenue/m_title.png';

const Main = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const MainStyle = css`
    .container {
      display: flex;
      align-items: center;
      flex-direction: column;
      .img {
        width: 890px;
        img {
          width: 100%;
        }
      }
      .bar {
        width: 6px;
        height: 50px;
        background: #000;
        margin: 48px 0;
      }
    }
    ${mq.mobile} {
      .container {
        .img {
          width: 100%;
        }
        .bar {
          width: 4px;
          height: 30px;
          background: #000;
          margin: 32px 0;
        }
      }
    }
  `;
  return (
    <div css={MainStyle}>
      <div className="container">
        <div className="img">
          <img
            src={isPc ? title : mTitle}
            alt="단계별 수익실현 | 이미지"
            title="단계별 수익실현"
          />
        </div>
        <div className="bar" />
      </div>
    </div>
  );
};

export default Main;
