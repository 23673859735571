/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import section5 from 'static/myshop/make/section5.png';

const Section5 = () => {
  const { mq } = useTheme();

  const section5Style = css`
    .container {
      max-width: 1535px;
      padding: 110px 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .text {
        line-height: 28px;
        font-size: 18px;
        margin-top: 14px;
        font-weight: 200;
      }
      img {
        width: 800px;
      }
      button {
        margin-top: 32px;
      }
    }
    ${mq.mobile} {
      .container {
        flex-direction: column-reverse;
        padding: 0;

        .left {
          padding: 35px 32px 126px 32px;
          width: 100%;
          h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
          }
          .text {
            font-size: 14px;
            line-height: 22px;
            margin-top: 12px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  `;
  return (
    <div css={section5Style}>
      <div className="container">
        <div className="left">
          <h2>
            입점사를 위한
            <br />몰 만들기
          </h2>
          <div className="text">
            몰파이에 상품을 공급하는 입점사도 몰을 만들 수 있습니다.
            <br />
            취급하는 상품으로 몰을 운영할 수 있고,
            <br />
            다른 판매자의 연관된 상품도 함께 판매하여,
            <br /> 더욱 높은 판매 효과를 누려보세요
          </div>
          <Button
            color="blue"
            onClick={() =>
              window.open(
                'https://guide.mallpie.kr/seller-common/overview',
                '_blank',
              )
            }
          >
            입점사/샐러 서비스 안내
          </Button>
        </div>
        <div className="right">
          <img src={section5} alt="section3" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
