/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import phone1 from 'static/myshop/list/section1_1.png';
import phone2 from 'static/myshop/list/section1_2.png';
import phone3 from 'static/myshop/list/section1_3.png';

const Section1 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    padding: 0px 0 110px 0;
    .container {
      max-width: 1535px;
      padding: 0 40px;
      margin: 0 auto;
      .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
        span {
          font-weight: inherit;
          font-size: inherit;
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        li {
          flex: 1;
          display: flex;
          justify-content: center;
          text-align: left;
          .text {
            width: 600px;
            h3 {
              font-size: 48px;
              font-style: normal;
              font-weight: 700;
              line-height: 68px;
            }
            p {
              font-size: 20px;
              font-weight: 200;
              line-height: 30px;
              margin-top: 12px;
            }
          }
          img {
            width: 380px;
          }
          &:nth-of-type(1) {
            .text {
              margin-left: 64px;
            }
          }
          &:nth-of-type(2) {
            align-items: center;
            margin-top: -150px;
            text-align: right;
            .text {
              margin-right: 64px;
            }
          }
          &:nth-of-type(3) {
            align-items: end;
            margin-top: -150px;
            .text {
              margin-left: 64px;
            }
          }
        }
      }
    }

    ${mq.mobile} {
      padding-top: 0;
      .container {
        padding: 0 26px;
        .title {
          font-size: 20px;
          line-height: 30px;
        }
        .list {
          li {
            flex: 1;
            display: flex;
            .text {
              h3 {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
              }
              p {
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
            }
            img {
              width: 150px;
            }
            &:nth-of-type(1) {
              .text {
                margin-left: 15px;
              }
            }
            &:nth-of-type(2) {
              margin-top: -40px;
              text-align: right;
              .text {
                margin-right: 15px;
              }
            }
            &:nth-of-type(3) {
              margin-top: -40px;
              .text {
                margin-left: 15px;
              }
            }
          }
        }
      }
    }
  `;

  return (
    <div css={section1Style}>
      <div className="container">
        <ul className="list">
          <li>
            <img src={phone1} alt="phone1" />
            <div className="text">
              <h3>
                입점사를 위한
                <PC>
                  <br />
                </PC>
                몰 만들기
              </h3>
              <p>
                몰파이에 상품을 공급하는 입점사도 몰을 만들 수 있습니다.
                <br />
                취급하는 상품으로 몰을 운영할 수 있고, <br /> 다른 판매자의
                연관된 상품도 함께 판매하여,
                <PC>
                  <br />
                </PC>
                더욱 높은 판매 효과를 누려보세요
              </p>
            </div>
          </li>
          <li>
            <div className="text">
              <h3>다양한 쇼핑몰 특화 기능</h3>
              <p>
                쇼핑몰 운영에 필수적인 기획전,
                <Mobile>
                  <br />
                </Mobile>
                배너 등의 다양한 기능 등을 제공하며
                <br />
                나에게 필요한 기능만 선택하여
                <Mobile>
                  <br />
                </Mobile>
                사용할 수 있습니다.
              </p>
            </div>
            <img src={phone2} alt="phone2" />
          </li>
          <li>
            <img src={phone3} alt="phone3" />
            <div className="text">
              <h3>메뉴별 상품 구성</h3>
              <p>
                상품을 메뉴별로 각각 구성할 수 있습니다.
                <br />
                딱딱한 메뉴명 말고, 나만의 메뉴를 만들어
                <Mobile>
                  <br />
                </Mobile>
                어울리는 상품을 구성해보세요.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section1;
