/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-scroll';
import { ReactComponent as Cube } from 'static/method/cube.svg';
import { ReactComponent as Gift } from 'static/method/gift.svg';
import { ReactComponent as MagicWand } from 'static/method/magic_wand.svg';
import { ReactComponent as PaperPlane } from 'static/method/paper_plane.svg';

const Title = () => {
  const { mq } = useTheme();
  const TitleStyle = css`
    background: linear-gradient(180deg, #c2ccff 0%, #fff 100%);
    padding-top: 200px;
    padding-bottom: 40px;
    text-align: center;
    .title {
      font-size: 80px;
      font-weight: 700;
      line-height: 130%;
    }
    .sub {
      margin-top: 42px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
    }
    .anker {
      display: inline-flex;
      padding: 0 28px;
      background: #fff;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
      margin-top: 64px;
      border-radius: 32px;
      li {
        padding: 32px 48px;
        cursor: pointer;
        svg {
          color: #6079ff;
        }
        .text {
          display: block;
        }
        p {
          margin-top: 12px;
        }
      }
    }
    ${mq.mobile} {
      padding: 96px 12px 18px 12px;
      .title {
        font-size: 36px;
      }
      .sub {
        font-size: 20px;
        line-height: 30px;
      }
      .anker {
        flex-wrap: wrap;
        padding: 32px 42px;
        li {
          padding: 0;
          width: 50%;
          svg {
            width: 30px;
            height: 30px;
          }
          p {
            font-size: 14px;
            margin-top: 8px;
          }
          &:nth-of-type(3),
          &:nth-of-type(4) {
            margin-top: 32px;
          }
        }
      }
    }
  `;

  return (
    <div css={TitleStyle} id="methodTitle">
      <h2 className="title">
        몰파이 무료 기능
        <br />
        200% 활용하기
      </h2>
      <p className="sub">
        시작을 위해 필요한 모든 건 <br />
        이미 준비되어 있어요 !
      </p>
      <ul className="anker">
        <li>
          <Link
            className="text"
            activeClass="active"
            to="section1"
            spy={true}
            smooth={true}
            duration={500}
          >
            <MagicWand title="손쉬운 제작" />
            <p>손쉬운 제작</p>
          </Link>
        </li>
        <li>
          <Link
            className="text"
            activeClass="active"
            to="section2"
            spy={true}
            smooth={true}
            duration={500}
          >
            <Cube title="효율적인 운영" />
            <p>효율적인 운영</p>
          </Link>
        </li>
        <li>
          <Link
            className="text"
            activeClass="active"
            to="section3"
            spy={true}
            smooth={true}
            duration={500}
          >
            <Gift title="다양한 커머스 기능" />
            <p>다양한 커머스 기능</p>
          </Link>
        </li>
        <li>
          <Link
            className="text"
            activeClass="active"
            to="section4"
            spy={true}
            smooth={true}
            duration={500}
          >
            <PaperPlane title="신규 런칭" />
            <p>신규 런칭</p>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Title;
