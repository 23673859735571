/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';
import { useUser } from 'core/store/context/UserContext';
import { useLogout } from 'core/queries/user';
import { useCookies } from 'react-cookie';
import { css, useTheme } from '@emotion/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from 'static/common/logo.svg';
import { ReactComponent as Menu } from 'static/common/menu.svg';
import { ReactComponent as ArrowLeft } from 'static/user/mypage/arrowLeft.svg';

import Button from '../Button';
import Dropdown from '../Dropdown';
import { Mobile, PC } from '../Responsive';

interface HeaderProps {
  theme?: 'transparents' | 'blue' | 'white';
  isBanner?: boolean;
}

const Header = ({ theme = 'blue', isBanner }: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUserData } = useUser();
  const { mainColor } = useTheme();
  const { mutate: logout } = useLogout();
  const [cookies, setCookie, removeCookie] = useCookies(['mallpie-user']);
  const [active, setActive] = useState<number | undefined>(undefined);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      window.addEventListener('scroll', handleScroll);
    }, 100);
    return () => {
      clearInterval(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menu = [
    {
      name: '몰파이 쇼핑',
      path: '/',
    },
    {
      name: '몰파이 소개',
      path: '/intro',
    },
    {
      name: '나만의 쇼핑몰',
      children: [
        {
          path: '/myshop/make',
          name: '쇼핑몰 만들기',
          desc: '나의 취향을 반영한 쇼핑몰을 손쉽고 빠르게 만들어보세요.',
        },
        {
          path: '/myshop/style',
          name: '쇼핑몰 꾸미기',
          desc: '좋아하는 컬러, 사진 그리고 약간의 설정만로 멋진 쇼핑몰을 만들 수 있습니다.',
        },
        {
          path: '/myshop/list',
          name: '상품 진열하기',
          desc: '손쉽고 편리한 기능으로 나만의 쇼핑몰을 효율적으로 관리 해보세요.',
        },
        {
          path: '/myshop/calc',
          name: '판매 수익 정산',
          desc: '쇼핑몰을 주변에 널리 알리고 수익을 차곡 차곡 늘려보세요',
        },
      ],
    },
    {
      name: '비즈니스',
      children: [
        {
          path: '/business/guide',
          name: '입점사 안내',
          desc: '몰파이 플랫폼으로 다양한 판매 채널을 확보해보세요.',
        },
        {
          path: '/business/shop',
          name: '비즈니스용 쇼핑몰 구축',
          desc: '매체 특성에 맞는 맞춤형 커머스 서비스를 제공해 드립니다.',
        },
      ],
    },
  ];

  const onLogout = async () => {
    await logout(undefined, {
      onSuccess: response => {
        if (response.code === 'SUCCESS') {
          removeCookie('mallpie-user', {
            path: '/',
            domain: process.env.REACT_APP_PUBLIC_URL,
          });
          setUserData(undefined);
          navigate('/');
        }
      },
      onError: (error: any) => {
        console.log(error);
      },
    });
  };

  const headerStyle = css`
    width: 100%;
    height: 78px;
    padding: 20px 32px;
    background-color: ${isScroll
      ? '#fff'
      : theme === 'blue'
      ? mainColor
      : '#fff'};
    box-shadow: ${isScroll ? '0px 4px 8px 0px rgba(0, 0, 0, 0.1)' : '0px'};
    transition:
      background-color 0.3s,
      box-shadow 0.3s,
      color 0.3s;
    display: ${isBanner ? (isScroll ? 'flex' : 'flex') : 'flex'};
    align-items: center;
    position: ${isBanner ? (isScroll ? 'fixed' : 'absolute') : 'fixed'};
    top: ${isBanner ? (isScroll ? 0 : '90px') : 0};
    left: 0;
    z-index: 100;
    .logo {
      font-size: 0;
      img {
        width: 108px;
      }
    }
    nav {
      height: 100%;
      margin-left: 78px;
      > ul {
        height: 100%;
        display: flex;
      }
      > ul > li {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        color: ${isScroll ? '#000' : theme !== 'white' ? '#fff' : '#000'};
        cursor: pointer;
        padding: 0 18px;
        font-weight: 500;
        white-space: nowrap;
        &.path {
          padding-right: 24px;
        }
        &.active {
          color: ${mainColor};
          svg {
            stroke: ${mainColor};
          }
        }
        .active {
          color: ${mainColor};
        }
        .sub {
          display: inline-flex;
          flex-direction: column;
          position: absolute;
          width: auto;
          padding: 36px;
          color: #000;
          background-color: #fff;
          box-shadow: 0px 22px 22px 0px rgba(0, 0, 0, 0.2);
          top: calc(100% + 20px);
          left: 0;
          li {
            cursor: pointer;
            &:hover {
              color: ${mainColor};
            }
            .title {
              font-size: 16px;
              font-weight: normal;
              font-weight: 600;
              line-height: 24px;
            }
            .desc {
              color: #909090;
              line-height: 22px;
              font-size: 14px;
              font-weight: 400;
            }
          }
          li + li {
            margin-top: 24px;
          }
        }
      }
    }
    .more {
      display: flex;
      font-size: 14px;
      margin-left: 8px;
      li {
        padding: 0 12px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: ${isScroll ? '#000' : theme !== 'white' ? '#fff' : '#000'};
        cursor: pointer;
        &:nth-of-type(1) {
          &::before {
            content: '';
            margin-right: 26px;
            display: block;
            width: 1px;
            height: 14px;
            background: ${isScroll
              ? '#000'
              : theme !== 'white'
              ? '#fff'
              : '#000'};
          }
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      margin-left: auto;
      .user {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
      }
      .logout {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: ${isScroll ? '#666' : theme !== 'white' ? '#fff' : '#666'};
        white-space: nowrap;
      }
      div {
        font-size: 14px;
        color: ${isScroll ? mainColor : theme !== 'white' ? '#fff' : mainColor};
        margin-right: 36px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  `;

  const headerMoStyle = css`
    width: 100%;
    height: 48px;
    padding: 12px 16px;
    background: ${isScroll ? '#fff' : theme === 'blue' ? mainColor : '#fff'};

    box-shadow: ${isScroll ? '0px 4px 8px 0px rgba(0, 0, 0, 0.1)' : '0px'};
    transition:
      background-color 0.3s,
      box-shadow 0.3s,
      color 0.3s;
    display: ${isBanner ? (isScroll ? 'flex' : 'flex') : 'flex'};
    align-items: center;
    position: ${isBanner ? (isScroll ? 'fixed' : 'absolute') : 'fixed'};
    top: ${isBanner ? (isScroll ? 0 : '90px') : 0};
    left: 0;
    z-index: 100;
    .logo {
      font-size: 0;
      img {
        width: 88px;
      }
    }
    .user-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .user {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 16px;
          font-weight: 700;
          color: #6079ff;
        }
      }
      .logout {
        font-size: 16px;
        font-weight: 700;
        color: #666666;
        cursor: pointer;
      }
    }
    .icon {
      margin-left: auto;
    }
    .menu {
      width: 100%;
      height: 100%;
      position: fixed;
      transform: translateX(${isMenuVisible ? '0' : '100%'});
      transition: all 0.3s;
      left: 0;
      top: 0;
      background: #fff;
      .header {
        display: flex;
        width: 100%;
        height: 48px;
        padding: 12px 16px;
        align-items: center;
      }
      .content {
        height: calc(100% - 48px);
        overflow-y: auto;
        .info {
          padding: 20px;
          padding-bottom: 24px;
          button {
            width: 100%;
          }
          button + button {
            margin-top: 8px;
          }
        }
        .list {
          padding: 0 20px;
          > ul > li {
            padding: 24px 0px;
            border-top: 1px solid #f2f2f2;
            .name {
              font-size: 16px;
              padding: 8px 0;
              font-weight: 600;
              line-height: 24px;
            }
            .sub {
              li {
                padding: 8px 0px;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  `;

  const onClickMenu = useCallback(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      setIsMenuVisible(true);
    } else {
      setIsMenuVisible(false);
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 400);
    }
  }, [isMenuOpen]);

  return (
    <>
      <PC>
        <header css={headerStyle}>
          <h1 className="logo" onClick={() => navigate('/')}>
            <Logo
              fill={
                isScroll ? mainColor : theme !== 'white' ? '#fff' : mainColor
              }
            />
          </h1>
          <nav>
            <ul>
              {menu.map((v, k) => (
                <li
                  onClick={() => {
                    v.children === undefined ? navigate(v.path) : setActive(k);
                  }}
                  key={k}
                  className={
                    v.children === undefined
                      ? location.pathname === v.path
                        ? 'active path'
                        : 'path'
                      : (location.pathname.includes('/myshop') === true &&
                          v.name === '나만의 쇼핑몰') ||
                        (location.pathname.includes('/business') === true &&
                          v.name === '비즈니스')
                      ? 'active'
                      : ''
                  }
                >
                  {v.children !== undefined ? (
                    <Dropdown
                      title={v.name}
                      index={k}
                      isActive={active}
                      color={
                        isScroll
                          ? 'black'
                          : theme !== 'white'
                          ? 'white'
                          : 'black'
                      }
                    >
                      <ul className="sub">
                        {v.children.map((subMenu, menuKey) => (
                          <li
                            className={
                              subMenu.path === location.pathname ? 'on' : ''
                            }
                            onClick={() => navigate(subMenu.path)}
                            key={menuKey}
                          >
                            <div className="title">{subMenu.name}</div>
                            <div className="desc">{subMenu.desc}</div>
                          </li>
                        ))}
                      </ul>
                    </Dropdown>
                  ) : (
                    v.name
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <ul className="more">
            <li
              onClick={() => window.open('https://guide.mallpie.kr', '_blank')}
            >
              이용가이드
            </li>
            <li onClick={() => navigate('https://mallpie.biz/inquiry')}>
              문의
            </li>
          </ul>
          <div className="right">
            {user ? (
              <>
                <div
                  className="user"
                  onClick={() => navigate('/user/mypage/mymall')}
                >
                  {user?.userNm}님
                  <ArrowLeft
                    stroke={
                      isScroll
                        ? mainColor
                        : theme !== 'white'
                        ? '#fff'
                        : mainColor
                    }
                  />
                </div>
                <div className="logout" onClick={onLogout}>
                  로그아웃
                </div>
              </>
            ) : (
              <div onClick={() => navigate('/user/login')}>로그인</div>
            )}
            <Button
              color={isScroll ? 'blue' : theme !== 'white' ? 'white' : 'blue'}
              whiteSpace="nowrap"
              onClick={() => window.open('https://my.mallpie.kr', '_blank')}
            >
              몰 만들기
            </Button>
          </div>
        </header>
      </PC>
      <Mobile>
        <header css={headerMoStyle}>
          <h1 className="logo" onClick={() => navigate('/')}>
            <Logo
              fill={isScroll ? 'black' : theme !== 'white' ? 'white' : 'black'}
            />
          </h1>
          <div className="icon" onClick={onClickMenu}>
            <Menu
              stroke={
                isScroll ? 'black' : theme !== 'white' ? 'white' : 'black'
              }
            />
          </div>
          <div className="menu">
            <div className="header">
              <h1 className="logo" onClick={() => navigate('/')}>
                <Logo fill={mainColor} />
              </h1>
              <div className="icon" onClick={onClickMenu}>
                <Menu stroke={mainColor} />
              </div>
            </div>
            <div className="content">
              <div className="info">
                {user && (
                  <div className="user-box">
                    <div
                      className="user"
                      onClick={() => navigate('/user/mypage/mymall')}
                    >
                      <span>{user?.userNm}님</span>
                      <ArrowLeft width={24} height={24} stroke={mainColor} />
                    </div>
                    <div className="logout" onClick={onLogout}>
                      로그아웃
                    </div>
                  </div>
                )}
                <Button
                  color="blue"
                  onClick={() => window.open('https://my.mallpie.kr', '_blank')}
                >
                  몰 만들기
                </Button>
                {!user && (
                  <Button color="gray" onClick={() => navigate('/user/login')}>
                    로그인
                  </Button>
                )}
              </div>
              <nav className="list">
                <ul>
                  {menu.map((v, k) => (
                    <li key={k}>
                      <div className="name">{v.name}</div>
                      {v.children !== undefined ? (
                        <ul className="sub">
                          {v.children.map((subMenu, subMenuKey) => (
                            <li
                              onClick={() => navigate(subMenu.path)}
                              key={subMenuKey}
                            >
                              {subMenu.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul className="sub">
                          <li onClick={() => navigate(v.path)}>{v.name}</li>
                        </ul>
                      )}
                    </li>
                  ))}
                  <li>
                    <ul className="sub">
                      <li
                        onClick={() =>
                          window.open('https://guide.mallpie.kr', '_blank')
                        }
                      >
                        이용가이드
                      </li>
                      <li
                        onClick={() => navigate('https://mallpie.biz/inquiry')}
                      >
                        문의
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </Mobile>
    </>
  );
};

export default Header;
