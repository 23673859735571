/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import TitleImg from 'static/promotion/title/main.png';
import mTitleImg from 'static/promotion/title/m_main.png';
import LogoImg from 'static/promotion/title/logo.png';
import mLogoImg from 'static/promotion/title/m_logo.png';
import TextImg from 'static/promotion/title/text.png';
import ButtonImg from 'static/promotion/title/button.png';
import mButtonImg from 'static/promotion/title/m_button.png';
import mArrowImg from 'static/promotion/title/m_arrow.png';
import { Mobile, PC } from 'components/common/Responsive';

const Title = () => {
  const titleStyle = css`
    background: #6106e8;
    background-image: url(${TitleImg});
    background-size: 992px;
    background-repeat: no-repeat;
    background-position: right center;
    height: 890px;
    .container {
      max-width: 1560px;
      margin: 0 auto;
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 100%;
      .left {
        .logo {
          width: 480px;
        }
        .text {
          margin-top: 15px;
          width: 770px;
        }
        .button {
          width: 220px;
          margin-top: 50px;
          cursor: pointer;
        }
        img {
          display: block;
        }
      }
    }
  `;

  const titleMoStyle = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 80px 0 300px 0px;
      background-image: url(${mTitleImg});
      background-size: 360px;
      background-repeat: no-repeat;
      background-position: bottom center;
      position: relative;
      .logo {
        width: 320px;
      }
      .button {
        width: 320px;
        margin-top: 20px;
      }
      .arrow {
        position: absolute;
        width: 360px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={titleStyle}>
          <div className="container">
            <div className="left">
              <img src={LogoImg} alt="logo" className="logo" />
              <img src={TextImg} alt="text" className="text" />
              <img
                src={ButtonImg}
                alt="button"
                className="button"
                onClick={() => window.open('https://my.mallpie.kr', '_blank')}
              />
            </div>
          </div>
        </div>
      </PC>
      <Mobile>
        <div css={titleMoStyle}>
          <div className="container">
            <img src={mLogoImg} alt="logo" className="logo" />
            <img
              src={mButtonImg}
              alt="button"
              className="button"
              onClick={() => window.open('https://my.mallpie.kr', '_blank')}
            />
            <img src={mArrowImg} alt="mArrowImg" className="arrow" />
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Title;
