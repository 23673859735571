/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { css, useTheme } from '@emotion/react';
import { useCode } from 'core/queries/code';
import { useUser } from 'core/store/context/UserContext';
import { useWithdrawal } from 'core/queries/mypage/withdrawal';
import { WithdrawalFormValues } from 'components/user/withdrawal/WithdrawalContent';
import useAlertStore from 'core/store/common/useAlertStore';
import Layout from 'components/common/Layout';
import UserInfo from 'components/user/mypage/UserInfo';
import MypageSelect from 'components/user/mypage/MypageSelect';
import WithdrawalContent from 'components/user/withdrawal/WithdrawalContent';

const Withdrawl = () => {
  const { mq } = useTheme();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['mallpie-user']);
  const { setUserData } = useUser();
  const { openAlert } = useAlertStore();

  const { data: withdrawalType } = useCode({
    cdGrpId: 'WHDWL_TYPE_CD',
  });
  const { mutate: withdrawal } = useWithdrawal();

  const deleteUser = async ({ whdwlTypeCd, whdwlCn }: WithdrawalFormValues) => {
    await withdrawal(
      {
        whdwlTypeCd: whdwlTypeCd,
        whdwlCn: whdwlCn,
      },
      {
        onSuccess: response => {
          if (response.code === 'SUCCESS') {
            openAlert(
              {
                title: '알림',
                children: '정상적으로 탈퇴 처리되었습니다.',
                btnNm: '확인',
              },
              () => {
                removeCookie('mallpie-user', {
                  path: '/',
                  domain: process.env.REACT_APP_PUBLIC_URL,
                });
                setUserData(undefined);
                navigate('/');
              },
            );
          }
        },
        onError: (error: any) => {
          openAlert({
            title: '알림',
            children: <p>{error.response.data.message}</p>,
            btnNm: '확인',
          });
        },
      },
    );
  };

  const wrapperStyle = css`
    margin: 0 auto;
    max-width: 500px;
    * {
      word-break: keep-all;
    }
    ul {
      padding: 32px 0px;
      display: flex;
      li {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        color: #98a2b3;
        cursor: pointer;
      }
      li.active {
        color: #6079ff;
        border-bottom: 2px solid #6079ff;
      }
      li + li {
        margin-left: 20px;
      }
    }

    ${mq.mobile} {
      padding: 0px 20px;
    }
  `;

  return (
    <Layout theme="white">
      <UserInfo />
      <MypageSelect active="info" />
      <div css={wrapperStyle}>
        <ul>
          <li className="active">회원탈퇴</li>
        </ul>
      </div>
      <WithdrawalContent
        withdrawalType={withdrawalType?.list}
        onSubmit={deleteUser}
      />
    </Layout>
  );
};

export default Withdrawl;
