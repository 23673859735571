/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useCookies } from 'react-cookie';
import { enCryaes } from 'utils/crypto';
import { useLogin } from 'core/queries/user';
import { useUser } from 'core/store/context/UserContext';
import useAlertStore from 'core/store/common/useAlertStore';
import Layout from 'components/common/Layout';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import Checkbox from 'components/common/Checkbox';

export interface LoginFormValues {
  userEml?: string;
  password?: string;
  save?: boolean;
}

const Login = () => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<LoginFormValues>();

  const { mq } = useTheme();
  const { openAlert } = useAlertStore();
  const navigate = useNavigate();
  const { setUserData } = useUser();
  const { mutate: login } = useLogin();

  const [cookies, setCookie, removeCookie] = useCookies([
    'savepieId',
    'mallpie-user',
  ]);

  const [error, setError] = useState<string>('');

  useEffect(() => {
    reset({
      userEml: cookies.savepieId || '',
    });
    setValue('save', !!cookies.savepieId);
  }, [cookies.savepieId]);

  const onSubmit = async ({ userEml, password, save }: LoginFormValues) => {
    const loginForm = {
      loginDivCd: 'EM',
      userEml: userEml,
      password: password,
      mallType: 'IM',
    };

    await login(loginForm, {
      onSuccess: response => {
        if (response.code === 'SUCCESS') {
          if (save === true) {
            setCookie('savepieId', userEml, { path: '/' });
          } else {
            removeCookie('savepieId', { path: '/' });
          }
          setCookie(
            'mallpie-user',
            enCryaes({
              ...response.data,
            }),
            { path: '/', domain: process.env.REACT_APP_PUBLIC_URL },
          );
          setUserData({
            accessToken: response.data.accessToken,
            expires: response.data.expires,
            needToChangePwYn: response.data.needToChangePwYn,
            refreshToken: response.data.refreshToken,
            userEml: response.data.userEml,
            userMobile: response.data.userMobile,
            userNm: response.data.userNm,
          });
          navigate('/');
        }
      },
      onError: (error: any) => {
        if (error.response.status === 500) {
          const { resultCode } = error.response.data.data;
          if (resultCode === '1000' || resultCode === '1004') {
            openAlert({
              title: '알림',
              children: '이메일 혹은 비밀번호를 확인해주세요',
              btnNm: '확인',
            });
            return;
          }
          if (resultCode === '1005' || resultCode === '1002') {
            setError(
              '비밀번호를 10회 잘못 입력하였습니다. 비밀번호를 재설정 해주세요',
            );
            return;
          }
        }
      },
    });
  };

  const onSnsLogin = type => {
    if (type === 'kakao') {
      const restApiKey = process.env.REACT_APP_PUBLIC_KAKAO_CLIENT_ID;
      const redirect = `${process.env.REACT_APP_PUBLIC_SNS_REDIRECT_URL}/user/login/kakao`;
      window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${restApiKey}&redirect_uri=${redirect}&response_type=code`;
    } else if (type === 'naver') {
      const restApiKey = process.env.REACT_APP_PUBLIC_NAVER_CLIENT_ID;
      const redirect = `${process.env.REACT_APP_PUBLIC_SNS_REDIRECT_URL}/user/login/naver`;
      window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${restApiKey}&redirect_uri=${redirect}`;
    }
  };

  const wrapperStyle = css`
    margin: 0 auto;
    max-width: 408px;
    padding: 56px 0px;
    .title {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      line-height: 48px;
    }
    .info {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      margin-top: 8px;
      line-height: 18px;
      span {
        color: #909090;
      }
    }
    .content {
      margin-top: 36px;
      input {
        font-size: 16px !important;
      }
      .input + .input {
        margin-top: 24px;
      }
      button {
        margin-top: 48px;
      }
      form {
        button {
          margin-top: 32px;
        }
        .bottom {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .check {
            label + label {
              margin-left: 10px;
            }
          }
          .find {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      .driver {
        margin-top: 36px;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
      }
      .sns {
        display: inline-flex;
        width: 100%;
        justify-content: center;
        margin-top: 24px;
        li {
          width: 40px;
          height: 40px;
          margin: 0 10px;
          display: block;
          background: url('https://event.admerce.co.kr/mall/sp_member.svg')
            no-repeat;
          background-size: 195px 184px;
          cursor: pointer;
          &.kakao {
            background-position: -110.79545px -4.43373px;
          }
          &.naver {
            background-position: -110.79545px -53.20482px;
          }
          &.apple {
            background-position: -57.61364px -57.63855px;
          }
        }
      }
      .goToMall {
        background: #f9fafb;
        border-radius: 12px;
        padding: 12px 0px 20px;
        margin: 24px 0px;
        text-align: center;
        .mallButton {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #6079ff;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .partnerLogin {
        padding-top: 24px;
        border-top: 1px solid #e1e1e1;
        .info {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #606060;
        }
        button {
          margin-top: 24px;
          font-weight: 700;
        }
      }
      .nonMember {
        margin-top: 48px;
        font-weight: 400;
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        justify-content: center;
      }
    }

    ${mq.mobile} {
      padding: 50px 20px;
    }
  `;

  return (
    <Layout theme="white">
      <div css={wrapperStyle}>
        <div className="title">로그인</div>
        <div className="info">
          몰파이 구매회원 로그인
          <br />
          <span>
            몰파이의 여러 쇼핑몰의 구매내역 등을 확인하실 수 있습니다.
          </span>
        </div>
        <div className="content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="text"
              placeholder="이메일을 입력해주세요."
              label="이메일"
              reset={() => setValue('userEml', '')}
              {...register('userEml', { required: true })}
              prefix={
                errors.userEml &&
                errors.userEml.type === 'required' &&
                '이메일을 입력해주세요.'
              }
            />
            <Input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              label="비밀번호"
              show
              {...register('password', { required: true })}
              prefix={
                (errors.password &&
                  errors.password.type === 'required' &&
                  '비밀번호를 입력해주세요.') ||
                error
              }
            />
            <Button color="blue" type="submit" fullWidth>
              로그인
            </Button>
            <div className="bottom">
              <div className="check">
                <Controller
                  control={control}
                  name="save"
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      {...register('save')}
                      checked={!!value}
                      onChange={onChange}
                    >
                      아이디 저장
                    </Checkbox>
                  )}
                />
              </div>
              <div className="find" onClick={() => navigate('/user/reset')}>
                비밀번호 재설정
              </div>
            </div>
          </form>
          <div className="driver">SNS계정으로 간편 로그인</div>
          <ul className="sns">
            <li className="kakao" onClick={() => onSnsLogin('kakao')} />
            <li className="naver" onClick={() => onSnsLogin('naver')} />
          </ul>
          <div className="goToMall">
            <div className="info">
              회원가입은 몰파이에서 운영되는
              <br />
              여러 쇼핑몰에서 가입할 수 있습니다.
            </div>
            <div className="mallButton" onClick={() => navigate('/')}>
              몰파이 쇼핑몰 구경하기
            </div>
          </div>
          <div className="partnerLogin">
            <div className="info">
              파트너(마이몰 운영자, 입점사)는
              <br />
              몰파이 파트너 사이트에서 로그인이 가능합니다.
            </div>
            <Button
              color="gray"
              fullWidth
              onClick={() => window.open('https://my.mallpie.kr', '_blank')}
            >
              파트너 회원 로그인
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
