/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Product from 'components/common/Product';
import Title from 'components/common/Title';
import { useIsPc } from 'core/hooks/useResponsive';
import { useNewProduct } from 'core/queries/now';

const NewProduct = () => {
  const { mq } = useTheme();

  const { data: newProductList } = useNewProduct();

  const isPc = useIsPc();

  const NewProductStyle = css`
    padding: 100px 0;
    overflow: hidden;
    .container {
      width: 1300px;
      margin: 50px auto 0;
      position: relative;
      .swiper {
        .swiper-wrapper {
          padding-bottom: 70px;
        }
        .swiper-slide {
          width: 320px;
        }
        .swiper-pagination-bullets {
          bottom: 0;
          .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            font-size: 15px;
            opacity: 1;
            background: #ebebeb;
          }
          .swiper-pagination-bullet-active {
            background: #6079ff;
          }
        }
      }
      .newPrev {
        position: absolute;
        top: 50%;
        margin-top: -30px;
        left: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/prev_arrow_grey.png');
        background-size: 100% 100%;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
      .newNext {
        position: absolute;
        top: 50%;
        margin-top: -30px;
        right: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/next_arrow_grey.png');
        background-size: 100% 100%;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
    }
    ${mq.mobile} {
      padding: 40px 0 30px 0;
      .wrap {
        padding-left: 20px;
      }
      .container {
        margin-top: 20px;
        width: 100%;
        .swiper {
          .swiper-wrapper {
            padding-left: 4px;
            padding-bottom: 10px;
          }

          .swiper-pagination-bullets {
            display: none;
            .swiper-pagination-bullet {
              width: 8px;
              height: 8px;
              font-size: 8px;
            }
          }
        }
        .newPrev,
        .newNext {
          display: none;
        }
      }
    }
  `;

  return (
    <div css={NewProductStyle}>
      <div className="wrap">
        <Title
          title="이번주 인기 신상 확인 ✨"
          subTitle="신상품 확인하고 마이몰에 담아보세요!"
        />
      </div>
      {newProductList?.length > 0 && (
        <div className="container">
          <Swiper
            slidesPerView={isPc ? 4 : 2}
            slidesPerGroup={isPc ? 4 : 1}
            spaceBetween={isPc ? 20 : 15}
            slidesOffsetBefore={isPc ? 0 : 20}
            slidesOffsetAfter={isPc ? 0 : 20}
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: '.newNext',
              prevEl: '.newPrev',
            }}
            className="mySwiper"
            freeMode={true}
          >
            {newProductList?.map((v, k) => (
              <SwiperSlide key={k}>
                <Product
                  direction="vertical"
                  img={v?.imgLink}
                  name={v?.name}
                  sellPrice={v?.sellPrice}
                  prdPrice={v?.prdPrice}
                  onClick={() =>
                    window.open(
                      `https://${v?.shopId}.${
                        process.env.REACT_APP_PUBLIC_ENV !== 'production'
                          ? 'sellerd.kr'
                          : 'mallpie.kr'
                      }/product/${v.prdSn}`,
                    )
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="newPrev"> </div>
          <div className="newNext"> </div>
        </div>
      )}
    </div>
  );
};

export default NewProduct;
