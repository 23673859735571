/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Mobile } from 'components/common/Responsive';
import text from 'static/promotion/section4/text.png';
import React from 'react';

const Sectino4 = () => {
  const section4MoStyle = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 200px 20px 200px 20px;
      background: #6106e8;
      position: relative;
      img {
        width: 100%;
        max-width: 200px;
      }
    }
  `;
  return (
    <Mobile>
      <div css={section4MoStyle}>
        <div className="container">
          <img src={text} alt="" />
        </div>
      </div>
    </Mobile>
  );
};

export default Sectino4;
