/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import list1Img from 'static/creator/section1_list1.png';
import list2Img from 'static/creator/section1_list2.png';
import list3Img from 'static/creator/section1_list3.png';

const Section1 = () => {
  const { mq } = useTheme();
  const Section1Style = css`
    display: flex;
    justify-content: center;
    .container {
      max-width: 1200px;
      padding: 46px 0px 227px;
      margin: 0 auto;
      > .title {
        color: #000;
        font-weight: 700;
        font-size: 68px;
        line-height: 50px;
        text-align: center;
        line-height: 96px;
        span {
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 155px;
        margin-top: 100px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          &:nth-of-type(2) {
            flex-direction: row;
          }
          .text {
            padding: 0px 40px;
            .icon {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              color: #6079ff;
            }
            .title {
              margin-top: 10px;
              font-size: 40px;
              font-weight: 800;
              line-height: 60px;
              span {
                color: #6079ff;
              }
            }
            .desc {
              margin-top: 10px;
              color: #444;
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
            }
          }
          .img {
            width: 480px;
            flex-shrink: 0;
            border-radius: 32px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 40px 0px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 20px 20px 96px;
        width: 100%;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 45px;
        }
        .list {
          gap: 64px;
          margin-top: 64px;
          li {
            align-items: flex-start;
            flex-direction: column-reverse;
            &:nth-of-type(2) {
              flex-direction: column-reverse;
            }
            .text {
              padding: 0px;
              text-align: left;
              margin-bottom: 24px;
              .icon {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
              .title {
                font-size: 28px;
                line-height: 40px; /* 142.857% */
              }
              .desc {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px; /* 150% */
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const scaleVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section1Style}>
      <div className="container">
        <h2 className="title">
          <span>몰파이</span>를
          <br /> 추천합니다!
        </h2>
        <ul className="list">
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list1Img}
                alt="내가 만든 콘텐츠로 쌓아가는 수익 | 이미지"
                title="내가 만든 콘텐츠로 쌓아가는 수익"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                내가 만든 콘텐츠로
                <br />
                쌓아가는 <span>수익</span>
              </h3>
              <p className="desc">
                SNS를 운영하면서 쌓은
                <br />
                팔로워(팬)를 활용하고 싶다면
              </p>
            </motion.div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list2Img}
                alt="구매를 부르는 커머스형 디자인 | 이미지"
                title="구매를 부르는 커머스형 디자인"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                구매를 부르는
                <br />
                <span>커머스형</span> 디자인
              </h3>
              <p className="desc">
                단순 링크 공유말고, 있어 보이는 <br />
                쇼핑몰로 상품을 공유하고 싶다면
              </p>
            </motion.div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list3Img}
                alt="복잡한 운영은 맡기고 오로지 홍보에만 집중 | 이미지"
                title="복잡한 운영은 맡기고 오로지 홍보에만 집중"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                복잡한 운영은 맡기고
                <br />
                오로지 <span>홍보에만 집중</span>
              </h3>
              <p className="desc">
                상품소싱, CS운영 외 내가 자신있는
                <br />
                홍보에만 집중하여 수익을 만들고 싶다면
              </p>
            </motion.div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section1;
