import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CookiesProvider } from 'react-cookie';
import Home from 'pages/home';
import Make from 'pages/myshop/make';
import Style from 'pages/myshop/style';
import List from 'pages/myshop/list';
import Calc from 'pages/myshop/calc';
import Now from 'pages/now';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css/navigation';
import 'styles/reset.css';

import Alert from 'components/common/Alert';
import Confirm from 'components/common/Confirm';

import GlobalStyle from './styles/global';
import theme from './styles/theme';
import Guide from 'pages/business/guide';
import Shop from 'pages/business/shop';
import Inquiry from 'pages/inquiry';
import Terms from 'pages/terms';
import Event from 'pages/event';
import Promotion from 'pages/promotion';
import Login from 'pages/user/login';
import Mymall from 'pages/user/mypage/mymall';
import Purchase from 'pages/user/mypage/purchase';
import Point from 'pages/user/mypage/point';
import Coupon from 'pages/user/mypage/coupon';
import Info from 'pages/user/mypage/info';
import Withdrawl from 'pages/user/mypage/withdrawal';
import KakaoLogin from 'pages/user/login/kakao';
import NaverLogin from 'pages/user/login/naver';
import Reset from 'pages/user/reset';
import UserPassword from 'pages/user/pw';
import PrivateRoute from 'routes/PrivateRoute';
import Personal from 'pages/personal';
import Method from 'pages/method';
import Store from 'pages/store';
import Cs from 'pages/cs';
import Faq from 'pages/faq';
import FaqDetail from 'pages/faq/detail';
import Creator from 'pages/creator';
import Class from 'pages/class';
import Revenue from 'pages/revenue';
import Company from 'pages/company';
import NotFound from '404';
import Welfare from 'pages/welfare';
import Download from 'pages/download';
import NewsDetail from 'pages/news/detail';
import News from 'pages/news';
import { useEffect } from 'react';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/method',
    element: <Method />,
  },
  {
    path: '/myshop/make',
    element: <Make />,
  },
  {
    path: '/myshop/style',
    element: <Style />,
  },
  {
    path: '/myshop/list',
    element: <List />,
  },
  {
    path: '/myshop/calc',
    element: <Calc />,
  },
  {
    path: '/now',
    element: <Now />,
  },
  {
    path: '/intro/personal',
    element: <Personal />,
  },
  {
    path: '/intro/creator',
    element: <Creator />,
  },
  {
    path: '/intro/class',
    element: <Class />,
  },
  {
    path: '/intro/company',
    element: <Company />,
  },
  {
    path: '/business/guide',
    element: <Guide />,
  },
  {
    path: '/business/shop',
    element: <Shop />,
  },
  {
    path: '/welfare',
    element: <Welfare />,
  },
  {
    path: '/inquiry',
    element: <Inquiry />,
  },
  {
    path: '/store',
    element: <Store />,
  },
  {
    path: '/inquiry',
    element: <Inquiry />,
  },
  {
    path: '/terms/:id',
    element: <Terms />,
  },
  {
    path: '/cs',
    element: <Cs />,
  },
  {
    path: '/faq/:type/:category',
    element: <Faq />,
  },
  {
    path: '/faq/:type/:category/:id',
    element: <FaqDetail />,
  },
  {
    path: '/news',
    element: <News />,
  },
  {
    path: '/download',
    element: <Download />,
  },
  {
    path: '/news/:id',
    element: <NewsDetail />,
  },
  {
    path: '/revenue',
    element: <Revenue />,
  },
  {
    path: '/event/:id',
    element: <Event />,
  },
  {
    path: '/promotion',
    element: <Promotion />,
  },
  {
    path: '/user/login',
    element: <Login />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: '/user/mypage/mymall',
        element: <Mymall />,
      },
      {
        path: '/user/mypage/purchase',
        element: <Purchase />,
      },
      {
        path: '/user/mypage/point',
        element: <Point />,
      },
      {
        path: '/user/mypage/coupon',
        element: <Coupon />,
      },
      {
        path: '/user/mypage/info',
        element: <Info />,
      },
      {
        path: '/user/mypage/withdrawal',
        element: <Withdrawl />,
      },
    ],
  },
  {
    path: '/user/login/kakao',
    element: <KakaoLogin />,
  },
  {
    path: '/user/login/naver',
    element: <NaverLogin />,
  },
  {
    path: '/user/reset',
    element: <Reset />,
  },
  {
    path: '/user/pw',
    element: <UserPassword />,
  },
  { path: '/*', element: <NotFound /> },
]);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 10,
      cacheTime: 1000 * 10,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
});

declare global {
  interface Window {
    fbq: (...args: any[]) => void;
  }
}

export {};

const App = () => {
  useEffect(() => {
    if (window.fbq && process.env.REACT_APP_PUBLIC_META_PIXEL_CODE_DATASET_ID) {
      window.fbq(
        'init',
        process.env.REACT_APP_PUBLIC_META_PIXEL_CODE_DATASET_ID,
      );
      window.fbq('track', 'PageView');
    }
  }, []);

  return (
    <>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            {/* <UserProvider userInfo=""> */}
            <RouterProvider router={router} />
            {/* </UserProvider> */}
            <Alert />
            <Confirm />
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </CookiesProvider>
    </>
  );
};

export default App;
