/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as NoDataIcon } from 'static/user/mypage/noData.svg';

interface NoDataType {
  text: string;
}

const NoData = ({ text }: NoDataType) => {
  const wrapperStyle = css`
    margin: 0 auto;
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 320px;
      .text {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #bdbdbd;
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      <div className="content">
        <NoDataIcon />
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

export default NoData;
