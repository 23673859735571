/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/home/method3_list1.png';
import list2Img from 'static/home/method3_list2.png';
import { ReactComponent as Ticket } from 'static/home/ticket.svg';
import { motion } from 'framer-motion';

const Method3 = () => {
  const { mq } = useTheme();

  const Method3Style = css`
    display: flex;
    justify-content: center;
    padding: 128px 0;
    .wrap {
      .title {
        .sub {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 24px;
          font-weight: 700;
          color: #a7acc6;
        }
        .text {
          color: #000;
          font-weight: bold;
          font-size: 56px;
          margin-top: 16px;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
      }
      .list {
        display: flex;
        gap: 36px;
        margin-top: 72px;
        li {
          height: 701px;
          position: relative;
          border-radius: 40px;
          &:nth-of-type(1) {
            width: 740px;
            background: #e4f1fa;
            .text {
              color: #6998b9;
              span {
                color: #000;
              }
            }
          }
          &:nth-of-type(2) {
            width: 500px;
            background: #f1f4f6;
            .text {
              color: #8897a2;
              span {
                color: #000;
              }
            }
          }
          .text {
            padding: 64px 56px;
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
          }
          img {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    ${mq.mobile} {
      padding: 0px 20px 96px;
      justify-content: start;
      .wrap {
        .title {
          .sub {
            font-size: 16px;
          }
          .text {
            font-size: 30px;
            margin-top: 10px;
            line-height: 42px;
          }
          svg {
            width: 28px;
            height: 28px;
          }
        }
        width: 100%;
        .list {
          flex-direction: column;
          margin-top: 24px;
          gap: 20px;
          li {
            border-radius: 20px;
            .text {
              padding: 38px 38px 20px 38px;
              font-size: 20px;
              line-height: 30px;
            }
            width: 100%;
            height: auto;
            img {
              position: relative;
            }
            &:nth-of-type(1),
            &:nth-of-type(2) {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Method3Style}>
      <div className="wrap">
        <div className="title">
          <div className="sub">
            <Ticket />
            다양한 커머스 기능
          </div>
          <h2 className="text">
            구매 전환율을 높이는
            <br />
            디테일이 담긴 <span>커머스 기능</span>
          </h2>
        </div>
        <motion.ul
          className="list"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <motion.li variants={defaultVariants}>
            <h3 className="text">
              직접 발행하는
              <br />
              금액 쿠폰으로
              <br />
              <span>구매 전환율 상승</span>
            </h3>
            <img
              src={list1Img}
              alt="다양한 커머스 기능1 | 이미지"
              title="다양한 커머스 기능1"
            />
          </motion.li>
          <motion.li variants={defaultVariants}>
            <h3 className="text">
              비용없이 쓸 수 있는
              <br />
              요즘 필요한
              <br />
              <span>선물하기 기능</span>
            </h3>
            <img
              src={list2Img}
              alt="다양한 커머스 기능2 | 이미지"
              title="다양한 커머스 기능2"
            />
          </motion.li>
        </motion.ul>
      </div>
    </div>
  );
};

export default Method3;
