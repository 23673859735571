/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import Radio from 'components/common/Radio';
import TextArea from 'components/common/TextArea';
import Checkbox from 'components/common/Checkbox';

export interface WithdrawalFormValues {
  whdwlCn: string;
  whdwlTypeCd: string;
}

interface withdrawalTypeData {
  cd: string;
  cdNm: string;
}

interface WithdrawalProps {
  onSubmit?: SubmitHandler<WithdrawalFormValues>;
  withdrawalType?: withdrawalTypeData[];
}

const WithdrawalContent = ({ onSubmit, withdrawalType }: WithdrawalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<WithdrawalFormValues>({
    defaultValues: {
      whdwlTypeCd: '01',
    },
  });

  const { mq } = useTheme();

  const [isAgree, setIsAgree] = useState<boolean>(false);

  const withdrawalStyle = css`
    padding-bottom: 42px;
    margin: 0 auto;
    max-width: 500px;
    * {
      word-break: keep-all;
    }
    .header {
      margin-bottom: 32px;
      div {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        span {
          color: #6079ff;
          font-weight: 700;
        }
      }
    }
    .title-box {
      padding-bottom: 20px;
      border-bottom: 1px solid #e1e1e1;
      .title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .sub-title {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #606060;
      }
    }
    .radiobox {
      padding-top: 20px;
      .radio {
        display: flex;
        flex-direction: column;
        gap: 14px;
      }
    }
    .notice {
      margin: 32px 0px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #909090;
    }
    .button-box {
      margin-top: 32px;
    }

    ${mq.mobile} {
      padding: 0px 20px 32px;
      .header {
        div {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  `;

  return (
    <div css={withdrawalStyle}>
      <div className="header">
        <div>
          탈퇴 시 보유하신 <span>쿠폰 및 몰파이 적립금은 모두 소멸</span>되며,
        </div>
        <div>구독 중인 모든 마이몰에서 해지처리됩니다.</div>
      </div>
      <div className="title-box">
        <div className="title">불편하신 점이 있으셨나요?</div>
        <div className="sub-title">
          탈퇴 하시려는 사유를 알려주시면 서비스 개선에 반영하도록 하겠습니다.
        </div>
      </div>
      <div className="radiobox">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul className="radio">
            {withdrawalType?.map(type => (
              <li key={type.cd}>
                <Radio
                  value={type.cd}
                  checked={watch('whdwlTypeCd') === type.cd}
                  onChange={() => setValue('whdwlTypeCd', type.cd)}
                >
                  {type.cdNm}
                </Radio>
                {type.cd === '06' && (
                  <TextArea
                    placeholder="탈퇴 사유를 작성해주세요"
                    maxLength={100}
                    showMaxLength
                    {...register('whdwlCn', {
                      required: watch('whdwlTypeCd') === '06',
                    })}
                    prefix={
                      errors.whdwlCn &&
                      errors.whdwlCn.type === 'required' &&
                      '탈퇴 사유를 작성해주세요'
                    }
                  />
                )}
              </li>
            ))}
          </ul>
          <ul className="notice">
            <li>- 탈퇴 후 7일 동안 동일 아이디로 재가입이 불가능합니다.</li>
            <li>
              - 신규 가입 시 받은 혜택(몰파이 적립금, 쿠폰 및 기타 혜택은) 1회에
              한정되며, 재가입시 혜택은 회수될 수 있습니다.
            </li>
            <li>
              - 탈퇴 시 민감한 개인정보 및 개인식별정보를 비롯한 모든 개인
              정보는 즉시 파기합니다.
            </li>
            <li>
              - 거래내역은 전자상거래 등에서의 소비자보호에 관한 법률에 의거
              사후처리를 위해 5년간 별도 보관됩니다.
            </li>
            <li>
              - 진행중인 거래(구매확정 전/교환/반품/취소 처리 상태)가 있는 경우
              거래를 완료하신 후 탈퇴하실 수 있습니다.
            </li>
          </ul>
          <div>
            <Checkbox
              checked={isAgree}
              onChange={(e: any) => {
                setIsAgree(e.target.checked);
              }}
            >
              위 탈퇴 안내 사항을 모두 확인하였으며, 탈퇴에 동의합니다.
            </Checkbox>
          </div>
          <div className="button-box">
            <Button color="blue" fullWidth disabled={!isAgree} type="submit">
              탈퇴
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WithdrawalContent;
