/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as ArrowLeft } from 'static/store/arrow_left.svg';

const Store4 = () => {
  const { mq } = useTheme();

  const TitleStyle = css`
    background: #2b2b2b;
    padding: 180px 0;
    text-align: center;
    .title {
      font-size: 64px;
      font-weight: 800;
      line-height: 130%;
      color: #fff;
      span {
        color: #6079ff;
      }
    }
    .sub {
      margin-top: 48px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: #fff;
      opacity: 0.8;
    }
    .anker {
      display: inline-flex;
      margin-top: 48px;
      li {
        button {
          border-radius: 27px;
          padding: 12px 36px;
          align-items: center;
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          background: linear-gradient(90deg, #6079ff 0%, #af60ff 100%);
          border: 0;
          svg {
            margin-left: 6px;
          }
        }
      }
      li + li {
        margin-left: 20px;
        button {
          background: #000;
        }
      }
    }
    ${mq.mobile} {
      padding: 96px 0;
      .title {
        font-size: 34px;
        line-height: 46px;
      }
      .sub {
        font-size: 16px;
        line-height: 24px;
        margin-top: 32px;
      }
      .anker {
        margin-top: 32px;
        li {
          button {
            width: 100%;
            justify-content: center;
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  `;
  return (
    <div css={TitleStyle}>
      <h2 className="title">
        <span>몰파이 입점에 대해</span>
        <br />더 궁금하신가요?
      </h2>
      <p className="sub">
        입점부터 정산까지
        <br />더 궁금한 점이 있다면 차근차근 안내해드려요!
      </p>
      <ul className="anker">
        <a
          href="https://my.mallpie.kr/customer/inquiry"
          target="_blank"
          rel="noreferrer noopener"
        >
          <li>
            <button>
              문의하기
              <ArrowLeft />
            </button>
          </li>
        </a>
      </ul>
    </div>
  );
};

export default Store4;
