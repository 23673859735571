/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import React from 'react';
import { useIsMobile } from 'core/hooks/useResponsive';

const Bottom = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const BottomStyle = css`
    padding: 180px 0;
    background: #282828;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      text-align: center;
      font-size: 64px;
      line-height: 130%;
      font-weight: bold;
      color: #fff;
      span {
        color: #6079ff;
      }
    }
    button {
      border-radius: 27px;
      border: 0;
      margin-top: 48px;
      background: linear-gradient(90deg, #6079ff 0%, #af60ff 100%);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      color: #fff;
      padding: 12px 36px;
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 8px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    ${mq.mobile} {
      padding: 96px 20px;
      .title {
        font-size: 34px;
        font-style: normal;
        font-weight: 800;
        line-height: 46px; /* 135.294% */
      }
      button {
        font-size: 18px;
      }
    }
  `;

  return (
    <div css={BottomStyle}>
      <h2 className="title">
        내 쇼핑몰 만드는 <br />
        <span>가장 빠른 길</span>
      </h2>
      <a
        href="https://my.mallpie.kr"
        target="_blank"
        rel="noopener noreferrer"
        id="per_bottom_bu_mymall"
      >
        <button>
          나만의 쇼핑몰 시작하기 <MainArrow />
        </button>
      </a>
    </div>
  );
};

export default Bottom;
