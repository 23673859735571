/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export interface BadgeProps {
  text: string;
  onClick?: () => void;
  tagYn?: 'Y' | 'N';
}

const Badge = ({ text, onClick, tagYn }: BadgeProps) => {
  const { mq } = useTheme();
  const BadgeStyle = css`
    display: inline-flex;
    isolation: isolate;
    padding: 0 12px;
    border-radius: 99px;
    border: 1px solid rgba(96, 121, 255, 0.8);
    color: rgba(96, 121, 255, 0.8);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 32px;
    line-height: 28px;
    margin-right: 10px;
    margin-bottom: 2px;
    z-index: 99;
    ${mq.mobile} {
      font-size: 12px;
      line-height: 24px;
      height: 28px;
    }
  `;

  return (
    <div css={BadgeStyle} onClick={onClick}>
      {text}
    </div>
  );
};

export default Badge;
