import { useLocation, useSearchParams } from 'react-router-dom';
import SnsLogin from 'components/user/login/SnsLogin';

const KakaoLogin = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams(location.search);
  const code = searchParams.get('code');
  const error = searchParams.get('error');

  return (
    <>
      <SnsLogin type="kakao" code={code} error={error} />
    </>
  );
};

export default KakaoLogin;
