/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Layout from 'components/common/Layout';
import UserInfo from 'components/user/mypage/UserInfo';
import MypageSelect from 'components/user/mypage/MypageSelect';
import PointInfo from 'components/user/mypage/point/PointInfo';

const Point = () => {
  return (
    <Layout theme="white">
      <UserInfo />
      <MypageSelect active="point" />
      <PointInfo />
    </Layout>
  );
};

export default Point;
