/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  active: string;
}

const MypageSelect = ({ active }: Props) => {
  const navigate = useNavigate();

  const Menu = [
    {
      id: 'M0001',
      name: '구독중인 마이몰',
      to: '/user/mypage/mymall',
      path: 'mymall',
    },
    {
      id: 'M0002',
      name: '구매내역',
      to: '/user/mypage/purchase',
      path: 'history',
    },
    {
      id: 'M0003',
      name: '적립금 내역',
      to: '/user/mypage/point',
      path: 'point',
    },
    {
      id: 'M0004',
      name: '내 쿠폰',
      to: '/user/mypage/coupon',
      path: 'coupon',
    },
    {
      id: 'M0005',
      name: '내 정보',
      to: '/user/mypage/info',
      path: 'info',
    },
  ];

  const wrapperStyle = css`
    border-bottom: 1px solid #e4e7ec;
    .list {
      margin: 0 auto;
      max-width: 482px;
      display: flex;
      overflow-y: scroll;
      scrollbar-width: none;
      .list::-webkit-scrollbar {
        display: none;
      }
      li {
        padding: 14px 0px;
        margin: 0px 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #909090;
        white-space: nowrap;
        cursor: pointer;
      }
      li.active {
        color: #6079ff;
        border-bottom: 2px solid #6079ff;
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      <ul className="list">
        {Menu.map((item, index) => (
          <li
            key={item.id}
            className={item.path === active ? 'active' : ''}
            onClick={() => navigate(item.to)}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MypageSelect;
