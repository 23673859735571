import Layout from 'components/common/Layout';
import Title from 'components/business/Title';
import Section1 from 'components/business/guide/Section1';
import titleImg from 'static/business/guide/title.png';
import titleImgM from 'static/business/guide/title_m.png';
import Section2 from 'components/business/guide/Section2';
import Section3 from 'components/business/guide/Section3';

const Guide = () => (
  <Layout theme="white">
    <Title
      title="입점사 안내"
      img={titleImg}
      mImg={titleImgM}
      text={
        '좋은 제품에는 좋은 플랫폼이 필요합니다.\n몰파이 플랫폼 안에서 다양한 판매 채널을 확보해보세요.'
      }
    />
    <Section1 />
    <Section2 />
    <Section3 />
  </Layout>
);

export default Guide;
