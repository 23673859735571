import Layout from 'components/common/Layout';
import Section1 from 'components/myshop/calc/Section1';
import Section2 from 'components/myshop/calc/Section2';
import Section3 from 'components/myshop/calc/Section3';
import Section4 from 'components/myshop/calc/Section4';
import Title from 'components/myshop/Title';
import TitleIcon from 'static/myshop/calc/title_icon.png';

const Style = () => (
  <Layout theme="white">
    <Title
      title="정산"
      titleAccent="판매 수익"
      img={TitleIcon}
      text={
        '나의 몰을 통해 구매가 이루어지면 상품의 일정 금액이 여러분의 수익이 됩니다.\n쇼핑몰을 주변에 널리 알리고 수익을 차곡 차곡 늘려보세요.'
      }
      mText={
        '나의 몰을 통해 구매가 이루어지면 상품의 일정 금액이\n여러분의 수익이 됩니다.\n쇼핑몰을 주변에 널리 알리고 수익을 차곡 차곡 늘려보세요.'
      }
    />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
  </Layout>
);

export default Style;
