/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { BannerStyle, BannerStyleM } from './styled';
import { PC, Mobile } from '../Responsive';
import { setCookie } from 'core/config/cookie';
import pcBanner from '../../../static/event/mallpie_line_banner_pc.png';
import mobileBanner from '../../../static/event/mallpie_line_banner_mobile.png';
import closeBtn from '../../../static/event/x.svg';
import { useNavigate } from 'react-router-dom';

interface BannerProps {
  navigationRoute: string;
  todayNotShow: boolean;
  onClick: () => void;
}

const Banner = ({ navigationRoute, todayNotShow, onClick }: BannerProps) => {
  const navigation = useNavigate();
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const setTodayNotShowCookie = () => {
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() + 1);

    setCookie('notShowToday', showBanner, {
      path: '/',
      expires: nowDate,
    });
  };

  const moveEventPage = () => {
    navigation(navigationRoute);
  };

  return (
    <>
      <PC>
        <div css={BannerStyle}>
          <img
            src={pcBanner}
            alt="mallpie_line_banner"
            onClick={moveEventPage}
          />
          {todayNotShow && (
            <div
              className="show-not-today"
              onClick={() => {
                setShowBanner(true);
                onClick();
                setTodayNotShowCookie();
              }}
            >
              <p>오늘 하루 보지 않기</p>
              <img src={closeBtn} alt="close_btn" />
            </div>
          )}
        </div>
      </PC>
      <Mobile>
        <div css={BannerStyleM}>
          <img
            src={mobileBanner}
            alt="mallpie_line_banner"
            onClick={moveEventPage}
          />
          {todayNotShow && (
            <div
              className="show-not-today"
              onClick={() => {
                setShowBanner(true);
                onClick();
                setTodayNotShowCookie();
              }}
            >
              <p>오늘 하루 보지 않기</p>
            </div>
          )}
        </div>
      </Mobile>
    </>
  );
};

export default Banner;
