/** @jsxImportSource @emotion/react */
import { Section2Style, Section2StyleM } from './style';
import { PC, Mobile } from 'components/common/Responsive';

import event6 from '../../../static/event/event_6.png';

const Section2 = () => (
  <>
    <PC>
      <div css={Section2Style}>
        <img src={event6} alt="안내사항" />
      </div>
    </PC>
    <Mobile>
      <div css={Section2StyleM}>
        <img src={event6} alt="안내사항" />
      </div>
    </Mobile>
  </>
);

export default Section2;
