import React, { useEffect, useState } from 'react';
import Layout from 'components/common/Layout';
import Section1 from 'components/event/Section1';
import Section2 from 'components/event/Section2';
import { useNavigate } from 'react-router-dom';

const Event = () => {
  const navigate = useNavigate();
  const [startDate] = useState(new Date(2024, 3, 1, 0, 0, 0));
  const [endDate] = useState(new Date(2024, 4, 1, 0, 0, 0));
  const [nowDate] = useState(Date.now());

  useEffect(() => {
    const currentTime = new Date(nowDate).getTime();
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    if (currentTime < startTime || currentTime >= endTime) {
      alert('종료된 이벤트 입니다');
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <Layout theme="transparents" pd>
      <Section1 />
      <Section2 />
    </Layout>
  );
};

export default Event;
