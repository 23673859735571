/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import markImg from 'static/business/mark.png';

interface TitleProps {
  img: string;
  mImg: string;
  title: string;
  text: string;
  mark?: boolean;
}

const Title = ({ img, mImg, title, text, mark = false }: TitleProps) => {
  const { mq } = useTheme();

  const titleStyle = css`
    text-align: center;
    padding: 120px 0px 0px 0px;
    .container {
      max-width: 1680px;
      padding: 0px 40px;
      margin: 0 auto;
      position: relative;
      img {
        width: 100%;
        margin-top: 63px;
      }
      h2 {
        font-size: 56px;
        font-weight: 600;
        line-height: 76px;
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        white-space: pre-wrap;
      }
      .mark {
        width: 809px;
        position: absolute;
        bottom: -150px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.5;
      }
    }
    ${mq.mobile} {
      padding: 63px 0px 0px 0px;
      .container {
        padding: 0;
        img {
          width: 100%;
        }
        h2 {
          font-size: 30px;
          line-height: 40px;
        }
        .text {
          font-size: 14px;
          line-height: 22px;
          padding: 0 32px;
        }
        .mark {
          width: 278px;
          position: absolute;
          bottom: -50px;
        }
      }
    }
  `;
  return (
    <div css={titleStyle}>
      <div className="container">
        <h2>{title}</h2>
        <div className="text">{text}</div>
        <PC>
          <img src={img} alt="title_img" />
        </PC>
        <Mobile>
          <img src={mImg} alt="title_img" />
        </Mobile>
        {mark && (
          <div className="mark">
            <img src={markImg} alt="mark_img" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
