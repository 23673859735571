/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Mall from 'components/common/Mall';
import { useNewShop } from 'core/queries/new';
import Title from 'components/common/Title';
import { useIsPc } from 'core/hooks/useResponsive';
import { useNewChannel } from 'core/queries/now';

const NewShop = () => {
  const { mq } = useTheme();

  const { data: shopList } = useNewChannel();

  const isPc = useIsPc();

  const NewShopStyle = css`
    padding: 120px 0;
    overflow: hidden;
    .container {
      width: 1300px;
      margin: 48px auto 0;
      position: relative;
      .swiper {
        position: relative;
        .swiper-slide {
          width: 200px;
        }
      }
      .newShopPrev {
        position: absolute;
        top: 131px;
        left: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/prev_arrow_grey.png');
        background-size: 100% 100%;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
      .newShopNext {
        position: absolute;
        top: 131px;
        right: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/next_arrow_grey.png');
        background-size: 100% 100%;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
    }
    ${mq.mobile} {
      padding: 70px 0 50px 0;
      .wrap {
        padding-left: 20px;
      }
      .container {
        width: 100%;
        padding: 0;
        margin-top: 20px;
        .swiper {
          .swiper-slide {
            width: 162px;
          }
        }
        .newShopPrev,
        .newShopNext {
          display: none;
        }
      }
    }
  `;

  return (
    <div css={NewShopStyle}>
      <div className="wrap">
        <Title
          title="방금 개설된 신규 마이몰 📣"
          subTitle="새롭게 개설된 마이몰을 환영해 주세요"
          className="newShopTitle"
        />
      </div>
      {shopList && shopList.length > 0 && (
        <div className="container">
          <Swiper
            navigation={{
              nextEl: '.newShopNext',
              prevEl: '.newShopPrev',
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            slidesPerView={isPc ? 6 : 'auto'}
            slidesPerGroup={isPc ? 6 : 1}
            spaceBetween={isPc ? 14 : 10}
            slidesOffsetBefore={isPc ? 0 : 20}
            slidesOffsetAfter={isPc ? 0 : 20}
          >
            {shopList?.map((v, k) => (
              <SwiperSlide key={k}>
                <Mall
                  direction="vertical"
                  imgUrl={
                    v.images?.filter(it => it.dsgnImgTypeCd === 'LOGO')[0]
                      .imgLink
                  }
                  shopId={v.shopId}
                  shopName={v.shopNm}
                  categories={[v.hashtag[0]]}
                  nowCovers={v.images?.filter(
                    it => it.dsgnImgTypeCd === 'COVER',
                  )}
                  type="now"
                  onClick={() =>
                    window.open(
                      `https://${v.shopId}.${
                        process.env.REACT_APP_PUBLIC_ENV !== 'production'
                          ? 'sellerd.kr'
                          : 'mallpie.kr'
                      }`,
                    )
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="newShopPrev"> </div>
          <div className="newShopNext"> </div>
        </div>
      )}
    </div>
  );
};

export default NewShop;
