import Layout from 'components/common/Layout';
import Section1 from 'components/myshop/style/Section1';
import Section2 from 'components/myshop/style/Section2';
import Section3 from 'components/myshop/style/Section3';
import Section4 from 'components/myshop/style/Section4';
import Section5 from 'components/myshop/style/Section5';
import Title from 'components/myshop/Title';
import TitleIcon from 'static/myshop/style/title_icon.png';

const Style = () => (
  <Layout theme="white">
    <Title
      title="꾸미기"
      titleAccent="쇼핑몰"
      img={TitleIcon}
      text={
        '복잡한 디자인이나, 개발 지식은 필요없어요.\n좋아하는 컬러, 사진 그리고 약간의 설정만으로도 누구나 감탄하는 멋진 쇼핑몰을 만들 수 있습니다.'
      }
      mText={
        '복잡한 디자인이나, 개발 지식은 필요없어요.\n좋아하는 컬러, 사진 그리고 약간의 설정만으로도\n누구나 감탄하는 멋진 쇼핑몰을 만들 수 있습니다.'
      }
    />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
  </Layout>
);

export default Style;
