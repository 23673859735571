import { useMediaQuery } from 'react-responsive';

interface ResponsiveProps {
  children: React.ReactNode;
}

export const Mobile = ({ children }: ResponsiveProps) => {
  const isMobile = useMediaQuery({
    query: '(max-width:1023px)',
  });

  return <>{isMobile && children}</>;
};

export const PC = ({ children }: ResponsiveProps) => {
  const isPc = useMediaQuery({
    query: '(min-width:1024px)',
  });

  return <>{isPc && children}</>;
};
