/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { css, useTheme } from '@emotion/react';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { useUser } from 'core/store/context/UserContext';
import { enCryaes, deCryaes } from 'utils/crypto';
import { useUserDetail } from 'core/queries/mypage';
import {
  useChangeUser,
  useChangePassword,
  useNickname,
} from 'core/queries/mypage/info';
import { PasswordFormValues } from 'components/user/mypage/info/ChangePassword';
import useAlertStore from 'core/store/common/useAlertStore';
import Layout from 'components/common/Layout';
import UserInfo from 'components/user/mypage/UserInfo';
import MypageSelect from 'components/user/mypage/MypageSelect';
import ChangeInfo from 'components/user/mypage/info/ChangeInfo';
import ChangePassword from 'components/user/mypage/info/ChangePassword';

const Info = () => {
  const { mq } = useTheme();
  const navigate = useNavigate();
  const { openAlert } = useAlertStore();
  const { user, setUserData } = useUser();
  const [tab, setTab] = useState<'info' | 'change'>('info');
  const [cookies, setCookie] = useCookies(['mallpie-user']);

  const { data: userDetailData } = useUserDetail();
  const { mutate: checkNickname } = useNickname();
  const { mutate: changeUser } = useChangeUser();
  const { mutate: changePassword } = useChangePassword();
  if (userDetailData === undefined) return null;

  /** 닉네임 중복 확인, 내 정보 변경 */
  const onChangeSubmit = ({ userNm, nickname, mktAgrYn }) => {
    checkNickname(
      { nickname: nickname },
      {
        onSuccess: async () => {
          changeUser(
            {
              nickName: nickname,
              userNm: userNm,
              mktAgrYn: mktAgrYn,
            },
            {
              onSuccess: async response => {
                if (response.code === 'SUCCESS') {
                  setUserData({
                    ...user,
                    nickname: nickname,
                    userNm: userNm,
                  });

                  await setCookie(
                    'mallpie-user',
                    enCryaes({
                      ...JSON.parse(deCryaes(cookies['mallpie-user'])),
                      userNm: userNm,
                      nickname: nickname,
                    }),
                    { domain: process.env.REACT_APP_PUBLIC_URL, path: '/' },
                  );

                  const alertChildren =
                    mktAgrYn === userDetailData.mktAgrYn ? (
                      '정보가 변경되었습니다.'
                    ) : (
                      <>
                        <p>정보가 변경되었습니다.</p>
                        <p>{moment().format('YYYY년 MM월 DD일')}</p>
                        <p>
                          마케팅 수신에 {mktAgrYn ? '동의' : '거부'}하셨습니다.
                        </p>
                      </>
                    );

                  openAlert(
                    {
                      title: '알림',
                      children: alertChildren,
                      btnNm: '확인',
                    },
                    () => navigate('/user/mypage/mymall'),
                  );
                }
              },
              onError: (error: any) => {
                openAlert({
                  title: '알림',
                  children: error.response.data.message,
                  btnNm: '확인',
                });
              },
            },
          );
        },
        onError: (error: any) => {
          if (userDetailData.nickName === nickname) {
            changeUser(
              {
                nickName: nickname,
                userNm: userNm,
                mktAgrYn: mktAgrYn,
              },
              {
                onSuccess: async () => {
                  setUserData({
                    ...user,
                    nickname: userDetailData.nickName,
                    userNm: userDetailData.userNm,
                  });

                  await setCookie(
                    'mallpie-user',
                    enCryaes({
                      ...JSON.parse(deCryaes(cookies['mallpie-user'])),
                      userNm: userDetailData.userNm,
                      nickname: userDetailData.nickName,
                    }),
                    { domain: process.env.REACT_APP_PUBLIC_URL, path: '/' },
                  );

                  const alertChildren =
                    mktAgrYn === userDetailData.mktAgrYn ? (
                      '정보가 변경되었습니다.'
                    ) : (
                      <>
                        <p>정보가 변경되었습니다.</p>
                        <p>{moment().format('YYYY년 MM월 DD일')}</p>
                        <p>
                          마케팅 수신에 {mktAgrYn === 'Y' ? '동의' : '거부'}
                          하셨습니다.
                        </p>
                      </>
                    );

                  openAlert(
                    {
                      title: '알림',
                      children: alertChildren,
                      btnNm: '확인',
                    },
                    () => navigate('/user/mypage/mymall'),
                  );
                },
                onError: (error: any) => {
                  openAlert({
                    title: '알림',
                    children: error.response.data.message,
                    btnNm: '확인',
                  });
                },
              },
            );
          } else {
            openAlert({
              title: '알림',
              children: error.response.data.message,
              btnNm: '확인',
            });
          }
        },
      },
    );
  };

  /** 비밀번호 변경 */
  const changeUserPassword = async ({
    userEml,
    oldPassword,
    newPassword,
  }: PasswordFormValues) => {
    await changePassword(
      {
        userEml: userEml,
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      {
        onSuccess: response => {
          if (response.code === 'SUCCESS') {
            openAlert(
              {
                title: '알림',
                children: ' 비밀번호가 변경되었습니다.',
                btnNm: '확인',
              },
              () => navigate('/user/mypage/mymall'),
            );
          }
        },
        onError: (error: any) => {
          openAlert({
            title: '알림',
            children: error.response.data.message,
            btnNm: '확인',
          });
        },
      },
    );
  };

  const wrapperStyle = css`
    margin: 0 auto;
    max-width: 500px;
    * {
      word-break: keep-all;
    }
    ul {
      padding: 32px 0px;
      display: flex;
      li {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        color: #98a2b3;
        cursor: pointer;
      }
      li.active {
        color: #6079ff;
        border-bottom: 2px solid #6079ff;
      }
      li + li {
        margin-left: 20px;
      }
    }

    ${mq.mobile} {
      padding: 0px 20px;
    }
  `;

  return (
    <Layout theme="white">
      <UserInfo />
      <MypageSelect active="info" />
      <div css={wrapperStyle}>
        <ul>
          <li
            className={tab === 'info' ? 'active' : ''}
            onClick={() => setTab('info')}
          >
            기본 정보
          </li>
          <li
            className={tab === 'change' ? 'active' : ''}
            onClick={() => setTab('change')}
          >
            비밀번호 변경
          </li>
        </ul>
      </div>
      {tab === 'info' && (
        <ChangeInfo
          userNm={userDetailData.userNm}
          nickname={userDetailData.nickName}
          userMobile={userDetailData.userMobile}
          mktAgrYn={userDetailData.mktAgrYn}
          onSubmit={onChangeSubmit}
        />
      )}
      {tab === 'change' && <ChangePassword onSubmit={changeUserPassword} />}
    </Layout>
  );
};

export default Info;
