/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import list1 from 'static/promotion/section3/list1.png';
import list2 from 'static/promotion/section3/list2.png';
import list3 from 'static/promotion/section3/list3.png';
import button from 'static/promotion/section3/button.png';
import mlist1 from 'static/promotion/section3/m_list1.png';
import mlist2 from 'static/promotion/section3/m_list2.png';
import mlist3 from 'static/promotion/section3/m_list3.png';
import mbutton from 'static/promotion/section3/m_button.png';
import React from 'react';
import { Mobile, PC } from 'components/common/Responsive';

const Section3 = () => {
  const section3Style = css`
    .container {
      padding: 313px 20px 240px 20px;
      max-width: 1560px;
      margin: 0 auto;
      .cont {
        img {
          display: block;
          width: 100%;
        }
        img + img {
          margin-top: 200px;
        }
      }
      .button {
        margin-top: 190px;
        cursor: pointer;
        img {
          margin: 0 auto;
          width: 800px;
        }
      }
    }
  `;

  const section3MoStyle = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 20px 140px 20px;
      position: relative;
      img {
        width: 100%;
        max-width: 340px;
      }
      img + img {
        margin-top: 25px;
      }
      .button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 100%;
        max-width: 360px;
      }
    }
  `;
  return (
    <>
      <PC>
        <div css={section3Style}>
          <div className="container">
            <div className="cont">
              <img src={list1} alt="list1" />
              <img src={list2} alt="list2" />
              <img src={list3} alt="list3" />
              <div className="button">
                <img
                  src={button}
                  alt="button"
                  onClick={() => window.open('https://my.mallpie.kr', '_blank')}
                />
              </div>
            </div>
          </div>
        </div>
      </PC>
      <Mobile>
        <div css={section3MoStyle}>
          <div className="container">
            <img src={mlist1} alt="title" className="title" />
            <img src={mlist2} alt="title" className="list1" />
            <img src={mlist3} alt="title" className="list2" />
            <img
              src={mbutton}
              alt="button"
              className="button
            "
              onClick={() => window.open('https://my.mallpie.kr', '_blank')}
            />
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section3;
