import { css, useTheme } from '@emotion/react';

/** @jsxImportSource @emotion/react */
const MymallDataList = (data: any) => {
  const { mq } = useTheme();

  const wrapperStyle = css`
    display: grid;
    gap: 30px;

    .list {
      display: flex;
      align-items: center;
      max-width: 840px;
      .img-wrapper {
        width: 80px;
        height: 80px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50px;
          object-fit: cover;
        }
      }
      .item {
        margin-left: 20px;

        .title {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
        }
        .link {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #6079ff;
        }
        .text {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 740px;
        }
      }
    }
    .error {
      margin: 100px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .no-text {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #bdbdbd;
      }
    }
    ${mq.mobile} {
      .list {
        display: flex;
        align-items: center;
        .img-wrapper {
          width: 70px;
          height: 70px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50px;
            object-fit: cover;
          }
        }
        .item {
          .title {
            font-size: 16px;
            line-height: 24px;
          }
          .link {
            font-size: 12px;
            line-height: 18px;
          }
          .text {
            width: auto;
            font-size: 12px;
            line-height: 18px;
            white-space: normal;
          }
        }
      }
    }
  `;
  return (
    <div css={wrapperStyle}>
      {data?.list.map(it => {
        return (
          <div className="list" key={it.shopId}>
            <div className="img-wrapper">
              <img src={it.imgLink} alt="profile-img" />
            </div>
            <div className="item">
              <div className="title">{it.shopNm}</div>
              <a
                className="link"
                href={`//${it.shopId}.${process.env.REACT_APP_PUBLIC_URL}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${it.shopId}.${process.env.REACT_APP_PUBLIC_URL}`}
              </a>
              <div className="text">{it.shopDesc}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MymallDataList;
