/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';
import { css, useTheme } from '@emotion/react';

import infoIcon from 'static/home/info_icon1.png';
import infoIcon2 from 'static/home/info_icon2.png';
import infoIcon3 from 'static/home/info_icon3.png';

import info1 from 'static/home/info1.webp';
import info2 from 'static/home/info2.webp';
import info3 from 'static/home/info3.webp';

const Info = () => {
  const { mq } = useTheme();
  const InfoStyle = css`
    padding: 160px 70px 200px 70px;
    position: relative;
    background: linear-gradient(
      180deg,
      #f2f4f7 0%,
      rgba(242, 244, 247, 0) 100%
    );
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    > .title {
      font-size: 56px;
      font-weight: 700;
      line-height: 76px;
      margin-bottom: 64px;
      span {
        color: #6079ff;
        font-weight: inherit;
      }
    }
    .list {
      display: flex;
      flex-direction: column;
      padding-top: 176px;
      li {
        width: 100%;
        position: sticky;
        background: #fff;
        border-radius: 48px;
        box-shadow: 0px 20px 100px 0px rgba(0, 0, 0, 0.04);
        &:nth-of-type(1) {
          z-index: 2;
          bottom: 276px;
          margin-bottom: 176px;
          margin-top: -176px;
        }
        &:nth-of-type(2) {
          z-index: 1;
          bottom: 188px;
          margin-bottom: 88px;
          margin-top: -88px;
        }
        &:nth-of-type(3) {
          bottom: 100px;
        }
        .cont {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .text {
            padding-left: 140px;
            text-align: left;
            .icon {
              width: 56px;
              height: 56px;
              img {
                width: 100%;
              }
            }
            .subTitle {
              font-size: 28px;
              color: #6079ff;
              font-weight: 800;
              line-height: 42px;
            }
            .title {
              font-size: 40px;
              font-style: normal;
              font-weight: 800;
              line-height: 60px;
              margin-top: 16px;
            }
            .info {
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
              margin-top: 16px;
            }
          }
          .img {
            width: 986px;
            display: flex;
            img {
              width: 100%;
            }
          }
          .step {
            position: absolute;
            bottom: 24px;
            left: 24px;
            padding: 6px 20px;
            background: rgba(96, 121, 255, 0.1);
            color: #6079ff;
            border-radius: 20px;
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
          }
        }
      }
    }
    @media screen and (max-width: 1700px) {
      .list {
        li {
          .cont {
            .text {
              padding-left: 100px;

              .subTitle {
                font-size: 26px;
              }
              .title {
                font-size: 36px;
                line-height: 45px;
                margin-top: 16px;
              }
              .info {
                font-size: 18px;
                font-weight: 400;
                line-height: 26px;
                margin-top: 10px;
              }
            }
            .img {
              width: 750px;
              display: flex;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1400px) {
      .list {
        li {
          .cont {
            .text {
              padding-left: 30px;

              .subTitle {
                font-size: 24px;
              }
              .title {
                font-size: 32px;
                line-height: 45px;
                margin-top: 16px;
              }
              .info {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                margin-top: 10px;
              }
            }
            .img {
              width: 650px;
              display: flex;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .list {
        li {
          .cont {
            .text {
              padding-left: 30px;

              .subTitle {
                font-size: 20px;
              }
              .title {
                font-size: 28px;
                line-height: 32px;
                margin-top: 10px;
              }
              .info {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                margin-top: 8px;
              }
            }
            .img {
              width: 520px;
            }
            .step {
              padding: 2px 12px;
              border-radius: 20px;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
            }
          }
        }
      }
    }

    ${mq.mobile} {
      padding: 96px 20px;
      > .title {
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 45px;
        span {
          color: #6079ff;
          font-weight: inherit;
        }
      }
      .list {
        padding-top: 0px;
        gap: 24px;
        li {
          position: relative;
          margin-bottom: 0;
          border-radius: 12px;
          &:nth-of-type(1) {
            bottom: 0;
            margin-bottom: 0px;
            margin-top: 0px;
          }
          &:nth-of-type(2) {
            bottom: 0;
            margin-bottom: 0px;
            margin-top: 0px;
          }
          &:nth-of-type(3) {
            bottom: 0;
          }
          .cont {
            flex-direction: column;
            gap: 24px;
            .text {
              padding: 82px 32px 24px 32px;
              text-align: left;
              width: 100%;
              .subTitle {
                display: flex;
                font-size: 14px;
                gap: 6px;
                line-height: 24px;
              }
              .icon {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                }
              }
              .title {
                font-size: 24px;
                line-height: 36px;
                margin-top: 8px;
              }
              .info {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-top: 8px;
              }
            }
            .img {
              width: 100%;
              display: flex;
            }
            .step {
              position: absolute;
              bottom: auto;
              top: 32px;
              left: 24px;
              padding: 6px 10px;
              background: rgba(96, 121, 255, 0.1);
              color: #6079ff;
              border-radius: 20px;
              font-size: 12px;
              font-weight: 700;
              line-height: 18px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={InfoStyle}>
      <h2 className="title">
        단계별로 진행 하는
        <br />
        수익 창출의 <span>쉬운 시작</span>
      </h2>
      <ul className="list">
        <li>
          <div className="cont">
            <div className="text">
              <h3 className="subTitle">
                <div className="icon">
                  <img src={infoIcon} alt="infoIcon" title="infoIcon" />
                </div>
                상품 담기
              </h3>
              <h3 className="title">
                판매하고 싶은 상품을 고르고,
                <br />
                바로 판매해요
              </h3>
              <p className="info">
                몰파이에 입점된 5만여 개의 상품 중에서
                <br />
                판매하고 싶은 상품을 선택하고 담아보세요.
                <br /> 식품/패션/생활/가전 등 모든 카테고리를
                <br /> 커버하는 상품을 최저가로 소개할 수 있어요.
              </p>
            </div>
            <div className="img">
              <img src={info1} alt="상품 담기 | 이미지" title="상품 담기" />
            </div>
            <div className="step">STEP. 1</div>
          </div>
        </li>
        <li>
          <div className="cont">
            <div className="text">
              <h3 className="subTitle">
                <div className="icon">
                  <img src={infoIcon2} alt="infoIcon" title="infoIcon" />
                </div>
                쇼핑몰 디자인
              </h3>
              <h3 className="title">
                몇 번의 클릭으로,
                <br />
                쇼핑몰을 완성해요
              </h3>
              <p className="info">
                매번 쇼핑몰 디자인 단계에서 포기하셨나요?
                <br />
                학습 필요 없이, 누구나 클릭 몇 번이면
                <br />
                매력적인 내 쇼핑몰을 완성할 수 있어요.
                <br />
                중요한 건, 어렵지 않아야 한다는 것!
              </p>
            </div>
            <div className="img">
              <img
                src={info2}
                alt="쇼핑몰 디자인 | 이미지"
                title="쇼핑몰 디자인"
              />
            </div>
            <div className="step">STEP. 2</div>
          </div>
        </li>
        <li>
          <div className="cont">
            <div className="text">
              <h3 className="subTitle">
                <div className="icon">
                  <img src={infoIcon3} alt="infoIcon" title="infoIcon" />
                </div>
                수익 실현
              </h3>
              <h3 className="title">
                최대 40%까지 받는
                <br /> 수익 실현
              </h3>
              <p className="info">
                아직, 어떤 상품을 판매하더라도
                <br />
                같은 비율의 수익만 정산받았나요?
                <br />
                최대 40%까지 받을 수 있는 상품별 수익금
                <br />
                정책으로 더 합리적으로 수익을 만들어보세요!
              </p>
            </div>
            <div className="img">
              <img src={info3} alt="수익 실현 | 이미지" title="수익 실현" />
            </div>
            <div className="step">STEP. 3</div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Info;
