/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';
import { ReactComponent as Logo } from 'static/common/logo_md.svg';

const Section1 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const Section1Style = css`
    .wrap {
      width: 948px;
      margin: 40px auto 0 auto;
      .main {
        width: 100%;
        padding: 150px 0 45px 0;
        border-radius: 32px;
        text-align: center;
        .subTitle {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          color: #fff;
          br {
            display: none;
          }
        }
        .title {
          font-size: 46px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          color: #fff;
          span {
            color: #6079ff;
          }
          br {
            display: none;
          }
        }
        .logo {
          margin-top: 198px;
        }
      }
    }
    ${mq.mobile} {
      .wrap {
        width: 100%;
        margin: 48px auto 0 auto;
        padding: 0px 20px !important;
        .main {
          padding: 93px 0 20px 0;
          .subTitle {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            color: #fff;
            br {
              display: block;
            }
          }
          .title {
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            color: #fff;
            margin-top: 5px;
            br {
              display: block;
            }
          }
          .logo {
            margin-top: 140px;
          }
        }
      }
    }
  `;

  const { scrollYProgress } = useScroll();

  const color = useTransform(
    scrollYProgress,
    [0, 0.04, 0.07, 0.1, 0.14],
    ['#4d5999', '#49804e', '#997141', '#974646', '#4d5999'],
  );

  const [background, setBackground] = useState('');

  useEffect(() => {
    return color.onChange(color => {
      setBackground(
        `radial-gradient(111.35% 111.35% at 50% 100%, ${color} 0%, #000 100%)`,
      );
    });
  }, [color]);

  return (
    <div css={Section1Style} style={{ padding: isMobile ? '0px 20px' : '' }}>
      <motion.div
        className="wrap"
        style={{
          borderRadius: '32px',
          background: background
            ? background
            : `radial-gradient(
          111.35% 111.35% at 50% 100%,
          #4d5999 0%,
          #000 100%
        )`,
        }}
      >
        <div className="main">
          <div className="subTitle">
            커머스로 우리 기업과 비즈니스를
            <br /> 성장시키는 새로운 방법
          </div>
          <div className="title">
            <span>몰파이 비즈니스</span>
            <br /> 서비스 소개서
          </div>
          <div className="logo">
            <Logo fill="white" />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Section1;
