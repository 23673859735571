/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/home/method2_list1.png';
import list2Img from 'static/home/method2_list2.png';
import list3Img from 'static/home/method2_list3.png';
import { ReactComponent as Package } from 'static/home/package.svg';
import { motion } from 'framer-motion';
import { useIsMobile } from 'core/hooks/useResponsive';

const Method2 = () => {
  const { mq } = useTheme();

  const Method2Style = css`
    display: flex;
    justify-content: center;
    padding: 128px 0;
    .wrap {
      .title {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        .sub {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 24px;
          font-weight: 700;
          align-items: center;
          color: #a7acc6;
        }
        .text {
          color: #000;
          font-weight: bold;
          font-size: 56px;
          margin-top: 16px;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
      }
      .main {
        width: 1276px;
        position: relative;
        height: 760px;
        margin-top: 72px;
        border-radius: 40px;
        background: linear-gradient(180deg, #9cb8ff 0%, #e6cdff 100%);
        .text {
          padding: 64px 56px;
          font-size: 30px;
          line-height: 42px;
          font-weight: 700;
          color: #fff;
          span {
            background-image: linear-gradient(90deg, #0032b3 0%, #00164d 100%);
            background-clip: text;
            color: transparent;
          }
        }
        img {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
      .list {
        display: flex;
        gap: 36px;
        margin-top: 36px;
        li {
          width: 620px;
          height: 620px;
          position: relative;
          border-radius: 40px;
          &:nth-of-type(1) {
            background: #414040;
            .text {
              color: #fff;
              span {
                color: #c0c0c0;
              }
            }
            img {
              width: 80%;
              left: 50%;
              transform: translateX(-50%);
              bottom: 95px;
            }
          }
          &:nth-of-type(2) {
            background: #f1f4f6;
            .text {
              color: #8897a2;
              span {
                color: #000;
              }
            }
          }
          .text {
            padding: 64px 56px;
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
          }
          img {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    ${mq.mobile} {
      padding: 96px 20px;
      justify-content: start;
      width: 100%;
      .wrap {
        .title {
          .sub {
            font-size: 16px;
          }
          .text {
            font-size: 30px;
            margin-top: 10px;
            line-height: 42px;
          }
          svg {
            width: 28px;
            height: 28px;
          }
        }
        .main {
          margin-top: 24px;
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          .text {
            padding: 41px 32px 40px 32px;
            font-size: 20px;
            line-height: 30px;
          }
          img {
            position: relative;
          }
        }
        .list {
          margin-top: 20px;
          height: auto;
          display: flex;
          flex-direction: column;
          gap: 20px;
          li {
            border-radius: 20px;
            width: 100%;
            height: auto;
            .text {
              padding: 41px 32px 40px 32px;
              font-size: 20px;
              line-height: 30px;
            }
            img {
              position: static;
            }
            &:nth-of-type(1) {
              padding-bottom: 61px;
              img {
                display: block;
                margin: 0 auto;
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Method2Style}>
      <div className="wrap">
        <div className="title">
          <div className="sub">
            <Package />
            효율적인 운영
          </div>
          <h2 className="text">
            운영 노력은 줄이고
            <br />
            <span>상품 선정</span>과 <span>홍보</span>에만 집중
          </h2>
        </div>
        <motion.div
          className="main"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
          variants={defaultVariants}
        >
          <h3 className="text">
            <span>내 상품이 없어도</span>
            <br />
            5만여개 전 카테고리 최저가 상품을
            <br />내 쇼핑몰에 연동판매
          </h3>
          <img
            src={list1Img}
            alt="효율적인 운영1 | 이미지"
            title="효율적인 운영1"
          />
        </motion.div>
        <motion.ul
          className="list"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <motion.li variants={defaultVariants}>
            <h3 className="text">
              별도 계약 없는
              <br />
              <span>
                카드사, 간편 결제
                <br /> 시스템 지원
              </span>
            </h3>
            <img
              src={list2Img}
              alt="효율적인 운영2 | 이미지"
              title="효율적인 운영2"
            />
          </motion.li>
          <motion.li variants={defaultVariants}>
            <h3 className="text">
              배송·교환·환불
              <br />
              직접 할 필요없이
              <br />
              <span>모든 건 업체에서 알아서 진행</span>
            </h3>
            <img
              src={list3Img}
              alt="효율적인 운영3 | 이미지"
              title="효율적인 운영3"
            />
          </motion.li>
        </motion.ul>
      </div>
    </div>
  );
};

export default Method2;
