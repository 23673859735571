/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import { ReactComponent as ArrowLeft } from 'static/common/arrow_left.svg';

interface CardProps {
  onClick?: () => void;
  className?: string;
  direction?: 'vertical' | 'horizontal';
  img: string;
  name: string;
  sellPrice?: number;
  prdPrice?: number;
  shops?: shopData[];
  prdId?: number;
}

interface shopData {
  shopId: string;
  shopName: string;
  logoName: string;
  logoImageUrl: string;
}

const Product = ({
  direction,
  className,
  onClick,
  img,
  name,
  sellPrice,
  prdPrice,
  shops,
  prdId,
}: CardProps) => {
  const { mq } = useTheme();

  const discrountedPercent = Math.round(100 - (sellPrice / prdPrice) * 100);

  const ProductStyle = css`
    ${direction === 'vertical' ? `width: 100%;` : null}
    ${direction === 'horizontal' && `display: flex;`}
    ${direction === 'horizontal' && `flex-direction: row-reverse;`}
    ${direction === 'horizontal' && `align-items: flex-start;`}
    ${direction === 'horizontal' && `justify-content: space-between;`}
    ${direction === 'vertical' &&
    shops &&
    `box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.08); margin-bottom: 10px;`}
    
    ${shops ? `null` : `cursor: pointer;`}
    a {
      color: #000;
    }
    &:hover {
      .thumbnail {
        img {
          max-width: 100%;
          max-height: 100%;
          transform: scale(1.02);
        }
      }
      .productInfo {
        h6 {
          ${shops ? `null` : `text-decoration: underline;`}
        }
      }
    }
    .thumbnail {
      aspect-ratio: 1/1;
      ${direction === 'horizontal' && `width: 110px;`}
      ${direction === 'horizontal' && `height: 110px;`}
        ${direction === 'horizontal' && `margin-left: 50px;`}
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
    .inner {
      ${direction === 'vertical' && shops && `padding: 15px;`}
    }
    .productInfo {
      width: 100%;
      h6 {
        color: #222;
        font-size: 18px;
        ${direction === 'horizontal' && `font-size: 20px;`}
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        ${direction === 'horizontal' && `line-height: 28px;`}
        ${direction === 'vertical' ? `margin-top: 25px;` : 'margin-top: 0;'}
        ${direction === 'vertical' && shops && `margin: 0 0 30px;`}
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        height: 54px;
      }
      .price {
        display: flex;
        margin-top: 10px;
        strong {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          margin-right: 15px;
        }
        .percent {
          display: flex;
          align-items: center;
          p {
            color: #b2b2b2;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-decoration-line: line-through;
            margin-right: 15px;
          }
          span {
            color: #6079ff;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
          }
        }
      }
    }
    .mall {
      padding-top: 25px;
      border-top: 1px solid #d8d8d8;
      h6 {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #6079ff;
      }
      .mallList {
        margin: 15px 0 0;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          ${shops ? `cursor: pointer;` : `null`}
          .shop {
            display: flex;
            align-items: center;
            p {
              font-size: 16px;
              font-style: normal;
              line-height: 16px;
              font-weight: 600;
            }
            .logo {
              width: 55px;
              height: 55px;
              font-size: 0;
              border-radius: 5px;
              margin-right: 15px;
            }
          }
        }
        li + li {
          margin-top: 15px;
        }
      }
    }
    ${mq.mobile} {
      width: 100%;
      ${direction === 'horizontal' && `justify-content: flex-end;`}
      ${direction === 'vertical' && shops && `width: 200px;`}
      .thumbnail {
        ${direction === 'horizontal' && `margin-left: 20px;`}
        ${direction === 'horizontal' && `width: 80px;`}
        ${direction === 'horizontal' && `height: 80px;`}
        ${direction === 'horizontal' && `border-radius: 5px;;`}
        ${direction === 'vertical' && shops && `width: 200px;`}
        ${direction === 'vertical' && shops && `height: 180px;`}
      }
      .inner {
        ${direction === 'horizontal' && `width: 100%;`}
      }
      .productInfo {
        ${direction === 'vertical' && `min-height: 105px;`}

        .priceWrap {
          ${direction === 'horizontal' && `display: flex;`}
          ${direction === 'horizontal' && `margin-top: 8px;`}
        }
        h6 {
          font-size: 14px;
          line-height: 22px;
          ${direction === 'vertical' && shops && `margin-bottom: 20px;`}
          height: 45px;
          margin-top: 10px;
        }
        .priceWrap {
          margin-top: 4px;
          .price {
            display: flex;
            align-items: center;
            margin-top: 0px;
            span {
              color: #6079ff;
              font-size: 16px;
              line-height: 20px;
              font-weight: 800;
              margin-left: 6px;
            }
            p {
              color: #b2b2b2;
              font-size: 14px;
              line-height: 20px;
              text-decoration-line: line-through;
            }
          }
          strong {
            font-size: 18px;
            line-height: 30px;
            font-weight: bold;
          }
        }
      }
      .mall {
        ${direction === 'vertical' && shops && `padding-top: 15px;`}
        h6 {
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
        }
        .mallList {
          li {
            padding: 8px 0;
            .shop {
              p {
                font-size: 13px;
                font-style: normal;
                line-height: 21px;
              }
              .logo {
                width: 32px;
                height: 32px;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  `;

  return (
    <div css={ProductStyle} onClick={onClick} className={className}>
      <div className="thumbnail">
        <img src={img} alt="상품 사진" />
      </div>
      <div className="inner">
        <div className="productInfo">
          <h6>{name}</h6>
          <PC>
            <div className="price">
              {sellPrice && <strong>{sellPrice?.toLocaleString()}원</strong>}
              {prdPrice && (
                <div className="percent">
                  <p>{prdPrice?.toLocaleString()}원</p>
                  <span>{discrountedPercent}%</span>
                </div>
              )}
            </div>
          </PC>
          <Mobile>
            <div className="priceWrap">
              <div className="price">
                {prdPrice && <p>{prdPrice?.toLocaleString()}원</p>}
                {prdPrice && <span>{discrountedPercent}%</span>}
              </div>
              {sellPrice && <strong>{sellPrice?.toLocaleString()}원</strong>}
            </div>
          </Mobile>
        </div>
        {shops && (
          <div className="mall">
            <h6>판매중인 몰</h6>
            <ul className="mallList">
              {shops?.map(shop => (
                <li
                  key={shop.shopId}
                  onClick={() =>
                    window.open(
                      `https://${shop.shopId}.mallpie.kr/product/${prdId}`,
                      '_self',
                    )
                  }
                >
                  <div className="shop">
                    <img
                      className="logo"
                      src={shop.logoImageUrl}
                      alt={shop.shopName}
                    />
                    <p>{shop.shopName}</p>
                  </div>
                  <ArrowLeft />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
