import { useEffect } from 'react';

import { axiosAuth, encodeValue } from 'core/config/axios';
import { useUser } from 'core/store/context/UserContext';

import useRefreshToken from './useRefreshToken';
import { useCookies } from 'react-cookie';
import { deCryaes } from 'utils/crypto';

const useAxiosAuth = () => {
  const { user } = useUser();
  const [cookies] = useCookies(['mallpie-user']);
  const refreshToken = useRefreshToken();

  useEffect(() => {
    const requestIntercept = axiosAuth.interceptors.request.use(
      config => {
        const newConfig = { ...config };

        if (
          newConfig.headers.Authorization === `Basic ${encodeValue}` &&
          cookies['mallpie-user']
        ) {
          newConfig.headers.Authorization = `Bearer ${
            JSON.parse(deCryaes(cookies['mallpie-user'])).accessToken
          }`;
        }
        return newConfig;
      },
      error => Promise.reject(error),
    );

    const responseIntercept = axiosAuth.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error.config;

        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403) &&
          !prevRequest.sent
        ) {
          prevRequest.sent = true;
          await refreshToken();
          prevRequest.headers.Authorization = `Bearer ${
            JSON.parse(deCryaes(cookies['mallpie-user'])).accessToken
          }`;
          return axiosAuth(prevRequest);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosAuth.interceptors.request.eject(requestIntercept);
      axiosAuth.interceptors.response.eject(responseIntercept);
    };
  }, [user]);

  return axiosAuth;
};

export default useAxiosAuth;
