/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';

import list1 from 'static/business/guide/section2.png';

const Section2 = () => {
  const { mq } = useTheme();

  const section2Style = css`
    text-align: center;
    padding: 110px 0 100px 0;
    .container {
      max-width: 1535px;
      padding: 0 160px;
      margin: 0 auto;

      .title {
        font-size: 48px;
        font-weight: 600;
        line-height: 68px;
      }
      .text {
        font-size: 18px;
        margin-top: 12px;
        font-weight: 200;
        line-height: 28px;
      }
      .list {
        margin-top: 66px;
        display: flex;
        justify-content: center;
        li {
          width: 1112px;
          text-align: left;
          img {
            width: 100%;
          }
          h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            margin-top: 20px;
          }
          p {
            font-size: 18px;
            font-weight: 200;
            line-height: 28px;
          }
        }
      }
    }

    ${mq.mobile} {
      padding: 0 0 70px 0;
      .container {
        padding: 0 20px;
        .title {
          font-size: 24px;
          line-height: 36px;
        }
        .text {
          font-size: 14px;
          line-height: 21px;
        }
        .list {
          margin-top: 14px;
          display: flex;
          flex-direction: column;
          margin-top: 42px;
          li {
            width: 100%;
            h3 {
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              margin-top: 21px;
            }
            p {
              font-size: 14px;
              font-weight: 200;
              line-height: 21px;
            }
          }
        }
      }
    }
  `;

  const contents = [
    {
      img: list1,
    },
  ];

  return (
    <div css={section2Style}>
      <div className="container">
        <div className="title">
          상품관리, 주문처리, 정산까지
          <Mobile>
            <br />
          </Mobile>
          모든 것을 한 곳에서
        </div>
        <div className="text">
          상품등록, 주문상태별 처리 및 판매 대금 정산까지
          <Mobile>
            <br />
          </Mobile>
          입점사에게 필요한 모든 기능을 제공합니다.
          <br />
          복잡한 설정이나 관리 기능을 최소화하고, 상품관리,
          <Mobile>
            <br />
          </Mobile>
          주문처리에
          <PC>
            <br />
          </PC>
          꼭 필요한 기능으로 편리하게 구성되어있습니다.
          <br />
        </div>
        <ul className="list">
          {contents.map((v, k) => (
            <li key={k}>
              <img src={v.img} alt={`list-${k}`} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Section2;
