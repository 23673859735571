/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as ArrowLeft } from 'static/store/arrow_left.svg';

const Title = () => {
  const { mq } = useTheme();

  const TitleStyle = css`
    background: #2b2b2b;
    padding-top: 240px;
    padding-bottom: 128px;
    text-align: center;
    .title {
      font-size: 64px;
      font-weight: 800;
      line-height: 86px;
      color: #fff;
      span {
        color: #6079ff;
      }
    }
    .sub {
      margin-top: 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      color: #fff;
      opacity: 0.8;
    }
    .anker {
      display: inline-flex;
      margin-top: 80px;
      li {
        button {
          border-radius: 27px;
          padding: 12px 32px;
          align-items: center;
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          background: #6079ff;
          border: 0;
          svg {
            margin-left: 6px;
          }
          a {
            color: #fff;
            text-decoration: none;
            outline: none;
            font-weight: 700;
          }
        }
      }
      a + a {
        margin-left: 20px;
        button {
          background: #000;
        }
      }
    }
    ${mq.mobile} {
      padding: 96px 38px 64px 38px;
      .title {
        font-size: 30px;
        line-height: 45px;
      }
      .sub {
        font-size: 16px;
        line-height: 24px;
        margin-top: 17px;
      }
      .anker {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        li {
          button {
            width: 100%;
            justify-content: center;
            font-size: 16px;
            line-height: 30px;
          }
        }
        a + a {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  `;
  return (
    <div css={TitleStyle}>
      <h2 className="title">
        <span>한 번의 입점으로</span>
        <br />
        빠르게 확장되는 판매채널
      </h2>
      <p className="sub">쉽고 효과적인 매출 증가를 경험해 보세요!</p>
      <ul className="anker">
        <a href="#storeForm">
          <li>
            <button>
              바로 입점하기
              <ArrowLeft />
            </button>
          </li>
        </a>
        <a href="#storeQna">
          <li>
            <button>
              자주 묻는 질문
              <ArrowLeft />
            </button>
          </li>
        </a>
      </ul>
    </div>
  );
};

export default Title;
