import axios from 'axios';
import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import Bottom from 'components/company/Bottom';
import Navigation from 'components/company/Navigation';
import Section1 from 'components/company/Section1';
import Section3 from 'components/company/Section3';
import Section4 from 'components/company/Section4';
import Section5 from 'components/company/Section5';
import Section6 from 'components/company/Section6';
import Title from 'components/company/Title';
import { useEffect, useState } from 'react';

const Company = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showUI, setShowUI] = useState(false);

  const handleScroll = () => {
    const section = document.getElementById('companyTitle');
    if (window.scrollY > section.offsetHeight) {
      setShowUI(true);
    } else {
      setShowUI(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onSubmit = data => {
    if (data.term !== 'Y') {
      window.alert('개인정보 이용에 동의해주세요');
      return;
    }
    setIsSubmiting(true);

    const createQuery = `mutation { create_item (board_id: 4300636478, group_id: "emailed_____", item_name: "[몰파이] 도입문의 - 공통에 새 응답이 접수되었습니다.") { id }}`;

    axios
      .post(
        'https://api.monday.com/v2',
        JSON.stringify({
          query: createQuery,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
          },
        },
      )
      .then(res => {
        const updateQuery = `mutation { create_update (item_id: ${res.data.data.create_item.id}, body: "기업/브랜드명: ${data.compNm}\n 담당자 연락처: ${data.mobile}\n 이메일 주소: ${data.id}@${data.id2}\n 업종: ${data.sectors}\n 마케팅 동의여부:${data.marketing}\n 문의 내용:${data.content}") { id }}`;
        axios.post(
          'https://api.monday.com/v2',
          JSON.stringify({
            query: updateQuery,
          }),
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization:
                'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjMwMDI5NTIwMSwiYWFpIjoxMSwidWlkIjozNTQ3OTEyNCwiaWFkIjoiMjAyMy0xMi0wNFQwMTowNTo1NS4wMDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTI2MzQxNjksInJnbiI6InVzZTEifQ.IgFcORzpDWwyO3pbRP4NdoF4YuosvnSaxID3qrwBwP8',
            },
          },
        );
      })
      .then(() => {
        alert('도입 문의가 완료 되었습니다.');
        window.location.reload();
      })
      .catch(() => {
        setIsSubmiting(false);
      });
  };

  return (
    <Layout theme="black">
      <Meta
        title="서비스 소개 - 기업 | 몰파이"
        ogTitle="서비스 소개 - 기업 | 몰파이"
      />
      <Title />
      <Section1 />
      <Section3 />
      <Section4 />
      <Section5 />
      {/* <Section6 onSubmit={onSubmit} isSubmiting={isSubmiting} /> */}
      <Bottom />
      <Navigation show={showUI} />
    </Layout>
  );
};

export default Company;
