/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Link, useNavigate } from 'react-router-dom';
import logoBlck from 'static/common/logo-black.png';
import logoWhite from 'static/common/logo-white.png';
import arrowUpWhite from 'static/common/arrow_up_white.svg';
import arrowDownWhite from 'static/common/arrow_down_white.svg';
import arrowUpBlack from 'static/common/arrow_up_black.svg';
import arrowDownBlack from 'static/common/arrow_down_black.svg';
import { Mobile, PC } from '../Responsive';
import { useState } from 'react';

interface FooterProps {
  theme?: 'white' | 'black';
}

const Footer = ({ theme = 'white' }: FooterProps) => {
  const { mq } = useTheme();
  const navigate = useNavigate();

  const [expandedSections, setExpandedSections] = useState<string[]>([]);

  const toggleSection = (section: string) => {
    setExpandedSections(prev =>
      prev.includes(section)
        ? prev.filter(s => s !== section)
        : [...prev, section],
    );
  };

  const colors = {
    primary: theme === 'white' ? '#000000' : '#FFFFFF',
    secondary: theme === 'white' ? '#727272' : '#D7D7D7',
    third: theme === 'white' ? '#A1A1A1' : '#B7B7B7',
    divider: theme === 'white' ? '#e8e8e8' : '#727272',
  };

  const sections = [
    {
      title: '서비스 소개',
      items: [
        {
          title: '상품 없이 쇼핑몰 만들기',
          items: [
            { text: '개인', href: '/intro/personal' },
            { text: '크리에이터', href: '/intro/creator' },
            { text: '모임·단체', href: '/intro/class' },
            { text: '단계별 수익실현', href: '/revenue' },
          ],
        },
        {
          title: '기업 서비스',
          items: [
            {
              text: '자사 브랜드 쇼핑몰',
              href: 'https://mallpie.biz/intro/company',
            },
            { text: '임직원 복지몰', href: 'https://mallpie.biz/welfare' },
          ],
        },
      ],
    },
    { title: '기능', items: [], href: '/method' },
    {
      title: '블로그',
      items: [],
      href: 'https://blog.mallpie.kr',
      type: 'link',
    },
    { title: 'mallpieNOW', items: [], href: '/now' },
    {
      title: '입점 센터',
      href: '/store',
      items: [{ title: null, items: [{ text: '입점 신청', href: '/store' }] }],
    },
    {
      title: '고객 지원',
      href: '/cs',
      items: [
        {
          title: null,
          items: [
            {
              text: '마이몰 가이드',
              href: 'https://help-partner.mallpie.kr',
              type: 'blank',
            },
            {
              text: '입점사 가이드',
              href: 'https://help-seller.mallpie.kr',
              type: 'blank',
            },
          ],
        },
      ],
    },
    {
      title: '관리자 페이지',
      href: '',
      items: [
        {
          title: null,
          items: [
            {
              text: '마이몰 관리자',
              href: 'https://my.mallpie.kr/',
              type: 'blank',
            },
            {
              text: '입점사 관리자',
              href: 'https://partner.mallpie.kr',
              type: 'blank',
            },
            {
              text: '복지몰 관리자',
              href: 'https://partner.mallpie.biz/',
              type: 'blank',
            },
          ],
        },
      ],
    },
  ];

  const sectionsM = [
    {
      title: '서비스 소개',
      items: [
        {
          title: '상품 없이 쇼핑몰 만들기',
          items: [
            { text: '개인', href: '/intro/personal' },
            { text: '크리에이터', href: '/intro/creator' },
            { text: '모임·단체', href: '/intro/class' },
            { text: '단계별 수익실현', href: '/revenue' },
          ],
        },
        {
          title: '기업 서비스',
          items: [
            {
              text: '자사 브랜드 쇼핑몰',
              href: 'https://mallpie.biz/intro/company',
            },
            { text: '임직원 복지몰', href: 'https://mallpie.biz/welfare' },
          ],
        },
      ],
    },
    { title: '기능', items: [], href: '/method' },
    {
      title: '블로그',
      items: [],
      href: 'https://blog.mallpie.kr',
      type: 'link',
    },
    { title: 'mallpieNOW', items: [], href: '/now' },
    {
      title: '입점 센터',
      href: '/store',
      items: [],
    },
    {
      title: '고객 지원',
      href: '/cs',
      items: [],
    },
    {
      title: '관리자 페이지',
      href: '',
      items: [
        {
          title: null,
          items: [
            {
              text: '마이몰 관리자',
              href: 'https://my.mallpie.kr/',
              type: 'blank',
            },
            {
              text: '입점사 관리자',
              href: 'https://partner.mallpie.kr',
              type: 'blank',
            },
            {
              text: '복지몰 관리자',
              href: 'https://partner.mallpie.biz/',
              type: 'blank',
            },
          ],
        },
      ],
    },
  ];

  const footerStyleP = css`
    padding: 64px 120px;
    background-color: ${theme === 'white' ? '#FFFFFF' : '#1F1F1F'};

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    .container {
      margin: 0 auto;
      .top {
        display: flex;
        justify-content: space-between;

        .logo {
          img {
            width: 156px;
          }
        }
        .list {
          display: flex;
          gap: 50px;
          padding: 0;
          .section {
            display: flex;
            flex-direction: column;

            h4 {
              color: ${colors.primary};
              font-size: 20px;
              margin: 0 0 10px 0;
              font-weight: 600;
              cursor: pointer;
              a {
                font-weight: 600;
              }
            }

            ul {
              list-style: none;
              padding: 0;
              margin: 0;

              .sub {
                font-size: 12px;
                color: ${colors.third};
                font-weight: 500;
              }
              .title {
                margin-top: 20px;
                cursor: default;
              }
              .null {
                margin-top: 20px;
              }
              li {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: ${colors.secondary};

                cursor: pointer;
                margin-bottom: 10px;
              }

              li:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      hr {
        border: 0;
        background-color: ${colors.divider};
        height: 1px;
        margin: 32px 0px;
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .bottom-left {
          font-size: 12px;
          color: ${colors.third};
          font-weight: 400;
          line-height: 18px;
        }

        .bottom-right {
          ul {
            display: flex;
            font-size: 14px;
            font-weight: 700;
            color: ${colors.secondary};
          }
          li {
            cursor: pointer;
          }
          li + li {
            margin-left: 46px;
          }
        }
      }
    }

    ${mq.mobile} {
      padding: 64px 20px;
      .container {
        .bottom {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 40px;
          .bottom-left {
            font-size: 12px;
            line-height: 18px;
          }
          .bottom-right {
            ul {
              font-size: 12px;
              flex-wrap: wrap;
              gap: 40px;
            }
            li + li {
              margin-left: 0px;
            }
          }
        }
      }
    }
  `;

  const footerStyleM = css`
    margin-bottom: 30px;

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    .logo {
      margin-bottom: 20px;
      img {
        width: 115px;
      }
    }
    .section {
      border-bottom: 1px solid ${colors.divider};
      padding: 10px 0px;

      h4 {
        font-size: 18px;
        color: ${colors.primary};

        margin: 0;
        cursor: pointer;
        font-weight: 600;
        padding: 10px;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease;
        width: 100%;

        &.expanded {
          max-height: 1000px;
          overflow: visible;
        }

        .subitem {
          display: flex;
          gap: 40px;

          li {
            font-size: 16px;
            color: ${colors.secondary};
            font-weight: 500;
            padding: 10px;

            &:last-of-type {
              border-bottom: none;
            }
          }

          .subtitle {
            .sub {
              font-size: 12px;
              color: ${colors.third};
              font-weight: 500;
              padding-bottom: 15px;
              padding: 10px;
            }
          }
        }
      }
    }
  `;

  return (
    <>
      <div css={footerStyleP}>
        <div className="container">
          <PC>
            <div className="top">
              <div className="logo">
                <Link to="/">
                  <img
                    src={theme === 'white' ? logoBlck : logoWhite}
                    alt="로고 | 이미지"
                    title="로고"
                    style={{ cursor: 'pointer' }}
                  />
                </Link>
              </div>
              <nav>
                <div className="list">
                  {sections.map(section => (
                    <div key={section.title} className="section">
                      {section.items.length > 0 ? (
                        <>
                          <h4>
                            {section.href ? (
                              <Link to={`${section.href}`} className="link">
                                {section.title}
                              </Link>
                            ) : (
                              section.title
                            )}
                          </h4>
                          <ul>
                            {section.items.map(item => (
                              <div key={item.title} className="subtitle">
                                <li
                                  className={`sub ${
                                    item.title === null ? 'null' : 'title'
                                  }`}
                                >
                                  {item.title}
                                </li>
                                {item.items.map(item => (
                                  <li
                                    key={item.text}
                                    style={{
                                      paddingTop:
                                        item.text === '단계별 수익실현'
                                          ? '10px'
                                          : '',
                                    }}
                                  >
                                    <Link to={`${item.href}`} className="link">
                                      {item.text}
                                    </Link>
                                  </li>
                                ))}
                              </div>
                            ))}
                          </ul>
                        </>
                      ) : (
                        <h4>
                          <Link to={`${section.href}`} className="link">
                            {section.title}
                          </Link>
                        </h4>
                      )}
                    </div>
                  ))}
                </div>
              </nav>
            </div>
            <hr />
          </PC>
          <Mobile>
            <div css={footerStyleM}>
              <div className="logo">
                <Link to="/">
                  <img
                    src={theme === 'white' ? logoBlck : logoWhite}
                    alt="로고 | 이미지"
                    title="로고"
                    style={{ cursor: 'pointer' }}
                  />
                </Link>
              </div>
              <nav>
                {sectionsM.map(section => (
                  <div key={section.title} className="section">
                    {section.items.length > 0 ? (
                      <>
                        <h4 onClick={() => toggleSection(section.title)}>
                          {section.title}
                          <span>
                            {expandedSections.includes(section.title) ? (
                              theme === 'white' ? (
                                <img src={arrowUpBlack} alt="arrowUp" />
                              ) : (
                                <img src={arrowUpWhite} alt="arrowBlack" />
                              )
                            ) : theme === 'white' ? (
                              <img src={arrowDownBlack} alt="arrowUp" />
                            ) : (
                              <img src={arrowDownWhite} alt="arrowBlack" />
                            )}
                          </span>
                        </h4>
                        <ul
                          className={
                            expandedSections.includes(section.title)
                              ? 'expanded'
                              : ''
                          }
                        >
                          <div className="subitem">
                            {section.items.map(item => (
                              <div key={item.title} className="subtitle">
                                <li
                                  className="sub"
                                  style={{
                                    display: !item.title && 'none',
                                  }}
                                >
                                  {item.title}
                                </li>
                                {item.items.map(subItem => (
                                  <Link
                                    key={subItem.text}
                                    to={`${subItem.href}`}
                                    className="link"
                                    style={{
                                      display: 'block',
                                      paddingTop:
                                        subItem.text === '단계별 수익실현'
                                          ? '10px'
                                          : '',
                                    }}
                                  >
                                    <li>{subItem.text}</li>
                                  </Link>
                                ))}
                              </div>
                            ))}
                          </div>
                        </ul>
                      </>
                    ) : (
                      <Link
                        to={`${section.href}`}
                        className="link"
                        style={{ display: 'block' }}
                      >
                        <h4>{section.title}</h4>
                      </Link>
                    )}
                  </div>
                ))}
              </nav>
            </div>
          </Mobile>
          <div className="bottom">
            <div className="bottom-left">
              (주)지니웍스 | 대표 : 이앙 | 강남구 논현로 653, 2층 |
              사업자등록번호 : 551-88-00073
              <br />
              고객센터 : 1522-2856 (평일, 10:00 ~18:00) | e-mail :
              support@genieworks.net
              <br /> ©Genieworks.Inc All Right Reserved.
            </div>
            <div className="bottom-right">
              <ul>
                <li>
                  <a href="https://genieworks.net/" className="link">
                    회사소개
                  </a>
                </li>
                <li>
                  <Link to="/terms/1" className="link">
                    몰파이 이용약관
                  </Link>
                </li>
                <li>
                  <Link to="/terms/3" className="link">
                    판매자 이용약관
                  </Link>
                </li>
                <li>
                  <Link to="/terms/2" className="link">
                    개인정보처리방침
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
