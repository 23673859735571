import React from 'react';
import Layout from 'components/common/NewLayout';
import Title from 'components/creator/Title';
import Section1 from 'components/creator/Section1';
import Section2 from 'components/creator/Section2';
import Faq from 'components/creator/Faq';
import Bottom from 'components/creator/Bottom';
import Meta from 'components/common/Meta';

const Creator = () => {
  return (
    <Layout theme="black">
      <Meta
        title="서비스 소개 - 크리에이터 | 몰파이"
        ogTitle="서비스 소개 - 크리에이터 | 몰파이"
      />
      <Title />
      <Section1 />
      <Section2 />
      <Faq />
      <Bottom />
    </Layout>
  );
};

export default Creator;
