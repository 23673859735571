/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { usePopularProduct } from 'core/queries/popular';
import Title from 'components/common/Title';
import Product from 'components/common/Product';
import { useIsPc } from 'core/hooks/useResponsive';
import { useRecmdProduct } from 'core/queries/now';

const PopularProduct = () => {
  const { mq } = useTheme();

  const { data: productList } = useRecmdProduct();

  const isPc = useIsPc();

  const PopularProductStyle = css`
    width: 100%;
    padding: 120px 0;
    overflow: hidden;
    .container {
      width: 1300px;
      margin: 0 auto;
      position: relative;
      .swiper {
        margin-top: 50px;
        width: 100%;
        height: 100%;
        position: relative;
        .swiper-wrapper {
          padding-bottom: 0px;
          flex-direction: unset;
        }
        .slideWrap {
          display: flex;
          .slideContainer {
            width: fit-content;
            div + div {
              margin-top: 20px;
            }
          }
          .slideContainer + .slideContainer {
            margin-left: 20px;
          }
        }
        .swiper-slide {
          width: 310px;
        }

        .swiper-pagination-bullets {
          bottom: 0;
          .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            font-size: 15px;
            opacity: 1;
            background: #ebebeb;
          }
          .swiper-pagination-bullet-active {
            background: #6079ff;
          }
        }
      }
      .popularPrev {
        position: absolute;
        top: 387px;
        left: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/prev_arrow_grey.png');
        background-size: 100% 100%;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
      .popularNext {
        position: absolute;
        top: 387px;
        right: -10%;
        cursor: pointer;
        color: white;
        width: 60px;
        height: 60px;
        background: url('	https://d1nwcwsdvx7ar2.cloudfront.net/ncms/mallpie/files/1698025289032/next_arrow_grey.png');
        background-size: 100% 100%;
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
    }
    ${mq.mobile} {
      padding: 40px 0 30px;
      .wrap {
        padding-left: 20px;
      }
      .container {
        width: 100%;
        padding: 0;
        .swiper {
          margin-top: 20px;
          .swiper-wrapper {
            padding-bottom: 10px;
          }
          .slideWrap {
            .slideContainer {
              div + div {
                margin-top: 10px;
              }
            }
            .slideContainer + .slideContainer {
              margin-left: 10px;
            }
          }
          .swiper-slide {
            width: 162px;
          }
          .swiper-wrapper {
            align-items: baseline;
          }
          .swiper-pagination-bullets {
            display: none;
            .swiper-pagination-bullet {
              width: 8px;
              height: 8px;
              font-size: 8px;
            }
          }
        }
        .popularPrev,
        .popularNext {
          display: none;
        }
      }
    }
  `;

  const chunkData = isPc ? 2 : 1;

  return (
    <div css={PopularProductStyle}>
      <div className="wrap">
        <Title
          title="지금, 가장 잘 나가는 상품 🛒"
          subTitle="최근 일주일 마이몰 장바구니에 가장 많이 담겼어요"
        />
      </div>
      {productList && productList?.length > 0 && (
        <div className="container">
          <Swiper
            slidesPerView={isPc ? 4 : 2}
            slidesPerGroup={isPc ? 4 : 1}
            spaceBetween={isPc ? 20 : 15}
            slidesOffsetBefore={isPc ? 0 : 20}
            slidesOffsetAfter={isPc ? 0 : 20}
            modules={[Pagination, Navigation]}
            navigation={{
              nextEl: '.popularNext',
              prevEl: '.popularPrev',
            }}
          >
            {new Array(
              Math.ceil(Number(productList?.length) / (chunkData * 4)) *
                (chunkData * 2),
            )
              .fill(null)
              ?.map((v, k) => {
                const row1Product =
                  productList[
                    Math.floor(k / (chunkData * 2)) * (chunkData * 2) + k
                  ];
                const row2Product =
                  productList[
                    Math.floor(k / (chunkData * 2)) * (chunkData * 2) +
                      k +
                      chunkData * 2
                  ];

                return (
                  <SwiperSlide key={k}>
                    <div className="slideWrap">
                      <div className="slideContainer">
                        {row1Product && (
                          <Product
                            direction="vertical"
                            img={row1Product?.imgLink}
                            name={row1Product?.name}
                            sellPrice={row1Product?.sellPrice}
                            prdPrice={row1Product?.prdPrice}
                            onClick={() =>
                              window.open(
                                `https://${row1Product?.shopId}.${
                                  process.env.REACT_APP_PUBLIC_ENV !==
                                  'production'
                                    ? 'sellerd.kr'
                                    : 'mallpie.kr'
                                }/product/${row1Product.prdSn}`,
                              )
                            }
                          />
                        )}
                        {row2Product && (
                          <Product
                            direction="vertical"
                            img={row2Product?.imgLink}
                            name={row2Product?.name}
                            sellPrice={row2Product?.sellPrice}
                            prdPrice={row2Product?.prdPrice}
                            onClick={() =>
                              window.open(
                                `https://${row2Product?.shopId}.${
                                  process.env.REACT_APP_PUBLIC_ENV !==
                                  'production'
                                    ? 'sellerd.kr'
                                    : 'mallpie.kr'
                                }/product/${row2Product.prdSn}`,
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div className="popularPrev"> </div>
          <div className="popularNext"> </div>
        </div>
      )}
    </div>
  );
};

export default PopularProduct;
