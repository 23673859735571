/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import icon1 from 'static/business/shop/section1_1.png';
import icon2 from 'static/business/shop/section1_2.png';
import icon3 from 'static/business/shop/section1_3.png';

const Section3 = () => {
  const { mq } = useTheme();

  const section3Style = css`
    text-align: center;
    padding: 269px 0 100px 0;
    .container {
      max-width: 1535px;
      padding: 0 40px;
      margin: 0 auto;

      .list {
        display: flex;
        justify-content: center;
        li {
          padding: 48px;
          border-radius: 12px;
          img {
            width: 96px;
          }
          h3 {
            margin-top: 32px;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            white-space: pre-wrap;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 19px;
            white-space: pre-wrap;
          }
          &:nth-of-type(1) {
            background: rgba(86, 91, 228, 0.1);
          }
          &:nth-of-type(2) {
            background: rgba(86, 194, 228, 0.1);
          }
          &:nth-of-type(3) {
            background: rgba(228, 154, 86, 0.1);
          }
        }
        li + li {
          margin-left: 20px;
        }
      }
    }

    ${mq.mobile} {
      padding: 156px 0 35px 0;
      .container {
        padding: 0 32px;
        .list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          li {
            width: 100%;
            padding: 24px;
            margin-left: 0;
            img {
              width: 128px;
            }
            h3 {
              margin-top: 32px;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              margin-top: 20px;
            }
            &:nth-of-type(1) {
              background: rgba(86, 91, 228, 0.1);
            }
            &:nth-of-type(2) {
              background: rgba(86, 194, 228, 0.1);
            }
            &:nth-of-type(3) {
              background: rgba(228, 154, 86, 0.1);
              margin-top: 15px;
            }
          }
          li + li {
            margin-left: 0px;
            margin-top: 20px;
          }
        }
      }
    }
  `;

  const contents = [
    {
      title: '브랜드의 가치에 어울리는\n쇼핑몰을 제공해드립니다.',
      text: '파트너들이 구축한 브랜드의 가치와 디자인을 해치지 않고,\n일관된 고객경험을 유지할 수 있도록 높은 수준의 디자인과\n사용자 경험이 적용된 쇼핑몰을 제공해드립니다.',
      mText:
        '파트너들이 구축한 브랜드의 가치와 디자인을\n해치지 않고, 일관된 고객경험을 유지할 수 있도\n록 높은 수준의 디자인과 사용자 경험이 적용된\n 쇼핑몰을 제공해드립니다.',
      img: icon1,
    },
    {
      title: '커머스 기능으로 고객의\n리텐션을 높일 수 있습니다.',
      text: '파트너의 고객들에게 경쟁력 있는 상품 구매의 기회와 혜택\n을 제공하여, 고객의 리텐션을 더욱 높이고 이를 통해 지속\n적인 고객 확보 및 유지, 동시에 커머스 수익 창출의 효과를\n누릴 수 있습니다.',
      mText:
        '파트너의 고객들에게 경쟁력 있는 상품 구매의\n기회와 혜택을 제공하여, 고객의 리텐션을 더욱\n높이고 이를 통해 지속적인 고객 확보 및 유지,\n동시에 커머스 수익 창출의 효과를 누릴 수 있습\n니다.',
      img: icon2,
    },
    {
      title: '커머스 운영에 필요한\n비용 최소화',
      text: '쇼핑몰 하나를 운영하는데는 많은 비용과 인력이 필요합니\n다. 몰파이 플랫폼은 상품 공급, 상품 관리, 쇼핑몰 구축, 상\n품 기획, 판매 트래킹, 정산까지 커머스에 필요한 모든 영역\n에 대한 기술 및 자원 인프라를 이미 갖추고 있습니다.',
      mText:
        '쇼핑몰 하나를 운영하는데는 많은 비용과 인력이\n필요합니다. 몰파이 플랫폼은 상품 공급, 상품\n관리, 쇼핑몰 구축, 상품 기획, 판매 트래킹, 정산\n까지 커머스에 필요한 모든 영역에 대한 기술 및\n자원 인프라를 이미 갖추고 있습니다.',
      img: icon3,
    },
  ];

  return (
    <div css={section3Style}>
      <div className="container">
        <ul className="list">
          {contents.map((v, k) => (
            <li key={k}>
              <img src={v.img} alt={`list-${k}`} />
              <h3>{v.title}</h3>
              <p>
                <PC>{v.text}</PC>
                <Mobile>{v.mText}</Mobile>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Section3;
