/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
  flexRender,
  Row,
} from '@tanstack/react-table';
import Pagination from 'react-js-pagination';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import { ReactComponent as DoubleRight } from 'static/user/mypage/doubleRight.svg';
import { ReactComponent as DoubleLeft } from 'static/user/mypage/doubleLeft.svg';
import { ReactComponent as Right } from 'static/user/mypage/right.svg';
import { ReactComponent as Left } from 'static/user/mypage/left.svg';

export type TableProps<T> = {
  name: string;
  data: T[];
  columns: any[];
  noDataMessage?: string;
  useMinHeight?: boolean;
  pageData?: any;
  onChange?: (page: number) => void;
};
export type TableRenderSubRowComponent<T> = (props: {
  row: Row<T>;
}) => React.ReactElement;

function Table<T>(props: TableProps<T>) {
  const { mq } = useTheme();

  const {
    useMinHeight = false,
    data,
    columns,
    noDataMessage,
    pageData,
    onChange,
  } = props;
  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { getHeaderGroups, getRowModel } = table;

  const isNoData = getRowModel().rows.length === 0;

  const noDataStyle = css`
    padding: 20px 0px;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  `;

  const tableStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    overflow: scroll;
    .header {
      font-weight: 500;
      background: #f9fafb;
    }
    .row {
      width: 100vh;
      display: flex;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
    .body-row {
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }
    .shop {
      color: #6079ff;
      margin-top: 4px;
    }
    .save {
      color: #6079ff;
    }
    .sub {
      color: #fb3b3b;
    }

    ${mq.mobile} {
      padding: 0px 20px;
    }
  `;

  return (
    <>
      <div css={tableStyle}>
        {getHeaderGroups().map((headerGroup, index) => (
          <div className="header row" key={index}>
            {headerGroup.headers.map((header, index) =>
              header.isPlaceholder ? null : (
                <TableCell key={index} width={header.column.getSize()}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </TableCell>
              ),
            )}
          </div>
        ))}
        <TableBody useMinHeight={useMinHeight}>
          {isNoData ? (
            <NoDataComponent useMinHeight={useMinHeight}>
              <div css={noDataStyle}>
                <p>{noDataMessage}</p>
              </div>
            </NoDataComponent>
          ) : (
            getRowModel().rows.map((row, index) => (
              <Fragment key={index}>
                <div className="body-row row">
                  {row.getVisibleCells().map((cell, index) => (
                    <TableCell key={index} width={cell.column.getSize()}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </div>
              </Fragment>
            ))
          )}
        </TableBody>
      </div>
      {!isNoData && (
        <PaginationBox>
          <Pagination
            activePage={pageData?.pageNum}
            itemsCountPerPage={pageData?.pageSize}
            totalItemsCount={pageData?.total}
            pageRangeDisplayed={5}
            onChange={page => {
              if (onChange !== undefined) onChange(page);
            }}
            firstPageText={
              <DoubleLeft
                fill={pageData?.pageNum === 1 ? '#E1E1E1' : '#000000'}
              />
            }
            lastPageText={
              <DoubleRight
                fill={
                  pageData?.pageNum === pageData?.navigateLastPage
                    ? '#E1E1E1'
                    : '#000000'
                }
              />
            }
            nextPageText={
              <Right fill={pageData?.hasNextPage ? '#000000' : '#E1E1E1'} />
            }
            prevPageText={
              <Left fill={pageData?.hasPreviousPage ? '#000000' : '#E1E1E1'} />
            }
          />
        </PaginationBox>
      )}
    </>
  );
}

export default Table;

const TableCell = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const TableBody = styled.div<{ useMinHeight: boolean }>`
  min-height: ${({ useMinHeight }) => (useMinHeight ? '560px' : 'auto')};
  display: flex;
  flex-direction: column;
`;

const NoDataComponent = styled.div<{ useMinHeight: boolean }>`
  width: 100%;
  height: ${({ useMinHeight }) => (useMinHeight ? '560px' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-left: 0 !important;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #000000;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: #6079ff;
  }
  ul.pagination li.active {
    border: 1px solid #6079ff;
    border-radius: 2px;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }
`;
