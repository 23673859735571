/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Element } from 'react-scroll';
import list1Img from 'static/method/method4_list1.png';
import list2Img from 'static/method/method4_list2.png';
import list3Img from 'static/method/method4_list3.png';
import { ReactComponent as PaperPlane } from 'static/method/paper_plane.svg';
import { motion } from 'framer-motion';
import { useIsMobile } from 'core/hooks/useResponsive';

const Method4 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const Method1Style = css`
    display: flex;
    justify-content: center;
    background: #2b2b2b;
    .wrap {
      width: 1232px;
      padding: 200px 0px;
      > .title {
        svg {
          width: 30px;
        }
        .sub {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 24px;
          font-weight: 700;
          color: #a7acc6;
        }
        .text {
          color: #fff;
          font-weight: bold;
          font-size: 56px;
          margin-top: 16px;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-top: 80px;
        li {
          display: flex;
          position: relative;
          align-items: center;
          &:nth-of-type(2) {
            flex-direction: row-reverse;
          }
          .text {
            padding: 0px 112px;
            .title {
              font-size: 40px;
              font-weight: bold;
              line-height: 60px;
              color: #fff;
            }
            .desc {
              margin-top: 20px;
              font-size: 20px;
              color: #fff;
              opacity: 0.7;
              line-height: 30px;
              span {
                margin-top: 12px;
                display: block;
              }
            }
          }
          .img {
            width: 600px;
            flex-shrink: 0;
            border-radius: 32px;
            overflow: hidden;
            display: flex;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .wrap {
        width: 100%;
        padding: 96px 20px;
        .title {
          .sub {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
          }
          .text {
            font-size: 28px;
            margin-top: 8px;
            line-height: 40px;
          }
        }
        .list {
          gap: 40px;
          margin-top: 40px;
          li {
            flex-direction: column;
            &:nth-of-type(2) {
              flex-direction: column;
            }
            .text {
              padding: 16px 0px 0px;
              width: 100%;
              .title {
                font-size: 24px;
                line-height: 30px;
              }
              .desc {
                margin-top: 6px;
                font-size: 16px;
                line-height: 24px;
                span {
                  margin-top: 4px;
                }
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const scaleVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Element name="section4" className="element">
      <div css={Method1Style}>
        <div className="wrap">
          <div className="title">
            <div className="sub">
              <PaperPlane fill="#9EA2B8" title="신규 런칭" />
              신규 런칭
            </div>
            <h3 className="text">
              더 나은 쇼핑 경험을{isMobile && <br />} 제공하기 위해
              <br />
              <span>새로운 기능</span>이 추가 됐어요!
            </h3>
          </div>
          <ul className="list">
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list1Img}
                  alt="상품 판매가 커스터마이징 | 이미지"
                  title="상품 판매가 커스터마이징"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">
                  상품 판매가 <br />
                  커스터마이징
                </h3>
                <p className="desc">
                  기본 판매가에서 원하는 판매가로 <br />
                  상품 가격을 직접 변경 할 수 있어요.
                  <span>
                    상황에 맞게 판매가를 조정하여 <br />
                    가격 경쟁력과 수익성을 개선해보세요 !
                  </span>
                </p>
              </motion.div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img src={list2Img} alt="선물하기 | 이미지" title="선물하기" />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">선물하기</h3>
                <p className="desc">
                  마이몰에서 상품 구매 시 선물하기를 통해 <br />
                  메시지 카드와 함께 마음을 전할 수 있어요.
                  <br />
                  <span>
                    회원분들께 단순 구매를 넘어
                    <br />
                    색다른 구매 경험을 선사해보세요 !
                  </span>
                </p>
              </motion.div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list3Img}
                  alt="부운영자 저장 기능 | 이미지"
                  title="부운영자 저장 기능"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">
                  부운영자 <br />
                  저장 기능
                </h3>
                <p className="desc">
                  마이몰의 부운영자를 추가하여 <br />
                  함께 운영할 수 있어요.
                  <span>
                    친구나 가족들과 함께 <br />
                    더욱 효율적으로 운영해 보세요 !
                  </span>
                </p>
              </motion.div>
            </motion.li>
          </ul>
        </div>
      </div>
    </Element>
  );
};

export default Method4;
