/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import phone1 from 'static/myshop/style/section4_1.png';
import phone2 from 'static/myshop/style/section4_2.png';

const Section4 = () => {
  const { mq } = useTheme();

  const section4Style = css`
    padding: 110px 0 116px 0;
    text-align: center;
    .container {
      max-width: 1535px;
      padding: 0 40px;
      margin: 0 auto;
      .title {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
        span {
          font-weight: inherit;
          font-size: inherit;
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        margin-top: 130px;
        li {
          flex: 1;
          display: flex;
          justify-content: center;
          p {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
            width: 400px;
            span {
              font-weight: bold;
              font-size: inherit;
            }
          }
          img {
            width: 380px;
          }
          &:nth-of-type(1) {
            p {
              margin-left: 64px;
            }
          }
          &:nth-of-type(2) {
            align-items: end;
            margin-top: -380px;
            p {
              margin-right: 64px;
              text-align: right;
            }
          }
        }
      }
    }

    ${mq.mobile} {
      padding-top: 0;
      .container {
        padding: 0 26px;
        .title {
          font-size: 20px;
          line-height: 30px;
        }
        .list {
          margin-top: 68px;
          li {
            flex: 1;
            display: flex;
            p {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              width: 400px;
              span {
                font-weight: bold;
                font-size: inherit;
              }
            }
            img {
              width: 150px;
            }
            &:nth-of-type(1) {
              p {
                margin-left: 15px;
              }
            }
            &:nth-of-type(2) {
              align-items: end;
              margin-top: -140px;
              p {
                margin-right: 15px;
                text-align: right;
              }
            }
          }
        }
      }
    }
  `;

  return (
    <div css={section4Style}>
      <div className="container">
        <div className="title">
          복잡한 메뉴 없이 심플하게,
          <Mobile>
            <br />
          </Mobile>
          혹은 다양한 상품으로 풍성하게..
          <br />
          <span>
            몰 운영에 최적의 화면으로
            <Mobile>
              <br />
            </Mobile>
            상품을 진열해보세요.
          </span>
        </div>

        <ul className="list">
          <li>
            <img src={phone1} alt="phone1" />
            <p>
              몰에서 운영할 상품이 많지 않다면,
              <br />
              <span>간결한 UI로 상품이 자동으로</span>
              <br />
              진열되어 보여집니다.
            </p>
          </li>
          <li>
            <p>
              또한 다양한 카테고리의 상품으로
              <Mobile>
                <br />
              </Mobile>
              운영할 수 있도록
              <PC>
                <br />
              </PC>
              <span>
                커스텀 메뉴와
                <Mobile>
                  <br />
                </Mobile>
                기획전 관리, 배너 관리 등
              </span>
              의
              <br />
              여러 기능을 지원합니다.
            </p>
            <img src={phone2} alt="phone2" />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Section4;
