/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export const Section2Style = css`
  background-color: #000000;
  display: grid;
  place-items: center;
  margin: 0 auto;
  img {
    width: 640px;
  }
`;

export const Section2StyleM = css`
  background-color: #000000;
  display: grid;
  place-items: center;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;
