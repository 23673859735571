import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCheckChangePw, useChangePw } from 'core/queries/user';
import useAlertStore from 'core/store/common/useAlertStore';
import Layout from 'components/common/Layout';
import ResetPasswordContent from 'components/user/pw/ResetPasswordContent';
import ExpirePasswordContent from 'components/user/pw/ExpirePasswordContent';

const UserPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(location.search);
  const code = searchParams.get('code');

  const { openAlert } = useAlertStore();

  const [isValidLink, setIsValidLink] = useState<boolean | undefined>(false);

  const { mutate: changePw } = useChangePw();
  const { mutate: checkChangePw } = useCheckChangePw();

  useEffect(() => {
    if (code) {
      checkCode();
    } else {
      navigate('/');
    }
  }, []);

  const checkCode = async () => {
    await checkChangePw(
      { secretKey: code },
      {
        onSuccess: response => {
          if (response.code === 'SUCCESS') {
            setIsValidLink(true);
          }
        },
        onError: (error: any) => {
          setIsValidLink(false);
        },
      },
    );
  };

  const onSubmit = form => {
    changePw(
      { password: form.password, secretKey: code as string },
      {
        onSuccess: () => {
          openAlert(
            {
              title: '알림',
              children: '비밀번호 변경이 완료 되었습니다.',
              btnNm: '확인',
            },
            () => {
              navigate('/user/login');
            },
          );
        },
        onError: (error: any) => {
          openAlert({
            title: '알림',
            children: error.response.data.message,
            btnNm: '확인',
          });
        },
      },
    );
  };

  return (
    <Layout theme="white">
      {isValidLink ? (
        <ResetPasswordContent onSubmit={onSubmit} />
      ) : (
        <ExpirePasswordContent />
      )}
    </Layout>
  );
};

export default UserPassword;
