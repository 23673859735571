/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import section3 from 'static/myshop/calc/section3.png';

const Section3 = () => {
  const { mq } = useTheme();

  const section3Style = css`
    .container {
      max-width: 1535px;
      padding: 110px 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .text {
        line-height: 28px;
        font-size: 18px;
        margin-top: 14px;
        font-weight: 200;
      }
      img {
        width: 800px;
      }
    }
    ${mq.mobile} {
      .container {
        flex-direction: column;
        padding: 0;

        .right {
          padding: 35px 32px 126px 32px;
          width: 100%;
          h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
          }
          .text {
            font-size: 14px;
            line-height: 22px;
            margin-top: 12px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  `;
  return (
    <div css={section3Style}>
      <div className="container">
        <div className="left">
          <img src={section3} alt="section3" />
        </div>
        <div className="right">
          <h2>
            매월 정산받을 수익금 내역을
            <br />
            확인할 수 있습니다
          </h2>
          <div className="text">
            내가 판매한 상품의 정산 상세 내역을 확인할 수 있습니다. <br />
            정산 금액은 일정 금액 도달 시, 등록된 계좌로 환급됩니다. <br />
            (사업자는 세금계산서 발급)
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
