/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

interface Terms {
  termsAtclNm: string;
  termsCn: string;
}

interface Section1Props {
  data: Terms;
}

const Section1 = ({ data }: Section1Props) => {
  const { mq } = useTheme();

  const section1Style = css`
    padding: 120px 0 170px 0;
    .container {
      max-width: 1200px;
      margin: 0 auto;
      .title {
        font-size: 48px;
        font-weight: 700;
        line-height: 68px;
        padding: 90px 0;
      }
      .content {
        line-height: 1.5;
      }
    }
    ${mq.mobile} {
      padding: 10px 0 60px 0;
      .container {
        width: 100%;
        padding: 32px;
        .title {
          font-size: 32px;
          font-weight: 700;
          line-height: 48px;
          padding: 40px 0;
        }
      }
    }
  `;
  return (
    <div css={section1Style}>
      <div className="container">
        <h2 className="title">
          {data.termsAtclNm === '파트너 이용약관'
            ? '판매자 이용약관'
            : data.termsAtclNm}
        </h2>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.termsCn }}
        />
      </div>
    </div>
  );
};

export default Section1;
