import { axiosAuth } from 'core/config/axios';
import { useInfiniteQuery } from 'react-query';

import { mymallKeys } from 'constants/queryKeys';

export const useMymallList = () => {
  return useInfiniteQuery(
    [...mymallKeys.all],
    async ({ pageParam = 1 }) => {
      const { data } = await axiosAuth.get(
        `/my-mallpie/shop-subscribe?page=${pageParam}&size=3`,
      );
      return data;
    },
    {
      getNextPageParam: lastPage => {
        if (!lastPage) return undefined;

        const { hasNextPage, nextPage } = lastPage.data;
        return hasNextPage ? nextPage : undefined;
      },
    },
  );
};
