/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Element } from 'react-scroll';
import list1Img from 'static/method/method2_list1.png';
import list1MImg from 'static/method/method2_m_list1.png';
import list2Img from 'static/method/method2_list2.png';
import list3Img from 'static/method/method2_list3.png';
import { ReactComponent as Cube } from 'static/method/cube.svg';
import { motion } from 'framer-motion';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

const Method2 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Method2Style = css`
    display: flex;
    justify-content: center;
    padding: 128px 0;
    .wrap {
      > .title {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        .sub {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 24px;
          font-weight: 700;
          align-items: center;
          color: #a7acc6;
        }
        .text {
          color: #000;
          font-weight: bold;
          font-size: 56px;
          margin-top: 16px;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
      }
      .main {
        width: 1232px;
        position: relative;
        height: 700px;
        margin-top: 48px;
        border-radius: 40px;
        background: #6079ff;
        .text {
          padding: 64px 56px;
          font-size: 30px;
          line-height: 42px;
          font-weight: 700;
          color: #fff;
          .title {
            font-size: 30px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px;
          }
          .desc {
            font-size: 20px;
            font-style: normal;
            line-height: 30px;
            margin-top: 8px;
            opacity: 0.7;
            span {
              margin-top: 8px;
              display: block;
            }
          }
        }
        img {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }

      .list {
        display: flex;
        gap: 36px;
        margin-top: 36px;
        li {
          width: 600px;
          height: 697px;
          position: relative;
          border-radius: 40px;
          &:nth-of-type(1) {
            background: #e4f1fa;
            img {
              width: 80%;
              left: 50%;
              transform: translateX(-50%);
              bottom: 95px;
            }
          }
          &:nth-of-type(2) {
            background: #f5f5f5;
          }
          .text {
            padding: 64px 56px;
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
            .title {
              font-size: 30px;
              font-style: normal;
              font-weight: 800;
              line-height: 40px;
            }
            .desc {
              font-size: 20px;
              font-style: normal;
              line-height: 30px;
              margin-top: 8px;
              color: #667085;
              span {
                margin-top: 8px;
                display: block;
              }
            }
          }
          img {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    ${mq.mobile} {
      padding: 0;
      .wrap {
        width: 100%;
        padding: 48px 20px 96px 20px;
        > .title {
          svg {
            width: 30px;
          }
          .sub {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
          }
          .text {
            font-size: 28px;
            margin-top: 8px;
            line-height: 40px;
          }
        }
        .main {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          border-radius: 20px 20px 40px 20px;
          .text {
            padding: 38px 0 0 30px;

            .title {
              font-size: 20px;
              font-weight: 800;
              line-height: 30px;
            }
            .desc {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
          }
          img {
            position: relative;
          }
        }

        .list {
          display: flex;
          gap: 20px;
          margin-top: 20px;
          flex-direction: column;
          li {
            width: 100%;
            position: relative;
            border-radius: 20px;
            height: auto;
            display: flex;
            flex-direction: column;
            img {
              position: static;
              margin-top: 16px;
            }
            &:nth-of-type(1) {
              background: #e4f1fa;

              img {
                display: block;
                width: 80%;
                transform: translate(0);
                margin: 52px auto 44px auto;
              }
            }
            &:nth-of-type(2) {
              background: #f5f5f5;
            }

            .text {
              padding: 38px 0 0 30px;

              .title {
                font-size: 20px;
                font-weight: 800;
                line-height: 30px;
              }
              .desc {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Element name="section2" className="element">
      <div css={Method2Style}>
        <div className="wrap">
          <div className="title">
            <div className="sub">
              <Cube fill="#9EA2B8" title="효율적인 운영" />
              효율적인 운영
            </div>
            <h2 className="text">
              <span>상품 소싱 · 배송 처리· 결제</span>는<br />
              몰파이가 지원해드려요
            </h2>
          </div>
          <motion.div
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">몰파이 상품 연동</h3>
              <p className="desc">
                몰파이에 등록되어 있는 5만여 개의 상품 {isMobile && <br />}
                중에서
                {isPc && <br />} 판매하고 싶은 상품을 선택하면{' '}
                {isMobile && <br />}
                즉시 판매 가능해요.
                <span>상품은 실시간으로 신규로 등록되고 있어요 !</span>
              </p>
            </div>
            <img
              src={isPc ? list1Img : list1MImg}
              alt="몰파이 상품 연동 | 이미지"
              title="몰파이 상품 연동"
            />
          </motion.div>
          <motion.ul
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
          >
            <motion.li variants={defaultVariants}>
              <div className="text">
                <h3 className="title">간편 결제 지원</h3>
                <p className="desc">
                  네이버페이 · 카카오페이 간편 결제시스템을
                  <br /> 지원해 드려요.
                  <span>
                    PG 연동과 더불어 별도 비용없이
                    <br /> 간편한 결제 서비스를 제공하세요 !
                  </span>
                </p>
              </div>
              <img
                src={list2Img}
                alt="간편 결제 지원 | 이미지"
                title="간편 결제 지원"
              />
            </motion.li>
            <motion.li variants={defaultVariants}>
              <div className="text">
                <h3 className="title">배송 처리 대행</h3>
                <p className="desc">
                  마이몰에서 주문이 발생하면 상품을 등록한 업체에서 <br />
                  확인 후 배송을 준비해요.
                  <span>
                    주문 진행 현황은 ‘주문관리’ 메뉴에서
                    <br />
                    한눈에 볼 수 있어요 !
                  </span>
                </p>
              </div>
              <img
                src={list3Img}
                alt="배송 처리 대행 | 이미지"
                title="배송 처리 대행"
              />
            </motion.li>
          </motion.ul>
        </div>
      </div>
    </Element>
  );
};

export default Method2;
