/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/home/list1.png';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useIsMobile } from 'core/hooks/useResponsive';
import { useBlog } from 'core/queries/main';

const Tip = () => {
  const { data } = useBlog();
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const TipStyle = css`
    padding: 160px 0px;
    width: 100%;
    text-align: center;
    background: #f9fafb;
    .container {
      width: 1360px;
      margin: 0 auto;
      > .title {
        font-size: 56px;
        font-weight: 700;
        line-height: 76px;
        text-align: center;
        span {
          color: #6079ff;
        }
      }
      .slide {
        position: relative;
        .popularPrev,
        .popularNext {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
          width: 48px;
          height: 48px;
          background: #fff;
          border: 1px solid #e1e1e1;
          font-family: swiper-icons;
          font-size: var(--swiper-navigation-size);
          text-transform: none !important;
          letter-spacing: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.swiper-button-disabled {
            opacity: 0.2;
          }
        }

        .popularPrev {
          left: -70px;
          &::after {
            content: 'prev';
            color: #98a2b3;
            font-size: 17px;
          }
        }

        .popularNext {
          right: -70px;
          &::after {
            content: 'next';
            color: #98a2b3;
            font-size: 17px;
          }
        }
      }
      .list {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        text-align: left;
        margin-top: 48px;

        li {
          cursor: pointer;
          flex-grow: 1;
          width: calc(33% - 24px);
          .imgBox {
            position: relative;
            border-radius: 12px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .text {
            padding-top: 16px;
            .title {
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px;
            }
            .desc {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              margin-top: 8px;
              line-height: 24px;
              color: #878787;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .more {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;
        padding: 12px 20px;
        color: #6079ff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        gap: 8px;
        cursor: pointer;
      }
    }
    ${mq.mobile} {
      padding: 96px 0px;
      .container {
        width: 100%;
        .title {
          font-size: 30px;
          line-height: 42px;
        }
        .list {
          padding: 0;
          margin-top: 32px;
          li {
            text-align: left;
            cursor: pointer;
            .text {
              padding-top: 8px;
              .title {
                font-size: 18px;
                line-height: 27px;
              }
              .desc {
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
        .more {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin-top: 32px;
        }
      }
    }
  `;
  return (
    <div css={TipStyle}>
      <div className="container">
        <div className="title">
          시작에 힘이 될 <span>노하우</span>
        </div>
        <div className="slide">
          <Swiper
            tag="ul"
            modules={[Navigation]}
            className="list"
            slidesPerGroup={isMobile ? 1 : 3}
            slidesPerView={isMobile ? 1.2 : 3}
            spaceBetween={isMobile ? 14 : 24}
            slidesOffsetBefore={isMobile ? 20 : 0}
            slidesOffsetAfter={isMobile ? 20 : 0}
            navigation={
              !isMobile && {
                nextEl: '.popularNext',
                prevEl: '.popularPrev',
              }
            }
          >
            {data?.posts?.map(it => (
              <SwiperSlide tag="li" key={it.comment_id}>
                <div
                  className="imgBox"
                  onClick={() => {
                    window.location.href = it.url;
                  }}
                >
                  <img src={it.feature_image} alt="list1" />
                </div>
                <div className="text">
                  <div className="title">{it.title}</div>
                  <div className="desc">{it.excerpt}</div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {!isMobile && (
            <>
              <div className="popularPrev"></div>
              <div className="popularNext"></div>
            </>
          )}
        </div>
        <div
          className="more"
          onClick={() => {
            window.location.href = 'https://blog.mallpie.kr';
          }}
        >
          더 많은 팁 보기 <MainArrow />
        </div>
      </div>
    </div>
  );
};

export default Tip;
