/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export interface TitleProps {
  title: string;
  subTitle?: string;
  className?: string;
}

const Title = ({ title, subTitle, className }: TitleProps) => {
  const { mq } = useTheme();
  const TitleStyle = css`
    max-width: 1300px;
    margin: 0 auto;
    h3 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      color: #222;
    }
    h5 {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 15px;
      color: #999;
      margin-top: 10px;
    }
    ${mq.mobile} {
      h3 {
        font-size: 22px;
        line-height: 32px;
        font-weight: 700;
      }
      h5 {
        font-size: 14px;
        line-height: 22px;
        margin-top: 0;
      }
    }
  `;

  return (
    <div css={TitleStyle} className={className}>
      <h3>{title}</h3>
      {subTitle && <h5>{subTitle}</h5>}
    </div>
  );
};

export default Title;
