import CryptoJs from 'crypto-js';

const key = CryptoJs.enc.Utf8.parse(
  process.env.REACT_APP_PUBLIC_USER_DECRYPT_NUM,
);
const iv = CryptoJs.enc.Utf8.parse(
  process.env.REACT_APP_PUBLIC_USER_DECRYPT_KEY,
);

export const enCryaes = obj => {
  const encrypt = CryptoJs.AES.encrypt(JSON.stringify(obj), key, {
    iv,
  });
  return encrypt.toString();
};

export const deCryaes = data => {
  const result = CryptoJs.AES.decrypt(data, key, {
    iv,
  });
  return result.toString(CryptoJs.enc.Utf8);
};
