/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { css, useTheme } from '@emotion/react';
import { useUser } from 'core/store/context/UserContext';
import Input from 'components/common/Input';
import Button from 'components/common/Button';

export interface PasswordFormValues {
  userEml: string;
  oldPassword: string;
  newPassword: string;
  newPasswordCheck?: string;
}

interface EditPasswordProps {
  onSubmit?: SubmitHandler<PasswordFormValues>;
}

const ChangePassword = ({ onSubmit }: EditPasswordProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<PasswordFormValues>();

  const [error, setError] = useState();

  const { mq } = useTheme();

  const { user } = useUser();

  const infoStyle = css`
    padding-bottom: 42px;
    margin: 0 auto;
    max-width: 500px;
    * {
      word-break: keep-all;
    }
    button {
      padding: 10px 20px;
      height: fit-content;
    }
    label {
      width: 100%;
      display: block;
      position: relative;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    .input-box {
      margin-bottom: 32px;
      .phone {
        display: flex;
        align-items: end;
        gap: 8px;
        margin-bottom: 8px;
      }
      .radio-wrap {
        display: flex;
        gap: 30px;
      }
    }
    .withdrawal {
      margin-top: 32px;
      text-align: center;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }

    ${mq.mobile} {
      padding: 0px 20px 32px;
    }
  `;

  return (
    <div css={infoStyle}>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input type="hidden" value={user.userEml} {...register('userEml')} />
          <div className="input-box">
            <Input
              type="password"
              placeholder="현재 비밀번호를 입력해주세요"
              label="현재 비밀번호"
              show
              {...register('oldPassword', {
                required: {
                  value: true,
                  message: '현재 비밀번호를 입력해주세요',
                },
              })}
            />
          </div>
          <div className="input-box">
            <Input
              type="password"
              placeholder="새 비밀번호를 입력해주세요"
              label="새 비밀번호"
              show
              {...register('newPassword', {
                required: true,
                pattern: {
                  value: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/,
                  message: 'error',
                },
              })}
              validations={{
                value: watch('newPassword'),
                list: [
                  {
                    regex: /^.{8,}$/,
                    name: '8자이상',
                  },
                  {
                    regex: /[a-zA-Z]+/,
                    name: '영문',
                  },
                  {
                    regex: /[0-9]+/,
                    name: '숫자',
                  },
                  {
                    regex: /[!@#$%^&*?]+/,
                    name: '특수문자',
                  },
                ],
              }}
            />
          </div>
          <div className="input-box">
            <Input
              type="password"
              placeholder="비밀번호를 한번 더 입력해 주세요"
              label="새 비밀번호 확인"
              show
              {...register('newPasswordCheck', {
                required: true,
                validate: value =>
                  watch('newPassword') === value ||
                  '비밀번호가 일치하지 않습니다.',
              })}
              prefix={errors.newPasswordCheck?.message}
            />
          </div>
          <Button color="blue" fullWidth type="submit">
            비밀번호 변경
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
