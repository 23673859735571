/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import { Mobile } from 'components/common/Responsive';

const Section6 = () => {
  const { mq } = useTheme();

  const section6Style = css`
    padding: 96px 0px;
    background: #6079ff;
    text-align: center;
    color: #fff;
    .title {
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
    }
    .text {
      font-size: 18px;
      font-weight: 200;
      line-height: 28px;
      margin-top: 12px;
    }
    button {
      margin-top: 24px;
    }
    ${mq.mobile} {
      .title {
        font-size: 24px;
        line-height: 36px;
      }
      .text {
        font-size: 14px;
        line-height: 21px;
      }
    }
  `;
  return (
    <div css={section6Style}>
      <div className="title">맛있는 파이 한 조각처럼...</div>
      <div className="text">
        누구나 만들 수 있는 한 조각 한 조각의 몰이 모여 이루는
        <Mobile>
          <br />
        </Mobile>
        새로운 커머스 플랫폼
      </div>
      <Button
        color="white"
        onClick={() => window.open('https://my.mallpie.kr', '_blank')}
      >
        무료로 몰파이 시작하기
      </Button>
    </div>
  );
};

export default Section6;
