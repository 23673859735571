/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import { PC } from 'components/common/Responsive';
import { useNavigate } from 'react-router-dom';
import section3 from 'static/myshop/make/section3.png';
import icon1 from 'static/myshop/make/section3_1.png';
import icon2 from 'static/myshop/make/section3_2.png';
import icon3 from 'static/myshop/make/section3_3.png';
import icon4 from 'static/myshop/make/section3_4.png';
import icon5 from 'static/myshop/make/section3_5.png';
import icon6 from 'static/myshop/make/section3_6.png';

const Section3 = () => {
  const { mq } = useTheme();
  const navigate = useNavigate();

  const section3Style = css`
    padding: 70px 0 140px 0;
    background-color: #fff;
    .container {
      max-width: 1535px;
      padding: 0 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .text {
        margin-top: 12px;
        font-size: 18px;
        font-weight: 200;
        line-height: 28px;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 90px;
        li {
          width: 26%;
          text-align: center;
          img {
            width: 56px;
          }
          .content {
            white-space: pre-wrap;
            margin-top: 8px;
            color: #98a2b3;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
          &:nth-of-type(n + 4) {
            margin-top: 53px;
          }
        }
      }
      button {
        margin-top: 96px;
      }
      .right {
        img {
          width: 740px;
        }
      }
    }

    ${mq.mobile} {
      padding: 42px 0px 86px 0px;
      .container {
        padding: 0;
        flex-direction: column-reverse;
        h2 {
          font-size: 28px;
          line-height: 42px;
        }
        .text {
          margin-top: 12px;
          font-size: 14px;
          line-height: 21px;
        }
        .list {
          margin-top: 70px;
          li {
            .content {
              font-size: 10px;
              line-height: 15px;
            }
          }
        }
        button {
          margin-top: 55px;
        }
        .right {
          padding: 0 20px;
          img {
            width: 100%;
          }
        }
        .left {
          padding: 40px 32px;
        }
      }
    }
  `;

  const contents = [
    {
      text: '커버 이미지 제공 및\n커버 사진 업로드',
      img: icon1,
    },
    {
      text: '쇼핑몰 로고 설정',
      img: icon2,
    },
    {
      text: '파비콘 설정',
      img: icon3,
    },
    {
      text: '주요 컬러 설정 기능',
      img: icon4,
    },
    {
      text: '홈 구성 기능',
      img: icon5,
    },
    {
      text: 'SNS 링크 기능',
      img: icon6,
    },
  ];

  return (
    <div css={section3Style}>
      <div className="container">
        <div className="left">
          <h2>
            디자인 하지 않아도 <br />
            멋지고 아름답게
          </h2>
          <div className="text">
            쇼핑몰을 멋지게 꾸미기 위해 어려운 포토샵이나 디자인을 하지 않아도
            됩니다.
            <PC>
              <br />
            </PC>
            나 또는 브랜드를 표현할 수 있는 멋진 사진이나 로고만 준비하세요.
            <br />
            심플한 디자인과 편리하게 설계된 UI로 편리하고 멋진 쇼핑몰이
            만들어집니다.
          </div>
          <ul className="list">
            {contents.map((v, k) => (
              <li key={k}>
                <div className="img">
                  <img src={v.img} alt="슬라이드" />
                </div>
                <div className="content">{v.text}</div>
              </li>
            ))}
          </ul>
          <Button color="blue" onClick={() => navigate('/myshop/style')}>
            쇼핑몰 꾸미기 알아보기
          </Button>
        </div>
        <div className="right">
          <img src={section3} alt="슬라이드" />
        </div>
      </div>
    </div>
  );
};

export default Section3;
