/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {
  useState,
  useMemo,
  forwardRef,
  ChangeEventHandler,
  KeyboardEventHandler,
} from 'react';
import { useEffect } from 'react';
import cx from 'clsx';
import moment from 'moment';
import { ReactComponent as InputDelete } from '../../../static/user/login/inputDelete.svg';

interface InputValidations {
  regex: RegExp;
  name: string;
}

interface InputValidationsProps {
  value?: string;
  list: InputValidations[];
}

interface extraProps {
  show: boolean;
  text: string;
  disabled?: boolean;
  action: () => void;
}

interface InputProps {
  className?: string;
  id?: string;
  prefix?: string;
  placeholder?: string;
  value?: string | number;
  variant?: 'contained' | 'outlined' | 'underline';
  type?: 'text' | 'number' | 'file' | 'password' | 'hidden';
  align?: 'left' | 'right';
  timer?: Date;
  label?: React.ReactNode;
  disabled?: boolean;
  show?: boolean;
  extra?: extraProps;
  validations?: InputValidationsProps;
  reset?: () => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      prefix = '',
      id,
      type = 'text',
      label,
      disabled,
      extra,
      timer,
      align = 'left',
      reset,
      variant = 'outlined',
      show = false,
      validations,
      onChange,
      onKeyDown,
      ...inputProps
    },
    ref,
  ) => {
    const [cusType, setCusType] = useState<string>(type);
    const [time, setTime] = useState<number>(undefined);

    useEffect(() => {
      if (timer !== undefined) {
        setTime(moment.duration(moment(timer).diff(moment())).asSeconds());
      }
    }, [timer]);

    useEffect(() => {
      if (time && time > 1) {
        const interval = setInterval(() => setTime(prev => prev - 1), 1000);
        return () => clearInterval(interval);
      } else {
        setTime(undefined);
        return () => {};
      }
    }, [time]);

    const transTimerFormat = data => {
      if (data < 10) {
        return `0${String(data)}`;
      }
      return String(data);
    };

    const inputRootStyle = useMemo(
      () => css`
        display: ${type === 'hidden' ? 'none' : 'block'};
        width: 100%;
        position: relative;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: space-between;

        label {
          width: 100%;
          display: block;
          position: relative;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          > .inputbox {
            position: relative;
            margin-top: ${label ? '16ppx' : '0px'};
            input {
              width: 100%;
              height: 46px;
              line-height: inherit;
              border: ${variant !== 'underline'
                ? variant === 'outlined' && prefix
                  ? '1px solid ##FB3B3B'
                  : '1px solid #E1E1E1'
                : 0};
              border-bottom: ${variant === 'outlined' && prefix
                ? '1px solid ##FB3B3B'
                : '1px solid #E1E1E1'};
              background-color: ${variant === 'contained' ? '' : '#FFFFFF'};
              border-radius: ${variant !== 'underline' ? '8px' : 0};
              outline: none;
              text-align: ${align};
              font-size: 14px;
              font-weight: 400;
              padding: ${variant !== 'underline' ? '12px 16px' : '24px 0px'};
              &::placeholder {
                color: #bdbdbd;
              }
              &:focus {
                border-color: #6079ff;
              }
              &:disabled {
                background-color: #f6f6f6;
              }
            }
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type='number'] {
              -moz-appearance: textfield;
            }
            .timer {
              position: absolute;
              top: 50%;
              right: 0;
              padding: 0 32px;
              height: 100%;
              transform: translateY(-50%);
              display: flex;
              align-items: center;
              color: #6079ff;
              font-size: 12px;
              font-weight: 800;
            }
            .extra {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
              display: flex;
              padding: 0 16px;
              align-items: center;
              cursor: pointer;
              font-size: 14px;
              font-weight: 800;
              color: #98a2b3;
              &.disabled {
                color: #e1e1e1;
                cursor: default;
              }
            }
            .show {
              position: absolute;
              top: 50%;
              right: 0;
              padding: 0 16px;
              height: 100%;
              transform: translateY(-50%);
              display: block;
              font-size: 0;
              background: none;
              cursor: pointer;
              border: 0;
              margin: 0;
              ::before {
                content: '';
                display: block;
                background: url('https://event.admerce.co.kr/mall/sp_member.svg')
                  no-repeat;
                width: 28px;
                height: 28px;
                background-position: -144px -4px;
                background-size: 176px 166px;
              }
              &.on::before {
                background: url('https://event.admerce.co.kr/mall/sp_member.svg')
                  no-repeat;
                width: 28px;
                height: 28px;
                background-position: -144px -40px;
                background-size: 176px 166px;
              }
            }
            .clean {
              display: flex;
              overflow: hidden;
              position: absolute;
              align-items: center;
              top: 50%;
              right: 0;
              border: 0;
              margin: 0;
              padding: 0 16px;
              transform: translateY(-50%);
              background: inherit;
              cursor: pointer;
            }
            .address {
              display: flex;
              overflow: hidden;
              position: absolute;
              align-items: center;
              top: 50%;
              left: 0;
              border: 0;
              margin: 0;
              padding: 0 32px;
              transform: translateY(-50%);
              background: inherit;
              cursor: pointer;
            }
          }
        }
        .validation {
          display: flex;
          margin-top: 8px;
          li {
            padding-left: 20px;
            position: relative;
            font-size: 14px;
            font-weight: 400;
            &.valid {
              color: #1cc100;
              ::before {
                border-bottom: 1px solid #1cc100;
                border-right: 1px solid #1cc100;
              }
            }
            &.invalid {
              color: #fb3b3b;
              ::before {
                border-bottom: 1px solid #fb3b3b;
                border-right: 1px solid #fb3b3b;
              }
            }
            ::before {
              content: '';
              display: block;
              width: 5px;
              height: 9px;
              transform: rotate(45deg);
              position: absolute;
              left: 6px;
              top: 4px;
            }
          }
          li + li {
            margin-left: 24px;
          }
        }

        > .prefix {
          display: block;
          margin-top: 8px;
          line-height: inherit;
          pointer-events: none;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          color: #fb3b3b;
          padding-left: 20px;
          position: relative;
          ::after {
            content: '';
            display: block;
            width: 5px;
            height: 9px;
            border-bottom: 1px solid #fb3b3b;
            border-right: 1px solid #fb3b3b;
            transform: rotate(45deg);
            position: absolute;
            left: 6px;
            top: 4px;
          }
        }

        > .timer {
          display: block;
          margin-top: 8px;
          text-align: right;
          font-size: 14px;
          font-weight: 400;
          margin-left: auto;
          color: #fb3b3b;
        }
      `,
      [type, label, align],
    );

    return (
      <div
        css={inputRootStyle}
        className={cx(
          className,
          'input',
          `${type === 'hidden' ? 'hidden' : ''}`,
        )}
      >
        <label htmlFor={id}>
          {label && label}
          <div className="inputbox">
            <input
              id={id}
              ref={ref}
              type={cusType}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onChange={e => {
                if (onChange !== undefined) {
                  onChange(e);
                }
              }}
              {...inputProps}
            />
            {timer && !extra && (
              <div className="timer">
                {transTimerFormat(Math.floor(time / 60))}:
                {transTimerFormat(Math.floor(time % 60))}
              </div>
            )}
            {reset && (
              <button type="button" className="clean" onClick={reset}>
                <InputDelete />
              </button>
            )}
            {show && (
              <button
                type="button"
                className={`show ${cusType === 'password' ? '' : 'on'}`}
                onClick={() =>
                  setCusType(cusType === 'password' ? 'text' : 'password')
                }
              >
                비밀번호 감추기
              </button>
            )}
            {extra && extra.show && (
              <div
                className={`extra ${extra.disabled ? 'disabled' : ''}`}
                onClick={() => !extra.disabled && extra.action()}
              >
                {extra.text}
              </div>
            )}
          </div>
        </label>
        {timer && time !== undefined && extra && (
          <div className="timer">
            {transTimerFormat(Math.floor(time / 60))}:
            {transTimerFormat(Math.floor(time % 60))}
          </div>
        )}
        {validations && (
          <ul className="validation">
            {validations.list.map((v, k) => (
              <li
                key={k}
                className={
                  validations.value !== undefined &&
                  v.regex.test(validations.value)
                    ? `valid`
                    : 'invalid'
                }
              >
                {v.name}
              </li>
            ))}
          </ul>
        )}
        {prefix && <span className="prefix">{prefix}</span>}
      </div>
    );
  },
);

export default Input;
