import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCheckExist, useSendChangeMail } from 'core/queries/user';
import useAlertStore from 'core/store/common/useAlertStore';
import Layout from 'components/common/Layout';
import EmailPasswordContent from 'components/user/reset/EmailPasswordContent';

const Reset = () => {
  const navigate = useNavigate();
  const { openAlert } = useAlertStore();
  const [isSend, setIsSend] = useState<boolean>(false);

  const { mutateAsync: checkExist } = useCheckExist();
  const { mutate: sendChagePw } = useSendChangeMail();

  const onSubmit = form => {
    sendChagePw(form, {
      onSuccess: () => {
        setIsSend(true);
      },
      onError: (error: any) => {
        openAlert({
          title: '알림',
          children: error.response.data.message,
          btnNm: '확인',
        });
      },
    });
  };

  const checkEmail = async (value: any) => {
    try {
      await checkExist({ userEml: value });
      return '입력하신 이메일의 계정을 찾을 수 없어요';
    } catch {
      return true;
    }
  };

  return (
    <Layout theme="white">
      <EmailPasswordContent
        onSubmit={onSubmit}
        checkEmail={checkEmail}
        isSend={isSend}
        onLogin={() => navigate('/user/login')}
      />
    </Layout>
  );
};

export default Reset;
