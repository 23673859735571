/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import list1Img from 'static/company/seciton1_list1.png';
import list2Img from 'static/company/section1_list2.png';
import list3Img from 'static/company/section1_list3.png';
import back1Img from 'static/company/seciton1_back1_pc.png';
import back1MImg from 'static/company/seciton1_back1_mo.png';
import back2Img from 'static/company/seciton1_back2_pc.png';
import back2MImg from 'static/company/seciton1_back2_mo.png';
import { motion } from 'framer-motion';

const Section1 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section1Style = css`
    padding-bottom: 200px;
    > .title {
      font-size: 72px;
      text-align: center;
      font-weight: 700;
      line-height: 96px;
      span {
        color: #87b0ca;
      }
    }
    ul {
      width: 838px;
      margin: 0 auto;
      padding: 120px 0px;
      display: flex;
      flex-direction: column;
      gap: 96px;
      li {
        padding-left: 130px;
        position: relative;
        img {
          width: 38px;
          position: absolute;
          left: 0;
          top: 12px;
        }
        &:nth-of-type(2) {
          img {
            width: 61px;
          }
        }
        &:nth-of-type(3) {
          img {
            width: 66px;
          }
        }
        .title {
          display: flex;
          flex-direction: column;
          font-size: 44px;
          line-height: 66px;
          .top {
            font-weight: 700;
          }
          .bottom {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            gap: 20px;
            .big {
              font-size: 114px;
              font-weight: 800;
              line-height: 137px;
              color: #87b0ca;
            }
            .small {
              font-weight: 700;
            }
          }
        }
        .text {
          font-size: 24px;
          line-height: 36px;
          margin-top: 20px;
          opacity: 0.6;
        }
      }
    }
    .info {
      padding-top: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .infoImg1 {
        width: 528px;
        position: relative;
        > img {
          width: 100%;
        }
        > div {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .infoTitle1 {
          font-size: 60px;
          font-weight: 800;
          line-height: 70px; /* 116.667% */
          font-family: 'Montserrat', sans-serif;
        }
        .infoSub1 {
          font-size: 24px;
          font-weight: 700;
          line-height: 36px; /* 150% */
        }
      }
      .infoImg2 {
        width: 100%;
        max-width: 1146px;
        position: relative;
        > img {
          width: 100%;
        }
        > div {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .infoTitle2 {
          font-size: 100px;
          font-weight: 800;
          line-height: 100px; /* 100% */
          font-family: 'Montserrat', sans-serif;
        }
        .infoSub2 {
          font-size: 36px;
          font-weight: 700;
          line-height: 54px; /* 150% */
        }
      }
    }
    ${mq.mobile} {
      padding-bottom: 96px;
      > .title {
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
      }
      ul {
        width: 293px;
        padding: 64px 0 96px 0;
        gap: 56px;
        li {
          padding-left: 44px;
          position: relative;
          img {
            width: 14px;
            top: 8px;
          }
          &:nth-of-type(2) {
            img {
              width: 20px;
            }
          }
          &:nth-of-type(3) {
            img {
              width: 21px;
            }
            .title {
              .bottom {
                flex-direction: column;
                align-items: flex-start;
              }
            }
          }
          .title {
            font-size: 18px;
            line-height: 24px;
            .top {
              font-weight: 700;
            }
            .bottom {
              display: flex;
              gap: 7px;
              .big {
                font-size: 43px;
                font-weight: 800;
                line-height: 48px;
                color: #87b0ca;
              }
              .small {
                font-weight: 700;
              }
            }
          }
          .text {
            font-size: 14px;
            line-height: 20px;
            margin-top: 12px;
            opacity: 0.6;
          }
        }
      }
      .info {
        padding-top: 0px;
        width: 100%;
        overflow: hidden;
        .infoImg1 {
          width: 264px;
          > div {
          }
          .infoTitle1 {
            font-size: 39px;
            font-weight: 800;
            line-height: 35px; /* 89.744% */
          }
          .infoSub1 {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px; /* 150% */
          }
        }
        .infoImg2 {
          width: 140%;
          max-width: 490px;
          > div {
          }
          .infoTitle2 {
            font-size: 60px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 800;
            line-height: 72px; /* 120% */
          }
          .infoSub2 {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px; /* 150% */
          }
        }
      }
    }
  `;

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section1Style}>
      <motion.h2
        className="title"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ amount: 0.4 }}
        variants={yVariants}
      >
        우리 고객에게 <span>쇼핑 서비스</span>
        <br /> 제공을 고민한다면
      </motion.h2>

      <ul>
        <motion.li
          initial="offscreen"
          whileInView="onscreen"
          variants={yVariants}
        >
          <h3 className="title">
            <img src={list1Img} alt="list1Img" title="list1Img" />
            <div className="top">고객을</div>
            <div className="bottom">
              <div className="big">확실하게</div>
              <div className="small">
                붙잡는
                <br />
                방법
              </div>
            </div>
          </h3>
          <p className="text">
            돈 써서 데려온 고객을 그냥 보내지마세요! <br />
            몰파이 쇼핑 서비스로 재방문을 높이고{isMobile && <br />} 확실하게
            붙잡아두세요.
          </p>
        </motion.li>
        <motion.li
          initial="offscreen"
          whileInView="onscreen"
          variants={yVariants}
        >
          <h3 className="title">
            <img src={list2Img} alt="list2Img" title="list2Img" />
            <div className="top">구축부터 운영까지</div>
            <div className="bottom">
              <div className="big">올인원</div>
              <div className="small">솔루션</div>
            </div>
          </h3>
          <p className="text">
            쇼핑 서비스 제공을 위한 팀 구성은 몰파이에 맡기세요.
            {isPc && <br />}
            쇼핑몰 개발, 상품, 운영 및 CS까지 구축부터 운영까지 모두
            준비되었습니다.
            <br />
            이미 국내 유수 기업이 선택한 몰파이로 {isMobile && <br />}
            시작하세요.
          </p>
        </motion.li>
        <motion.li
          initial="offscreen"
          whileInView="onscreen"
          variants={yVariants}
        >
          <h3 className="title">
            <img src={list3Img} alt="list3Img" title="list3Img" />
            <div className="bottom">
              <div className="big">진짜 수익</div>
              <div className="small">
                만드는
                {isPc && <br />} 커머스 경쟁력
              </div>
            </div>
          </h3>
          <p className="text">
            변화 많은 커머스 환경에서 살아남는 법!
            <br />
            커머스 경쟁력을 갖춘 몰파이로 <br />
            고객 리텐션을 넘어 신규 수익까지{isMobile && <br />} 확보할 수
            있습니다.
          </p>
        </motion.li>
      </ul>
      <div className="info">
        <div className="infoImg1">
          {isPc && (
            <motion.img
              src={back1Img}
              alt="3,000+ 입점 브랜드 및 공급처 | 이미지"
              title="3,000+ 입점 브랜드 및 공급처"
              initial="offscreen"
              whileInView="onscreen"
              variants={yVariants}
            />
          )}
          {isMobile && (
            <motion.img
              src={back1MImg}
              alt="3,000+ 입점 브랜드 및 공급처 | 이미지"
              title="3,000+ 입점 브랜드 및 공급처"
              initial="offscreen"
              whileInView="onscreen"
              variants={yVariants}
            />
          )}

          <div>
            <h3 className="infoTitle1">3,000+</h3>
            <h3 className="infoSub1">입점 브랜드 및 공급처</h3>
          </div>
        </div>
        <div className="infoImg2">
          {isPc && (
            <motion.img
              src={back2Img}
              alt="50,000+ 취급상품 | 이미지"
              title="50,000+ 취급상품"
              initial="offscreen"
              whileInView="onscreen"
              variants={yVariants}
            />
          )}
          {isMobile && (
            <motion.img
              src={back2MImg}
              alt="50,000+ 취급상품 | 이미지"
              title="50,000+ 취급상품"
              initial="offscreen"
              whileInView="onscreen"
              variants={yVariants}
            />
          )}

          <div>
            <h3 className="infoTitle2">50,000+</h3>
            <h3 className="infoSub2">취급 상품</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
