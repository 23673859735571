/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import section2 from 'static/myshop/calc/section2.png';

const Section2 = () => {
  const { mq } = useTheme();

  const section2Style = css`
    .container {
      max-width: 1535px;
      padding: 110px 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .text {
        line-height: 28px;
        font-size: 18px;
        margin-top: 14px;
        font-weight: 200;
      }
      img {
        width: 800px;
      }
    }
    ${mq.mobile} {
      .container {
        flex-direction: column-reverse;
        padding: 0;

        .left {
          padding: 35px 32px 126px 32px;
          width: 100%;
          h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
          }
          .text {
            font-size: 14px;
            line-height: 22px;
            margin-top: 12px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  `;
  return (
    <div css={section2Style}>
      <div className="container">
        <div className="left">
          <h2>
            상품별 수익금을
            <br />
            미리 알아볼 수 있어요
          </h2>
          <div className="text">
            상품별로 판매시 얼마의 수익을 얻을 수 있는지 미리 알 수 있습니다.
            <br />
            홈화면에 진열할 상품을 검색하거나, 기획전을 만들 때<br />
            상품별로 수익금이 조회되어 보여집니다.
          </div>
        </div>
        <div className="right">
          <img src={section2} alt="section3" />
        </div>
      </div>
    </div>
  );
};

export default Section2;
