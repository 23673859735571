'use client';

import { useQuery } from 'react-query';
import axios from 'core/config/axios';
import { BlogResponse } from './type';

const dashboardQueryKeys = {
  all: ['dashboard'] as const,
  notice: ['dashboard', 'notice'] as const,
  blog: ['dashboard', 'blog'] as const,
};

const getBlog = async (): Promise<BlogResponse> => {
  const { data } = await axios.get(
    'https://blog.mallpie.kr/ghost/api/content/posts/?key=918eb1e5421ca59755a625fb20&limit=9',
  );
  return data;
};
export const useBlog = () => {
  return useQuery(dashboardQueryKeys.blog, getBlog);
};
