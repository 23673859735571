import useAxiosAuth from 'hooks/useAxiosAuth';
import { useMutation } from 'react-query';
import { queryClient } from 'App';
import { mainKeys } from 'constants/queryKeys';
import { DeleteUserRequest } from './type';

export const useWithdrawal = () => {
  const axiosAuth = useAxiosAuth();
  return useMutation(
    async (form: DeleteUserRequest) => {
      const { data } = await axiosAuth.delete(`/user/users`, {
        data: form,
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(mainKeys.all);
      },
    },
  );
};
