import { axiosMain } from 'core/config/axios';
import { useQuery } from 'react-query';
import {
  barBannerKeys,
  nowProductKeys,
  nowChannelKeys,
} from 'constants/queryKeys';
import { BannerResponse, ChannelResponse, ProductResponse } from './type';

/** NOW 추천 상품 목록 조회 요청 */
export const useRecmdProduct = () =>
  useQuery([...nowProductKeys.recmd()], async () => {
    const { data } = await axiosMain.get<ProductResponse>(
      `/mallpie/now/product/recmd`,
    );
    return data.data;
  });

/** NOW 신규 상품 목록 조회 요청 */
export const useNewProduct = () =>
  useQuery([...nowProductKeys.new()], async () => {
    const { data } = await axiosMain.get<ProductResponse>(
      `/mallpie/now/product/new`,
    );
    return data.data;
  });

/** NOW 띠배너 조회 요청 */
export const useBarBanner = () =>
  useQuery([...barBannerKeys.all], async () => {
    const { data } = await axiosMain.get<BannerResponse>(
      `/mallpie/now/bar-banner`,
    );
    return data.data;
  });

/** NOW POP 채널 목록 조회 요청 */
export const usePopChannel = () =>
  useQuery([...nowChannelKeys.pop()], async () => {
    const { data } = await axiosMain.get<ChannelResponse>(
      `/mallpie/now/channel/pop`,
    );
    return data.data;
  });

/** NOW NEW 채널 목록 조회 요청 */
export const useNewChannel = () =>
  useQuery([...nowChannelKeys.new()], async () => {
    const { data } = await axiosMain.get<ChannelResponse>(
      `/mallpie/now/channel/new`,
    );
    return data.data;
  });
