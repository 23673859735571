import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import Layout from 'components/common/NewLayout';
import Top from 'components/home/Top';
import Info from 'components/home/Info';
import Main from 'components/home/Main';
import Target from 'components/home/Target';
import Method1 from 'components/home/Method1';
import Method2 from 'components/home/Method2';
import Method3 from 'components/home/Method3';
import Shop from 'components/home/Shop';
import Bottom from 'components/home/Bottom';
import NoticePopup from 'components/home/NoticePopup';
import Meta from 'components/common/Meta';
import HomePopup from 'components/home/HomePopup';
import Tip from 'components/home/Tip';

const Home = () => {
  const [cookie, setCookie] = useCookies(['hideMallpiePopup']);
  const [popup, setPopup] = useState<boolean>(false);
  const targetDate = moment('2024-08-13 17:00', 'YYYY-MM-DD HH:mm');

  useEffect(() => {
    const hidePopup = cookie.hideMallpiePopup;
    if (!hidePopup) {
      setPopup(true);
    }
  }, []);

  const handleDontShowAgain = () => {
    const nowDate = new Date();
    nowDate.setDate(nowDate.getDate() + 7);

    setCookie('hideMallpiePopup', true, {
      path: '/',
      expires: nowDate,
    });
    setPopup(false);
  };

  const handleClosePopup = () => {
    setPopup(false);
  };

  const goToLink = () => {
    window.open('https://event-us.kr/m/88500/19632', '_blank');
  };

  return (
    <Layout theme="black">
      <Meta
        title="몰파이 | 쇼핑몰의 쉬운 시작"
        ogTitle="몰파이 | 쇼핑몰의 쉬운 시작"
      />
      <Top />
      <Main />
      <Info />
      <Target />
      <Method1 />
      <Method2 />
      <Method3 />
      <Tip />
      <Shop />
      <Bottom />

      {/* <NoticePopup
        open={popup}
        onClose={handleClosePopup}
        onDontShowAgain={handleDontShowAgain}
      /> */}
      {/* <HomePopup
        open={popup}
        onClose={handleClosePopup}
        onDontShowAgain={handleDontShowAgain}
        goToLink={goToLink}
      /> */}
    </Layout>
  );
};

export default Home;
