/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Mobile, PC } from 'components/common/Responsive';
import list1 from 'static/business/shop/section4_1.png';
import list2 from 'static/business/shop/section4_2.png';
import listm1 from 'static/business/shop/section4_m1.png';
import listm2 from 'static/business/shop/section4_m2.png';
import listm3 from 'static/business/shop/section4_m3.png';
import listm4 from 'static/business/shop/section4_m4.png';

const Section4 = () => {
  const { mq } = useTheme();

  const section4Style = css`
    padding: 120px 0 175px 0;
    text-align: center;
    img {
      width: 1200px;
    }
    ${mq.mobile} {
      padding: 60px 32px;
      img {
        width: 100%;
      }
    }
  `;

  return (
    <div css={section4Style}>
      <div className="container">
        <PC>
          <img src={list1} alt="list1" />
          <img src={list2} alt="list2" />
        </PC>
        <Mobile>
          <img src={listm1} alt="list1" />
          <img src={listm2} alt="list2" />
          <img src={listm3} alt="list3" />
          <img src={listm4} alt="list4" />
        </Mobile>
      </div>
    </div>
  );
};

export default Section4;
