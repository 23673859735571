/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Search from '../Search';
import List from '../List';
import mymallImg from 'static/cs/mymall.png';
import sellerImg from 'static/cs/seller.png';
import { ReactComponent as ArrowLeft } from 'static/cs/arrow_left.svg';
import useNews from 'core/hooks/useNews';
import { title } from 'process';

const Main = () => {
  const { mq } = useTheme();

  const { newsList } = useNews();

  const MainStyle = css`
    padding: 172px 0;

    a {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
    }
    .wrap {
      width: 1110px;
      margin: 0 auto;
      > .title {
        font-size: 64px;
        font-weight: 800;
        line-height: 80px;
        text-align: center;
        .sub {
          color: #6079ff;
          font-size: 30px;
          line-height: 42px;
          font-weight: 700;
        }
      }
      .search {
        margin-top: 48px;
      }
      > .list {
        display: flex;
        gap: 72px;
        margin-top: 90px;
        > li {
          width: 50%;
          > .more {
            display: flex;
            height: 112px;
            cursor: pointer;
            align-items: center;
            gap: 20px;
            padding: 20px 32px;
            background: rgba(96, 121, 255, 0.1);
            border-radius: 12px;
            img {
              width: 70px;
            }
            svg {
              width: 20px;
              height: 20px;
            }
            .text {
              flex: 1;
              .title {
                font-size: 24px;
                font-weight: 700;
                line-height: 36px;
              }
              .sub {
                font-size: 18px;
                font-weight: 300;
                line-height: 27px;
              }
            }
          }
          &:nth-of-type(2) {
            > .more {
              background: rgba(186, 86, 247, 0.1);
              img {
                width: 66px;
              }
            }
          }
        }
      }
      > .notice {
        margin-top: 72px;
      }
    }
    ${mq.mobile} {
      padding: 90px 0 84px 0;
      a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
      }
      .wrap {
        width: 100%;
        padding: 0 20px;
        > .title {
          font-size: 32px;
          font-style: normal;
          font-weight: 800;
          line-height: 48px;
          .sub {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
          }
        }
        .search {
          margin-top: 24px;
        }
        > .list {
          display: flex;
          gap: 64px;
          flex-direction: column;
          margin-top: 64px;
          > li {
            width: 100%;
            > .more {
              height: 84px;
              gap: 16px;
              padding: 16px 24px;
              border-radius: 12px;
              img {
                width: 52px;
              }
              svg {
                width: 20px;
                height: 20px;
              }
              .text {
                flex: 1;
                .title {
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 27px;
                }
                .sub {
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 20px;
                }
              }
            }
            &:nth-of-type(2) {
              > .more {
                img {
                  width: 48px;
                }
              }
            }
          }
        }
      }
    }
  `;

  return (
    <div css={MainStyle}>
      <div className="wrap">
        <h2 className="title">
          <div className="sub">몰파이 고객지원</div>
          무엇을 도와드릴까요?
        </h2>
        <ul className="list">
          <li>
            <List
              title="마이몰"
              more="/faq/mymall/service"
              list={[
                {
                  title: '[서비스] 몰파이는 어떤 서비스인가요?',
                  link: '/faq/mymall/service/1',
                },
                {
                  title: '[서비스] 회원이 되기 위한 조건이 있나요?',
                  link: '/faq/mymall/service/2',
                },
                {
                  title: '[서비스] 가입 비용이 따로 있나요?',
                  link: '/faq/mymall/service/3',
                },
              ]}
            />

            <a
              className="more"
              href="https://help-partner.mallpie.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={mymallImg}
                alt="마이몰 이용가이드 | 이미지"
                title="마이몰 이용가이드"
              />
              <div className="text">
                <h3 className="title">마이몰 이용가이드</h3>
                <p className="sub">마이몰 개설부터 정산까지</p>
              </div>
              <ArrowLeft />
            </a>
          </li>
          <li>
            <List
              title="입점사"
              more="/faq/seller/inner"
              list={[
                {
                  title: '[입점/퇴점] 입점 시 필요한 서류는 무엇인가요?',
                  link: '/faq/seller/inner/1',
                },
                {
                  title: '[입점/퇴점] 지류 계약으로 진행 가능한가요?',
                  link: '/faq/seller/inner/2',
                },
                {
                  title: '[입점/퇴점] 통신판매업 신고증 필수 인가요?',
                  link: '/faq/seller/inner/3',
                },
              ]}
            />

            <a
              className="more"
              href="https://help-seller.mallpie.kr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={sellerImg}
                alt="입점사 이용가이드 | 이미지"
                title="입점사 이용가이드"
              />
              <div className="text">
                <h3 className="title">입점사 이용가이드</h3>
                <p className="sub">입점 신청부터 주문 관리까지</p>
              </div>
              <ArrowLeft />
            </a>
          </li>
        </ul>
        <div className="notice">
          <List
            title="몰파이 소식"
            more="/news"
            list={newsList
              .reverse()
              .map((v, k) => {
                return {
                  title: v.title,
                  date: v.date,
                  link: `/news/${newsList.length - k}`,
                };
              })
              .splice(0, 3)}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
