/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import section2 from 'static/myshop/style/section2.png';
import section2M from 'static/myshop/style/section2_m.png';
import { Mobile, PC } from 'components/common/Responsive';

const Section2 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    text-align: center;
    padding: 110px 0 100px 0;
    .title {
      font-size: 40px;
      font-weight: 600;
      line-height: 60px;
    }
    .text {
      font-size: 18px;
      margin-top: 12px;
      font-weight: 200;
      line-height: 28px;
    }
    .img {
      margin-top: 80px;
      img {
        width: 100%;
      }
    }

    ${mq.mobile} {
      padding-top: 0;
      padding-bottom: 80px;
      .title {
        font-size: 20px;
        line-height: 30px;
        padding: 0 32px;
      }
      .text {
        font-size: 14px;
        line-height: 21px;
      }
      .img {
        margin-top: 36px;
      }
    }
  `;
  return (
    <div css={section1Style}>
      <div className="title">몰에 어울리는 커버이미지로 멋지게...</div>
      <div className="text">
        내가 찍은 사진으로 쇼핑몰의 커버이미지를 꾸며보세요.
        <br />
        적당한 사진이 없다면, 몰파이에 연동된 세계 유명 사진가들의
        <Mobile>
          <br />
        </Mobile>
        사진을 찾아서 사용할 수도 있습니다.
      </div>
      <div className="img">
        <PC>
          <img src={section2} alt="section2" />
        </PC>
        <Mobile>
          <img src={section2M} alt="section2" />
        </Mobile>
      </div>
    </div>
  );
};

export default Section2;
