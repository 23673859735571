/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import section1 from 'static/myshop/calc/section1.png';

const Section1 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    .container {
      max-width: 1535px;
      padding: 110px 40px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;
      }
      .text {
        line-height: 28px;
        font-size: 18px;
        margin-top: 14px;
        font-weight: 200;
      }
      img {
        width: 800px;
      }
    }
    ${mq.mobile} {
      .container {
        flex-direction: column;
        padding: 0;

        .right {
          padding: 35px 32px 126px 32px;
          width: 100%;
          h2 {
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
          }
          .text {
            font-size: 14px;
            line-height: 22px;
            margin-top: 12px;
          }
        }
        img {
          width: 100%;
        }
      }
    }
  `;
  return (
    <div css={section1Style}>
      <div className="container">
        <div className="left">
          <img src={section1} alt="section3" />
        </div>
        <div className="right">
          <h2>
            내가 판매한 상품의
            <br />
            수익금을 정산받아요
          </h2>
          <div className="text">
            내 쇼핑몰에서 상품을 판매하고 판매한 상품의 <br />
            수수료를 정산 받을 수 있습니다. <br />
            상품을 선택하고, 지인이나 주변의 친구들에게 <br />
            꾸준히 홍보하면 지속적인 수익을 창출할 수 있습니다.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
