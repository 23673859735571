import Layout from 'components/common/NewLayout';
import NewsDetailCom from 'components/faq/NewsDetail';
import Main from 'components/faq/Main';
import Menu from 'components/faq/Menu';
import React from 'react';
import { useParams } from 'react-router-dom';
import useNews from 'core/hooks/useNews';
import Meta from 'components/common/Meta';

const NewsDetail = () => {
  const { id } = useParams();
  const { newsList } = useNews();

  return (
    <Layout theme="white">
      <Meta title="몰파이 소식 | 몰파이" ogTitle="몰파이 소식 | 몰파이" />
      <Main title="몰파이 소식">
        <NewsDetailCom data={newsList[Number(id) - 1]} />
      </Main>
    </Layout>
  );
};

export default NewsDetail;
