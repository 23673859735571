/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import section3 from 'static/myshop/list/section3.png';

const Section3 = () => {
  const { mq } = useTheme();

  const section3Style = css`
    .container {
      max-width: 1535px;
      padding: 140px 40px;
      margin: 0 auto;
      display: flex;
      > div {
        width: 50%;
      }
      .left {
        background-color: #6079ff;
        padding: 0 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        h2 {
          font-size: 40px;
          font-weight: 600;
          line-height: 60px;
        }
        .text {
          line-height: 28px;
          margin-top: 12px;
          font-size: 18px;
          font-weight: 200;
        }
        button {
          margin-top: 64px;
        }
      }

      img {
        width: 100%;
      }
    }
    ${mq.mobile} {
      .container {
        flex-direction: column-reverse;
        padding: 0;
        > div {
          width: 100%;
        }
        .left {
          width: 100%;
          padding: 35px 32px;
          h2 {
            font-size: 24px;
            line-height: 36px;
          }
          .text {
            font-size: 14px;
            line-height: 22px;
            margin-top: 12px;
          }
          button {
            margin-top: 40px;
          }
        }
        img {
          width: 100%;
          float: left;
        }
      }
    }
  `;
  return (
    <div css={section3Style}>
      <div className="container">
        <div className="left">
          <h2>
            내 맘대로,
            <br />
            나를 위한 쇼핑몰
          </h2>
          <div className="text">
            나만의 쇼핑몰을 운영하는것을 꿈꿨던 적이 있나요?
            <br />
            또는 여행, 운동, 패션 등 관심사를 공유하는 모임이나 활동을 하고
            계신가요?
            <br />
            내가 판매하고 싶은 상품을 자유롭게 등록하고 SNS, 친구, 모임 등에
            알려보세요.
            <br />나 혼자, 친구/가족과 함께 운영하는 쇼핑몰 몰파이에서
            시작하세요.
            <br />
            <Button
              color="white"
              onClick={() => window.open('https://my.mallpie.kr', '_blank')}
            >
              무료로 몰파이 시작하기
            </Button>
          </div>
        </div>
        <div className="right">
          <img src={section3} alt="section3" />
        </div>
      </div>
    </div>
  );
};

export default Section3;
