/** @jsxImportSource @emotion/react */
import { Section1Style, Section1StyleM } from './style';
import { PC, Mobile } from 'components/common/Responsive';

import event1 from '../../../static/event/event_1.png';
import event2 from '../../../static/event/event_2.png';
import event3 from '../../../static/event/event_3.png';
import event4 from '../../../static/event/event_4.png';
import event5 from '../../../static/event/event_5.png';

const Section1 = () => {
  const buttonHandler = () => {
    window.open('https://my.mallpie.kr', '_blank');
  };

  const buttonHandler2 = () => {
    window.open('https://my.mallpie.kr/customer/notice/40', '_blank');
  };

  return (
    <>
      <PC>
        <div css={Section1Style}>
          <img src={event1} alt="이벤트 이미지1" />
          <img
            className="button-img"
            src={event2}
            onClick={buttonHandler2}
            alt="이벤트 이미지2"
          />
          <img src={event3} alt="이벤트 이미지3" />
          <img src={event4} alt="이벤트 이미지4" onClick={buttonHandler} />
          <img src={event5} alt="이벤트 이미지5" />
        </div>
      </PC>

      <Mobile>
        <div css={Section1StyleM}>
          <img src={event1} alt="이벤트 이미지1" />
          <img
            className="button-img"
            src={event2}
            onClick={buttonHandler2}
            alt="이벤트 이미지2"
          />
          <img src={event3} alt="이벤트 이미지3" />
          <img src={event4} alt="이벤트 이미지4" onClick={buttonHandler} />
          <img src={event5} alt="이벤트 이미지5" />
        </div>
      </Mobile>
    </>
  );
};

export default Section1;
