/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { ReactComponent as MainArrow } from 'static/home/main_arrow.svg';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

const Bottom = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const BottomStyle = css`
    padding: 180px 0;
    background: #282828;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      text-align: center;
      font-size: 64px;
      line-height: 130%;
      font-weight: bold;
      color: #fff;
      span {
        color: #6079ff;
      }
    }
    .desc {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      line-height: 32px;
      font-size: 24px;
      margin-top: 48px;
    }
    button {
      border-radius: 27px;
      border: 0;
      margin-top: 48px;
      background: linear-gradient(90deg, #6079ff 0%, #af60ff 100%);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      color: #fff;
      padding: 12px 36px;
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 8px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    ${mq.mobile} {
      padding: 96px 20px;
      .title {
        font-size: 34px;
        font-weight: 800;
        line-height: 46px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 32px;
      }
      button {
        margin-top: 32px;
        font-size: 18px;
      }
    }
  `;

  return (
    <div css={BottomStyle}>
      <h2 className="title">
        {isMobile && (
          <>
            <span>몰파이 입점에 대해</span>
            <br />더 궁금하신가요?
          </>
        )}
        {isPc && (
          <>
            <span>비용은 최소, 수익은 더 크게</span>
            <br />
            몰파이 수익 실현이 궁금하신가요?
          </>
        )}
      </h2>
      <p className="desc">
        {isMobile && (
          <>
            쇼핑몰 개설부터 수익 실현까지 <br />더 궁금한 점이 있다면 차근차근
            안내해드려요!
          </>
        )}
        {isPc && (
          <>
            입점부터 정산까지 <br />더 궁금한 점이 있다면 차근차근 안내해드려요!
          </>
        )}
      </p>
      <a
        href="https://my.mallpie.kr/customer/inquiry"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button>
          문의하러 가기 <MainArrow />
        </button>
      </a>
    </div>
  );
};

export default Bottom;
