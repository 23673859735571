import Layout from 'components/common/Layout';
import Section1 from 'components/myshop/list/Section1';
import Section2 from 'components/myshop/list/Section2';
import Section3 from 'components/myshop/list/Section3';

import Title from 'components/myshop/Title';
import TitleIcon from 'static/myshop/list/title_icon.png';

const Style = () => (
  <Layout theme="white">
    <Title
      title="진열하기"
      titleAccent="상품"
      img={TitleIcon}
      text="손쉽고 편리한 기능으로 나만의 쇼핑몰을 효율적으로 관리 해보세요."
      mText={
        '손쉽고 편리한 기능으로 나만의 쇼핑몰을\n효율적으로 관리 해보세요.'
      }
    />
    <Section1 />
    <Section2 />
    <Section3 />
  </Layout>
);

export default Style;
