export const size = {
  large: '1024px',
  small: '1023px',
};

const theme = {
  mainColor: '#6079FF',
  mq: {
    laptop: `@media only screen and (min-width: ${size.large})`,
    mobile: `@media only screen and (max-width: ${size.small})`,
  },
};

export default theme;
