/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import arrowImg from 'static/company/arrow_left.png';

const MethodNavigation = ({ show }) => {
  const { mq } = useTheme();
  const navigation = useNavigate();
  const NavigationStyle = css`
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, 0);
    padding: 0px 20px;
    .anchor {
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 0px 8px;
      background: #fff;
      border: 1px solid #72aed3;
      margin-bottom: 32px;
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      .text {
        display: block;
        cursor: pointer;
        padding: 9px 8px;
        word-break: keep-all;

        .textWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          color: #fff;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.16px;
          border-radius: 30px;
          padding: 8px 16px 8px 20px;

          p {
            padding-top: 4px;
          }
          &:hover {
            opacity: 0.8;
            transition: opacity 0.3s ease;
          }
        }
        .textWrapper.company {
          background-color: #72aed3;
        }
        .textWrapper.mall {
          background-color: #4c6c82;
        }
      }
    }
    ${mq.mobile} {
      .anchor {
        border-radius: 20px;
        gap: 10px;
        .text {
          font-size: 13px;
          line-height: 18px;
          padding: 16px 8px;
          .textWrapper {
            border-radius: 12px;
          }
        }
      }
    }
  `;

  return (
    <>
      {show && (
        <div css={NavigationStyle}>
          <ul className="anchor">
            <li>
              <div className="text">
                <div
                  className="textWrapper company"
                  onClick={() => navigation('/download')}
                >
                  <p>기업 소개서 받기</p>
                  <img src={arrowImg} alt="arrow" />
                </div>
              </div>
            </li>
            <li>
              <div className="text">
                <div
                  className="textWrapper mall"
                  onClick={() => navigation('/inquiry')}
                >
                  <p>자사몰 도입 문의</p>
                  <img src={arrowImg} alt="arrow" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default MethodNavigation;
