/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import { useMymallList } from 'core/queries/mypage/mymall';
import MymallDataList from '../MymallDataList';
import { ReactComponent as NoDataIcon } from 'static/user/mypage/noData.svg';
import { ReactComponent as ArrowDownIcon } from 'static/user/mypage/arrowDown.svg';

const MymallData = () => {
  const { mq } = useTheme();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useMymallList();

  const wrapperStyle = css`
    margin: 0 auto;
    max-width: 840px;
    * {
      word-break: keep-all;
    }
    .info {
      padding: 32px 0px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #ebebeb;
      span {
        font-weight: 700;
      }
    }
    .content {
      padding: 32px 0px 42px;
    }
    .button {
      display: flex;
      justify-content: center;
      padding-top: 42px;
      button {
        padding: 12px 36px;
        display: flex;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        .arrow {
          margin-left: 8px;
          width: 24px;
        }
      }
    }
    .error {
      margin: 160px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .no-text {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #bdbdbd;
      }
    }
    ${mq.mobile} {
      padding: 0px 20px 50px;
      .info {
        padding: 20px 0px;
        flex-direction: column;
        font-size: 12px;
        line-height: 18px;
      }
      .content {
        padding: 32px 0px;
        .button {
          padding-top: 32px;
          button {
            padding: 8px 24px;
            font-size: 14px;
            line-height: 22px;
            .arrow {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={wrapperStyle}>
      {data?.pages ? (
        <>
          <div className="info">
            <div>
              총 <span>{data?.pages[0].data.total}개</span>의 마이몰을
              구독중입니다.
            </div>
            <div>해지는 해당 마이몰의 마이페이지에서 가능합니다.</div>
          </div>
          <div className="content">
            <MymallDataList
              list={data?.pages.flatMap(page => page.data.list)}
            />
            {hasNextPage && (
              <div className="button">
                <Button color="gray" variant="outlined" onClick={fetchNextPage}>
                  더보기
                  <ArrowDownIcon className="arrow" stroke="#667085" />
                </Button>
              </div>
            )}
            {isFetchingNextPage && <div>Loading...</div>}
          </div>
        </>
      ) : (
        <div className="error">
          <NoDataIcon />
          <p className="no-text">구독중인 마이몰이 없어요</p>
        </div>
      )}
    </div>
  );
};

export default MymallData;
