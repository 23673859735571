import useAxiosAuth from 'hooks/useAxiosAuth';
import { useQuery, useInfiniteQuery, useMutation } from 'react-query';
import { queryClient } from 'App';
import { PagingProps } from 'core/queries/type';
import {
  CouponResponse,
  AddCouponBody,
  CouponMallBody,
  CouponMallResponse,
} from './type';
import { couponKeys } from 'constants/queryKeys';

export const useCoupon = ({ page, size }: PagingProps) => {
  const axiosAuth = useAxiosAuth();
  return useInfiniteQuery(
    couponKeys.lists(),
    async ({ pageParam = page }) => {
      const { data } = await axiosAuth.get<CouponResponse>(
        `/my-mallpie/coupons`,
        {
          params: {
            page: pageParam,
            size,
          },
        },
      );

      return data.data;
    },
    {
      getNextPageParam: lastPage =>
        lastPage.isLastPage ? undefined : lastPage.pageNum + 1,
    },
  );
};

export const useAddCoupon = () => {
  const axiosAuth = useAxiosAuth();

  return useMutation(
    async (form: AddCouponBody) => {
      const { data } = await axiosAuth.post(`/my-mallpie/coupon/issue`, form);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(couponKeys.lists());
      },
    },
  );
};

export const useCouponMall = (form: CouponMallBody) => {
  const axiosAuth = useAxiosAuth();
  return useQuery(
    [...couponKeys.detail(), form.shopAllYn, form.couponSn],
    async () => {
      const { data } = await axiosAuth.get<CouponMallResponse>(
        `/my-mallpie/coupons/${form.shopAllYn}/${form.couponSn}`,
      );
      return data.data;
    },
  );
};
