/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { SwiperSlide, Swiper } from 'swiper/react';
import Icon1 from 'static/download/icon1.png';
import Icon2 from 'static/download/icon2.png';
import Icon3 from 'static/download/icon3.png';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const Section3 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section3Style = css`
    max-width: 948px;
    margin: 100px auto 0 auto;
    .box {
      .container {
        margin: 0 auto;
        > .title {
          text-align: center;
          font-size: 48px;
          font-weight: 700;
          line-height: 64px;
          span {
            color: #6079ff;
          }
        }

        ul {
          margin-top: 56px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 24px;
          li {
            flex: 1;
            padding: 32px;
            border-radius: 36px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 462px;
            text-align: center;
            .sub {
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
            }
            .title {
              font-size: 38px;
              font-style: normal;
              font-weight: 800;
              line-height: 52px;
            }
            .desc {
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px;
              margin-top: 20px;
            }
            &:nth-of-type(1) {
              color: #7551c0;
              background: #f9f6ff;
            }
            &:nth-of-type(2) {
              color: #2b7eb0;
              background: #ebf7fe;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      margin: 76px auto 0 auto;
      width: 100%;
      .box {
        .container {
          > .title {
            font-size: 24px;
            line-height: 36px;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 32px;
            padding: 0 20px;
            li {
              width: 100%;
              padding: 32px;
              border-radius: 24px;
              min-height: 335px;
              .sub {
                font-size: 14px;
                line-height: 20px;
              }
              .title {
                font-size: 30px;
                line-height: 42px;
              }
              .desc {
                font-size: 18px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  `;

  const yVariants = {
    offscreen: {
      y: 100,
      x: 0,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section3Style}>
      <div className="box">
        <div className="container">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
            className="title"
          >
            도입 목적에 따라 {isMobile && <br />}
            <span>몰파이 비즈니스</span>를 <br />
            다양하게 활용할 수 있습니다
          </motion.div>
          <ul>
            <motion.li
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: 'easeOut',
                  duration: 0.7,
                },
              }}
            >
              <div className="sub">우리 서비스 고객들을 붙잡는 방법</div>
              <div className="title">자사 브랜드 쇼핑몰</div>
              <div className="desc">
                몰파이가 보유한 상품으로
                <br />
                고객을 위한 쇼핑몰을 구성하고
                <br />
                신규 수익 모델을 창출합니다
              </div>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, scale: 0.2 }}
              whileInView={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: 'easeOut',
                  duration: 0.7,
                },
              }}
            >
              <div className="sub">비용은 낮추고 임직원 만족도는 높이는</div>
              <div className="title">임직원 복지몰</div>
              <div className="desc">
                몰파이 상품 및 혜택을
                <br />
                우리 기업 임직원들에게도
                <br />
                동일하게 제공하여 만족도를 높입니다
              </div>
            </motion.li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Section3;
