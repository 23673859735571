/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Button from 'components/common/Button';
import { Mobile } from 'components/common/Responsive';

const Section5 = () => {
  const { mq } = useTheme();

  const section5Style = css`
    padding: 96px 0px;
    background: #6079ff;
    text-align: center;
    color: #fff;
    .title {
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
    }
    .text {
      font-size: 18px;
      font-weight: 200;
      line-height: 28px;
      margin-top: 12px;
    }
    button {
      margin-top: 24px;
    }
    ${mq.mobile} {
      .title {
        font-size: 24px;
        line-height: 36px;
      }
      .text {
        font-size: 14px;
        line-height: 21px;
      }
    }
  `;
  return (
    <div css={section5Style}>
      <div className="title">어떤 스타일의 몰을 꾸며볼까요?</div>
      <div className="text">
        상품 진열, 기획전, 홈 화면 구성에 필요한
        <Mobile>
          <br />
        </Mobile>
        관리자 기능을 지원해드립니다.
      </div>
      <Button
        color="white"
        onClick={() =>
          window.open('https://guide.mallpie.kr/mall/manage-design', '_blank')
        }
      >
        마이몰 디자인 및 상품 진열 가이드
      </Button>
    </div>
  );
};

export default Section5;
