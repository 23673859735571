/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import list1Img from 'static/personal/section1_list1.png';
import list2Img from 'static/personal/section1_list2.png';
import list3Img from 'static/personal/section1_list3.png';

const Section1 = () => {
  const { mq } = useTheme();
  const Section1Style = css`
    display: flex;
    justify-content: center;
    .container {
      max-width: 1200px;
      padding: 46px 0px 227px;
      margin: 0 auto;
      > .title {
        color: #000;
        font-weight: 700;
        font-size: 68px;
        line-height: 50px;
        text-align: center;
        line-height: 96px;
        span {
          color: #6079ff;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 155px;
        margin-top: 100px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
          &:nth-of-type(2) {
            flex-direction: row;
          }
          .text {
            padding: 0px 40px;
            .icon {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 24px;
              font-weight: 700;
              line-height: 30px;
              color: #6079ff;
            }
            .title {
              font-size: 40px;
              font-weight: 800;
              line-height: 60px;
              span {
                color: #6079ff;
              }
            }
            .desc {
              margin-top: 10px;
              color: #444;
              font-size: 20px;
              font-weight: 400;
              line-height: 30px;
            }
          }
          .img {
            width: 480px;
            flex-shrink: 0;
            border-radius: 32px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 40px 0px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 20px 20px 96px;
        width: 100%;
        > .title {
          font-size: 32px;
          font-weight: 800;
          line-height: 45px; /* 140.625% */
        }
        .list {
          gap: 64px;
          margin-top: 64px;
          li {
            align-items: flex-start;
            flex-direction: column-reverse;
            &:nth-of-type(2) {
              flex-direction: column-reverse;
            }
            .text {
              padding: 0px;
              text-align: left;
              margin-bottom: 24px;
              .icon {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                svg {
                  width: 20px;
                  height: 20px;
                }
              }
              .title {
                font-size: 28px;
                line-height: 40px;
              }
              .desc {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const scaleVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section1Style}>
      <div className="container">
        <h2 className="title">
          <span>몰파이</span>를
          <br /> 추천합니다!
        </h2>
        <ul className="list">
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list1Img}
                alt="몰파이를 추천합니다1 | 이미지"
                title="몰파이를 추천합니다1"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                시작은 쉽게,
                <br />
                수익은 <span>확실하게!</span>
              </h3>
              <p className="desc">
                매일 10원씩 버는 앱테크 대신 <br />좀 더 확실한 수익이
                필요하다면
              </p>
            </motion.div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list2Img}
                alt="몰파이를 추천합니다2 | 이미지"
                title="몰파이를 추천합니다2"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                비교가 필요없는
                <br />
                알뜰한 <span>최저가 구매</span>
              </h3>
              <p className="desc">
                여기저기 가격 비교 쇼핑에 지치고, <br />
                최저가로 더 똑똑한 구매를 원한다면
              </p>
            </motion.div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.4 }}
          >
            <motion.div className="img" variants={scaleVariants}>
              <img
                src={list3Img}
                alt="몰파이를 추천합니다3 | 이미지"
                title="몰파이를 추천합니다3"
              />
            </motion.div>
            <motion.div className="text" variants={yVariants}>
              <h3 className="title">
                <span>다다익선!</span>
                <br />
                나눌수록 커지는 기쁨
              </h3>
              <p className="desc">
                평소 가족/친구/지인들에게 알뜰 쇼핑 <br />
                정보 공유에 적극적이라면
              </p>
            </motion.div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section1;
