/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/Button';

const ExpirePasswordContent = () => {
  const navigate = useNavigate();

  const SessionPageStyle = css`
    margin: 0 auto;
    height: calc(100vh - 336px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 48px;
      height: 48px;
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #909090;
      margin-top: 12px;
    }
    button {
      margin-top: 16px;
      margin: 16px auto 0 auto;
    }
  `;

  return (
    <div css={SessionPageStyle}>
      <img
        src="https://event.admerce.co.kr/mall/images/notice.png"
        alt="lock"
        className="icon"
      />
      <div className="content">
        <div className="text">
          비밀번호 재설정 가능한 시간이 지났습니다.
          <br />
          비밀번호 재설정을 다시 시도해주세요.
        </div>
        <Button color="gray" onClick={() => navigate('/user/login')}>
          비밀번호 재설정
        </Button>
      </div>
    </div>
  );
};

export default ExpirePasswordContent;
