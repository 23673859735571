import { axiosAuth } from 'core/config/axios';
import { useQuery } from 'react-query';

import { purchaseKeys } from 'constants/queryKeys';

export const useParcel = (invoice: string, coCd: string) => {
  return useQuery(
    [...purchaseKeys.parcel(), invoice, coCd],
    async () => {
      const { data } = await axiosAuth.get(`/my-mallpie/${invoice}/${coCd}`);
      return data;
    },
    {
      enabled: invoice !== undefined,
    },
  );
};
