/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import list1Img from 'static/home/method1_list1.png';
import list2Img from 'static/home/method1_list2.png';
import list2MImg from 'static/home/method1_m_list2.png';
import { ReactComponent as MagicWand } from 'static/home/magic_wand.svg';
import { motion } from 'framer-motion';
import { useIsMobile } from 'core/hooks/useResponsive';

const Method1 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();

  const Method1Style = css`
    display: flex;
    justify-content: center;
    padding: 128px 0;
    .wrap {
      .title {
        .sub {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 24px;
          font-weight: 700;
          color: #a7acc6;
        }
        .text {
          color: #000;
          font-weight: bold;
          font-size: 56px;
          margin-top: 16px;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
      }
      .list {
        display: flex;
        gap: 36px;
        margin-top: 72px;
        li {
          width: 620px;
          height: 728px;
          position: relative;
          border-radius: 40px;
          &:nth-of-type(1) {
            background: #282828;
            .text {
              color: #fff;
              span {
                color: #6079ff;
              }
            }
          }
          &:nth-of-type(2) {
            background: #e4f1fa;
            .text {
              color: #6998b9;
              span {
                color: #000;
              }
            }
          }
          .text {
            padding: 64px 56px;
            font-size: 30px;
            line-height: 42px;
            font-weight: 700;
          }
          img {
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
    ${mq.mobile} {
      padding: 0px 20px;
      justify-content: start;
      .wrap {
        .title {
          .sub {
            font-size: 16px;
          }
          .text {
            font-size: 30px;
            margin-top: 10px;
            line-height: 42px;
          }
          svg {
            width: 28px;
            height: 28px;
          }
        }
        width: 100%;
        .list {
          flex-direction: column;
          margin-top: 24px;
          gap: 20px;
          li {
            border-radius: 20px;
            .text {
              padding: 38px 38px 51px 38px;
              font-size: 20px;
              line-height: 30px;
            }
            width: 100%;
            height: auto;
            img {
              position: relative;
            }
            &:nth-of-type(2) {
              .text {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Method1Style}>
      <div className="wrap">
        <h2 className="title">
          <div className="sub">
            <MagicWand />
            손쉬운 제작
          </div>
          <div className="text">
            복잡한 기능 대신
            <br />
            <span>간편한 연동</span>과 <span>템플릿</span>으로
            <br />
            빠르게 개설
          </div>
        </h2>
        <motion.ul
          className="list"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ amount: 0.2 }}
        >
          <motion.li variants={defaultVariants}>
            <h3 className="text">
              근사한 촬영 컷은
              <br />
              무료 상업용
              <br />
              이미지 연동으로
              <br />
              <span>한번에 해결</span>
            </h3>
            <img
              src={list1Img}
              alt="손쉬운 제작1 | 이미지"
              title="손쉬운 제작1"
            />
          </motion.li>
          <motion.li variants={defaultVariants}>
            <h3 className="text">
              커스텀 메뉴,
              <br />
              배너 디자인
              <br />
              구매를 부르는
              <br />
              <span>쇼핑몰 특화 기능 설정</span>
            </h3>
            <img
              src={isMobile ? list2MImg : list2Img}
              alt="손쉬운 제작2 | 이미지"
              title="손쉬운 제작2"
            />
          </motion.li>
        </motion.ul>
      </div>
    </div>
  );
};

export default Method1;
