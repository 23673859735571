/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

const Title = () => {
  const { mq } = useTheme();

  const TitleStyle = css`
    .container {
      max-width: 1200px;
      padding-top: 200px;
      padding-bottom: 100px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .title {
        color: #000;
        font-size: 64px;
        font-weight: 800;
        line-height: 86px;
      }
      .sub {
        font-size: 30px;
        color: #6079ff;
        font-weight: 700;
        line-height: 42px;
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px 48px;
        .title {
          font-size: 36px;
          font-weight: 800;
          line-height: 56px;
        }
        .sub {
          font-size: 16px;
          color: #6079ff;
          font-weight: 700;
          line-height: 42px;
        }
      }
    }
  `;

  return (
    <div css={TitleStyle}>
      <div className="container">
        <div className="sub">몰파이 BIZ</div>
        <h2 className="title">도입 문의</h2>
      </div>
    </div>
  );
};

export default Title;
