import Meta from 'components/common/Meta';
import Layout from 'components/common/NewLayout';
import FaqDetailCom from 'components/faq/FaqDetail';
import Main from 'components/faq/Main';
import Menu from 'components/faq/Menu';
import useFaq from 'core/hooks/useFaq';
import React from 'react';
import { useParams } from 'react-router-dom';

const FaqDetail = () => {
  const { type, category, id } = useParams();
  const { typeList, menuList, categoryList } = useFaq();

  return (
    <Layout theme="white">
      <Meta title="자주 묻는 질문 | 몰파이" ogTitle="자주 묻는 질문 | 몰파이" />
      <Main title="자주 묻는 질문" subTitle={typeList[type]} isSearch={true}>
        <Menu menus={menuList[type]} type={type} category={category} />
        <FaqDetailCom
          data={
            categoryList[type][category].filter(v => v.id === Number(id))[0]
          }
        />
      </Main>
    </Layout>
  );
};

export default FaqDetail;
