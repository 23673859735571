/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Element } from 'react-scroll';
import list1Img from 'static/method/method3_list1.png';
import list2Img from 'static/method/method3_list2.png';
import list3Img from 'static/method/method3_list3.png';
import { ReactComponent as Gift } from 'static/method/gift.svg';
import { motion } from 'framer-motion';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';

const Method3 = () => {
  const { mq } = useTheme();

  const Method1Style = css`
    display: flex;
    justify-content: center;
    background: #f4f6ff;
    .wrap {
      width: 1232px;
      padding: 200px 0px;
      > .title {
        text-align: center;
        svg {
          width: 30px;
        }
        .sub {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-size: 24px;
          font-weight: 700;
          color: #a7acc6;
        }
        .text {
          color: #000;
          font-weight: bold;
          font-size: 56px;
          margin-top: 16px;
          line-height: 76px;
          span {
            color: #6079ff;
          }
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 80px;
        margin-top: 80px;
        li {
          display: flex;
          position: relative;
          align-items: center;
          &:nth-of-type(2) {
            flex-direction: row-reverse;
          }
          .text {
            padding: 0px 112px;
            .title {
              font-size: 40px;
              font-weight: bold;
              line-height: 60px;
            }
            .desc {
              margin-top: 20px;
              font-size: 20px;
              color: #667085;
              line-height: 30px;
              span {
                margin-top: 12px;
                display: block;
              }
            }
          }
          .img {
            width: 600px;
            flex-shrink: 0;
            background: #e5eafd;
            border-radius: 32px;
            overflow: hidden;
            display: flex;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    ${mq.mobile} {
      .wrap {
        width: 100%;
        padding: 96px 20px;
        .title {
          .sub {
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
          }
          .text {
            font-size: 28px;
            margin-top: 8px;
            line-height: 40px;
          }
        }
        .list {
          gap: 40px;
          margin-top: 40px;
          li {
            flex-direction: column;
            &:nth-of-type(2) {
              flex-direction: column;
            }
            .text {
              padding: 16px 0px 0px;
              width: 100%;
              .title {
                font-size: 24px;
                line-height: 30px;
              }
              .desc {
                margin-top: 6px;
                font-size: 16px;
                color: #667085;
                line-height: 24px;
                span {
                  margin-top: 4px;
                }
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const scaleVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Element name="section3" className="element">
      <div css={Method1Style}>
        <div className="wrap">
          <div className="title">
            <div className="sub">
              <Gift fill="#9EA2B8" title="다양한 커머스 기능" />
              다양한 커머스 기능
            </div>
            <h2 className="text">
              <span>간편하고 자동화된 기능</span>을 통해
              <br />
              이용 만족도를 높일 수 있어요!
            </h2>
          </div>
          <ul className="list">
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list1Img}
                  alt="쿠폰 생성 | 이미지"
                  title="쿠폰 생성"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">쿠폰 생성</h3>
                <p className="desc">
                  원하는 조건을 선택하고 예산을 설정하면 <br />
                  쿠폰을 생성할 수 있어요.
                  <span>
                    상황에 맞게 쿠폰을 활용하여 고객 만족도를 높이고 <br />
                    매출을 키워보세요 !
                  </span>
                </p>
              </motion.div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list2Img}
                  alt="SNS 간편 가입 · 로그인 | 이미지"
                  title="SNS 간편 가입 · 로그인"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">SNS 간편 가입 · 로그인 </h3>
                <p className="desc">
                  카카오, 네이버 아이디만으로
                  <br />
                  간편하게 쇼핑을 시작할 수 있어요.
                  <span>편리하고 안전하게 더 많은 회원을 확보하세요 !</span>
                </p>
              </motion.div>
            </motion.li>
            <motion.li
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ amount: 0.4 }}
            >
              <motion.div className="img" variants={scaleVariants}>
                <img
                  src={list3Img}
                  alt="주문 상태별 카카오 알림톡 | 이미지"
                  title="주문 상태별 카카오 알림톡"
                />
              </motion.div>
              <motion.div className="text" variants={yVariants}>
                <h3 className="title">
                  주문 상태별 <br />
                  카카오 알림톡{' '}
                </h3>
                <p className="desc">
                  주문 진행 상황이 카카오 알림톡을 통해
                  <br />
                  고객에게 자동으로 발송됩니다.
                  <span>
                    별도 안내 없이 실시간으로 주문 상황을 <br />
                    쉽게 확인할 수 있어요 !
                  </span>
                </p>
              </motion.div>
            </motion.li>
          </ul>
        </div>
      </div>
    </Element>
  );
};

export default Method3;
