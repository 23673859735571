/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import Badge from '../Badge';
import { Mobile, PC } from '../Responsive';

interface MallCardProps {
  onClick?: () => void;
  className?: string;
  direction?: 'vertical' | 'horizontal';
  imgUrl: string;
  shopId: string;
  shopName: string;
  shopDesc?: string;
  categories: string[];
  covers?: Cover[];
  nowCovers?: ImageData[];
  type?: 'common' | 'now';
}

interface Cover {
  dispCd: string;
  name: string;
  imgUrl: string;
}

interface ImageData {
  dsgnImgTypeCd: string;
  imgLink: string;
}

const Mall = ({
  onClick,
  className,
  direction,
  imgUrl,
  shopId,
  shopName,
  shopDesc,
  categories,
  covers,
  nowCovers,
  type,
}: MallCardProps) => {
  const { mq } = useTheme();
  const MallCardStyle = css`
    position: relative;
    ${direction === 'horizontal' ? `min-height: 187px;` : null}
    ${direction === 'horizontal' ? `display: flex;` : null}
    ${direction === 'horizontal' && `background: #FFFFFF;`}
    ${direction === 'horizontal' && `border-radius: 10px;`}
    ${direction === 'horizontal' &&
    `box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);`}
    ${direction === 'horizontal' && `padding: 27px 28px;`}
    ${direction === 'horizontal'
      ? `width: 420px;height: auto;`
      : `width: 200px;`}
    cursor: pointer;
    a {
      color: #000;
    }
    .cover {
      width: 200px;
      aspect-ratio: 1/1;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
      }
    }
    .logo {
      ${direction === 'vertical' ? `position: absolute;` : null}
      ${direction === 'vertical' ? `top: 173px;` : null}
      ${direction === 'vertical' ? `left: 28px;` : null}
      ${direction === 'vertical' ? `width: 64px;` : `width: 80px;`}
      ${direction === 'vertical' ? `height: 64px;` : `height: 80px;`}
      flex-shrink:0;
      font-size: 0;
      border-radius: 999px;
      margin-right: 15px;
      aspect-ratio: 1 / 1;

      img {
        width: 100%;
        height: 100%;
        border-radius: 999px;
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
      }
    }
    .content {
      width: 100%;
      height: auto;
      ${direction === 'vertical' ? `padding: 36px 28px 28px;` : null}
      ${direction === 'vertical' ? `min-height: 200px;` : null}
      h6 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
      }
      p {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-top: 8px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        ${direction === 'vertical' ? `height: auto;` : 'height: 44px;'}
      }
      .tag {
        margin-top: 15px;
        margin-bottom: 2px;
        position: relative;
        min-height: 32px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        isolation: isolate;
        z-index: 9;
        color: rgba(96, 121, 255, 0.8);
        div {
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
        }
      }
    }
    ${mq.mobile} {
      ${direction === 'horizontal' && `padding: 20px;`}
      ${direction === 'horizontal' && `width: 100%;height: auto;`}
      ${direction === 'vertical' ? `margin-right: 10px;` : null}
      ${direction === 'vertical' && `width: 162px;`}
      min-height: 0;
      flex-direction: column;

      .cover {
        /* aspect-ratio: 1/1.1; */
        width: unset;
        height: unset;
      }
      .logo {
        ${direction === 'vertical' ? `top: 80%;` : null}
        ${direction === 'vertical' ? `left: 6%;` : null}
        ${direction === 'vertical' && `width: 30%;`}
        ${direction === 'vertical' && `height: 30%;`}
        width: 64px;
        height: 64px;
        aspect-ratio: 1 / 1;
      }
      .content {
        ${direction === 'vertical' ? `padding: 30px 10px 14px;` : null}
        ${direction === 'vertical' ? `min-height: 0; height: auto;` : null}
        margin-top: 15px;
        h6 {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
        }
        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
        }
        .tag {
          margin-top: 15px;
          min-height: 28px;
        }
      }
    }
  `;

  return (
    <div css={MallCardStyle} onClick={onClick} className={className}>
      {direction === 'vertical' && (type !== 'now' ? covers : nowCovers) ? (
        <>
          <div className="cover">
            <img
              src={type !== 'now' ? covers[0]?.imgUrl : nowCovers[0]?.imgLink}
              alt="커버 이미지"
            />
            <Mobile>
              <div className="logo">
                <img src={imgUrl} alt={shopId} />
              </div>
            </Mobile>
          </div>
          <PC>
            <div className="logo">
              <img src={imgUrl} alt={shopId} />
            </div>
          </PC>
        </>
      ) : (
        <div className="logo">
          <img src={imgUrl} alt={shopId} />
        </div>
      )}
      <div className="content">
        <h6>{shopName}</h6>
        <p>{shopDesc && shopDesc}</p>
        <ul className="tag">
          {categories?.map((tag, index) => (
            <Badge key={index} text={tag} tagYn="Y" />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Mall;
