/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from 'static/cs/back.svg';

interface FaqDetailData {
  title: string;
  content: any;
}

interface FaqDetailProps {
  data: FaqDetailData;
}

const FaqDetail = ({ data }: FaqDetailProps) => {
  const { mq } = useTheme();
  const navigator = useNavigate();

  const FaqDetailStyle = css`
    display: flex;
    flex-direction: column;
    width: 1000px;
    .back {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    .title {
      padding-left: 52px;
      margin-top: 24px;
      position: relative;
      box-sizing: border-box;
      &::after {
        content: 'Q';
        display: block;
        width: 40px;
        height: 40px;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        color: #98a2b3;
        background: #f2f4f7;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 3px;
      }
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
    }
    .content {
      margin-top: 48px;
      font-size: 20px;
      font-weight: 300;
      line-height: 34px;
    }

    .inquiry {
      margin-top: 48px;
      padding: 48px 0;
      border-top: 1px solid #f2f4f7;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      button {
        background: #6079ff;
        cursor: pointer;
        display: block;
        margin-top: 12px;
        border: 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        border-radius: 4px;
        color: #fff;
        padding: 7px 16px;
      }
    }

    ${mq.mobile} {
      width: 100%;
      .title {
        padding-left: 0;
        padding-top: 38px;
        font-size: 24px;
        line-height: 36px;
        &::after {
          width: 30px;
          height: 30px;
          font-size: 18px;
          font-weight: 700;
          line-height: 30px;
          left: 0;
          top: 0;
          border-radius: 3px;
        }
      }
      .content {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
      .inquiry {
        margin-top: 32px;
        padding: 32px 0;
        border-top: 1px solid #f2f4f7;
        font-size: 16px;
        line-height: 30px;
        button {
          background: #6079ff;
          display: block;
          margin-top: 12px;
          border: 0;
          border-radius: 4px;
          color: #fff;
          padding: 7px 12px;
        }
      }
    }
  `;
  return (
    <div css={FaqDetailStyle}>
      <div className="back" onClick={() => navigator(-1)}>
        <Back />
        질문 목록
      </div>
      <div className="title">{data.title}</div>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
      <div className="inquiry">
        아직도 해결되지 않았다면
        <a
          href="https://my.mallpie.kr/customer/inquiry"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>1:1 문의 하기</button>
        </a>
      </div>
    </div>
  );
};

export default FaqDetail;
