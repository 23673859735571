/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import {
  useIsMobile,
  useIsMobileSmall,
  useIsPc,
} from 'core/hooks/useResponsive';

import list1Img from 'static/creator/section2_list1.png';
import list2Img from 'static/creator/section2_list2.png';
import list3Img from 'static/creator/section2_list3.png';
import list4Img from 'static/creator/section2_list4.png';
import { motion } from 'framer-motion';

const Section2 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isMobileSmall = useIsMobileSmall();
  const isPc = useIsPc();
  const sectionStyle = css`
    display: flex;
    justify-content: center;
    background: #353535;
    .container {
      max-width: 1200px;
      padding: 180px 0px;

      > .title {
        color: #fff;
        text-align: center;
        font-size: 56px;
        font-weight: 800;
        line-height: 76px;
        margin-bottom: 56px;
        span {
          color: #6079ff;
          font-size: 56px;
          font-weight: 800;
          line-height: 76px;
        }
      }
      .list {
        li {
          margin-top: 20px;
          background: #fff;
          border-radius: 36px;
          display: flex;
          width: 1200px;
          height: 564px;
          padding-left: 64px;
          justify-content: space-between;
          align-items: center;
          .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            .title {
              font-size: 32px;
              font-weight: 700;
              line-height: 48px;
              margin-bottom: 20px;
              > span {
                color: #6079ff;
              }
            }
            .desc {
              color: #444;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
            }
          }
          .img {
            overflow: hidden;
            width: 564px;
            img {
              width: 100%;
            }
          }
          &:nth-of-type(1) {
            margin-top: 0;
            background: #6079ff;
            .text {
              .title {
                color: #fff;
              }
              .desc {
                color: #fff;
                opacity: 0.6;
              }
            }
          }
          &:nth-of-type(2) {
            padding-left: 0;
            background: #d3e4f4;
            flex-direction: row-reverse;
            padding-right: 70px;
          }
          &:nth-of-type(3) {
            padding-left: 64px;
            background: #dae0ff;
            .text {
              .title {
                color: #22307e;
              }
              .desc {
                color: #22307e;
                opacity: 0.7;
              }
            }
          }
          &:nth-of-type(4) {
            padding-left: 0;
            background: #f9fafb;
            flex-direction: row-reverse;
            padding-right: 76px;
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px;
        width: 100%;
        > .title {
          font-size: 28px;
          font-weight: 800;
          line-height: 40px;
          margin-bottom: 32px;
          span {
            font-size: 28px;
            line-height: 40px;
          }
        }
        .list {
          li {
            margin-top: 20px;
            border-radius: 20px;
            width: 100%;
            height: auto;
            padding: 0 !important;
            gap: 0;
            flex-direction: column !important;
            align-items: flex-start;
            .text {
              padding: 36px 30px 0px 30px !important;
              .title {
                color: #000;
                font-size: 24px;
                font-weight: 800;
                line-height: 32px;
                letter-spacing: -0.24px;
              }
              .desc {
                color: #444;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={sectionStyle}>
      <div className="container">
        <h2 className="title">
          몰파이로 운영 부담없이
          <br />
          <span>
            쇼핑몰을 만들고 {isMobileSmall && <br />}수익을 실현하세요
          </span>
        </h2>
        <ul className="list">
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                개성이 담긴
                <br />
                매력적인 쇼핑몰의 완성
              </h3>
              <p className="desc">
                내 브랜드로 쇼핑몰을 만들고 싶은데, <br />
                어떻게 시작해야 할 지 막막했다면, <br />
                몰파이로 시작하세요!
              </p>
            </div>
            <div className="img">
              <img
                src={list1Img}
                alt="개성이 담긴 매력적인 쇼핑몰의 완성 | 이미지"
                title="개성이 담긴 매력적인 쇼핑몰의 완성"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                고민되는 상품 운영은
                <br />
                몰파이에 맡기고 홍보에만 집중
              </h3>
              <p className="desc">
                내 상품이 없어도, 운영을 도와줄 직원이 없어도 <br />
                혼자서도 충분히 가능한 쇼핑몰 운영, <br />
                몰파이와 함께, 가장 자신있는 홍보에만 집중하세요!
              </p>
            </div>
            <div className="img">
              <img
                src={list2Img}
                alt="고민되는 상품 운영은 몰파이에 맡기고 홍보에만 집중 | 이미지"
                title="고민되는 상품 운영은 몰파이에 맡기고 홍보에만 집중"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                무엇을 원하든 <br />다 갖춘 쇼핑 카테고리
              </h3>
              <p className="desc">
                ‘어떤 상품으로 시작해야 할까? 내 콘텐츠와 맞는 상품이 있을까?’
                <br />
                고민했다면, 식품/패션/생활/가전 등 모든 카테고리를 커버하는
                <br />
                상품군과 디테일한 상품 추천을 만나세요.
              </p>
            </div>
            <div className="img">
              <img
                src={list3Img}
                alt="무엇을 원하든 다 갖춘 쇼핑 카테고리 | 이미지"
                title="무엇을 원하든 다 갖춘 쇼핑 카테고리"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                복잡한 조건·검수 없이
                <br />
                바로 개설 가능한 쇼핑몰
              </h3>
              <p className="desc">
                복잡한 승인과 검수 절차로 포기한 경험이 있나요? <br />
                몰파이에서는 복잡한 절차는 줄이고 간단한 가입만으로도 <br />
                지금 바로, 판매를 시작할 수 있어요.
              </p>
            </div>
            <div className="img">
              <img
                src={list4Img}
                alt="복잡한 조건 검수 없이 바로 개설 가능한 쇼핑몰 | 이미지"
                title="복잡한 조건 검수 없이 바로 개설 가능한 쇼핑몰"
              />
            </div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section2;
