/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router-dom';

interface FaqListData {
  title: string;
  id: number;
}

interface FaqListProps {
  list: FaqListData[];
}

const FaqList = ({ list }: FaqListProps) => {
  const { mq } = useTheme();
  const navigator = useNavigate();

  const FaqListStyle = css`
    display: flex;
    flex-direction: column;
    gap: 50px;
    li {
      padding-left: 42px;
      position: relative;
      width: 1000px;
      box-sizing: border-box;
      cursor: pointer;
      &::after {
        content: 'Q';
        display: block;
        width: 30px;
        height: 30px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        color: #98a2b3;
        background: #f2f4f7;
        text-align: center;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 3px;
      }
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }
    ${mq.mobile} {
      gap: 24px;
      li {
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
      }
    }
  `;

  return (
    <ul css={FaqListStyle}>
      {list.map(v => (
        <li onClick={() => navigator(`${v.id}`)} key={v.id}>
          {v.title}
        </li>
      ))}
    </ul>
  );
};

export default FaqList;
