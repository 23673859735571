/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import TextImg from 'static/promotion/section1/title.png';
import buttonImg from 'static/promotion/section1/button.png';
import list1Img from 'static/promotion/section1/list1.png';
import list2Img from 'static/promotion/section1/list2.png';
import list3Img from 'static/promotion/section1/list3.png';
import mTextImg from 'static/promotion/section1/m_title.png';
import mlist1Img from 'static/promotion/section1/m_list1.png';
import mlist2Img from 'static/promotion/section1/m_list2.png';
import mlist3Img from 'static/promotion/section1/m_list3.png';
import { Mobile, PC } from 'components/common/Responsive';

const Section1 = () => {
  const section1Style = css`
    .container {
      padding: 214px 40px 145px 40px;
      max-width: 1560px;
      margin: 0 auto;
      .title {
        .titleImg {
          width: 50%;
        }
        img {
          display: block;
        }
        .button {
          margin-top: 48px;
        }
      }
      .cont {
        display: flex;
        margin-top: 80px;
        justify-content: space-between;
        img {
          width: 33%;
        }
      }
    }
  `;

  const section1MoStyle = css`
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px 0 40px 0px;
      position: relative;
      img {
        width: 340px;
      }
      .titleMImg {
        width: 140px;
        margin: 30px 0px;
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={section1Style}>
          <div className="container">
            <div className="title">
              <img className="titleImg" src={TextImg} alt="text" />
              <img
                src={buttonImg}
                alt="button"
                className="button"
                onClick={() => window.open('https://my.mallpie.kr', '_blank')}
              />
            </div>
            <div className="cont">
              <img src={list1Img} alt="list1" />
              <img src={list2Img} alt="list2" />
              <img src={list3Img} alt="list3" />
            </div>
          </div>
        </div>
      </PC>
      <Mobile>
        <div css={section1MoStyle}>
          <div className="container">
            <img src={mTextImg} alt="title" className="titleMImg" />
            <img src={mlist1Img} alt="title" className="list1" />
            <img src={mlist2Img} alt="title" className="list2" />
            <img src={mlist3Img} alt="title" className="list3" />
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section1;
