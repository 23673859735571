/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/common/Button';
import Input from 'components/common/Input';

export interface PasswordFormValues {
  password?: string;
  passwordCheck?: string;
}

interface PasswordPageProps {
  onSubmit: SubmitHandler<PasswordFormValues>;
}
const ResetPasswordContent = ({ onSubmit }: PasswordPageProps) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordFormValues>({});

  const [active, setActive] = useState<boolean>(true);

  useEffect(() => {
    setActive(watch('passwordCheck') === '');
  }, [watch('passwordCheck')]);

  const PasswordPageStyle = css`
    margin: 0 auto;
    padding: 205px 0;
    max-width: 624px;
    form {
      .input + .input {
        margin-top: 24px;
      }
    }
    .code {
      text-align: right;
      margin-top: 8px;
      margin-left: auto;
      font-size: 11px;
      color: #ababab;
      text-decoration: underline;
    }
    button {
      margin-top: 20px;
    }
  `;

  return (
    <div css={PasswordPageStyle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="password"
          placeholder="비밀번호"
          label="비밀번호"
          show
          {...register('password', {
            required: true,
            pattern: {
              value: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,}$/,
              message: 'error',
            },
          })}
          validations={{
            value: watch('password'),
            list: [
              {
                regex: /^.{8,}$/,
                name: '8자이상',
              },
              {
                regex: /[a-zA-Z]+/,
                name: '영문',
              },
              {
                regex: /[0-9]+/,
                name: '숫자',
              },
              {
                regex: /[!@#$%^&*?]+/,
                name: '특수문자',
              },
            ],
          }}
        />

        <Input
          type="password"
          placeholder="비밀번호를 한번 더 입력해 주세요"
          label="비밀번호 확인"
          show
          {...register('passwordCheck', {
            required: true,
            validate: value =>
              watch('password') === value || '비밀번호가 일치하지 않습니다.',
          })}
          prefix={errors.passwordCheck?.message}
        />

        <Button color="gray" type="submit" fullWidth disabled={active}>
          비밀번호 변경 완료
        </Button>
      </form>
    </div>
  );
};

export default ResetPasswordContent;
