import Layout from 'components/common/Layout';
import Section1 from 'components/myshop/make/Section1';
import Section2 from 'components/myshop/make/Section2';
import Section3 from 'components/myshop/make/Section3';
import Section4 from 'components/myshop/make/Section4';
import Section5 from 'components/myshop/make/Section5';
import Section6 from 'components/myshop/make/Section6';
import Title from 'components/myshop/Title';
import TitleIcon from 'static/myshop/make/title_icon.png';

const Make = () => (
  <Layout theme="white">
    <Title
      title="만들기"
      titleAccent="쇼핑몰"
      img={TitleIcon}
      text={
        '나의 취향을 반영한 쇼핑몰을 손쉽게 만들 수 있어요.\n몰파이가 제공하는 다양한 분야의 상품으로 여러분만의 쇼핑몰을 완성해보세요.'
      }
      mText={
        '나의 취향을 반영한 쇼핑몰을 손쉽게 만들 수 있어요.\n몰파이가 제공하는 다양한 분야의 상품으로\n여러분만의 쇼핑몰을 완성해보세요.'
      }
    />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
  </Layout>
);

export default Make;
