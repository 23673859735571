/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import leftImg from 'static/myshop/style/section1_1.png';
import rightImg from 'static/myshop/style/section1_2.png';
import arrowDown from 'static/myshop/style/arrow_down.png';

const Section1 = () => {
  const { mq } = useTheme();

  const section1Style = css`
    text-align: center;
    padding-bottom: 110px;
    .title {
      font-size: 40px;
      font-weight: 600;
      line-height: 60px;
    }
    .text {
      font-size: 18px;
      margin-top: 12px;
      font-weight: 200;
      line-height: 28px;
    }
    .list {
      display: flex;
      margin-top: 100px;
      li {
        width: 50%;
        display: flex;
        align-items: center;
        &:nth-of-type(1) {
          padding-right: 110px;
          position: relative;
          img {
            width: 693px;
            max-width: 100%;
            margin-left: auto;
          }
          .arrow {
            img {
              width: 64px;
            }
            position: absolute;
            right: -32px;
            top: 50%;
            margin-top: -32px;
          }
        }
        &:nth-of-type(2) {
          img {
            width: 900px;
            margin-left: auto;
          }
        }
      }
    }

    ${mq.mobile} {
      padding-bottom: 80px;
      .title {
        font-size: 20px;
        line-height: 30px;
        padding: 0 32px;
      }
      .text {
        font-size: 14px;
        line-height: 21px;
        padding: 0 32px;
      }
      .list {
        flex-direction: column;
        margin-top: 12px;
        li {
          width: 100%;
          display: flex;
          padding: 40px 0px;
          align-items: center;
          &:nth-of-type(1) {
            position: relative;
            padding-right: 0;
            img {
              width: 80%;
              margin-right: auto;
              margin-left: 0;
            }
            .arrow {
              img {
                width: 40px;
              }
              position: absolute;
              right: 50%;
              margin-right: -20px;
              bottom: -20px;
              top: auto;
              transform: rotate(90deg);
            }
          }
          &:nth-of-type(2) {
            img {
              width: 100%;
              margin-left: auto;
            }
          }
        }
      }
    }
  `;
  return (
    <div css={section1Style}>
      <div className="title">
        내가 원하는 색상과 느낌으로
        <br />
        쇼핑몰의 개성을 쉽게 표현할 수 있습니다
      </div>
      <div className="text">
        손쉽게 대표 컬러를 설정하고, 이미지를 등록하여
        <br />
        나만의 차별화된 몰을 완성해보세요.
      </div>
      <ul className="list">
        <li>
          <img src={leftImg} alt="leftImg" />
          <div className="arrow">
            <img src={arrowDown} alt="ok" />
          </div>
        </li>
        <li>
          <img src={rightImg} alt="rightImg" />
        </li>
      </ul>
    </div>
  );
};

export default Section1;
