import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Context,
} from 'react';
import { useCookies } from 'react-cookie';
import { useFetchAccessCheck } from 'core/queries/user';
import { deCryaes } from 'utils/crypto';
import { queryClient } from 'App';

export interface UserContextValues {
  user: {
    accessToken: string;
    expires: number;
    needToChangePwYn: string;
    nickname: string;
    refreshToken: string;
    userBirth: string;
    userEml: string;
    userMobile: string;
    userNm: string;
    adultYn: string;
  };
  setUserData: (user: any) => void;
}

const contextDefaultValues: UserContextValues = {
  user: undefined,
  setUserData: () => {},
};

const UserContext = createContext(contextDefaultValues);

export const useUser = () => React.useContext(UserContext);

const UserProvider = ({ children, userInfo }: any) => {
  const [user, setUser] = useState(userInfo);
  const { mutate: fetchAccess } = useFetchAccessCheck();
  const [cookies, , removeCookie] = useCookies(['mallpie-user']);

  useEffect(() => {
    const loginData = cookies['mallpie-user'];
    if (loginData) {
      checkUser();
    }
  }, [cookies]);

  const checkUser = async () => {
    const loginData = cookies['mallpie-user'];

    if (!loginData) {
      return;
    }

    if (JSON.parse(deCryaes(loginData)).refreshToken !== null) {
      await fetchAccess(JSON.parse(deCryaes(loginData)).refreshToken, {
        onSuccess: response => {
          if (response.data === false) {
            removeCookie('mallpie-user', {
              path: '/',
              domain: process.env.REACT_APP_PUBLIC_URL,
            });
            window.location.replace('/');
            return;
          }
        },
        onError: (error: any) => {
          console.log(error);
        },
      });
    }

    setUser(JSON.parse(deCryaes(loginData)));
  };

  const setUserData = useCallback(
    data => {
      queryClient.removeQueries();
      setUser(data);
    },
    [user],
  );

  const contextValue: UserContextValues = useMemo(
    () => ({
      user,
      setUserData,
    }),
    [user],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
