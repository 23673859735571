import useAxiosAuth from 'hooks/useAxiosAuth';
import { useQuery, useInfiniteQuery } from 'react-query';
import { queryClient } from 'App';
import { PagingProps } from 'core/queries/type';
import {
  ReservesRequest,
  ReservesResponse,
  ReservesAllResponse,
  ExpireReservesResponse,
} from './type';
import { reservesKeys } from 'constants/queryKeys';

export const useReservesAll = () => {
  const axiosAuth = useAxiosAuth();
  return useQuery([...reservesKeys.point()], async () => {
    const { data } = await axiosAuth.get<ReservesAllResponse>(
      `/my-mallpie/reserves-all`,
    );
    return data.data;
  });
};

export const useReserves = (params: ReservesRequest) => {
  const axiosAuth = useAxiosAuth();
  return useQuery([...reservesKeys.lists()], async () => {
    const { data } = await axiosAuth.get<ReservesResponse>(
      `/my-mallpie/reserves`,
      { params },
    );
    return data.data;
  });
};

export const useExpireReserves = ({ page, size }: PagingProps) => {
  const axiosAuth = useAxiosAuth();
  return useInfiniteQuery(
    reservesKeys.expire(),
    async ({ pageParam = page }) => {
      const { data } = await axiosAuth.get<ExpireReservesResponse>(
        `/my-mallpie/reserves/disappear-prediction`,
        {
          params: {
            page: pageParam,
            size,
          },
        },
      );

      return data.data;
    },
    {
      getNextPageParam: lastPage =>
        lastPage.isLastPage ? undefined : lastPage.pageNum + 1,
      enabled: page !== 0,
    },
  );
};
