/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { css, keyframes, useTheme } from '@emotion/react';
import ReactModal from 'react-modal';
import Button from '../Button';

type ModalProps = {
  open: boolean;
  color?: 'white' | 'blue' | 'green' | 'gray' | 'black';
  enableButton?: boolean;
  onClose: () => void;
  onClick?: () => void;
  children: React.ReactNode;
};

const Modal = ({
  open = false,
  color = 'gray',
  enableButton = true,
  onClose,
  onClick,
  children,
}: ModalProps) => {
  const { mq } = useTheme();

  const show = keyframes`
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  `;

  const hide = keyframes`
    0%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  `;

  const scale = keyframes`
    0%{
      transform: scale(.9,.9) translate(-50%, -50%);
    }
    100%{
      transform: scale(1,1) translate(-50%, -50%);
    }
  `;

  const lowScale = keyframes`
    0%{
      transform: scale(1,1);
    }
    100%{
      transform: scale(.9,.9);
    }
  `;

  const overlayStyle = css`
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100vh;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    animation: ${open ? show : hide} 0.3s ease forwards;
  `;

  const modalChidrenStyle = css`
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${mq.mobile ? '80%' : '100%'};
    max-width: 480px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(30, 30, 30, 0.185);
    box-sizing: border-box;
    background: #fff;
    z-index: 10000;
    border: 0;
    animation: ${open ? scale : lowScale} 0.3s ease forwards;
    .title {
      padding: 8px 0px 12px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      &.close-icon {
        display: flex;
        justify-content: space-between;
      }
    }
    .content {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #909090;
    }
    button {
      padding: 12px 20px;
      margin-top: 16px;
    }
    .confirm {
      display: flex;
      button {
        width: 100%;
      }
      button + button {
        margin-left: 8px;
      }
    }
  `;

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      overlayElement={(props, contentElement) => (
        <div css={overlayStyle}>{contentElement}</div>
      )}
      contentElement={() => (
        <div css={modalChidrenStyle}>
          {children}
          {enableButton && (
            <Button color={color} fullWidth onClick={onClose}>
              확인
            </Button>
          )}
        </div>
      )}
    />
  );
};

export default Modal;
