/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import list1Img from 'static/company/section4_list1.png';
import list2Img from 'static/company/section4_list2.png';
import list3Img from 'static/company/section4_list3.png';
import list4Img from 'static/company/section4_list4.png';
import { motion } from 'framer-motion';

const Section4 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section4Style = css`
    padding-bottom: 200px;
    .container {
      width: 1352px;
      margin: 0 auto;
      > .title {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 76px;
        span {
          color: #87b0ca;
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 48px;
        li {
          width: calc(50% - 12px);
          background: linear-gradient(
            180deg,
            rgba(199, 199, 199, 0.2) 0%,
            rgba(199, 199, 199, 0.07) 100%
          );
          min-height: 250px;
          border-radius: 32px;
          padding: 36px;
          display: flex;
          align-items: flex-start;
          gap: 24px;
          img {
            width: 64px;
          }
          .text {
            .title {
              font-size: 28px;
              font-weight: 700;
              line-height: 130%;
            }
          }
          .desc {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 400;
            line-height: 150%;
            opacity: 0.7;
          }
        }
      }
    }
    ${mq.mobile} {
      padding-bottom: 96px;
      .container {
        width: 100%;
        padding: 0px 20px 48px 20px;
        > .title {
          font-size: 32px;
          line-height: 42px;
        }
        ul {
          gap: 20px;
          margin-top: 24px;
          li {
            width: 100%;
            min-height: auto;
            border-radius: 20px;
            padding: 20px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            gap: 24px;
            img {
              width: 64px;
            }
            .text {
              .title {
                font-size: 20px;
              }
              .desc {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  `;

  const XVariants = {
    offscreen: {
      x: -200,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 200,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div css={Section4Style}>
      <div className="container">
        <motion.h2
          initial="offscreen"
          whileInView="onscreen"
          variants={XVariants}
          className="title"
        >
          커머스 도입의 {isMobile && <br />}
          모든 고민을
          {isPc && <br />} 해결할 {isMobile && <br />}
          <span>올인원 커머스 시스템</span>
        </motion.h2>

        <ul>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
            transition={{ duration: isMobile ? 0.5 : 0.3 }}
          >
            <img
              src={list1Img}
              alt="가격 경쟁력을 갖춘 종합 카테고리 상품 보유 | 이미지"
              title="가격 경쟁력을 갖춘 종합 카테고리 상품 보유"
            />
            <div className="text">
              <h3 className="title">
                가격 경쟁력을 갖춘
                <br />
                종합 카테고리 상품 보유
              </h3>
              <p className="desc">
                전 카테고리 영역을 커버하는 2,800여개의 입점사를 보유하고 있으며
                온라인 최저가 상품, 시즌 및 이슈 기획전 등을 경쟁력 있게
                운영하고 있습니다.
              </p>
            </div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
            transition={{ duration: isMobile ? 0.5 : 0.6 }}
          >
            <img
              src={list2Img}
              alt="각 기업별 맞춤형 커머스를 위한 커스터마이징 | 이미지"
              title="각 기업별 맞춤형 커머스를 위한 커스터마이징"
            />
            <div className="text">
              <h3 className="title">
                각 기업별 맞춤형 커머스를 위한 <br />
                커스터마이징
              </h3>
              <p className="desc">
                각 기업 브랜드에 최적화하여 고객이 자연스럽게 쇼핑을 경험할 수
                있도록 브랜드 기존 아이덴티티에 맞게 로고 및 디자인 등을
                커스터마이징합니다.
              </p>
            </div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
            transition={{ duration: isMobile ? 0.5 : 0.9 }}
          >
            <img
              src={list3Img}
              alt="국내 대기업이 이미 선택한 안정적인 서비스 운영 | 이미지"
              title="국내 대기업이 이미 선택한 안정적인 서비스 운영"
            />
            <div className="text">
              <h3 className="title">
                국내 대기업이 이미 선택한
                <br />
                안정적인 서비스 운영
              </h3>
              <p className="desc">
                통신 및 금융사 등 이미 많은 고객을 보유하고 있는 국내 유수의
                기업들이 선택한 몰파이. 우리 기업에도 커머스를 시작해 보세요!
              </p>
            </div>
          </motion.li>
          <motion.li
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
            transition={{ duration: isMobile ? 0.5 : 1.2 }}
          >
            <img
              src={list4Img}
              alt="효율적 관리를 위한 기업용 커머스 관리자 시스템 | 이미지"
              title="효율적 관리를 위한 기업용 커머스 관리자 시스템"
            />
            <div className="text">
              <h3 className="title">
                효율적 관리를 위한
                <br />
                기업용 커머스 관리자 시스템
              </h3>
              <p className="desc">
                매출을 실시간으로 확인 할 수 있는 관리자 시스템을 통해,{' '}
                {isPc && <br />}
                데이터를 기반으로 판매 수익을 계속해서 개선시킬 수 있습니다.
              </p>
            </div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section4;
