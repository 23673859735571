/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ChangeEventHandler, forwardRef, useEffect, useState } from 'react';

interface TextAreaProps {
  className?: string;
  id?: string;
  label?: React.ReactNode;
  name?: string;
  placeholder?: string;
  showMaxLength?: boolean;
  maxLength?: number;
  value?: string;
  disabled?: boolean;
  prefix?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  testId?: string;
  count?: number;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      placeholder,
      maxLength,
      showMaxLength,
      className,
      name,
      id,
      onChange,
      prefix,
      label,
      disabled,
      count,
      ...textAreaProps
    },
    ref,
  ) => {
    const [inputCount, setInputCount] = useState(0);

    const onTextareaHandler = e => {
      if (onChange !== undefined) {
        onChange(e);
      }
      setInputCount(e.target.value.length);
    };

    useEffect(() => {
      if (count > 0) {
        setInputCount(count);
      } else {
        setInputCount(0);
      }
    }, [count]);

    const TextAreaStyle = css`
      position: relative;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      textarea {
        width: 100%;
        height: 120px;
        resize: none;
        margin-top: 12px;
        padding: 12px 16px;
        border: 1px solid #e1e1e1;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 4px;
        outline: none;
        &:focus {
          border-color: #6079ff;
        }
      }
      textarea::placeholder {
        color: #bdbdbd;
      }
      textarea:disabled {
        background-color: #f6f6f6;
        border: 1px solid #e1e1e1;
        color: #bdbdbd;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        > .textLength {
          display: flex;
          align-items: center;
          justify-content: end;
          margin-left: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #bdbdbd;

          span {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            color: #909090;
          }
        }
        > .prefix {
          display: block;
          margin-top: 4px;
          line-height: inherit;
          pointer-events: none;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #fb3b3b;
          padding-left: 20px;
          position: relative;
          ::after {
            content: '';
            display: block;
            width: 5px;
            height: 9px;
            border-bottom: 1px solid #fb3b3b;
            border-right: 1px solid #fb3b3b;
            transform: rotate(45deg);
            position: absolute;
            left: 6px;
            top: 4px;
          }
        }
      }
    `;

    return (
      <div css={TextAreaStyle} className={`${className} textarea`} id={id}>
        {label && label}
        <textarea
          ref={ref}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={onTextareaHandler}
          disabled={disabled}
          {...textAreaProps}
        />
        <div className="bottom">
          {prefix && <span className="prefix">{prefix}</span>}
          {showMaxLength && (
            <div className="textLength">
              <span>{inputCount}</span>/{maxLength}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default TextArea;
