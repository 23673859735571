/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import {
  useIsMobile,
  useIsMobileSmall,
  useIsPc,
} from 'core/hooks/useResponsive';

import { ReactComponent as MagicWand } from 'static/common/magic_wand.svg';
import list1Img from 'static/personal/section2_list1.png';
import list2Img from 'static/personal/section2_list2.png';
import list3Img from 'static/personal/section2_list3.png';
import list4Img from 'static/personal/section2_list4.png';
import { motion } from 'framer-motion';

const Section2 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isMobileSmall = useIsMobileSmall();
  const isPc = useIsPc();
  const sectionStyle = css`
    display: flex;
    justify-content: center;
    background: #353535;
    .container {
      max-width: 1200px;
      padding: 180px 0px;

      > .title {
        color: #fff;
        text-align: center;
        font-size: 56px;
        font-weight: 800;
        line-height: 76px;
        margin-bottom: 56px;
        span {
          color: #6079ff;
          font-size: 56px;
          font-weight: 800;
          line-height: 76px;
        }
      }
      .list {
        li {
          margin-top: 20px;
          background: #fff;
          border-radius: 36px;
          display: flex;
          width: 1200px;
          height: 564px;
          padding-left: 64px;
          justify-content: space-between;
          align-items: center;
          .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            .title {
              font-size: 32px;
              font-weight: 700;
              line-height: 48px;
              margin-bottom: 20px;
              > span {
                color: #6079ff;
              }
            }
            .desc {
              color: #444;
              font-size: 18px;
              font-weight: 400;
              line-height: 30px;
            }
          }
          .img {
            overflow: hidden;
            width: 564px;
            img {
              width: 100%;
            }
          }
          &:nth-of-type(1) {
            margin-top: 0;
            background: #6079ff;
            .text {
              .title {
                color: #fff;
              }
              .desc {
                color: #fff;
                opacity: 0.6;
              }
            }
          }
          &:nth-of-type(2) {
            padding-left: 0;
            background: #fff;
            flex-direction: row-reverse;
            padding-right: 70px;
          }
          &:nth-of-type(3) {
            padding-left: 64px;
            background: #dae0ff;
            .text {
              .title {
                color: #22307e;
              }
              .desc {
                color: #22307e;
                opacity: 0.7;
              }
            }
          }
          &:nth-of-type(4) {
            padding-left: 0;
            background: #d3e4f4;
            flex-direction: row-reverse;
            padding-right: 132px;
          }
        }
      }
    }
    ${mq.mobile} {
      .container {
        padding: 96px 20px;
        width: 100%;
        > .title {
          font-size: 28px;
          font-weight: 800;
          line-height: 40px;
          margin-bottom: 32px;
          span {
            font-size: 28px;
            line-height: 40px;
          }
        }
        .list {
          li {
            margin-top: 20px;
            border-radius: 20px;
            width: 100%;
            height: auto;
            padding: 0 !important;
            gap: 0;
            flex-direction: column !important;
            align-items: flex-start;
            .text {
              padding: 36px 30px 0px 30px !important;
              .title {
                color: #000;
                font-size: 24px;
                font-weight: 800;
                line-height: 32px;
                letter-spacing: -0.24px;
              }
              .desc {
                color: #444;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
              }
            }
            .img {
              width: 100%;
            }
          }
        }
      }
    }
  `;

  const defaultVariants = {
    offscreen: {
      scale: 0.7,
      opacity: 0,
    },
    onscreen: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={sectionStyle}>
      <div className="container">
        <h2 className="title">
          몰파이로 누구나 쉽게
          <br />
          <span>
            상품 없이 {isPc && '나만의'} 쇼핑몰을 {isMobileSmall && <br />}{' '}
            시작하세요
          </span>
        </h2>
        <ul className="list">
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                학습없이 빠르고 간편한
                <br />
                쇼핑몰 개설
              </h3>
              <p className="desc">
                쇼핑몰 개설, 남 얘기로만 생각했었나요?
                <br />
                누군가의 도움이나 학습 없이도 클릭 몇 번이면 <br />
                직접 만든 쇼핑몰을 완성할 수 있어요.
              </p>
            </div>
            <div className="img">
              <img
                src={list1Img}
                alt="학습없이 빠르고 간편한 쇼핑몰 개설 | 이미지"
                title="학습없이 빠르고 간편한 쇼핑몰 개설"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                필요한 상품 구매했는데
                <br />
                수익이 생기는 쇼핑
              </h3>
              <p className="desc">
                자주 구매하는 생수나 휴지, 할인 구매에만 만족했나요?
                <br />
                이제는 상품 구매하고 수익까지 돌려받는 더 만족스러운
                <br />
                쇼핑을 시작하세요!
              </p>
            </div>
            <div className="img">
              <img
                src={list2Img}
                alt="필요한 상품 구매했는데 수익이 생기는 쇼핑 | 이미지"
                title="필요한 상품 구매했는데 수익이 생기는 쇼핑"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                가족, 친구에게 공유하는
                <br />
                최저가 혜택
              </h3>
              <p className="desc">
                평소 좋은 정보 알리기에 적극적이라면!
                <br />
                우리 가족, 친구, 주변 지인에게 공유할 쇼핑몰을
                {isMobile && <br />} 만들고 {isPc && <br />}
                소소하게 번 수익으로 보답하는 건 어때요?
              </p>
            </div>
            <div className="img">
              <img
                src={list3Img}
                alt="가족 친구에게 공유하는 최저가 혜택 | 이미지"
                title="가족 친구에게 공유하는 최저가 혜택"
              />
            </div>
          </motion.li>
          <motion.li
            className="main"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ amount: 0.2 }}
            variants={defaultVariants}
          >
            <div className="text">
              <h3 className="title">
                걸어서 받는 10원 말고,
                <br />
                판매가의 <span>최대 40% 수익</span>
              </h3>
              <p className="desc">
                매일 걸어서 10원씩 받는 앱 테크 말고,
                <br />
                내가 구매한 상품의 최대 40%까지 돌려받는
                <br />
                확실한 수익의 몰파이를 시작하세요.
              </p>
            </div>
            <div className="img">
              <img
                src={list4Img}
                alt="걸어서 받는 10원 말고 판매가의 최대 40% 수익 | 이미지"
                title="걸어서 받는 10원 말고 판매가의 최대 40% 수익"
              />
            </div>
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Section2;
