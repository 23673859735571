import { css, useTheme } from '@emotion/react';
import { useParcel } from 'core/queries/mypage/purchase/parcel';
import Modal from 'react-modal';
import { ReactComponent as Close } from 'static/user/mypage/close.svg';
import { parcelCompany } from 'utils/parcelCompany';
import { ReactComponent as Package3 } from 'static/user/mypage/package3.svg';
import { ReactComponent as MapPin2 } from 'static/user/mypage/mapPin2.svg';
import { ReactComponent as Truck2 } from 'static/user/mypage/truck2.svg';
import { ReactComponent as Check2 } from 'static/user/mypage/check2.svg';
import { ReactComponent as Line } from 'static/user/mypage/line.svg';
import { useEffect } from 'react';
/** @jsxImportSource @emotion/react */

interface parcelPopupProps {
  coCd: string;
  invoice: string;
  onOpen: boolean;
  onClose: () => void;
}

const ParcelPopup = ({ coCd, invoice, onOpen, onClose }: parcelPopupProps) => {
  const { mq } = useTheme();
  const { data } = useParcel(invoice, coCd);

  const isSmallScreen = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth <= 425;
    }
    return false;
  };

  const customModalStyles: ReactModal.Styles = {
    overlay: {
      backgroundColor: ' rgba(34, 34, 34, 0.2)',
      width: '100%',
      height: '100vh',
      zIndex: '10',
      position: 'fixed',
      top: '0',
      left: '0',
    },
    content: {
      width: isSmallScreen() ? '90%' : '520px',
      height: '650px',
      maxHeight: 'calc(100% - 200px)',
      zIndex: '150',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#ffffff',
      justifyContent: 'center',
      overflow: 'auto',
      padding: 0,
    },
  };

  const wrapperStyle = css`
    padding: 32px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .info-wrapper {
      border-radius: 4px;
      border: 1px solid var(--Gray-gray-200, #f2f2f2);
      background-color: #fafafa;
      padding: 20px;
      display: grid;
      gap: 10px;
      .info-box {
        display: flex;
        gap: 10px;
        font-size: 14px;
        .title {
          color: #909090;
        }
      }
    }
    .stepWrap {
      width: fit-content;
      margin: 0 auto;

      .icon {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stepIcon {
          width: 40px;
          height: 40px;
          border-radius: 100px;
          background-color: #90a4b4;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .stepIcon.active {
          background-color: #6079ff;
        }
      }
      .text {
        display: flex;
        font-size: 12px;
        margin-top: 10px;
        gap: 20px;
        .active {
          font-weight: 500;
        }
      }

      ${mq.mobile} {
        .line {
          width: 10px;
          display: none;
        }
        .icon {
          .stepIcon {
            width: 30px;
            height: 30px;
          }
          .stepIcon.active {
            background-color: #6079ff;
          }
        }
        .text {
          display: flex;
          font-size: 11px;
          margin-top: 10px;
          .active {
            font-weight: 500;
          }
        }
      }
    }
    .statusWrap {
      border-top: 1px solid gray;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .status {
        display: flex;
        flex-direction: column;
        color: gray;
        gap: 10px;
        span {
          font-size: 14px;
          font-weight: 700;
        }
        strong {
          font-size: 16px;
          font-weight: 700;
        }
        p {
          font-size: 12px;
          font-weight: 400;
          color: gray;
        }
      }

      .status + .status {
        margin-top: 10px;
      }
      .active {
        span {
          color: black;
        }
        strong {
          color: #6079ff;
        }
      }
    }
  `;

  return (
    <div>
      <Modal
        isOpen={onOpen}
        style={customModalStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
      >
        <div css={wrapperStyle}>
          <div className="title-wrapper">
            <p>배송 조회</p>
            <Close onClick={onClose} style={{ cursor: 'pointer' }} />
          </div>
          <div className="info-wrapper">
            <div className="info-box">
              <p className="title">운송장번호</p>
              <p className="content">{invoice}</p>
            </div>
            <div className="info-box">
              <p className="title">택배사</p>
              <p className="content">{parcelCompany(coCd)}</p>
            </div>
          </div>

          <div className="stepWrap">
            <div className="icon">
              <div
                className={
                  data?.data.lastStateDetail?.level === 1 ||
                  data?.data.lastStateDetail?.level === 2
                    ? 'active stepIcon'
                    : 'stepIcon'
                }
              >
                <Package3 />
              </div>
              <Line className="line" />
              <div
                className={
                  data?.data.lastStateDetail?.level === 3
                    ? 'active stepIcon'
                    : 'stepIcon'
                }
              >
                <Truck2 />
              </div>
              <Line className="line" />
              <div
                className={
                  data?.data.lastStateDetail?.level === 4
                    ? 'active stepIcon'
                    : 'stepIcon'
                }
              >
                <MapPin2 />
              </div>
              <Line className="line" />
              <div
                className={
                  data?.data.lastStateDetail?.level === 5
                    ? 'active stepIcon'
                    : 'stepIcon'
                }
              >
                <Truck2 />
              </div>
              <Line className="line" />
              <div
                className={
                  data?.data.lastStateDetail?.level === 6
                    ? 'active stepIcon'
                    : 'stepIcon'
                }
              >
                <Check2 />
              </div>
            </div>
            <div className="text">
              <p
                className={
                  data?.data.lastStateDetail?.level === 1 ||
                  data?.data.lastStateDetail?.level === 2
                    ? 'active'
                    : null
                }
              >
                상품인수
              </p>
              <p
                className={
                  data?.data.lastStateDetail?.level === 3 ? 'active' : null
                }
              >
                상품이동중
              </p>
              <p
                className={
                  data?.data.lastStateDetail?.level === 4 ? 'active' : null
                }
              >
                배송지도착
              </p>
              <p
                className={
                  data?.data.lastStateDetail?.level === 5 ? 'active' : null
                }
              >
                배송출발
              </p>
              <p
                className={
                  data?.data.lastStateDetail?.level === 6 ? 'active' : null
                }
              >
                배송완료
              </p>
            </div>
          </div>
          <div className="statusWrap">
            {data &&
              data?.data?.trackingDetails &&
              data?.data?.trackingDetails
                .slice()
                .reverse()
                .map((v, k) => (
                  <div
                    key={k}
                    className={
                      data?.data.lastStateDetail?.level === v.level
                        ? 'active status'
                        : 'status'
                    }
                  >
                    <span>{v.where}</span>
                    <strong>{v.kind}</strong>
                    <p>{v.timeString}</p>
                  </div>
                ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ParcelPopup;
