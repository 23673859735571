/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useIsMobile, useIsPc } from 'core/hooks/useResponsive';
import { motion } from 'framer-motion';
import { SwiperSlide, Swiper } from 'swiper/react';

const Section1 = () => {
  const { mq } = useTheme();
  const isMobile = useIsMobile();
  const isPc = useIsPc();

  const Section1Style = css`
    margin-top: 60px;
    padding: 0px 60px;
    min-width: 1352px;
    .box {
      border-radius: 48px;
      background: linear-gradient(180deg, #fff 0%, #e4f2fb 100%);
      padding: 160px 0px 160px 60px;
      .container {
        margin: 0 auto;
        > .title {
          text-align: center;
          font-size: 48px;
          font-weight: 700;
          line-height: 64px;
          span {
            display: block;
            color: #72aed3;
          }
        }
        ul {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          gap: 24px;
          padding-right: 60px;
          max-width: 1352px;
          li {
            width: 320px;
            padding: 30px;
            border-radius: 12px;
            background-color: #fff;
            .icon {
              color: #69758c;
              font-size: 34px;
              font-style: normal;
              font-weight: 700;
              line-height: 34px; /* 100% */
            }
            .text {
              color: #69758c;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 30px;
              span {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    ${mq.mobile} {
      margin-top: 20px;
      padding: 0px 20px;
      min-width: 0;
      .box {
        border-radius: 48px;
        background: linear-gradient(180deg, #fff 0%, #e4f2fb 100%);
        padding: 80px 0px 80px 20px;
        .container {
          > .title {
            font-size: 28px;
            line-height: 40px;
          }
          ul {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding-right: 20px;
            li {
              width: 246px;
              padding: 20px;
              border-radius: 12px;
              background-color: #fff;
              .icon {
                color: #69758c;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px; /* 150% */
              }
              .text {
                color: #69758c;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                span {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  `;

  const xVariants = {
    offscreen: {
      x: 300,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        duration: isMobile ? 1.5 : 0.7,
      },
    },
  };

  const yVariants = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div css={Section1Style}>
      <div className="box">
        <div className="container">
          <motion.div
            className="title"
            initial="offscreen"
            whileInView="onscreen"
            variants={yVariants}
          >
            <span>이런 고민을 하고 있다면,</span>
            이제 몰파이 복지몰과 {isMobile && <br />}
            함께 하세요
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            variants={xVariants}
          >
            <Swiper
              className="list"
              tag="ul"
              slidesPerView="auto"
              spaceBetween={isPc ? 24 : 12}
            >
              <SwiperSlide tag="li">
                <div className="icon">“</div>
                <div className="text">
                  <span>구축 비용부터 까다로운 조건</span>까지
                  <br /> 도입이나 변경을 망설이게 하는 <br /> 조건들이 많아요
                </div>
              </SwiperSlide>

              <SwiperSlide tag="li">
                <div className="icon">“</div>
                <div className="text">
                  <span>작은 규모와 예산의 기업</span>도
                  <br /> 간편하게 운영할 수 있는 <br /> 복지몰이 있었으면
                  좋겠어요
                </div>
              </SwiperSlide>
              <SwiperSlide tag="li">
                <div className="icon">“</div>
                <div className="text">
                  복지몰 관리 및 운영에 매달
                  <br />
                  들어가는 <span>인적, 물적 비용들</span>이
                  <br />
                  소모적이고 부담이 많이 돼요
                </div>
              </SwiperSlide>
              <SwiperSlide tag="li">
                <div className="icon">“</div>
                <div className="text">
                  이용 중인 복지몰의 상품군이
                  <br />
                  적고 가격 이점이 없어서, <span>정말</span> <br />
                  <span>혜택 많은 복지몰</span>을 찾아요
                </div>
              </SwiperSlide>
            </Swiper>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
